import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Filters,
    name: "Events filter",
    description: "Filters events from the [Events AIR] widget.\n• It can only be added to the page once.\n• It must be added after the [Subject filter] widget.",
    constrains: {
        once: true,
        onlyAfter: WidgetType.FilterByQualification,
    }
})
export class EventsFilterWidget extends BaseWidget {

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.EventFilter
    }

    editable(): boolean {
        return false;
    }
}