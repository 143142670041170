import {Types, UUID} from "../types";
import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {formField} from "../../utils/decorators";
import {fi} from "../../utils/helpers";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import React, {ReactElement} from "react";
import {Faded, PreviewRow, PreviewWrapper, Regular} from "./_style";
import {ContentType} from "./ContentType";
import {Strings} from "../../utils/strings";
import {FieldType} from "./__ModelInfo";

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Content Type",
    description: "Displays a list of items matching the selected [Content Type].",
})
export class ContentTypeWidget extends BaseWidget {
    // content type selected to be displayed
    @formField({
        fieldtype: FieldType.Reference,
        order: 1,
        name: "Select content type",
        flags: {required: true},
        config: {refModel: Types.CONTENT_TYPE}
    })
    public contentType: UUID;

    // whether to show the title of the content group
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Show title",
        group: "display-options",
        order: 2,
        groupTitle: "Display options"
    })
    public showTitle: boolean;
    // whether to show the description of the content group
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Show description",
        group: "display-options"
    })
    public showDescription: boolean;
    // sort order of the child groups
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Sort descending",
        group: "display-options"
    })
    public showDescending: boolean;

    // group child groups by card units or components
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Units or components",
        group: "group-by",
        groupTitle: "Group by"
    })
    public byUnits: boolean;

    constructor(item: any = {}) {
        super(item);

        this.type = WidgetType.ContentType;

        this.contentType = item.contentType;
        this.byUnits = Boolean(item.byUnits);
        this.showDescription = Boolean(item.showDescription);
        this.showTitle = Boolean(item.showTitle);
        this.showDescending = Boolean(item.showDescending) || Boolean(item.sortDescending);
    }

    getTitle(): ReactElement | string {
        const title = super.getTitle();
        const contentType = getRecoil(references(this.contentType))
        if (!contentType) {
            return title
        }

        return <>{title} <Regular> :: {contentType.displayLabel()}</Regular></>
    }

    preview(): ReactElement | null {
        const contentType = getRecoil(references(this.contentType)) as ContentType
        if (!contentType) {
            return null
        }

        return (
            <PreviewWrapper>
                <PreviewRow className='vertical'>
                    <label>{contentType.displayLabel()}</label>
                    <span>{Strings.default(contentType.description, '-')}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Display options</label>
                    {fi(this.showTitle, <span>Show title</span>, <Faded>Show title</Faded>)} /
                    {fi(this.showDescription, <span>Show description</span>, <Faded>Show description</Faded>)} /
                    {fi(this.showDescending, <span>Sort descending</span>, <Faded>Sort descending</Faded>)} /
                    {fi(this.byUnits, <span>Group by units</span>, <Faded>Group by units</Faded>)}
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}