import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {sessionAtom} from '../../state/session';
import {fi} from '../../utils/helpers';
import {Messages} from '../../utils/messages';
import {Strings} from '../../utils/strings';
import Client from '../client';
import {CMSObject, DisplayMode} from './__CMSObject';
import {columnDefinition, formField, linkedFormField, tableColumn, tableConfig} from '../../utils/decorators';
import {ColumnType} from '../../components/TableComponent/renderers';
import {Types} from '../types';
import {pagesAtom} from '../../state/state';
import {Page} from './Page';
import {FieldType} from './__ModelInfo';
import {FormEvent, IFormEvents} from '../../components/Form/model';

@tableConfig({
	initialLoad: async () => {
		// We need to load pages and content groups before the table loads,
		// so we can display on whgat pages is the content type referenced on
		const res = await Client.query<CMSObject>({
			types: [Types.PAGE, Types.CONTENT_GROUP],
			limit: -1,
		});
		setRecoil(pagesAtom, res.results.filter(i => i.getType() === Types.PAGE).map(i => i as Page));
	},
})
export class ContentType extends CMSObject implements IFormEvents {
	public static title = 'Content type';
	public static autoPublish = true;

	@columnDefinition({
		label: 'Title',
	})
	public name: string;
	public description: string;
	public rateable_content: boolean;
	public keyword: string;

	@formField({
		fieldtype: FieldType.TagsList,
		name: 'Display order',
		order: 10,
		flags: {multiple: true},
	})
	@linkedFormField('name')
	public displayOrder: string[] = [];

	constructor(obj: any = {}) {
		super(obj);

		this.name = Strings.default(obj.name);
		this.description = Strings.default(obj.description);
		this.rateable_content = fi(typeof obj.rateable_content === 'undefined', true, Boolean(obj.rateable_content));
		this.keyword = Strings.default(obj.keyword);
	}

	public canCreate(): string {
		const user = getRecoil(sessionAtom);
		return fi(!user?.isPublisher(), 'You do not have permission to create content types.', '');
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom);
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete;
		}
		const usage = await Client.checkUsage(this.getId());
		if (usage.items_count > 0) {
			return Messages.CantDeletePublishedAssociations;
		}
		return '';
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const tmp = {...evt.state.values};
		delete tmp.displayOrder;
		return tmp;
	}

	public formOnSaveError(evt: FormEvent): Error | void {
		if (evt.error.message.toLowerCase().includes('value already exists: name')) {
			evt.state.setError('name', Messages.DuplicateValue);
			evt.error.message = Messages.FixErrors;
		}
		return evt.error;
	}

	@tableColumn({
		order: 3.5,
		label: 'Referenced on',
		field: 'referenced_on',
		type: ColumnType.Text,
	})
	public __referencedOn(_plain?: boolean): string | React.ReactNode {
		const pages = getRecoil(pagesAtom);
		const res = pages.filter(p => p.hasContentType(this.getId())).map(p => p.displayLabel());
		return res.join(', ');
	}
}
