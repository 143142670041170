import React, {ReactComponentElement} from 'react';
import styled from '@emotion/styled';
import SearchOff from '@mui/icons-material/SearchOff';
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import {ReactComponent as UnauthorizedError} from '../../assets/images/page-not-found.svg';
import {Messages} from '../../utils/messages';
import Header from '../../layout/Header';

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color-background);
	flex-direction: column;
`;

const ErrorContent = styled.div`
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
	
    svg {
        height: 80px;
        width: 80px;
        margin-bottom: 24px;
    }

    div {
        padding-right: 8px;
        margin-left: 8px;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        font-family: var(--font-semi-bold);
        text-align: center;
        color: var(--color-monocrome);

        &:first-of-type {
            margin-bottom: 16px;
        }
    }
`;

type Error = {
	code: number,
	title: string | any,
	content: string | any,
	icon: ReactComponentElement<any>
}

const errorMap: { [key: string]: Error } = {
	'error-unauthorized': {
		code: 401,
		title: <p>401 - Unauthorised</p>,
		content: Messages.UnauthorizedError,
		icon: <UnauthorizedError color={'disabled'}/>,
	},
	'error-not-found': {
		code: 404,
		title: <p>404 - Not found</p>,
		content: Messages.NotFoundError,
		icon: <SearchOff color={'disabled'}/>,
	},
	'error-unknown': {
		code: 500,
		title: <p>500 - Server error</p>,
		content: Messages.UnknownError,
		icon: <WarningAmberOutlined color={'disabled'}/>,
	},
};

const ErrorPageComponent = () => {
	let error = window.location.pathname;
	error = error.substring(error.lastIndexOf('/') + 1);
	return (
		<Wrapper>
			<Header/>
			<ErrorContent>
				{errorMap[error].icon}
				<div>
					{errorMap[error].title}
					{errorMap[error].content}
				</div>
			</ErrorContent>
		</Wrapper>
	);
};

export default ErrorPageComponent;