import styled from '@emotion/styled';
import React, {useMemo, useRef, useState} from 'react';
import {Numbers} from '../../../../../utils/numbers';
import TextAction from '../../../../commons/TextAction';
import {Strings} from '../../../../../utils/strings';
import {CMSFile} from '../../../../../cms/models/File';
import {cancelEvent, fi} from '../../../../../utils/helpers';
import Client from '../../../../../cms/client';
import {CancelTokenSource} from 'axios';

const FileUploadWrapper = styled.div`
    user-select: none;
    border: 1px solid var(--color-border);
    background: white;
    padding: 16px;
    border-radius: 4px;
	
	&.error {
		border: 1px solid var(--color-red);
	}
`;

const DropZone = styled.div`
    border: 2px dashed var(--color-border-light);
    padding: 16px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    position: relative;

    .dropError, .error {
        color: var(--color-red);
        font-weight: bold;
        display: none;
        position: absolute;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 239, 239, 0.77);
    }

    .error {
        display: flex;
    }

    &.over {
        border-color: var(--color-blue);

        & > * {
            pointer-events: none;
        }

        &.over-error {
            border-color: var(--color-red);

            .dropError {
                display: flex;
            }
        }
    }

    input {
        display: none;
    }
`;

const Right = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    label {
        color: var(--color-blue);
		cursor: pointer;
    }

    span {
        font-size: 14px;

        &:first-of-type {
            font-weight: bold;
            font-size: 16px;
        }
    }
`;

const ProgressWrapper = styled.div`
    color: var(--color-border-light);

    .idle {
        transition: all 0.2s ease-in;
    }
	.success, .error, .cancel {
		fill: var(--color-green);
		transform: scale(1.5) translate(7px, 10px); // scale(1.5) translate(7px, 7px)
		opacity: 0;
		transition: all 0.2s ease-in;
	}
    .cancel {
		cursor: pointer;
		fill: var(--color-black);
	}
    .error {
		fill: var(--color-red);
	}
	
	&.uploading {
        .idle {
            transform: scale(1.5) translate(7px, 4px);
            opacity: 0;
        }
        .cancel {
            opacity: 1;
            transform: scale(1.5) translate(7px, 7px);
        }
	}
	
	&.successful {
		.idle {
            transform: scale(1.5) translate(7px, 4px);
            opacity: 0;
		}
		.success {
			opacity: 1;
            transform: scale(1.5) translate(7px, 7px);
		}
	}

    &.failed {
        .idle {
            transform: scale(1.5) translate(7px, 4px);
            opacity: 0;
        }
        .error {
            opacity: 1;
            transform: scale(1.5) translate(7px, 7px);
        }
    }
	
    svg {
        circle {
            stroke: var(--color-border-light);
            stroke-width: 3px;
        }

        circle.bar {
            stroke: var(--color-blue);
            stroke-dasharray: 175;
            stroke-dashoffset: 175;
            opacity: 0;
        }

        path {
            fill: var(--color-border-light);
            stroke: var(--color-border-light);
            stroke-width: 0;
        }
    }
`;

type FileUploadProps = {
	maxSize?: number;
	accept?: string;
	multiple?: boolean;
	error?: boolean;
	uploadURL?: string;
	onUpload: (files: CMSFile[]) => void;
}

const FileUpload = (props: FileUploadProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const progressRef = useRef<SVGCircleElement>(null)
	const [dropError, setDropError] = useState('');
	const [error, setError] = useState('');
	const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);
	const [uploadState, setUploadState] = useState('idle');

	const maxSize = Numbers.default(props.maxSize, 10000000);
	const accept = Strings.default(props.accept, '*').split(',').map(type => type.trim().replaceAll('.', ''));

	const types = useMemo(() => {
		const unique = Array.from(new Set(accept.map(ext => CMSFile.ExtensionNames[`.${ext}`]).filter(a => a)));
		unique.sort();
		return unique.join(', ');
		// eslint-disable-next-line
	}, [props]);

	const validate = (event: React.DragEvent<HTMLDivElement>): string => {
		if (event.dataTransfer.items.length > 1 && props.multiple !== true) {
			return 'Only one file can be uploaded';
		}
		for (let i = 0; i < event.dataTransfer.items.length; i++) {
			const item = event.dataTransfer.items[i];
			if (item.kind !== 'file') {
				return 'Only files allowed';
			}
			const ext = CMSFile.ContentTypeExtensions[item.type];
			if (!ext || (!accept.includes(ext) && !accept.includes('*'))) {
				return 'File type not allowed';
			}
		}
		return '';
	};

	const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
		cancelEvent(event);
		ref.current?.classList.add('over');
		if (event.dataTransfer.items.length === 0) {
			return;
		}
		const error = validate(event);
		setDropError(error);
		if (error) {
			ref.current?.classList.add('over-error');
		} else {
			ref.current?.classList.remove('over-error');
		}
		event.dataTransfer.dropEffect = 'none';
	};

	const clearErrors = () => {
		ref.current?.classList.remove('over');
		ref.current?.classList.add('over-error');
		setError('')
	};

	const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		cancelEvent(event);
		clearErrors();
	};

	const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		cancelEvent(event);

	};

	const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
		cancelEvent(event);
		if (validate(event)) {
			return;
		}
		const files: File[] = [];
		for (let i = 0; i < event.dataTransfer.files.length; i++) {
			const file = event.dataTransfer.files[i];
			if (file.size > maxSize) {
				setError('File size exceeds maximum allowed size');
				return;
			}
			files.push(file);
		}

		if (files.length === 0) {
            return;
        }

		progressRef.current!.style.opacity = '0';
		progressRef.current!.style.strokeDashoffset = '175'

		if (!props.multiple && files.length) {
			uploadFile(files[0]);
		}

		clearErrors();
	};

	const onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			uploadFile(event.target.files[0]);
			event.target.value = '';
		}
	}

	const uploadFile = (file: File): void => {
		const [upload, cancel] = Client.upload(file, (progress => {
			const percentage = progress.percent * 175 / 100
			progressRef.current!.style.opacity = '1';
			progressRef.current!.style.strokeDashoffset = `${175 - percentage}`;
		}), props.uploadURL)
		setError('');
		setUploadState('uploading');
		setCancelToken(cancel);

		upload.then(res => {
			setUploadState('successful');
			setTimeout(() => {
				setUploadState('idle');
			}, 2000)
			props.onUpload([res]);
		}).catch(err => {
			setError(err.toString());
			setUploadState('failed');
			setTimeout(() => {
				setUploadState('idle');
			}, 2000)
		}).finally(() => {
			setCancelToken(null);
			progressRef.current!.style.opacity = '0';
			progressRef.current!.style.strokeDashoffset = '175'
		})
	}

	const cancelUpload = () => {
		if (cancelToken) {
			cancelToken.cancel();
			setCancelToken(null);
		}
	}

	return (
		<FileUploadWrapper className={fi(props.error, 'error')}>
			<DropZone ref={ref} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDragOver={onDragOver}
					  onDrop={onDrop}>
				<ProgressWrapper className={uploadState}>
					<svg className='progress' width='56' height='56' viewBox='-2 -2 60 60' version='1.1'
						 xmlns='http://www.w3.org/2000/svg'>
						<circle cx='28' cy='28' r='28' fill='none' />
						<circle ref={progressRef} className='bar' cx='28' cy='28' r='28' fill='none'
								transform='rotate(-90, 28, 28)' />
						<path className='idle'
							d='M7.4 10h1.59v5c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-5h1.59c.89 0 1.34-1.08.71-1.71L12.7 3.7a.9959.9959 0 0 0-1.41 0L6.7 8.29c-.63.63-.19 1.71.7 1.71zM5 19c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1z'
							transform='translate(10,10) scale(1.5)'></path>
						<path className='success' d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
						<path className='error' d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
						<path className='cancel' aria-label='Cancel upload' onClick={cancelUpload} d="M6 6h12v12H6z"></path>
					</svg>
				</ProgressWrapper>
				<Right>
					<span>Drag and drop file or <label htmlFor='browse-files'>Browse your computer</label></span>
					<span>
						Supported file types: {types}.
					</span>
					<span>
						The maximum file size is {Numbers.asFileSize(props.maxSize, false)}.
					</span>
					<input type='file' id='browse-files' onChange={onFileChanged} accept={'.' + accept.join(',.')} />
					<div className='dropError'>
						{dropError}
					</div>
					{error && (
						<div className='error flex-column'>
							<p>{error}</p><br />
							<TextAction onClick={clearErrors}>Close</TextAction>
						</div>
					)}
				</Right>
			</DropZone>
		</FileUploadWrapper>
	);
};

export default FileUpload;