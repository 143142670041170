import tinymce from 'tinymce/tinymce';

tinymce.PluginManager.add('infopanel', function (editor, _url) {
	editor.ui.registry.addIcon('infoicon', '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
		'<path fill-rule="evenodd" clip-rule="evenodd" d="M20 10C20 15.52 15.53 20 10.01 20C4.48 20 0 15.52 0 10C0 4.48 4.48 0 10.01 0C15.53 0 20 4.48 20 10ZM9 14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14L11 10C11 9.45 10.55 9 10 9C9.45 9 9 9.45 9 10L9 14ZM10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2ZM11 7V5H9V7H11Z" fill="#000"/>\n' +
		'</svg>');
	editor.ui.registry.addIcon('warnicon', '<svg xmlns="http://www.w3.org/2000/svg" width="19.063" height="17.008" viewBox="0 0 19.063 17.008">\n' +
		'    <g id="Group_25599" data-name="Group 25599" transform="translate(12605 -3494)">\n' +
		'        <g id="Group_25598" data-name="Group 25598" transform="translate(-12605.998 3493.998)">\n' +
		'            <path id="Path_13652" data-name="Path 13652" d="M3,17.01a2,2,0,0,1-1.73-3L8.8,1a2,2,0,0,1,3.46,0l7.53,13.01a2,2,0,0,1-1.73,3Zm15.06-2L10.529,2,3,15.01Z" fill="#000" fill-rule="evenodd"/>\n' +
		'            <path id="Path_13653" data-name="Path 13653" d="M9.529,7.01V10a1,1,0,0,0,2,0V7.01a1,1,0,0,0-2,0Z" fill="#000"/>\n' +
		'            <circle id="Ellipse_1" data-name="Ellipse 1" cx="1" cy="1" r="1" transform="translate(9.529 12.01)" fill="#000"/>\n' +
		'        </g>\n' +
		'    </g>\n' +
		'</svg>');
	editor.ui.registry.addIcon('criticalicon', '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
		'<path fill-rule="evenodd" clip-rule="evenodd" d="M0 10C0 4.48 4.47 0 9.99 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 9.99 20C4.47 20 0 15.52 0 10ZM11 6C11 5.45 10.55 5 10 5C9.45 5 9 5.45 9 6V10C9 10.55 9.45 11 10 11C10.55 11 11 10.55 11 10V6ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9 13V15H11V13H9Z" fill="#000"/>\n' +
		'</svg>');

	editor.ui.registry.addIcon('emptyicon', '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>');

	const isInNotification = (value) => {
		const node = editor.dom.getParent(editor.selection.getNode(), 'div');
		if (node) {
			return node.classList.contains(value);
		}
		return false;
	};

	editor.ui.registry.addSplitButton('infopanel', {
		icon: 'infoicon',
		columns: 1,
		tooltip: 'Info panel',
		fetch: function (callback) {
			const items: any[] = [
				{
					type: 'choiceitem',
					value: 'info',
					icon: 'infoicon',
					text: 'Info',
				},
				{
					type: 'choiceitem',
					value: 'warning',
					icon: 'warnicon',
					text: 'Warning',
				},
				{
					type: 'choiceitem',
					value: 'critical',
					icon: 'criticalicon',
					text: 'Critical',
				},
				{
					type: 'choiceitem',
					value: 'regular',
					icon: 'emptyicon',
					text: 'Regular',
				},
			];
			callback(items);
		},
		onAction: (_api: any) => {
			if (isInNotification('block-notification')) {
				const node = editor.dom.getParent(editor.selection.getNode(), 'div');
				if (node && node.parentNode) {
					const content = node.innerHTML;
					const fr = editor.dom.createFragment(`<p>${content}</p>`)
					node.parentNode.insertBefore(fr, node)
					node.parentNode.removeChild(node);
				}
			}
		},
		onItemAction: (api: any, value: string) => {
			if (!isInNotification('block-notification')) {
				const node = editor.dom.getParent(editor.selection.getNode(), 'p');
				if (node && node.parentNode) {
					const content = node.innerHTML;
					const fr = editor.dom.createFragment(`<div class='block-notification ${value}'>${content}</div>`)
					node.parentNode.insertBefore(fr, node)
					node.parentNode.removeChild(node);
				} else {
					editor.insertContent(`<div class='block-notification ${value}'></div>`, {format: 'raw'});
				}
			} else {
				const node = editor.dom.getParent(editor.selection.getNode(), 'div');
				if (node) {
					node.className = `block-notification ${value}`;
				}
			}
		},
		select: (value: string): boolean => {
			return isInNotification(value);
		},
		onSetup: (api) => {
			const nodeChangeHandler = (_e) => {
				api.setActive(isInNotification('block-notification'));
			};
			editor.on('NodeChange', nodeChangeHandler);
			return function () {
				return editor.off('NodeChange', nodeChangeHandler);
			};
		},
	});

	return {
		getMetadata: () => ({
			name: 'Info panel',
			url: 'https://teachcambridge.org/',
		}),
	};
});
