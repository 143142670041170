import styled from '@emotion/styled';
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded';
import CloseRounded from '@mui/icons-material/CloseRounded';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded';
import IconButton from '@mui/material/IconButton';
import {ThemeProvider} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import {SnackbarProvider, useSnackbar} from 'notistack';
import React, {Suspense} from 'react';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import Auth from './Auth';
import PageLoader from './components/Loader/PageLoader';
import {theme} from './layout/theme';
import PageRouter from './PageRouter';
import {Browser} from './utils/browser';
import { RecoilEnv } from 'recoil';
import {Process} from "./utils/process";

if (Process.ENV === 'development') {
    RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
}

// Global error handler. Should catch all those unhandled errors and post them somewhere to be investigated by the team.
window.onerror = (msg, url, line, col, error) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const logger.ts = useLog('App');
    console.error('unhandled exception -> ', `${msg} at ${url}:${line}:${col}\n`, error);
};

// useNavigate() forces the component it's used in to render every single time the url changes even though is used
// only to navigate to new pages. So this component extracts the useNavigate function and publishes it to a global
// where it can be used without having to redraw components that don't need to.
const Navigator = () => {
    Browser.navigate = useNavigate();
    return null;
};

// Customize the Notistack window to have a different close button
const NotificationCloseAction = ({notificationID}: { notificationID: string | number }) => {
    const {closeSnackbar} = useSnackbar();
    return (
        <IconButton data-testid='close-snackbar' onClick={() => closeSnackbar(notificationID)}><CloseRounded sx={{color: '#fff'}}/></IconButton>
    );
};

const AppElem = styled.div`
  background-color: var(--color-background);
  height: 100%;
  position: relative;
`;

// Application entry point
const App = ({children}: {children?: any}) => {
    return (
        <AppElem role="application" data-testid="app-component">
            <SnackbarProvider
                maxSnack={1}
                iconVariant={{
                    success: <CheckCircleOutlineRounded style={{marginRight: '8px'}}/>,
                    error: <ErrorOutlineRounded style={{marginRight: '8px'}}/>,
                    warning: <WarningAmberRounded style={{marginRight: '8px'}}/>,
                }}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                TransitionComponent={Collapse}
                action={(notificationId) => <NotificationCloseAction notificationID={notificationId}/>}
            >
                <ThemeProvider theme={theme}>
                    <Suspense fallback={<PageLoader/>}>
                        <BrowserRouter>
                            <Navigator/>
                            <Auth/>
                            <PageRouter/>
                            {children}
                        </BrowserRouter>
                    </Suspense>
                </ThemeProvider>
            </SnackbarProvider>
        </AppElem>
    );
};

export default App;
