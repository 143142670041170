import {Strings} from "../../utils/strings";
import {UUID} from "../types";
import {columnDefinition, tableHidden} from '../../utils/decorators';
import {LibraryItem} from "./__MediaLibrary";

export class Video extends LibraryItem {
    public static title = 'Video';
    @tableHidden
    public link: string;
    public content_type: UUID;

    @columnDefinition({
        label: 'Available for trial'
    })
    public public_from?: Date;

    constructor(item: any = {}) {
        super(item);

        this.link = Strings.default(item.link);
        this.content_type = Strings.default(item.content_type);

        if (item.public_from) {
            this.public_from = new Date(item.public_from)
        }
    }
}
