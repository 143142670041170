import { CustomItemAction, PredefinedActions } from '../../components/ActionMenu/Actions';
import { cmsModelsSelector } from '../../state/models';
import { getRecoilPromise, setRecoil } from '../../state/recoilNexus';
import { Lists } from '../../utils/lists';
import { Numbers } from '../../utils/numbers';
import { Objects } from '../../utils/objects';
import { Strings } from '../../utils/strings';
import { Status, Types, UUID } from '../types';
import { BaseClass } from './__base';
import { FieldType, ModelInfo } from './__ModelInfo';
import { ClassOf, InstanceOf } from './index';
import { formField, tableColumn } from '../../utils/decorators';
import React from 'react';
import { ColumnType, renderers } from '../../components/TableComponent/renderers';
import { fi } from '../../utils/helpers';
import { ItemStatus } from '../../components/commons/ItemStatus';
import type { ModalWrapperProps } from '../../components/ModalDialogs/ModalWrapper';
import Client from '../client';
import { IFormEvents } from '../../components/Form/model';
import { cacheBuster } from '../../state/state';

export class CMSObjectMetadata extends BaseClass implements IFormEvents {
	// Private objects are objects that are only available in the CMS admin panel
	private: boolean;
	// Object version
	version: number;
	// Date when the object was created
	created: Date; // Date string
	// Date when the object was last modified
	modified: Date; // Date string
	// Date when the object was/will be published.
	published?: Date; // Date string
	// Date when the object was/will be unpublished
	unpublish?: Date; // Date string
	// Internal tags associated with the object used to easily group/search/identify the object
	tags?: string[];
	// External mapping
	mapping?: string;
	// Number of times the object was accessed from the website (eg view count, download count)
	accessed?: number;
	// Author of the last change on the object. Objects that were seeded to the database hav eno author
	author: string;
	creator: string;
	status: string;

	constructor(data: any = {}) {
		super(data);

		this.private = Boolean(data.private);
		this.version = fi(typeof data.version === 'undefined', 0, +data.version);
		this.created = new Date(data.created);
		this.modified = new Date(data.modified);

		if (data.published) {
			this.published = new Date(data.published);
		}
		if (data.unpublish) {
			this.unpublish = new Date(data.unpublish);
		}
		this.tags = Lists.default(data.tags);
		this.mapping = Strings.default(data.mapping);
		this.accessed = Numbers.default(data.accessed);
		this.author = Strings.default(data.author);
		this.creator = Strings.default(data.creator, data.author);
		this.status = Strings.startCase(data.status);
	}

	public toJSON() {
		const res: any = {};
		if (this.tags?.length) {
			res.tags = this.tags;
		}
		if (this.mapping) {
			res.mapping = this.mapping;
		}

		return fi(Object.keys(res).length, res, null);
	}
}

export type CMSObjectCommit = {
	date: string;
	description: string;
	change?: string;
	minor: boolean;
	version: number;
	accountname: string;
}

export type CMSObjectData = {[key: string]: any}

export enum DisplayMode {
	SHORT,
	FULL,
	DETAILED,
}

export type ItemRoutes = {
	list?: string;
	edit?: string
	editInline?: React.FC<ModalWrapperProps>;
	create?: string;

	createInline?: React.FC<ModalWrapperProps>;
	createChild?: string
	createChildInline?: React.FC<ModalWrapperProps>;
}

// CMSObject contains the base properties and functionalities of all CMS objects
// Most of the methods do nothing or implement some basic behaviour and may need to be overridden
// by the classes that inherit from this one.
//
// Objects that inherit from CMSObject can define the following static properties:
//    title: string        - Used as label for buttons and titles
//    autoPublish: boolean - Used to determine if the object requires manual publishing
//                           actions and display information or not (Version panel,  Publish/Unpublish actions, etc)
//
export class CMSObject extends BaseClass {
	public static title = 'Item';
	public __type: Types;
	public __uuid: UUID;
	public __draft: boolean;
	public __meta: CMSObjectMetadata;
	public __versions: {[key: number]: CMSObjectData};
	public __commitHistory: CMSObjectCommit[];

	@formField({
		name: 'Tags',
		fieldtype: FieldType.Tags,
		order: 2.5,
		flags: {multiple: true},
	})
	public __tag: string[] = [];

	constructor(item: any = {}) {
		super(item);

		this.__uuid = Strings.default(item.__uuid);
		this.__type = Strings.default(item.__type, Types.UNDEFINED) as Types;
		this.__draft = Boolean(item.__draft);

		const meta = Objects.default({...item.__meta});
		meta.status = '';

		this.__meta = new CMSObjectMetadata(meta);
		this.__commitHistory = Lists.sort(Lists.default(item.__commitHistory), 'date', true);
		this.__tag = this.__meta.tags as string[];

		// If this is a detailed object that also contains the version and commit information, then we
		// need to convert those objects to instances of classes, set their correct status and amend their modification
		// date with the dates we get from commit history.
		const ref: CMSObject[] = [];
		const versions: number[] = Object.keys(Objects.default(item.__versions)).map(v => +v);
		versions.sort((a, b) => a - b);

		versions.forEach((version: number) => {
			const commit: CMSObjectCommit | undefined = this.__commitHistory.find(c => c.version === version);
			const objectVersion = InstanceOf({
				__type: this.__type,
				__uuid: this.__uuid,
				__commitHistory: this.__commitHistory,
				__meta: new CMSObjectMetadata({
					...this.__meta.__data,
					version: version,
					status: '',
					modified: commit?.date || this.__meta.modified,
					author: commit?.accountname || this.__meta.author,
				}),
				...item.__versions[version],
			});

			objectVersion.__versions = ref;

			objectVersion.__meta.status = fi(version === this.getMeta().version, objectVersion.getStatus(), Status.Archived);
			if (objectVersion.__meta.status === Status.Archived) {
				objectVersion.__meta.published = null;
				objectVersion.__meta.unpublish = null;
			}

			ref.push(objectVersion);
		});

		Lists.sort(ref, 'version');
		this.__versions = ref;
	}

	// Returns an object of routes where the page needs to go to edit or create an object of this type.
	// If the edit or creation of the object needs to be done in a popup and not a form page, then it needs to return
	// the components to be created in the popup. See ItemRoutes type.
	public routes(): ItemRoutes {
		return {
			list: `/${this.getType()}`,
			edit: `/${this.getType()}/${this.getId()}`,
			create: `/${this.getType()}/create`,
			createChild: `/${this.getType()}/${this.getId()}/create`,
		};
	}

	// Returns the class used to instantiate this object from its type
	public classOf(): any {
		return ClassOf(this.__type);
	}

	public getId(): UUID {
		return this.__uuid;
	}

	public getType(): Types {
		return this.__type;
	}

	public getMeta(): CMSObjectMetadata {
		return this.__meta;
	}

	public clone(overrides: any = {}): any {
		return InstanceOf({...JSON.parse(JSON.stringify(this.__data)), ...overrides});
	}

	public complete(): boolean {
		return this.__commitHistory.length !== 0;
	}

	public isNewerThan(other: CMSObject | null): boolean {
		if (!other) {
			return false;
		}
		return this.getMeta().modified.getTime() >= other.getMeta().modified.getTime();
	}

	public isOlderThan(other: CMSObject | null): boolean {
		if (!other) {
			return false;
		}
		return this.getMeta().modified.getTime() <= other.getMeta().modified.getTime();
	}

	// Determines the published state of the object based on its published/unpublished dates.
	// A 'scheduled' object is an object that was published and is set to expire in the future, or
	// will be published in the future (with or without an expiration date).
	// A 'live' object (available to the public) is an object for which the published date already
	// passed and has no expiration date or the expiration date is in the future
	public publishedState(): {published: boolean, isLive: boolean, scheduled: boolean} {
		const result = {
			published: false,
			isLive: false,
			scheduled: false,
		};

		if (!this.__meta.published) {
			return result;
		}

		const published = this.__meta.published.getTime();
		const now = new Date().getTime();

		result.published = true;
		if (published > now) {
			result.scheduled = true;
		} else {
			result.isLive = true;
		}

		if (this.__meta.unpublish) {
			const unpublished = this.__meta.unpublish.getTime();
			result.isLive = result.isLive && unpublished > now;
			result.published = unpublished > now;
			result.scheduled = result.scheduled && published < unpublished;
		}

		return result;
	}

	public isDraft(): boolean {
		return Boolean(this.__draft);
	}
	public disabledFields(): string[] {
		return []
	}

	public hasTag(tag: string): boolean {
		return Strings.default(this.__meta.tags).split(',').includes(Strings.default(tag));
	}

	public isUnpublished(): boolean {
		if (this.__meta.unpublish) {
			return this.__meta.unpublish.getTime() < (new Date()).getTime();
		}
		return false;
	}

	public isPublished(): boolean {
		const {published} = this.publishedState();
		return published;
	}

	public isScheduled(): boolean {
		const {scheduled} = this.publishedState();
		return scheduled;
	}

	// Display label is used to represent the object in the UI.
	// This method returns the label for some basic fields but should be overridden
	// by the inheriting classes.
	// Based on where the item is displayed it can have one of three modes.
	//    DisplayMode.SHORT - Just display the object name (eg J800)
	//    DisplayMode.LONG - Add some extra information to the object name (eg J800 - Sports Science)
	//    DisplayMode.FULL - Add all available information to the object name (eg J800 - Sports Science - modular)
	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		return Strings.default(this.__data['label'],
			Strings.default(this.__data['title'],
				Strings.default(this.__data['name'],
					Strings.default(this.__data['code'],
						''))));
	}

	public async modelInfo(): Promise<ModelInfo> {
		return getRecoilPromise(cmsModelsSelector(this.__type));
	}

	// This method returns the reason why the object cannot be deleted or an empty
	// string if it can be deleted. Some types (eg Content Type) require an additional check to see
	// if they are referenced by other objects before they can be deleted.
	public async canDelete(): Promise<string> {
		return '';
	}

	// This method returns the reason why the object cannot be created or an empty
	// string if it can be created. Some types (eg Content Type) require a special role to create.
	public canCreate(): string {
		return '';
	}

	// This method returns the reason why the object cannot be published or an empty
	// string if it can be published. Some types (eg Content Type) require a special role to create.
	public canPublish(): string {
		return '';
	}

	// This method is called before the query for content is sent to the server.
	// For example, the Media Library class needs to add the selected folder to the query.
	public applyFilters(): object {
		return {};
	}

	public getStatus(): Status {
		if (this.__meta.status) {
			return this.__meta.status as Status;
		}
		const state = this.publishedState();
		if (this.isDraft()) {
			if (state.isLive) {
				return Status.DraftPublished;
			}
			if (state.published && state.scheduled) {
				return Status.DraftScheduled;
			}
			return Status.Draft;
		}
		if (this.isUnpublished()) {
			return Status.Unpublished;
		}
		if (state.isLive) {
			return Status.Published;
		}
		if (state.published && state.scheduled) {
			return Status.Scheduled;
		}
		return Status.Unknown;
	}

	public async save(): Promise<CMSObject> {
		if (this.getId()) {
			return Client.update(this);
		}
		return Client.create(this);
	}

	public async delete(id?:string): Promise<void> {
		return Client.delete(id?id:this.getId());
	}

	// Override the serialize method to return the object as a JSON string.
	// This method is responsible for ignoring null or unset values and for the actual JSON object that
	// ends up to the server to be saved or updated.
	public toJSON() {
		const res: any = {};
		const excluded = ['__data', '__draft', '__versions', '__commitHistory'];
		for (let key in this) {
			if (excluded.includes(key)) {
				continue;
			}
			if (key === '__uuid' && !this[key]) {
				continue;
			}
			if (key === '__meta' && this.__meta.toJSON() === null) {
				continue;
			}
			if (typeof this[key] === 'undefined' || this[key] === null) {
				continue;
			}
			res[key] = this[key];
		}
		return res;
	}

	// Additional columns used by the table view as column renderers for some generic fields
	// -------------------------------------------------------------------------------------

	@tableColumn({
		order: -1, // always first
		label: 'UID',
		field: '__uuid',
		default: false,
		type: ColumnType.Text,
	})
	public __uuidColumn(_plain?: boolean): string | React.ReactNode {
		return this.getId();
	}

	// Columns that exist only for types that require manual publishing (Status, Version, Expire)

	@tableColumn({
		field: '__meta.status',
		label: 'Status',
		type: ColumnType.List,
		order: 100,
		sortable: true,
		default: true,
		manualPublish: true,
		filter: {
			getLabel: (val) => Strings.startCase(val),
		},
	})
	public __statusColumn(plain?: boolean): string | React.ReactNode {
		if (plain) {
			return this.getStatus();
		}
		const status = this.getStatus();
		const className = fi(status === Status.Published, 'published',
			fi(status === Status.Scheduled || status === Status.DraftScheduled, 'scheduled'),
		);

		return <ItemStatus className={className}>{status}</ItemStatus>;
	}

	@tableColumn({
		order: 101,
		field: '__meta.version',
		label: 'Version',
		type: ColumnType.Number,
		sortable: true,
		manualPublish: true,
	})
	public __versionColumn(_plain?: boolean): string | React.ReactNode {
		return this.__meta.version;
	}

	@tableColumn({
		field: '__meta.unpublish',
		label: 'Expire',
		type: ColumnType.Date,
		order: 102,
		sortable: true,
		manualPublish: true,
	})
	public __expireColumn(plain?: boolean): string | React.ReactNode {
		return renderers[ColumnType.Date](this, '__meta.unpublish', plain);
	}

	// Columns that exist on all types (Tags, Uploaded/Modified, User)

	@tableColumn({
		order: 200,
		label: 'Tags',
		field: '__meta.tags',
		type: ColumnType.List,
	})
	public __tagsColumn(plain?: boolean): string | React.ReactNode {
		return renderers[ColumnType.List](this, '__meta.tags', plain);
	}

	@tableColumn({
		order: 201,
		sortable: true,
		default: true,
		label: 'Uploaded / Modified',
		field: '__meta.modified',
		type: ColumnType.Date,
	})
	public __modifiedColumn(plain?: boolean): string | React.ReactNode {
		return renderers[ColumnType.Date](this, '__meta.modified', plain);
	}

	@tableColumn({
		order: 202,
		sortable: true,
		default: true,
		label: 'User',
		field: '__meta.author',
		type: ColumnType.List,
	})
	public __authorColumn(plain?: boolean): string | React.ReactNode {
		return renderers[ColumnType.Text](this, '__meta.author', plain);
	}

	public formOnSaveSuccess(): any {
		setRecoil(cacheBuster(this.getType()), (val) => val + 1);
	}
}

// All objects that need to render in the TreeComponent need to inherit from this class
export class TreeObject extends CMSObject {
	// Optional list of additional actions to add to the action menu of the items displayed in the tree
	public static actions: CustomItemAction[] = [PredefinedActions.quickAccess];
	// Parent id of the current tree node.
	public parent: UUID | undefined | null;
	// Order of the current tree node.
	public order: number;

	constructor(item: any = {}) {
		super(item);

		this.parent = item.parent;
		this.order = Numbers.default(item.order);
	}

	// Handle reordering or moving of a tree node to a different parent.
	public async move(): Promise<void> {
		throw new Error('You must override TreeObject.move() method');
	}
}
