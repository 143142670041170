import React, {useEffect, useRef, useState} from "react"
import {selector, useRecoilValue} from "recoil";
import {Numbers} from "../../utils/numbers";
import {fi} from "../../utils/helpers";
import {Dates} from "../../utils/dates";
import {Strings} from "../../utils/strings";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Client from "../../cms/client";
import { AccountsOverview, AvgBoxComponent, Visits } from './components/AvgBoxComponent';
import { Lists } from '../../utils/lists';
import { Objects } from '../../utils/objects';

let mouse = {x: 0, y: 0}

export const overviewSelector = selector<AccountsOverview>({
    key: 'overviewSelector',
    get: async () => {
        return await Client.accountsOverview() as any
    }
})

const AccountOverview = () => {
    const canvas: any = useRef<HTMLCanvasElement | null>()
    const overview = useRecoilValue(overviewSelector)

    const [state, setState] = useState({
        width: 0,
        height: 0,
        monthMax: 0,
        dayMax: 0,
        dataPoints: 0,
        months: [],
        monthValues: [],
        dayValues: [],
    })

    const draw = () => {
        const cvs = canvas.current;
        if (!cvs) {
            return;
        }
        const ctx = cvs.getContext('2d');
        if (!ctx) {
            return
        }
        const canvasHeight = state.height - 12
        ctx.clearRect(0, 0, state.width, state.height);

        const monthWidth = (state.width / state.monthValues.length) - 2; // -2 for border
        const dayStep = (state.width / state.dayValues.length);

        // draw months
        ctx.fillStyle = 'rgba(0, 0, 0, 0.04)';
        for (let i = 0; i < state.monthValues.length; i++) {
            const height = ((canvasHeight - 15) * state.monthValues[i]) / state.monthMax;
            const x = (i * monthWidth) + (i * 2)
            ctx.fillRect(x, canvasHeight - height, monthWidth, height);

            if (mouse.x !== 0 && mouse.y !== 0) {
                if (mouse.x > x && mouse.x < x + monthWidth) { // over a month column
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(x + monthWidth / 2, canvasHeight - height + 1, 2, 2 * Math.PI, false);
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.3)';
                    ctx.fill();
                    ctx.stroke()

                    ctx.font = "10px Arial";
                    ctx.textAlign = "center";
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
                    ctx.fillText(`${state.months[i]} - ${Numbers.default(state.monthValues[i]).toLocaleString()}🧍`, x + monthWidth / 2, canvasHeight - height - 5);

                    ctx.restore();
                }
            }
        }

        // draw days
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.1)';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(0, canvasHeight - (canvasHeight * state.dayValues[0]) / state.dayMax); // initial point

        for (let i = 1; i < state.dataPoints; i++) {
            const [x, y] = [(i * dayStep), canvasHeight - 10 - (canvasHeight * state.dayValues[i]) / state.dayMax]
            ctx.lineTo(x, y);
            if (mouse.x !== 0 && mouse.y !== 0) {
                if (mouse.x > (i * dayStep) - (dayStep / 2) && mouse.x < (i * dayStep) + (+(dayStep / 2))) {
                    ctx.stroke();
                    ctx.save()

                    ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)'
                    ctx.setLineDash([2, 3]);
                    ctx.beginPath();
                    ctx.moveTo(x, y + 1)
                    ctx.lineTo(x, state.height - 10)
                    ctx.stroke();

                    ctx.font = "10px Arial";
                    ctx.textAlign = fi(x < 50, "left", fi(x > state.width - 50, "right", "center"));
                    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
                    ctx.fillText(`${Dates.local(overview.logins[i].date).split(' ').shift()} - ${Numbers.default(state.dayValues[i]).toLocaleString()}`, x, state.height)

                    ctx.restore();
                    ctx.beginPath();
                    ctx.moveTo(x, y)
                }
            }
        }
        ctx.stroke();

        requestAnimationFrame(draw)
    }

    useEffect(() => {
        let box: any;

        if (canvas && canvas.current) {
            const cvs = canvas.current;
            box = cvs.getBoundingClientRect();

            cvs.width = Math.floor(box.width * window.devicePixelRatio);
            cvs.height = Math.floor(box.height * window.devicePixelRatio);

            const ctx = cvs.getContext('2d');
            if (ctx) {
                ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
            }
        }

        const data: any = {
            dataPoints: Lists.default(overview.logins).length,
            width: Objects.default(box).width,
            height: Objects.default(box).height,
            monthMax: 0,
            dayMax: 0,
            months: new Array<string>(),
            monthValues: new Array<number>(),
            dayValues: new Array<number>(),
        }

        const now = new Date();
        const values = Lists.default<Visits>(overview.logins).map(login => ({
            count: login.count,
            month: `${Strings.pad(new Date(login.date).getMonth() + 1)} / ${new Date(login.date).getFullYear()}`,
            monthsAgo: Dates.monthDiff(new Date(login.date), now)
        }));

        data.monthValues = values.reduce<number[]>((acc, login) => {
            const val = Numbers.default(acc[login.monthsAgo]) + login.count; // sum for month

            data.monthMax = Math.max(data.monthMax, val); // max for all months
            data.dayMax = Math.max(data.dayMax, login.count); // max for all days

            if (!data.months.includes(login.month)) data.months.push(login.month);

            data.dayValues.push(login.count);

            acc[login.monthsAgo] = val;
            return acc
        }, []).reverse()

        setState(data);

    }, [canvas, overview])

    useEffect(() => {
        if (state.dataPoints) {
            requestAnimationFrame(draw)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const onMouseMove = (evt) => {
        mouse.x = evt.nativeEvent.offsetX;
        mouse.y = evt.nativeEvent.offsetY;
    }

    return (
        <>
            <Box>
                <Typography variant='h1'>Accounts overview</Typography>
                <div className='flex-row' style={{columnGap: 32}}>
                    <Value>
                        <span>{Numbers.default(overview.accounts).toLocaleString()}</span>
                        <span>Accounts</span>
                    </Value>
                    <Value>
                        <span>{Numbers.default(overview.active).toLocaleString()}</span>
                        <span>Active - <strong>{(Numbers.default(overview.active) * 100 / Numbers.default(overview.accounts)).toFixed(2)}%</strong></span>
                    </Value>
                    <Value>
                        <span>{Numbers.default(overview.orgcount).toLocaleString()}</span>
                        <span>Organizations</span>
                    </Value>
                </div>
            </Box>
            <div className='flex-row' style={{columnGap: 16}}>
                <div className='flex-column' style={{rowGap: 16}}>
                    <AvgBoxComponent label={'User subject preferences'} distribution={Objects.default(overview.qualifications)}/>
                    <AvgBoxComponent label={'Organizations per user'} distribution={Objects.default(overview.orgs)}/>
                </div>
                <div className='flex-column' style={{rowGap: 16}}>
                    <AvgBoxComponent label={'Favorited documents'} distribution={Objects.default(overview.favorites)}/>
                    <AvgBoxComponent label={'Downloaded documents'} distribution={Objects.default(overview.seen)}/>
                </div>
            </div>
            <canvas ref={canvas} onMouseMove={onMouseMove} onMouseLeave={() => mouse = {x: 0, y: 0}}></canvas>
        </>
    )
}

const Box = styled(Paper)`
  padding: 8px 16px;
  margin-bottom: 16px;

  h1 {
    margin-bottom: 16px;
    color: var(--color-backdrop)
  }
`

const Value = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  flex-grow: 1;

  span {
    text-align: right;
  }

  span:first-of-type {
    font-family: sans-serif;
    font-size: 38px;
    margin-bottom: 4px;
  }

  span:last-of-type {
    font-size: 12px;
    text-transform: uppercase;
  }
`

export default AccountOverview