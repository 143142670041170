import styled from '@emotion/styled';
import NavigateNext from '@mui/icons-material/NavigateNext';
import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import React, {useMemo} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import {breadcrumbSelection, selectedMenuItem, selectedObject, selectedTreeItem} from '../../state/state';
import {fi} from '../../utils/helpers';
import {Strings} from '../../utils/strings';
import {walkTreeUp} from '../TreeMenu/utils';

const Wrapper = styled.div`
  flex-grow: 1;
  min-width: 10%;
`;

const BreadcrumbsElement = styled(MaterialBreadcrumbs)`
  li p, li a {
    font-size: 16px;
    color: var(--color-monocrome);
    line-height: 21px;
    font-family: var(--font-regular);
  }

  li a {
    color: var(--color-blue);
  }

  svg {
    height: 20px;
  }
`;


type NavItem = {
    path: string,
    name: string,
    id: string,
}

const Breadcrumbs = () => {
    useLocation();
    const mainMenuItem = useRecoilValue(selectedMenuItem);
    const treeItem = useRecoilValue(selectedTreeItem);
    const obj = useRecoilValue(selectedObject)
    const selection = useRecoilValue(breadcrumbSelection)

    const items: NavItem[] = useMemo(() => {
        const result: NavItem[] = [
            {path: '/', name: 'Home', id: 'home'},
        ];
        // add whatever is selected in the main menu
        if (mainMenuItem) {
            result.push({
                path: mainMenuItem.path,
                name: mainMenuItem.label,
                id: Strings.default(mainMenuItem.label).toLowerCase().replace(/ /g, '-'),
            });
        }

        // add whatever is selected in the tree menu if any
        if (treeItem && treeItem.object) {
            const trace: any[] = []
            walkTreeUp(treeItem, (node) => {
                trace.push({
                    path: node.object.routes().edit,
                    name: node.object.displayLabel(),
                    id: node.id,
                });
            })
            result.push(...trace.reverse());
        }

        if (obj) {
            if (obj.getId() !== result[result.length - 1].id) {
                result.push({
                    path: Strings.default(obj.routes().edit, document.location.pathname),
                    name: Strings.default(obj.displayLabel(), "New"),
                    id: obj.getId(),
                })
            }
        }

        if (selection) {
            result.push({
                path: selection,
                name: selection,
                id: '',
            })
        }

        return result;
    }, [treeItem, mainMenuItem, obj, selection]);


    return (
        <Wrapper className='no-select'>
            <BreadcrumbsElement
                separator={<NavigateNext fontSize="small"/>}
                aria-label="breadcrumbs"
                maxItems={10}
                itemsAfterCollapse={5}
                itemsBeforeCollapse={2}
                id="breadcrumbs"
                data-testid={'breadcrumbs'}>
                {items.map((item, index) => fi(index === items.length - 1, (
                            <Typography variant={'body1'} key={index} title={item.name}
                                        data-testid={`item-${item.id}`}>{item.name}</Typography>
                        ),
                        <NavLink to={item.path} key={index} title={item.name}
                                 data-testid={`item-${item.id}`}>{item.name}</NavLink>,
                    ),
                )}
            </BreadcrumbsElement>
        </Wrapper>
    );
};

export default Breadcrumbs;
