import React from "react";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {ActionButton} from "../commons/ActionButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Client from '../../cms/client';
import { useSnackbar } from 'notistack';

const Misc = () => {
	const {enqueueSnackbar} = useSnackbar();
	const refresh = async () => {

	}

	const reloadCMSData = async () => {
		Client.refreshCMSData().then(() => {
			enqueueSnackbar('Data reloaded successfully', {variant: 'success'});
		}).catch(() => {
			enqueueSnackbar('Error reloading data', {variant: 'error'});
		});
	}

	return (
		<div data-testid='feature-flags-container'>
			<div className='flex-row spaced'>
				<Typography variant={'h4'}>Misc</Typography>
				<div>
					<ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
								  data-testid={'refresh'}>
						<RefreshIcon/>
					</ActionButton>
				</div>
			</div>
			<Divider className='mt8 mb24'/>
			<Button variant={"contained"} onClick={reloadCMSData}>Reload CMS data</Button>
		</div>
	)
}

export default Misc;
