import React from 'react';
import {Field} from '../../../cms/models/__ModelInfo';
import {useFormField} from '../state';
import styled from '@emotion/styled';
import {Lists} from '../../../utils/lists';

const Wrapper = styled.div`
    label {
        margin-bottom: 8px;
        color: var(--color-red);
    }
`;

const StyledList = styled.ul`
    list-style: circle;
    padding-left: 24px;

    li {
        margin: 4px 0;
    }
`;

const ErrorsRenderer = ({field}: {field: Field}) => {
	const formField = useFormField(field.uid);

	if (!Lists.default(formField.value).length) {
		return null;
	}

	return (
		<Wrapper>
			<label>{field.name}</label>
			<StyledList>
				{formField.value.map((error, index) => (
					<li key={index}>{error}</li>
				))}
			</StyledList>
		</Wrapper>
	);
};

export default ErrorsRenderer;