import { getRecoil } from '../../state/recoilNexus';
import { references } from '../../state/state';
import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { UUID } from '../types';
import { CMSObject, DisplayMode } from './__CMSObject';

export class SpecificationGroup extends CMSObject {
	public static title = 'Specification group';
	public static autoPublish = true;
	public name: string;
	public id: number;
	public qualification_group: UUID;

	constructor(item: any = {}) {
		super(item);

		this.name = Strings.default(item.name);
		this.id = Numbers.default(item.id);
		this.qualification_group = Strings.default(item.qualification_group);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
				return this.name;
			case DisplayMode.DETAILED:
			case DisplayMode.FULL:
				const qualificationGroup = getRecoil(references(this.qualification_group));
				if (qualificationGroup) {
					return `${this.name} (${qualificationGroup.displayLabel()})`;
				}
				return this.name
		}
	}
}
