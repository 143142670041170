import { Strings } from '../../utils/strings';
import { columnDefinition, formField, linkedFormField, tableColumn, tableConfig } from '../../utils/decorators';
import { ColumnType } from '../../components/TableComponent/renderers';
import { UUID } from '../types';
import { CMSFile } from './File';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { references } from '../../state/state';
import React from 'react';
import { CMSObject } from './__CMSObject';
import { CustomItemAction, PredefinedActions } from '../../components/ActionMenu/Actions';
import { FieldType } from './__ModelInfo';
import Client from '../client';
import { FormEvent, IFormEvents } from '../../components/Form/model';
import { Browser } from '../../utils/browser';
import { confirmationModalAtom } from '../../components/ModalDialogs/ConfirmationModal';
import { atom, atomFamily } from 'recoil';
import { Lists } from '../../utils/lists';
import ExamDatesTemplates from '../../components/TableComponent/bulkActionButtons/ExamDatesTemplates';
import { Messages } from '../../utils/messages';

const keyDatesFileErrors = atomFamily<string[], string>({
	key: 'keyDatesFileErrors',
	default: [],
});

export const loadingProcess = atom<boolean>({
	key: 'loadingProcess',
	default: false,
});

export const keyDatesStatusAtom = atom<any>({
	key: 'keyDatesStatus',
    default: null,
})

@tableConfig({
	additionalActions: [<ExamDatesTemplates/>],
})
export class KeyDateFile extends CMSObject implements IFormEvents {
	public static actions: CustomItemAction[] = [PredefinedActions.download, PredefinedActions.move];
	public static noRestore: boolean = true;
	public title: string;
	@columnDefinition({
		label: 'Filename',
		order: 4,
		type: ColumnType.File,
		default: true,
		filter: {noFilter: true},
	})
	public file: UUID;

	@formField({
		fieldtype: FieldType.CardsList,
		name: 'Cards list',
		order: 5,
	})
	@linkedFormField('file')
	public displayCards: any = null;

	@formField({
		fieldtype: FieldType.ErrorsList,
		name: 'File processed with errors',
		order: 4.5,
	})
	public errors: any[];

	constructor(item: any = {}) {
		super(item);

		this.title = Strings.default(item.title);
		this.file = item.file;
		this.errors = getRecoil(keyDatesFileErrors(this.getId()));

		this.__commitHistory = [];
		this.__versions = [];
	}

	public fileInfo(): CMSFile | null {
		return getRecoil(references(this.file)) as any as CMSFile;
	}

	@tableColumn({
		order: 4.5,
		label: 'File size',
		field: 'fileSize',
		type: ColumnType.Number,
		filter: {noFilter: true},
	})
	public __fileSizeColumn(plain?: boolean): string | React.ReactNode {
		if (!this.fileInfo) {
			return '-';
		}
		const info = this.fileInfo();
		if (info) {
			return info.formattedSize;
		}
		return '-';
	}

	public async canDelete(): Promise<string> {
		if (this.isPublished()) {
			return Messages.CantDeletePublishedItem;
		}
		return '';
	}

	public formOnFieldChange(evt: FormEvent) {
		if (evt.fieldUID !== 'file') {
			return;
		}
		const fileInfo = getRecoil(references(evt.fieldValue)) as CMSFile;
		if (!fileInfo) {
			return;
		}

		// check if the file already exists on other item
		if (!this.getId()) { // new key_dates_file item
			Client.checkKeyDateFile(fileInfo.fileuid).then(res => {
				if (res) {
					setRecoil(confirmationModalAtom, {
						message:
							<span>The file you are trying to upload already exists under the name <strong>{res.title}</strong> <br/>
								Do you want to replace the existing item?
								</span>,
						onConfirm: async () => {
							setRecoil(loadingProcess, true);
							Client.processKeyDates(fileInfo.fileuid, res.getId()).then((response) => {
								setRecoil(keyDatesStatusAtom, {message: 'Item saved successfully', variant: 'success'});
								setRecoil(confirmationModalAtom, null);
								setRecoil(keyDatesFileErrors(res.getId()), this.handleErrors(response.errors));
								Browser.navigate(this.routes().list + '/' + res.getId());
							}).catch((error) => {
								setRecoil(keyDatesStatusAtom, {message: 'Error processing key dates file: ' + error.message, variant: 'error'});
								console.log(error);
							}).finally(() => {
								setRecoil(loadingProcess, false);
							});
						},
						onCancel: () => {
							setRecoil(loadingProcess, true);
							Client.processKeyDates(fileInfo.fileuid).then((response) => {
								setRecoil(keyDatesStatusAtom, {message: 'Item created successfully', variant: 'success'});
								setRecoil(confirmationModalAtom, null);
								setRecoil(keyDatesFileErrors(response.__uuid), this.handleErrors(response.errors));
								evt.state.setValue('file', this.file);
								Browser.navigate(this.routes().list + '/' + response.__uuid);
							}).catch((error) => {
								setRecoil(keyDatesStatusAtom, {message: 'Error processing key dates file: ' + error.message, variant: 'error'});
								console.log(error);
							}).finally(() => {
								setRecoil(loadingProcess, false);
							});
						},
					});
				} else {
					setRecoil(loadingProcess, true);
					Client.processKeyDates(fileInfo.fileuid).then((response) => {
						setRecoil(keyDatesStatusAtom, {message: 'Item saved successfully', variant: 'success'});
						setRecoil(keyDatesFileErrors(response.__uuid), this.handleErrors(response.errors));
						Browser.navigate(this.routes().list + '/' + response.__uuid);
					}).catch((error) => {
						setRecoil(keyDatesStatusAtom, {message: 'Error processing key dates file: ' + error.message, variant: 'error'});
						console.log(error);
					}).finally(() => {
						setRecoil(loadingProcess, false);
					});
				}
			});
		} else {// update existing key_dates_file item by replacing the file
			setRecoil(loadingProcess, true);
			Client.processKeyDates(fileInfo.fileuid, this.getId()).then((res) => {
				setRecoil(keyDatesStatusAtom, {message: 'Item saved successfully', variant: 'success'});
				setRecoil(confirmationModalAtom, null);
				setRecoil(keyDatesFileErrors(this.getId()), this.handleErrors(res.errors));
				Browser.navigate(this.routes().edit!);
			}).catch((error) => {
				setRecoil(keyDatesStatusAtom, {message: 'Error processing key dates file: ' + error.message, variant: 'error'});
				console.log(error);
			}).finally(() => {
				setRecoil(loadingProcess, false);
			});
		}
	}

	private handleErrors(errors: any[]): string[] {
		return Lists.default(errors).map((error: any) => `Row ${error.row} - ${error.error}`);
	}
}