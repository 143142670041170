import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from './PageWidget';
import React from 'react';
import {fi} from '../../utils/helpers';
import {Faded, PreviewRow, PreviewWrapper} from './_style';
import {formField} from '../../utils/decorators';
import {FieldType} from './__ModelInfo';
import {FormEvent} from '../../components/Form/model';

@widgetConfig({
	group: WidgetGroup.Filters,
	name: 'Year/Series',
	description: 'Adds a Year/Series filters to the top of the page that further filters other widgets based on it\'s selections.\n• It can only be added to the page once.\n• It must be added after the [Subject filter] widget.',
	constrains: {
		once: true,
		onlyAfter: WidgetType.FilterByQualification,
	},
})
export class YearFilterWidget extends BaseWidget {
	// need to be at least one of these two
	@formField({
		fieldtype: FieldType.Boolean,
		name: 'By year',
		group: 'group',
		groupTitle: 'Display options',
	})
	public byYear: boolean = true;

	@formField({
		fieldtype: FieldType.Boolean,
		name: 'By series',
		group: 'group',
	})
	public bySeries: boolean = true;

	constructor(item: any = {}) {
		super(item);
		this.type = WidgetType.FilterContent;
		if (typeof item.byYear !== 'undefined') {
			this.byYear = Boolean(item.byYear);
		}
		if (typeof item.bySeries !== 'undefined') {
			this.bySeries = Boolean(item.bySeries);
		}
	}

	formOnBeforeFieldChange(evt: FormEvent): boolean {
		if (evt.fieldUID === 'byYear' && evt.fieldValue === false && evt.state.values.bySeries === false) {
			return false;
		}
		return !(evt.fieldUID === 'bySeries' && evt.fieldValue === false && evt.state.values.byYear === false);

	}

	// formOnValidate(evt: FormEvent): any | void {
	// 	// need to check if there is at least one option selected
	// 	if (evt.state.values.byYear === false && evt.state.values.bySeries === false) {
	// 		return {byYear: 'Please select one option.'};
	// 	}
	// }

	public preview(): React.ReactElement | null {
		return (
			<PreviewWrapper>
				<PreviewRow>
					<label>Display options</label>
					{fi(this.byYear, <span>By year</span>, <Faded>By year</Faded>)} /
					{fi(this.bySeries, <span>By series</span>, <Faded>By series</Faded>)}
				</PreviewRow>
			</PreviewWrapper>
		);
	}
}