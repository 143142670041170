export const Numbers = {
	default: (value?: any, def: number = 0): number => {
		if (typeof value === 'number') {
			return value;
		}
		if (typeof value === 'string') {
			let numVal: number;
			if (value.indexOf('.') > -1) {
				numVal = parseFloat(value);
			} else {
				numVal = parseInt(value, 10);
			}
			if (Number.isNaN(numVal)) {
				return def;
			}
			return numVal;
		}
		return def
	},
	asFileSize: (bytes: number | undefined | null | string, si: boolean = true) => {
		if (typeof bytes === 'string') {
			bytes = parseInt(bytes, 10);
			if (Number.isNaN(bytes)) {
				return '-';
			}
		}
		if (typeof bytes === 'undefined' || bytes === null) {
			return '-';
		}
		if (!bytes) {
			return '0 B';
		}
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes.toString() + ' B';
		}
		const units = [ 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
		let u = -1;
		do {
			bytes /= thresh;
			++u;
		} while (Math.abs(bytes) >= thresh && u < units.length - 1);
		return bytes.toFixed(1) + ' ' + units[u];
	}
}
