import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Strings} from "../../utils/strings";
import {PreviewColumn, PreviewRow, PreviewWrapper} from "./_style";
import React from "react";
import {Lists} from "../../utils/lists";
import {Types, UUID} from "../types";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {LibraryItem} from "./__MediaLibrary";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";
import {FormEvent, IFormEvents} from "../../components/Form/model";

type QuickLinkPage = {
    order: number,
    uuid: UUID;
}

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Quick links",
    description: "A two column widget used to display a list of links.",
})
export class QuickLinksWidget extends BaseWidget implements IFormEvents {
    @formField({
        fieldtype: FieldType.Text,
        name: "Column 1 title",
        flags: {required: true}
    })
    public form_1_title: string;

    @formField({
        fieldtype: FieldType.Reference,
        name: "Column 1 links",
        flags: {required: true, multiple: true},
        config: {refModel: Types.PAGE}
    })
    public form_1_pages: UUID[];

    @formField({
        fieldtype: FieldType.Text,
        name: "Column 2 title"
    })
    public form_2_title: string;

    @formField({
        fieldtype: FieldType.Reference,
        name: "Column 2 links",
        flags: {multiple: true},
        config: {refModel: Types.PAGE}
    })
    public form_2_pages: UUID[];

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.QuickLinks
        this.form_1_title = Strings.default(item.form_1_title);

        const col1 = Lists.default<QuickLinkPage>(item.form_1_pages);
        Lists.sort(col1, "order")
        this.form_1_pages = col1.map(page => page.uuid);

        this.form_2_title = Strings.default(item.form_2_title);

        const col2 = Lists.default<QuickLinkPage>(item.form_2_pages);
        Lists.sort(col2, "order")
        this.form_2_pages = col2.map(page => page.uuid);
    }

    public formOnBeforeSave(evt: FormEvent): any {
        const result = {...evt.state.values}
        result.form_1_pages = result.form_1_pages.map((uuid, order) => ({uuid, order}))
        result.form_2_pages = result.form_2_pages.map((uuid, order) => ({uuid, order}))
        return result;
    }

    public preview(): React.ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <PreviewColumn>
                        <PreviewRow className='vertical'>
                            <label>{Strings.default(this.form_1_title, '-')}</label>
                            {this.form_1_pages.map((p, idx) => {
                                const ref = getRecoil(references(p)) as LibraryItem
                                if (!ref) return null;
                                return <span key={idx}>{ref.displayLabel()}</span>
                            })}
                        </PreviewRow>
                    </PreviewColumn>
                    <PreviewColumn>
                        <PreviewRow className='vertical'>
                            <label>{Strings.default(this.form_2_title, '-')}</label>
                            {this.form_2_pages.map((p, idx) => {
                                const ref = getRecoil(references(p)) as LibraryItem
                                if (!ref) return null;
                                return <span key={idx}>{ref.displayLabel()}</span>
                            })}
                        </PreviewRow>
                    </PreviewColumn>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}