import React from "react";
import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Faded, PreviewRow, PreviewWrapper} from "./_style";
import {fi} from "../../utils/helpers";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Helpdesk FAQ",
    description: "Displays a section where it loads FAQs articles from Helpdesk that match the current selected subject.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class FAQWidget extends BaseWidget {
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Show title",
        group: "display-options",
        order: 1,
        groupTitle: "Display options"
    })
    public showTitle: boolean;

    @formField({
        fieldtype: FieldType.Boolean,
        name: "Always show all",
        group: "display-options",
        order: 2,
        groupTitle: "Display options"
    })
    public showAll: boolean;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.FAQ
        this.showTitle = Boolean(item.showTitle);
        this.showAll = Boolean(item.showAll);
    }

    public preview(): React.ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <label>Display options</label>
                    {fi(this.showTitle, <span>Show title</span>, <Faded>Show title</Faded>)} /
                    {fi(this.showAll, <span>Always show all</span>, <Faded>Always show all</Faded>)}
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}