import {ModalActions, ModalWrapperProps} from "./ModalWrapper";
import Form from "../Form/Form";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {SubjectEmailNotification} from "../../cms/models/__SubjectEmailNotification";
import {useForm, useFormField} from "../Form/state";
import {useSnackbar} from "notistack";
import Client from "../../cms/client";
import {RenderFormFields} from "../Form/utils";
import {PreviewMail} from "../../cms/models/__PreviewMail";
import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import {setRecoil} from "../../state/recoilNexus";
import {confirmationModalAtom} from "./ConfirmationModal";

const FormWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  button {
    margin-top: 23px;
  }
`
type EmailBody = {
    text: string
    page: string
    subject_update_id: string
}

const saveButton: ModalActions = {type: 'ok', hint: 'Save', label: 'Save', color: 'primary'};
const SendEmailNotificationModalContent = (props: ModalWrapperProps & { bubbleUpChanges: (data: EmailBody) => void, page: string}) => {
    const form = useForm();
    const text = useFormField('text')
    const newPage = useFormField('page')

    const {enqueueSnackbar} = useSnackbar();

    const onConfirm = useCallback(async () => {
        props.setError('');
        // validate form
        if (!form.validate()) {
            throw new Error('Please fix the errors and try again');
        }

        setRecoil(confirmationModalAtom, {
            message: <>Are you sure you want to set up this scheduled notification?</>,
            onConfirm: () => {
                const obj = form.state.getSaveObject();
                return Client.sendPreviewMail({
                    content: obj.text,
                    subject: props.page,
                    schedule: obj.date,
                    page: obj.page,
                }).then(() => {
                    enqueueSnackbar("send successfully", {variant: 'success'});
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        props.bubbleUpChanges({text:text.value,subject_update_id:props.page, page: newPage.value} as EmailBody)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text, newPage]);

    useEffect(() => {
        props.setTitle('Schedule email');
        props.setActions(saveButton);
        props.whenConfirm(onConfirm);
        // eslint-disable-next-line
    }, [props]);
    const fields = useMemo(() => {
        return RenderFormFields(form.state);
    }, [form]);

    return <>{fields}</>;
};

const SendEmailPreviewModalContent = (props: ModalWrapperProps&{emailBody:EmailBody|null}) => {
    const form = useForm();
    const {enqueueSnackbar} = useSnackbar();
    const [disablePreview,setDisablePreview] = useState<boolean>(true)
    const emailList = useFormField('email')

    const fields = useMemo(() => {
        return RenderFormFields(form.state);
    }, [form]);

    useEffect(() => {
        const text = props.emailBody?.text
        const container = document.createElement("div")
        container.innerHTML = text || ''
        const textContent = container.textContent || container.innerText || "";
        if (textContent.trim()&&emailList.value&&emailList.value.length>0){
            setDisablePreview(false)
        }else {
            setDisablePreview(true)
        }
    }, [props.emailBody?.text,emailList]);

    const sendPreview = useCallback(async () => {
        props.setError('');
        // validate form
        if (!form.validate()) {
            return
        }
        const obj = form.state.getSaveObject();

        delete obj.__type;
        if (props.emailBody) {
            return Client.sendPreviewMail({
                content:  props.emailBody.text,
                subject: obj.subject_update_id,
                page: props.emailBody.page,
                to: obj.email
            }).then(() => {
                enqueueSnackbar("email preview send successfully", {variant: 'success'});
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form,props.emailBody]);

    return  (
        <FormWrapper>
            {fields}
            <Button sx={{"text-wrap":"nowrap"}}
                    disabled={disablePreview}
                    variant="contained"
                    onClick={sendPreview} >Send preview</Button>
        </FormWrapper>
    );
};
const SendEmailNotificationModal = ({ ...props}: ModalWrapperProps&{page:string}) => {
    const formId = 'send-email-notification-modal';
    const formIdPreview = 'send-email-preview-modal';
    const [bodyMessage,setBodyMessage] = useState<EmailBody|null>(null)

    return (
        <>
            <Form model={SubjectEmailNotification.model} id={formId}>
                <SendEmailNotificationModalContent {...props} bubbleUpChanges={(mailBody)=>setBodyMessage(mailBody)}/>
            </Form>
            <hr/>
            <Form model={PreviewMail.model} id={formIdPreview}>
                <SendEmailPreviewModalContent {...props} emailBody={bodyMessage} />
            </Form>
        </>
    );
};

export default SendEmailNotificationModal;