import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Subject updates",
    description: "Displays a list of [Subject Updates] that match the selected subject.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class SubjectUpdatesWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.SubjectUpdate
    }

    public editable() {
        return false;
    }
}