import { Lists } from '../../utils/lists';
import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { UUID } from '../types';
import { CMSObject, DisplayMode } from './__CMSObject';
import {Pathway} from "./Pathway";

export class CTUnit extends CMSObject {
	public static title = 'Unit';
	public static autoPublish = true;
	public alias: string;
	public code: string;
	public glh: number;
	public name: string;

	public status: boolean;

	public mandatory_size: UUID[];
	public optional_size: UUID[];
	public pathway: UUID[];
	public optional_pathway: UUID[];

	public pathways: Pathway[] = [];
	public optionalPathways: Pathway[] = [];

	constructor(item: any = {}) {
		super(item);

		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.alias = Strings.default(item.alias);
		this.glh = Numbers.default(item.glh);
		this.status = Boolean(item.status);

		this.mandatory_size = Lists.default(item.mandatory_size);
		this.optional_size = Lists.default(item.optional_size);
		this.pathway = Lists.default(item.pathway);
		this.optional_pathway = Lists.default(item.optional_pathway);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
				return this.name;
			default:
				return `${this.alias} - ${this.name}`;
		}
	}

	public qualificationSizes(): UUID[] {
		const tmp: UUID[] = [];
		tmp.push(...this.mandatory_size);
		tmp.push(...this.optional_size);

		this.pathways.forEach(pathway => {
			tmp.push(...pathway.qualification_size);
		})
		this.optionalPathways.forEach(pathway => {
			tmp.push(...pathway.qualification_size);
		})

		return Array.from(new Set(tmp))
	}
}
