import React, {useState} from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import tagsHelpImg from "../../../../../assets/images/tags-help.png";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "@emotion/styled";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    borderRadius: '5px',
    overflow: 'auto',
};
const CustomButton = styled.div`
       position: relative;
       float: right;
       font-size: 14px;
       line-height: 1.57;
       text-align: left;
       color: var(--color-darker-blue);
       display: flex;
       align-items: center;
       cursor: pointer;
       z-index: 1;
`
const StyleContainer = styled(Modal)`
  &.tags-help-modal {
      z-index: 999999;
    .tags-help-modal-header {
      display: flex;
      justify-content: space-between;
      padding: 16px;
    }

    .tags-help-modal-body {
      padding: 16px;

      .tags-help-table-container {
        margin-top: 16px;
        border: 1px solid var(--color-grey);

        .tags-help-table {
          padding-top: 16px;
          width: 100%;
          table-layout: fixed;

          th {
            font-weight: bold;
            text-align: left;
            background-color: var(--color-grey);
            position: relative;

            &.col1 {
              width: 12.5%;
            }

            &.col2 {
              width: 25%;
            }

            &.col3 {
              width: 12.5%;
            }

            &.col4 {
              width: 50%;
            }
          }

          th:not(:last-child):after {
            content: '';
            width: 1px;
            height: 42%;
            background-color: var(--color-border);
            position: absolute;
            right: 0;
            top: 16px;
          }

          thead, tr:not(:last-child) {
            border-bottom: 1px solid var(--color-grey);
          }

          td, th {
            word-break: break-word;
            vertical-align: middle;
            padding: 16px 16px;
          }

          ul {
            list-style-type: disc;
            padding: 0 16px;
          }

          .info {
            display: flex;
            margin-top: 16px;
            padding: 16px;
            border-radius: 4px;
            background-color:var(--color-blue-light);

            .info-text {
              padding-left: 16px;
            }
          }
        }
      }
    }
  }
`

const TagsHelpSection = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <CustomButton data-testid="tags-help-button"
                 className="tags-help-button"
                 onClick={() => {
                     setShowModal(true)
                 }}>
                <HelpOutlineIcon/>
                <span>How to use tags</span>
            </CustomButton>
            <StyleContainer
                data-testid="tags-help-modal"
                className="tags-help-modal"
                open={showModal}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <div className="tags-help-modal-header">
                        <Typography variant="h2">
                            How to use tags
                        </Typography>
                        <IconButton aria-label="close" onClick={handleClose}>
                            <ClearIcon className="tags-help-modal-close"/>
                        </IconButton>
                    </div>
                    <Divider/>
                    <div className="tags-help-modal-body">
                        <Typography variant="body2">
                            This article describes how to use tags when creating items in the CMS to drive how content
                            displays to users of Teach Cambridge.
                            <br/><br/>
                            A variety of tags can be used to serve different purposes. To add tags simply use the format
                            described in the table below. This can be done either individually or in bulk.
                        </Typography>
                        <div className={'tags-help-table-container'}>
                            <table className={'tags-help-table'}>
                                <thead>
                                <tr>
                                    <th className="col1">Title</th>
                                    <th className="col2">Purpose</th>
                                    <th className="col3">Format</th>
                                    <th className="col4">Guidance</th>
                                </tr>
                                </thead>
                                <tbody className={'tags-help-table-body'}>
                                <tr>
                                    <td>Group</td>
                                    <td>
                                        Used to create subgroups within a specific content type and give the subgroup a
                                        heading.
                                    </td>
                                    <td>#group_xxxx</td>
                                    <td>
                                        Add a # at the end of the specific content type that you want to create
                                        subgroups for.
                                        <ul>
                                            <li>
                                                Use the search function to find and select the items to add to the
                                                group.
                                            </li>
                                            <li>
                                                Use the bulk edit function to add a tag to the group. Tags can be added
                                                in bulk or individually. To edit or delete tags this must be done on
                                                each individual item.
                                            </li>
                                            <li>
                                                Add the tag to the items. The tag must be in the format #group_[group
                                                title] e.g. #group Practical Activity Group 1
                                            </li>
                                            <li>
                                                To add a description to the group add /[Description text] after the
                                                group title e.g. #group Practical Activity Group 1/Physiology,
                                                responses, respiration.
                                            </li>
                                        </ul>
                                        <div className="info">
                                            <InfoOutlinedIcon/>
                                            <div className="info-text">
                                                Everything that you put after #group will display as the group header,
                                                and everything after #[group title] / will display as the group
                                                description. If you leave it blank the system will apply the default
                                                description for the content type. This can be toggled on/off within the
                                                widget on the page.
                                            </div>
                                        </div>
                                        <div className="info">
                                            <InfoOutlinedIcon/>
                                            <div className="info-text">
                                                The description tag only needs to be added to one item in the group.
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Online course</td>
                                    <td>
                                        Used to control the CMS media library icon and the card design that renders on
                                        Teach Cambridge.
                                    </td>
                                    <td>#online_course_xxxxx</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Add the <span
                                                className="typography-small-bold">#online_course</span> tag to the
                                                items. This will drive the design of the card when it renders on the
                                                Teacher Website and the CMS media library icon.
                                            </li>
                                            <li>
                                                Optionally, a label can be added at the end of the tag <span
                                                className="typography-small-bold">#online_course_[label title]</span> e.g.
                                                #online_course_essential. This will add the label to the card when it
                                                renders on the Teacher Website.
                                            </li>
                                            <li>e.g.</li>
                                        </ul>
                                        <img src={tagsHelpImg} alt="e.g" width='100%'/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Sort</td>
                                    <td>
                                        Used to control the display order of content items that belong to a specific
                                        content type.
                                    </td>
                                    <td>#sort_xxxxx</td>
                                    <td>
                                        By default, items are sorted alphabetically by title. To make items appear in a
                                        defined order:
                                        <ul>
                                            <li>Select the first item in the group</li>
                                            <li>
                                                Add a tag to define the order. The tag must start with #sort and be
                                                followed by a numerical reference that defines its position in the
                                                group. The table below shows some eligible references
                                            </li>
                                        </ul>
                                        <div className="info">
                                            <InfoOutlinedIcon/>
                                            <div className="info-text">
                                                Sorting examples
                                                <br/>
                                                #sort 1, #sort 2, #sort 3 etc
                                                <br/>
                                                #sort 1.1, #sort 1.2, #sort 1.3, #sort 1.4 etc
                                                <br/>
                                                #sort 1.1a, #sort 1.1b, #sort 1.1c etc
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Key document</td>
                                    <td>Used to bring together relevant documents on the subject home page for users to
                                        see as soon as they log in.
                                    </td>
                                    <td>#key_document</td>
                                    <td>
                                        Add the <span className="typography-small-bold">#key_document</span> tag to all materials which fall under the following content types:
                                        <ul style={{marginLeft: "16px"}}>
                                            <li>Anthology of texts</li>
                                            <li>Qualification handbooks</li>
                                            <li>Qualification calculator</li>
                                            <li>Specification</li>
                                        </ul>
                                        No other groups or sorting need to be applied in order to surface the content
                                        type with this tag in the Key Documents tab.
                                    </td>
                                </tr>
                                <tr>
                                    <td>User tour</td>
                                    <td>Used to identify the pages where user tour should be displayed.
                                    </td>
                                    <td>#user_tour_x</td>
                                    <td>
                                       <ul>
                                           <li>
                                               Add the <span className="typography-small-bold">#user_tour_1</span> tag to the page on which the first part of the user tour should be displayed.
                                           </li>
                                           <li>
                                               Add the <span className="typography-small-bold">#user_tour_2</span> tag to the page on which the second part of the user tour should be displayed.
                                           </li>
                                       </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Box>
            </StyleContainer>
        </>

    );
};

export default TagsHelpSection;