import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Filters,
    name: "Subject filter",
    description: "Allows the user to select a subject by which to filter all the content and other widgets in the page.\n• It can only be added to the page once.\n• It must be the first widget on the page.",
    constrains: {
        once: true,
        firstOnPage: true,
    }
})
export class SubjectFilterWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.FilterByQualification
    }

    editable(): boolean {
        return false;
    }
}