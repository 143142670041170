import React, { useContext, useEffect, useState } from 'react';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { selectedTableItemsSelector, TableContext } from '../state';
import { useRecoilValue } from 'recoil';
import { fi } from '../../../utils/helpers';
import { Messages } from '../../../utils/messages';
import AsyncActionButton from '../../commons/AsyncActionButton';
import Client from '../../../cms/client';

const ItemsReportButton = () => {
	const ctx = useContext(TableContext);
	const state = useRecoilValue(selectedTableItemsSelector(ctx.type));
	const [disabled, setDisabled] = useState(false);
	const [title, setTitle] = useState(Messages.MultipleItemsReport);

	useEffect(() => {
		let shouldDisable = state.count === 0;
		let newTitle: string = fi(shouldDisable, Messages.NoSelectedItem, Messages.MultipleItemsReport);

		setTitle(newTitle as Messages);
		setDisabled(shouldDisable);
	}, [state]);

	const exportItemsReport = async (): Promise<any> => {
		return await Client.exportItemsReport(state.ids)
	}

	return (
		<AsyncActionButton
			data-testid="items-report"
			disabled={disabled}
			title={title}
			onClick={exportItemsReport}
		>

			<AssessmentIcon fontSize="small"/>
		</AsyncActionButton>
	);
};

export default ItemsReportButton;