import styled from '@emotion/styled';
import Add from '@mui/icons-material/Add';
import CloudDone from '@mui/icons-material/CloudDone';
import CloudQueue from '@mui/icons-material/CloudQueue';
import Create from '@mui/icons-material/Create';
import Delete from '@mui/icons-material/Delete';
import MoreVert from '@mui/icons-material/MoreVert';
import Schedule from '@mui/icons-material/Schedule';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Client from '../../cms/client';
import { CMSObject, TreeObject } from '../../cms/models/__CMSObject';
import { cacheBuster } from '../../state/state';
import { Browser } from '../../utils/browser';
import { fi, suspensePromise } from '../../utils/helpers';
import { Lists } from '../../utils/lists';
import { Messages } from '../../utils/messages';
import { Strings } from '../../utils/strings';
import { ActionButton } from '../commons/ActionButton';
import Loader from '../Loader/Loader';
import { useModalDialog } from '../ModalDialogs/effect';
import { actionDefinitions, CustomItemAction } from './Actions';
import { modalAtom, ModalWrapperProps } from '../ModalDialogs/ModalWrapper';
import PublishModal from '../ModalDialogs/PublishModal';
import { setRecoil } from '../../state/recoilNexus';
import { confirmationModalAtom } from '../ModalDialogs/ConfirmationModal';
import { InstanceOf } from '../../cms/models';

export interface ActionMenuProps {
	item: CMSObject;
	onDelete?: () => void;
	onRefresh?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 2px;
`;

const ActionRow = styled(Button)`
  font-size: 14px;
  font-family: var(--font-semi-bold);
  justify-content: flex-start;
  padding: 4px 8px;
  border-radius: 0;

  &.danger {
    color: var(--color-red);

    svg {
      color: var(--color-red);
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: initial !important;
  }

  svg, .MuiCircularProgress-root {
    width: 20px;
    margin-right: 8px;
  }
`;

const errorCache: any = {};

const DeleteButton = ({item, onClick, onDeleted}: {item: CMSObject, onClick: () => void, onDeleted: () => void}) => {
	const [canDeleteResult, setDeleteResult] = useState<any>();
	const {confirmation} = useModalDialog();
	const {enqueueSnackbar} = useSnackbar();

	useEffect(() => {
		setDeleteResult(suspensePromise(item.canDelete()));
		// eslint-disable-next-line
	}, []);

	if (!canDeleteResult) return null;
	const cantDeleteReason = canDeleteResult?.read();

	const onDelete = async () => {
		if (cantDeleteReason) return;
		onClick();
		confirmation(`Are you sure you want to remove "${item.displayLabel()}"?`, async () => {
			return Client.delete(item.getId())
				.then(() => {
					onDeleted();
					enqueueSnackbar(`"${item.displayLabel()}" deleted successfully`, {variant: 'success'});
				})
				.catch((error) => {
					enqueueSnackbar(`Error deleting "${item.displayLabel()}": ` + error, {variant: 'error'});
				});
		});
	};

	return (
		<ActionRow
			className="danger"
			data-testid="delete-action-button"
			disabled={Boolean(cantDeleteReason)}
			onClick={onDelete}
			title={Strings.default(cantDeleteReason, 'Delete')}>
			<Delete/> Delete
		</ActionRow>
	);
};

const ActionMenu = ({item, onDelete, onRefresh}: ActionMenuProps) => {
	const popupState = usePopupState({variant: 'popover', popupId: 'item-action-menu'});
	const [state, setState] = useState<number>(0);
	const cache = useRecoilValue(cacheBuster('action-menu'));
	const setModalEdit = useSetRecoilState(modalAtom);

	const editItem = (item: CMSObject) => {
		popupState.close();
		const routes = item.routes();
		if (routes.editInline) {
			setModalEdit({component: routes.editInline});
		} else if (routes.edit) {
			Browser.navigate(Strings.default(routes.edit));
		}
	};

	const createChildItem = (item: CMSObject) => {
		popupState.close();
		const routes = item.routes();
		if (routes.createChildInline) {
			setModalEdit({component: routes.createChildInline});
		} else if (routes.createChild) {
			Browser.navigate(Strings.default(routes.createChild));
		}
	};

	const showReschedule = (item: CMSObject) => {
		popupState.close();
		setModalEdit({
			component: (props: ModalWrapperProps) => {
				return <PublishModal {...props} items={[item]} onConfirm={() => onRefresh && onRefresh()}/>;
			},
		});
	};

	const unpublish = (item: CMSObject) => {
		popupState.close();
		if (item.isPublished()) {
			setRecoil(confirmationModalAtom, {
				message: <>Are you sure you want to unpublish <strong>{item.displayLabel()}</strong>?</>,
				onConfirm: () => {
					return Client.unpublish({uuids: [item.getId()]}).catch(err => {
						enqueueSnackbar('Failed to unpublish item: ' + err, {variant: 'error'});
					}).then(() => {
						enqueueSnackbar('Item has been unpublished.', {variant: 'success'});
					}).finally(() => {
						onRefresh && onRefresh();
					});
				},
			});
		} else {
			showReschedule(item);
		}
	};

	const items: any[] = useMemo(() => {
		const result: any[] = [];
		const cls = item.classOf();
		const title = Strings.default(cls.title).toLowerCase();
		const objInstance = InstanceOf(item.getType());

		if (item.constructor.prototype instanceof TreeObject) {
			const cantCreateReason = objInstance.canCreate();

			const createButton = (
				<ActionRow
					disabled={Boolean(cantCreateReason)}
					onClick={() => createChildItem(item)}
					data-testid="create-child-action-button"
					title={Strings.default(cantCreateReason, `Create child ${title}`)}>
					<Add/> Create child {title}
				</ActionRow>
			);
			result.push(createButton, <Divider/>);
		}

		const canPublishUnpublish = objInstance.canPublish();
		if (!cls.autoPublish) {
			const rescheduleButton = (
				<ActionRow
					data-testid="reschedule-action-button"
					onClick={() => showReschedule(item)}
					disabled={Boolean(canPublishUnpublish) || !item.isPublished()}
					title={Strings.default(canPublishUnpublish, fi(item.isPublished(), 'Reschedule', Messages.CantReschedule))}>
					<Schedule/> Reschedule
				</ActionRow>
			);

			const publishUnpublishButton = (
				<ActionRow
					data-testid="publish-unpublish-action-button"
					disabled={Boolean(canPublishUnpublish)}
					onClick={() => unpublish(item)}
					title={Strings.default(canPublishUnpublish, fi(item.isPublished(),'Unpublish', 'Publish'))}>
					{fi(item.isPublished(), <CloudQueue/>,
						<CloudDone/>)} {fi(item.isPublished(), 'Unpublish', 'Publish')}
				</ActionRow>
			);
			result.push(rescheduleButton, publishUnpublishButton, <Divider/>);
		}

		const customActions: CustomItemAction[] = Lists.default(cls.actions);
		if (customActions.length) {
			const tmp: any[] = [];
			customActions.forEach((def) => {
				const action = fi(typeof def === 'string', actionDefinitions[def as string], def);
				if (!action) {
					// if there is no action definition then throw an error once and move on
					const key = `${cls.name}-${def}`;
					if (!errorCache[key]) {
						console.warn(`Unknown custom action '${def}' defined in class ${cls.name}`);
						errorCache[key] = true;
					}
					return;
				}

				const disabled = action.disabled(item);
				const label = action.label(item);
				tmp.push(
					<ActionRow
						data-testid={`${action.id}-action-button`}
						title={Strings.default(disabled, label)}
						onClick={() => {
							action.onClick(item);
							popupState.close();
							setState(state + 1);
						}}
						disabled={Boolean(disabled)}>
						{action.icon(item)} {label}
					</ActionRow>,
				);
			});
			if (tmp.length) {
				result.push(...tmp, <Divider/>);
			}
		}

		const editButton = (
			<ActionRow
				onClick={() => editItem(item)}
				data-testid="edit-action-button"
				title={`Edit ${title}`}>
				<Create/> Edit
			</ActionRow>
		);

		const deleteButton = (
			<Suspense fallback={<ActionRow className="danger" title="Checking, please wait" disabled><Loader
				size={20}/> Delete</ActionRow>}>
				<DeleteButton onClick={() => popupState.close()} item={item} onDeleted={() => onDelete && onDelete()}/>
			</Suspense>
		);
		result.push(editButton, deleteButton);

		return result;
		// eslint-disable-next-line
	}, [item, cache, state]);

	return (
		<>
			<ActionButton {...bindTrigger(popupState)}>
				<MoreVert/>
			</ActionButton>
			<Popover {...bindPopover(popupState)}
					 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
					 transformOrigin={{vertical: 'top', horizontal: 'left'}}
					 data-testid="item-action-menu"
			>
				<Wrapper>
					{items.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>)}
				</Wrapper>
			</Popover>
		</>
	);
};

export default ActionMenu;

