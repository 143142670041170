import {useCallback, useEffect} from 'react';
import {atom, useRecoilValue} from "recoil";

export const preventReloadAtom = atom({
    key: 'preventReloadAtom',
    default: false
})

// eslint-disable-next-line
const usePreventReload = (unloadOnly: boolean = false) => {
    const preventReload = useRecoilValue(preventReloadAtom)

    const preventReloadFunction = useCallback(event => {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.returnValue = "All progress will be lost. Are you sure you want to leave?";
    }, []);

    useEffect(() => {
        if (preventReload) {
            window.addEventListener("beforeunload", preventReloadFunction);
            window.addEventListener('popstate', preventReloadFunction, {once: true});
            window.addEventListener('pushstate', preventReloadFunction, {once: true});
        }
        return () => {
            if (preventReload) {
                window.removeEventListener("beforeunload", preventReloadFunction);
                window.removeEventListener('popstate', preventReloadFunction);
                window.removeEventListener('pushstate', preventReloadFunction);
            }
        };
        // eslint-disable-next-line
    }, [preventReload, preventReloadFunction]);
};

export default usePreventReload;