import CloudDone from '@mui/icons-material/CloudDone';
import CloudQueue from '@mui/icons-material/CloudQueue';
import React from 'react';
import { Types } from '../../cms/types';
import {
	ROUTE_LIBRARY,
	ROUTE_LIBRARY_ADD,
	ROUTE_LIBRARY_BROWSE,
	ROUTE_LIBRARY_EDIT,
	ROUTE_PAGE_ADD,
	ROUTE_PAGE_ADD_CHILD,
	ROUTE_PAGE_EDIT,
	ROUTE_PAGES,
} from '../../utils/constants';
import {ITreeItem} from "./Tree";

type RowItemAction = (item: ITreeItem) => React.ReactNode

export interface ITreeConfig {
	// what are the routes where this tree will be displayed
	routes: string[],
	// what model type is the tree displaying/loading
	type: Types,
	param: string | string[],
	// Extra actions buttons on a tree item row
	itemActions?: RowItemAction[]
}

export const TreeConfig = [
	{
		routes: [ ROUTE_LIBRARY, ROUTE_LIBRARY_BROWSE, ROUTE_LIBRARY_ADD, ROUTE_LIBRARY_EDIT ],
		type: Types.FOLDER,
		param: 'folderId'
	},
	{
		routes: [ ROUTE_PAGES, ROUTE_PAGE_ADD, ROUTE_PAGE_ADD_CHILD, ROUTE_PAGE_EDIT ],
		type: Types.PAGE,
		param: ['itemId', 'parentId'],
		itemActions: [
			(item: ITreeItem): React.ReactNode => {
				if (!item || !item.object) {
					return null
				}
				const style = {width: '16px', color: 'var(--color-blue)'};
				const scheduledStyle = {width: '16px', color: 'var(--color-orange)'};

				if (item.object.isDraft()) {
					if (item.object.isScheduled()) {
						return <div title="Scheduled (Draft)"><CloudQueue style={scheduledStyle}/></div>;
					} else if (item.object.isPublished()) {
						return <div title="Published (Draft)"><CloudQueue style={style}/></div>;
					}
				} else if (item.object.isScheduled()) {
					return <div title="Scheduled"><CloudDone style={scheduledStyle}/></div>;
				} else if (item.object.isPublished()) {
					return <div title="Published"><CloudDone style={style}/></div>;
				}
				return null;
			},
		],
	},
];
