import React, {useMemo} from "react";
import MenuButton from "../commons/MenuButton";
import {Strings} from "../../utils/strings";
import {ClassOf, InstanceOf} from "../../cms/models";
import {Browser} from "../../utils/browser";
import {Types} from "../../cms/types";
import {LibraryItemTypes} from "../../cms/models/__Library";

export const CreateMediaLibraryItem = () => {
    const navigateTo = (itemType: string) => {
        const objectInstance = InstanceOf(itemType, false);
        Browser.navigate(objectInstance.routes().create);
    };

    return useMemo(() => {
        const objectClass = ClassOf(Types.LIBRARY, false);
        const objectInstance = InstanceOf(Types.LIBRARY, false);

        if (!objectClass) {
            return null;
        }

        const cantCreateReason = objectInstance.canCreate();
        const label = <>Create {Strings.default(objectClass.title).toLowerCase()}</>;
        const menuItems: any[] = LibraryItemTypes.map(item => {
            const itemInstance = ClassOf(item);
            return {id: item, label: itemInstance.title};
        });
        return (
            <MenuButton key={'1'}
                        disabled={Boolean(cantCreateReason)}
                        title={Strings.default(cantCreateReason, 'Select the type of item you wish to create')}
                        onClick={(item) => navigateTo(item)}
                        label={label}
                        menuItems={menuItems}
            />
        )
    }, [])
}