import {getRecoil, setRecoil} from '../../state/recoilNexus';
import { sessionAtom } from '../../state/session';
import { Lists } from '../../utils/lists';
import { Messages } from '../../utils/messages';
import { Strings } from '../../utils/strings';
import Client from '../client';
import {Types, UUID} from '../types';
import { CMSObject } from './__CMSObject';
import {columnDefinition, tableColumn, tableConfig} from '../../utils/decorators';
import {ColumnType} from "../../components/TableComponent/renderers";
import {pagesAtom} from "../../state/state";
import {Page} from "./Page";
import {fi} from '../../utils/helpers';
import {FormEvent} from '../../components/Form/model';

@tableConfig({
	initialLoad: async () => {
		// We need to load pages and content groups before the table loads,
		// so we can display on what pages is the content group referenced on
		const res = await Client.query<Page>({
			types: [Types.PAGE],
			limit: -1
		})
		setRecoil(pagesAtom, res.results);
	}
})
export class ContentGroup extends CMSObject {
	public static title = 'Content group';
	public static autoPublish = true;

	@columnDefinition({
		label: 'Title'
	})
	public name: string;
	public description: string;
	public content_types: UUID[];

	constructor(object: any = {}) {
		super(object);

		this.name = Strings.default(object.name);
		this.description = Strings.default(object.description);
		this.content_types = Lists.default(object.content_types);
	}

	public canCreate(): string {
		const user = getRecoil(sessionAtom)
		return fi(!user?.isPublisher(), 'You do not have permission to create content groups.', '')
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom)
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete
		}
		const usage = await Client.checkUsage(this.getId());
		if (usage.items_count > 0) {
			return Messages.CantDeletePublishedAssociations;
		}
		return '';
	}

	public formOnSaveError(evt: FormEvent): Error | void {
		if (evt.error.message.toLowerCase().includes('value already exists: name')) {
			evt.state.setError('name', Messages.DuplicateValue)
			evt.error.message = Messages.FixErrors
		}
		return evt.error
	}

	@tableColumn({
		order: 3.5,
		label: 'Referenced on',
		field: 'referenced_on',
		type: ColumnType.Text,
	})
	public __referencedOn(_plain?: boolean): string | React.ReactNode {
		const pages = getRecoil(pagesAtom)
		const res = pages.filter(p => p.hasContentGroup(this.getId())).map(p => p.displayLabel())
		return res.join(', ')
	}
}
