import { Strings } from '../../utils/strings';
import { CMSObject } from './__CMSObject';
import {FormEvent} from '../../components/Form/model';
import {Messages} from '../../utils/messages';

export class UsefulLink extends CMSObject {
	public static title = 'Useful Link';
	public static autoPublish = true;
	public title: string;
	public link: string;
	public display_text: string;
	public description: string;
	constructor(obj: any = {}) {
		super(obj);

		this.title = Strings.default(obj.title, '');
		this.link = Strings.default(obj.link, '');
		this.display_text = Strings.default(obj.display_text, '');
		this.description = Strings.default(obj.description, '');
	}

	public formOnSaveError(evt: FormEvent): Error | void {
		if (evt.error.message.toLowerCase().includes('value already exists: title')) {
			evt.state.setError('title', Messages.DuplicateValue)
			evt.error.message = Messages.FixErrors
		}
		return evt.error
	}
}
