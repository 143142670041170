import {BaseClass} from './__base';
import {DisplayMode} from './__CMSObject';
import {UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import {ReactComponent as DOCIcon} from '../../assets/images/DOC.svg';
import {ReactComponent as XLSIcon} from '../../assets/images/XLS.svg';
import {ReactComponent as XLSMIcon} from '../../assets/images/XLSM.svg';
import {ReactComponent as CSVIcon} from '../../assets/images/CSV.svg';
import {ReactComponent as PPTIcon} from '../../assets/images/PPT.svg';
import {ReactComponent as ZIPIcon} from '../../assets/images/ZIP.svg';
import {ReactComponent as PDFIcon} from '../../assets/images/PDF.svg';
import {ReactComponent as AudioIcon} from '../../assets/images/AUDIO.svg';
import {ReactComponent as ImageIcon} from '../../assets/images/IMAGE.svg';
import Photo from '@mui/icons-material/Photo';
import React from 'react';
import {fi} from '../../utils/helpers';

export class CMSFile extends BaseClass {
	public static readonly Extensions: any = {
		PDF: ['pdf'],
		CSV: ['csv'],
		Excel: ['xls', 'xlsx'],
		MathExcel: ['xlsm'],
		Doc: ['doc', 'docx'],
		Image: ['jpeg', 'jpg', 'png', 'gif', 'jfif'],
		PPT: ['ppt', 'pptx'],
		ZIP: ['zip'],
		Audio: ['mp3', 'mp4', 'wav'],
	};

	public static ContentTypeExtensions: any = {
		'application/pdf': 'pdf',
		'text/csv': 'csv',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
		'application/vnd.ms-excel': 'xls',
		'application/vnd.ms-excel.sheet.macroEnabled.12': 'xlsm',
		'application/msword': 'doc',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
		'image/jpeg': 'jpeg',
		'image/jpg': 'jpg',
		'image/png': 'png',
		'image/gif': 'gif',
		'application/vnd.ms-powerpoint': 'ppt',
		'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
		'application/zip': 'zip',
		'application/x-zip-compressed': 'zip',
		'audio/mpeg': 'mp3',
		'video/mp4': 'mp4',
		'audio/wav': 'wav',
	};

	public static readonly ExtensionNames: any = {
		'.mp4': 'MP4 audio (.mp4)',
		'.mp3': 'MP3 audio (.mp3)',
		'.pdf': 'PDF (.pdf)',
		'.ppt': 'PPT (.ppt, .pptx)',
		'.pptx': 'PPT (.ppt, .pptx)',
		'.doc': 'Word (.doc, .docx)',
		'.docx': 'Word (.doc, .docx)',
		'.xls': 'Excel (.xls, .xlsx, .xlsm)',
		'.xlsx': 'Excel (.xls, .xlsx, .xlsm)',
		'.xlsm': 'Excel (.xls, .xlsx, .xlsm)',
		'.zip': 'Zip (.zip)',
		'.csv': 'CSV (.csv)',
		'.jpg': 'JPEG (.jpg, .jpeg, .jpe, .jfif)',
		'.jpeg': 'JPEG (.jpg, .jpeg, .jpe, .jfif)',
		'.jpe': 'JPEG (.jpg, .jpeg, .jpe, .jfif)',
		'.jfif': 'JPEG (.jpg, .jpeg, .jpe, .jfif)',
		'.png': 'PNG (.png)',
		'.gif': 'GIF (.gif)',
		'.wav': 'WAV audio (.wav)',
		'.*': 'Any (.*)',
	};

	public fileuid: UUID;
	public size: number;
	public filename: string;
	public contenttype: string;
	public hash: string;
	public created: Date; // Date string
	public author: string;
	public data?: any;

	constructor(data: any = {}) {
		super(data);

		this.fileuid = data.fileuid;
		this.size = data.size;
		this.filename = Strings.default(data.filename, '-');
		this.contenttype = Strings.default(data.contenttype);
		this.hash = Strings.default(data.hash);
		this.created = new Date(data.created);
		this.author = Strings.default(data.author, '-');
		if (data.data) {
			this.data = data.data;
		}
	}

	public get extension(): string {
		return Strings.default(this.filename.split('.').pop());
	}

	public get formattedSize(): string {
		return Numbers.asFileSize(this.size);
	}

	public displayLabel(_options: DisplayMode = DisplayMode.SHORT): string {
		return this.__data.filename;
	}

	public isDoc(): boolean {
		return CMSFile.Extensions.Doc.includes(this.extension);
	}

	public isExcel(): boolean {
		return CMSFile.Extensions.Excel.includes(this.extension);
	}

	public isMathExcel(): boolean {
		return CMSFile.Extensions.MathExcel.includes(this.extension);
	}

	public isPDF(): boolean {
		return CMSFile.Extensions.PDF.includes(this.extension);
	}

	public isCSV(): boolean {
		return CMSFile.Extensions.CSV.includes(this.extension);
	}

	public isImage(): boolean {
		return CMSFile.Extensions.Image.includes(this.extension);
	}

	public isAudio(): boolean {
		return CMSFile.Extensions.Audio.includes(this.extension);
	}

	public isZIP(): boolean {
		return CMSFile.Extensions.ZIP.includes(this.extension);
	}

	public isPPT(): boolean {
		return CMSFile.Extensions.PPT.includes(this.extension);
	}

	public isOffice(): boolean {
		return this.isPPT() || this.isDoc() || this.isExcel() || this.isMathExcel() || this.isCSV();
	}

	public icon(plain: boolean = false): any {
		if (this.isDoc()) {
			return fi(plain, 'Word document item', <DOCIcon title='Word document item' />);
		}
		if (this.isExcel()) {
			return fi(plain, 'Word Excel document item', <XLSIcon title='Excel document item' />);
		}
		if (this.isMathExcel()) {
			return fi(plain, 'Excel macro document item', <XLSMIcon title='Excel macro document item' />);
		}
		if (this.isCSV()) {
			return fi(plain, 'CSV document item', <CSVIcon title='CSV document item' />);
		}
		if (this.isPPT()) {
			return fi(plain, 'PowerPoint document item', <PPTIcon title='PPT content item' />);
		}
		if (this.isZIP()) {
			return fi(plain, 'ZIP document item', <ZIPIcon title='ZIP content item' />);
		}
		if (this.isPDF()) {
			return fi(plain, 'PDF document item', <PDFIcon title='PDF document item' />);
		}
		if (this.isAudio()) {
			return fi(plain, 'Audio document item', <AudioIcon title='Audio item' />);
		}
		if (this.isImage()) {
			return fi(plain, 'Image content item', <ImageIcon title='Image content item' />);
		}
		return fi(plain, 'Unknown content item', <Photo style={{fontSize: '36px', color: 'grey', fill: 'grey'}} />);
	}
}
