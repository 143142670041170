import React, {Suspense, useMemo} from "react";
import {IFeatureFlag} from "../../cms/models/__Interfaces";
import {Lists} from "../../utils/lists";
import Client from "../../cms/client";
import {useSnackbar} from "notistack";
import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import {Strings} from "../../utils/strings";
import RefreshIcon from '@mui/icons-material/Refresh';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ActionButton} from "../commons/ActionButton";
import {fi} from "../../utils/helpers";
import {cacheBuster} from "../../state/state";
import {selector, useRecoilValue} from "recoil";
import {setRecoil} from "../../state/recoilNexus";
import PublicIcon from '@mui/icons-material/Public';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import {bindPopover, bindTrigger} from "material-ui-popup-state";
import CloudIcon from '@mui/icons-material/Cloud';
import {usePopupState} from "material-ui-popup-state/hooks";
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ManIcon from '@mui/icons-material/Man';
import PlaceIcon from '@mui/icons-material/Place';

const BlueSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#0074bd !important',
        '&:hover': {
            backgroundColor: '#0074bd00 !important',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0074bd !important',
    },
}));

const FlagRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .flag-name {
    min-width: 120px;
    font-family: var(--font-semi-bold);
  }

  .flag-description {
    color: var(--color-border);
  }

  .segmentation {
    min-width: 40px;
    opacity: 0.5;
    color: var(--color-green);

    svg {
      cursor: help;
    }
  }
`;


const featureFlagsSelector = selector<IFeatureFlag[]>({
    key: 'featureFlags',
    get: async ({get}) => {
        get(cacheBuster('featureFlags'));
        let flags = await Client.getFeatureFlags();
        Lists.sort(flags, "flagid");
        return flags
    }
})

const Wrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  svg {
    width: 16px;
  }

  & > div {
    padding: 0 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;

    label {
      font-family: var(--font-semi-bold);
    }
  }
`

const FlagSegmentation = ({flag}: { flag: IFeatureFlag }) => {
    const popupState = usePopupState({variant: 'popover', popupId: 'account-dropdown'});

    const testGroups = useMemo(() => {
        if (!flag.testgroups) {
            return null
        }
        return flag.testgroups.map(group => {
            let icon: any;
            let label: any;
            let value: any;

            if (group.percentage) {
                icon = <ManIcon/>;
                label = 'Percentage';
                value = group.percentage + '%';
            }

            if (group.ip) {
                icon = <PublicIcon/>;
                label = "IP";
                value = group.ip;
            }

            if (group.country) {
                icon = <PlaceIcon/>;
                label = "Location";
                value = group.country + fi(group.city, ", " + group.city, '');
            }

            return (
                <div>
                    {icon}
                    <label>{label}</label>
                    <span>{value}</span>
                </div>
            )
        })

    }, [flag])

    return (
        <>
            <span title={'Segmentation applied. Click for more information'}>
                <DisplaySettingsIcon  {...bindTrigger(popupState)} />
            </span>
            <Popover {...bindPopover(popupState)}
                     anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                     transformOrigin={{vertical: 'top', horizontal: 'left'}}
                     data-testid="item-action-menu"
            >
                <Wrapper>
                    {fi(flag.environment,
                        <div>
                            <CloudIcon/> <label>Environment</label>
                            <span>{Lists.default(flag.environment).join(',')}</span>
                        </div>
                    )}
                    {fi(flag.module,
                        <div>
                            <DeveloperBoardIcon/> <label>Module</label>
                            <span>{flag.module}</span>
                        </div>
                    )}
                    {testGroups}
                </Wrapper>
            </Popover>
        </>
    )
}

const FeatureList = () => {
    const features = useRecoilValue(featureFlagsSelector);
    const {enqueueSnackbar} = useSnackbar();

    const toggleFlagState = (flag: IFeatureFlag, checked: boolean) => {
        if (checked) {
            flag.enabled = (new Date()).toISOString();
            delete (flag.disabled);
        } else {
            delete (flag.enabled);
            delete (flag.disabled);
        }
        Client.updateFeatureFlag(flag).then(() => {
            setRecoil(cacheBuster('featureFlags'), (val) => val + 1)
            enqueueSnackbar('Changes saved successfully', {variant: 'success'})
        }).catch((err) => {
            enqueueSnackbar('Error saving changes! ' + err.message, {variant: 'error'});
        });
    };

    const hasSegmentation = (flag: IFeatureFlag) => {
        return flag.testgroups || flag.module || flag.environment
    }

    const flags = useMemo(() => {
        return features.map((flag, idx) => (
            <FlagRow key={idx}>
                <div className={'flag-state'}>
                    <BlueSwitch size={'medium'} disabled={flag.name === 'ControlPanel'}
                                checked={Boolean(flag.enabled)} value={flag.flagid}
                                onChange={(_evt, checked) => {
                                    toggleFlagState({...flag}, checked);
                                }}/>
                </div>
                <div className={'flag-name'}>{flag.name}</div>
                <div className={'segmentation'}>{fi(hasSegmentation(flag), <FlagSegmentation flag={flag}/>)}</div>
                <div className={'flag-description'}>{Strings.default(flag.description, 'No description')}</div>
            </FlagRow>
        ))
        // eslint-disable-next-line
    }, [features]);

    if (features.length === 0) {
        return (
            <p className={'no-flags'}>
                There are no feature flags defined
            </p>
        );
    }

    return (
        <>
            {flags}
        </>
    );
};


const FeatureFlags = () => {
    const {enqueueSnackbar} = useSnackbar();

    const refresh = async () => {
        setRecoil(cacheBuster('featureFlags'), (val) => val + 1)
    };

    const redeploy = async () => {
        return Client.redeployFeatureFlags().then(() => {
            enqueueSnackbar('Feature flags were redeployed successfully', {variant: 'success'})
            refresh();
        }).catch((err) => {
            enqueueSnackbar('Error trying to redeploy feature flags: ' + err.message, {variant: 'success'})
        });
    }

    return (
        <div data-testid='feature-flags-container'>
            <div className='flex-row spaced'>
                <Typography variant={'h4'}>Feature Flags</Typography>
                <div>
                    <ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
                                  data-testid={'refresh'}>
                        <RefreshIcon/>
                    </ActionButton>
                    <ActionButton variant="text" title={'Redeploy\nResends all flag states to all applications'}
                                  onClick={redeploy} tabIndex={0}
                                  data-testid={'redeploy'}>
                        <CloudUploadIcon/>
                    </ActionButton>
                </div>
            </div>
            <Divider className='mt8 mb24'/>
            <Suspense fallback={null}>
                <FeatureList/>
            </Suspense>
        </div>
    )
}

export default FeatureFlags;
