import React, {useState} from "react";
import {useRecoilValue} from "recoil";
import {selectedObject} from "../../../state/state";
import {Messages} from "../../../utils/messages";
import Client from "../../../cms/client";
import {Types} from "../../../cms/types";
import {TextArea} from "../../Form/renderers/components/TextArea/TextArea";
import Button from '@mui/material/Button';
import styled from "@emotion/styled";

const BoxWrapper = styled.div`
  height: 80px;
  margin: 8px 16px 0 16px;
`

export const BoxFooter = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  button.expand {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }

  .spacer {
    flex-grow: 1;
  }
`
const ErrorMessage = styled.span`
  margin-top: 8px;
  font-size: 14px;
  color: var(--color-red)
`


export const NoteForm = ({onClose}: { onClose: (shouldRefresh: boolean) => void }) => {
    const [note, setNote] = useState('');
    const item = useRecoilValue(selectedObject);
    const [error, setError] = useState('');

    const saveNote = () => {
        if (!item) {
            return;
        }
        if (note.trim() === '') {
            setError(Messages.FieldIsRequired);
            return;
        }

        Client.create({
            __type: Types.ITEM_NOTE,
            comment: note.trim(),
            item: item.getId()
        }).catch().finally(() => {
            onClose(true);
        })
    }

    return (
        <>
            <BoxWrapper>
                <TextArea autoFocus={true}
                          placeholder='Add your note here...'
                          autoCorrect='off'
                          value={note}
                          error={Boolean(error)}
                          max={100}
                          helperText={error}
                          onChange={(evt: any) => setNote(evt.target.value)}/>
                {Boolean(error)&&<ErrorMessage>{error}</ErrorMessage>}
            </BoxWrapper>
            <BoxFooter>
                <div className='spacer'/>
                <Button variant='outlined' onClick={() => onClose(false)}>Cancel</Button>
                <Button variant='contained' onClick={saveNote}>Add</Button>
            </BoxFooter>
        </>
    )
}