import React from "react";
import {Job} from "../../../cms/models/Job";
import styled from "@emotion/styled";
import Spacer from "../../commons/Spacer";
import {Dates} from "../../../utils/dates";
import {Strings} from "../../../utils/strings";
import {getTrigger} from "./JobConfig";

const Wrapper = styled.div`
  border: 1px solid var(--color-border);
  min-width: 300px;
  max-width: 300px;
  min-height: 150px;
  cursor: pointer;
`

const Header = styled.div`
  display: flex;
  padding: 4px;
  font-size: 14px;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-border);
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-size: 14px;
  row-gap: 8px;

  div {
    display: flex;
    align-content: center;
    column-gap: 4px;
  }
  
  span {
    font-weight: 600;
  }
`

export const JobStatus = styled.div`
  text-transform: uppercase;

  &.idle {
    color: var(--color-border)
  }

  &.completed {
    color: var(--color-green)
  }

  &.running {
    color: var(--color-blue);
    font-weight: bold;
  }

  &.erorr {
    color: var(--color-red)
  }
`

const JobBox = ({job, onClick}: { job: Job, onClick: (job: Job) => void }) => {
    return (
        <Wrapper onClick={() => onClick(job)}>
            <Header>
                <strong>
                    {job.name}
                </strong>
                <Spacer/>
                <JobStatus className={job.status()}>
                    {job.status()}
                </JobStatus>
            </Header>
            <Body>
                <div>
                    {job.description}
                </div>
                <div>
                    Last run: <span>{Strings.default(Dates.timeAgo(Strings.default(job.lastRun())), 'Never')}</span>
                </div>
                <div>
                    Nodes: <span>{job.nodes.length}</span>
                </div>
                <div>
                    Trigger: {getTrigger(job)}
                </div>
            </Body>
        </Wrapper>
    )
}

export default JobBox