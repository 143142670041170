import { setRecoil } from '../../state/recoilNexus';
import { confirmationModalAtom } from './ConfirmationModal';

export const useModalDialog = () => {
	const confirmation = (message, onConfirm: () => Promise<void>, onCancel?: () => void) => {
		setRecoil(confirmationModalAtom, { message, onConfirm, onCancel });
	}

	const modal = () => {

	}

	return {
		confirmation,
		modal
	}
}

