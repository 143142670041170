import {useForm} from "../../../components/Form/state";
import {useSnackbar} from "notistack";
import {useModalDialog} from "../../../components/ModalDialogs/effect";
import React, {useEffect} from "react";
import {Strings} from "../../../utils/strings";
import {Browser} from "../../../utils/browser";
import Button from '@mui/material/Button';
import {fi} from "../../../utils/helpers";
import Loader from "../../../components/Loader/Loader";

export const DeleteButton = ({disabled}: {disabled: boolean}) => {
    const form = useForm();
    const {enqueueSnackbar} = useSnackbar()
    const {confirmation} = useModalDialog();
    const [reason, setReason] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        if (form.state.createMode) {
            return
        }

        if (form.state.object.getId()) {
            setLoading(true)
            form.state.object.canDelete().then(r => {
                setReason(r)
            }).catch(() => {
                setReason("Error checking object usage")
            }).finally(() => {
                setLoading(false)
            })
        }
        // eslint-disable-next-line
    }, [form.state.object])

    if (form.state.createMode) {
        return null;
    }

    const onDelete = (evt) => {
        evt.preventDefault();
        if (Boolean(reason)) return;

        confirmation(`Are you sure you want to delete this ${form.state.model.model.name}?`, async () => {
            const targetLocation = Strings.default(form.state.object.routes().list, '/');
            return form.state.object.delete(form.state.context.uid).catch((e) => {
                enqueueSnackbar(`Error deleting ${form.state.model.model.name}: ` + e.message, {variant: 'error'})
            }).then(() => {
                enqueueSnackbar(`${form.state.model.model.name} deleted successfully`, {variant: 'success'});
                Browser.navigate(targetLocation);
            })
        });
    }

    return (
        <Button variant="contained"
                disabled={loading || Boolean(reason) || disabled}
                color='error'
                title={fi(loading, 'Checking for object usage, please wait...', fi(Boolean(reason), reason, 'Delete'))}
                onClick={onDelete}>{fi(loading, <>&nbsp;<Loader size={24} inverted/></>, 'Delete')}</Button>
    )
}