import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Filters,
    name: "Size/Pathway",
    description: "Cambridge Technicals filter used to filter content by Qualification Size and Pathway where it is the case.\n• It can only be added to the page once.\n• It must be added after the [Subject filter] widget.",
    constrains: {
        onlyAfter: WidgetType.FilterByQualification,
        once: true,
    }
})
export class SizePathwayWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.SizePathway
    }

    public editable() {
        return false;
    }
}