import React, {useEffect} from "react";
import {Strings} from "../../../../../utils/strings";
import styled from "@emotion/styled";
import {fi} from "../../../../../utils/helpers";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  
  &:after {
    display: ${(props: any) => fi(typeof props['data-max'] === "undefined", 'none', 'block')};
    position: absolute;
    content: attr(data-length) "/" attr(data-max);
    font-size: 14px;
    color: var(--color-border);
    right: 8px;
    bottom: 8px;
  }

  &.error textarea {
    border: 1px solid var(--color-red);
  }
  
  textarea {
    padding: ${(props: any) => fi(typeof props['data-max'] === "undefined", '8px', '8px 50px 8px 8px')};
  }
`

const TextFieldElement = styled.textarea`
  box-sizing: border-box;
  padding: 8px;
  resize: none;
  font-family: var(--font-regular);
  border: 1px solid var(--color-border);
  font-size: 16px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: white;
  color: ${props => props.disabled ? 'var(--color-border)' : 'inherit'};
`

export const TextArea = (props: any) => {
    const [length, setLength] = React.useState(0);

    useEffect(() => {
        setLength(Strings.default(props.value).length);
    }, [props.value])

    const onChange = (e) => {
        if (props.max && props.max < e.target.value.length) {
            e.preventDefault()
            return
        }

        setLength(e.target.value.length);
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <Wrapper data-length={length} data-max={props.max} className={fi(props.error, 'error')}>
            <TextFieldElement
                error={true}
                {...props}
                onChange={onChange}
            >
                {props.value}
            </TextFieldElement>
        </Wrapper>
    )
}