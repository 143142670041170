import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { DisplayMode, ItemRoutes, TreeObject } from './__CMSObject';
import { ROUTE_PAGE_ADD, ROUTE_PAGE_ADD_CHILD, ROUTE_PAGE_EDIT } from '../../utils/constants';
import { Types, UUID } from '../types';
import {cacheBuster, pagesSelector, references, selectedTreeItem} from '../../state/state';
import { getRecoil, setRecoil } from '../../state/recoilNexus';
import { ContentGroup } from './ContentGroup';
import { formField, formHidden, linkedFormField, tableHidden, tableIgnored } from '../../utils/decorators';
import { FieldType } from './__ModelInfo';
import { WidgetType } from './PageWidget';
import { FormEvent, IFormEvents } from '../../components/Form/model';
import Client from '../client';
import { fi } from '../../utils/helpers';
import { Messages } from '../../utils/messages';
import { sessionAtom } from '../../state/session';

export class Page extends TreeObject implements IFormEvents {
	public static title = 'Page';

	@formField({
		group: 'title-group',
	})
	public title: string;
	public description: string;
	@formField({
		order: 3,
		group: 'homepage-type',
	})
	public is_home_page: boolean;

	@formField({
		order: 3.1,
		group: 'homepage-type',
	})
	public is_landing_page: boolean;


	@formField({
		fieldtype: FieldType.WidgetList,
		flags: {hidden: false},
		order: 4,
	})
	@linkedFormField('page_type')
	public config: string;

	@linkedFormField('page_type')
	public link: string;

	@formHidden()
	public qualification_group_mapping: string[];
	@formHidden()
	public qualification_mapping: string[];

	@formField({
		group: 'title-group',
		order: 0.5,
		fieldtype: FieldType.IconList,
	})
	@tableIgnored
	public icon: string;

	@formField({
		name: 'Product mapping',
		fieldtype: FieldType.ProductData,
		flags: {required: true, subjectOnly: true},
		order: 3.3,
	})
	public productData: string = '';

	@formField({
		flags: {hidden: true},
	})
	public all_assessments: boolean = false;

	@formField({
		name: 'Page type',
		fieldtype: FieldType.List,
		defaultvalue: [{lang: 'en', text: 'Widget'}],
		order: 3.8,
		uid: 'page_type',
		config: {
			isRadioGroup: true,
			options: [[{lang: 'en', text: 'Widget'}], [{lang: 'en', text: 'Link'}]],
		},
	})
	public page_type: string;

	constructor(item: any = {}) {
		super(item);
		getRecoil(pagesSelector);
		this.title = Strings.default(item.title);
		this.description = Strings.default(item.description);
		this.is_home_page = Boolean(item.is_home_page);

		let tmp: any;
		if (typeof item.config === 'string') {
			tmp = Lists.default<any>(JSON.parse(item.config));
		} else if (Array.isArray(item.config)) {
			tmp = item.config;
		} else {
			tmp = [];
		}

		const config = tmp;

		// needed to differentiate between widgets of same type
		config.forEach(config => {
			config.id = crypto.randomUUID();
		});

		this.config = JSON.stringify(config);

		this.qualification_group_mapping = Lists.default<string>(item.qualification_group_mapping);
		this.qualification_mapping = Lists.default<string>(item.qualification_mapping);
		this.icon = Strings.default(item.icon);

		this.productData = [...this.qualification_mapping, ...this.qualification_group_mapping].join(',');
		if (this.productData.length === 0) {
			this.productData = 'all_assessments';
			this.all_assessments = true;
		}
		this.link = Strings.default(item.link);
		this.page_type = fi(Boolean(this.link), 'Link', 'Widget');
		this.is_landing_page = Boolean(item.is_landing_page);
	}

	public routes(): ItemRoutes {
		return {
			edit: ROUTE_PAGE_EDIT.replace(':itemId', this.getId()),
			list: `/${Types.PAGE}`,
			create: ROUTE_PAGE_ADD,
			createChild: ROUTE_PAGE_ADD_CHILD
				.replace(':itemId', 'create')
				.replace(':parentId', this.getId())
				.replace(':type', this.getType()),
		};
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		if (options === DisplayMode.DETAILED) {
			const name: string[] = [this.title];
			let parent = this.parent;
			if (parent) {
				if (parent === this.getId()) {
					return '';
				}
				const parentPage = getRecoil(references(parent));
				if (parentPage) {
					name.unshift(parentPage.displayLabel(DisplayMode.DETAILED));
				}
			}
			return name.join(' ▸ ');
		}
		return this.title;
	}

	public canCreate(): string {
		const user = getRecoil(sessionAtom);
		return fi(!user?.isPublisher(), 'You do not have permission to create pages/child pages.', '');
	}

	public canPublish(): string {
		const user = getRecoil(sessionAtom);
		return fi(!user?.isPublisher(), 'You do not have permission to publish/unpublish pages', '');
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom);
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete;
		}
		const tmp = await Client.checkUsage(this.getId());
		if (this.isPublished()) {
			return Messages.CantDeletePublishedItem;
		}
		return fi(tmp.items_count !== 0, Messages.CantDeleteParentPage, '');
	}

	public hasContentType(contentTypeId: UUID): boolean {
		const config = JSON.parse(this.config);
		const hasContentTypeWidget = config.find(widget =>
			// is a straight content type widget
			widget.type === WidgetType.ContentType &&
			widget.contentType === contentTypeId,
		);
		if (hasContentTypeWidget) {
			return true;
		}
		const contentGroups = config.filter(widget => widget.type === 'content_group');
		return contentGroups.map(widget => {
			const group = getRecoil(references(widget.contentGroup)) as ContentGroup | null;
			return group?.content_types.includes(contentTypeId);
		}).filter(a => Boolean(a)).length > 0;
	}

	public hasTopic(topicId: UUID): boolean {
		const config = JSON.parse(this.config);
		const widget = config.find(widget =>
			// is a straight content type widget
			widget.type === WidgetType.FreeText &&
			widget.topic === topicId,
		);
		return Boolean(widget);
	}

	formOnValidate(evt: FormEvent): any {
		const errors: any = {};

		const values = evt.state.values;
		//const pages = Lists.default<Page>(getRecoil(treeObjectsSelector(Types.PAGE)));
		// remove this??
		// if (values.is_home_page) {
		// 	for (const page of pages) {
		// 		if (page.is_home_page && page.getId() !== this.getId()) {
		// 			errors.is_home_page = `'${page.displayLabel()}' page is configured as home page`;
		// 		}
		// 	}
		// }

		if (values.page_type === 'Widget') {
			if (values.config === '[]') {
				errors.config = Messages.FieldIsRequired;
			}
		} else {
			if (!values.link.trim()) {
				errors.link = Messages.FieldIsRequired;
			}
		}
		return errors;
	}

	public formOnRenderField(evt: FormEvent): boolean {
		if (evt.fieldUID === 'config') {
			return evt.state.values.page_type === 'Widget';
		}
		if (evt.fieldUID === 'link') {
			return evt.state.values.page_type === 'Link';
		}
		return true;
	}

	formOnBeforeSave(evt: FormEvent): any {
		const selectedPage = getRecoil(selectedTreeItem);
		const data = {...evt.state.values};
		if (selectedPage && selectedPage.id !== this.getId()) {
			data.parent = selectedPage.id;
			data.order = selectedPage.children.length;
		}
		if (!data.icon) {
			delete data.icon;
		}
		if (data.page_type === 'Widget') {
			delete (data.link);
		} else if (data.page_type === 'Link') {
			delete (data.config);
		}
		return data;
	}

	formOnSaveSuccess(): any {
		setRecoil(cacheBuster(this.getType()), (val) => val + 1);
		setRecoil(cacheBuster("pagesSelector"), (val) => val + 1);
	}

	configList(): any[] {
		return JSON.parse(this.config);
	}

	public hasContentGroup(groupId: UUID): boolean {
		const config = JSON.parse(this.config);
		const widget = config.find(widget =>
			// is a straight content type widget
			widget.type === WidgetType.ContentGroup &&
			widget.contentGroup === groupId,
		);
		return Boolean(widget);
	}

	public hasSubjectUpdates(): boolean {
		const config = JSON.parse(this.config);
		const widget = config.find(widget =>
			// is a straight content type widget
			widget.type === WidgetType.SubjectUpdate || widget.type === WidgetType.GlobalAnnouncements,
		);
		return Boolean(widget);
	}

	public disabledFields(): string[] {
		const pages = getRecoil(pagesSelector);
		const atLeastOneHomePage = (Array.isArray(pages)?pages:[]).filter(page => page.is_home_page);
		const fields:string[] = [];
		if (atLeastOneHomePage.length&&!this.is_home_page) {
			fields.push('is_home_page');
		}

		if (this.is_home_page) {
			fields.push('productData');
		}

		return fields;
	}

	public formOnFieldChange(evt: FormEvent): void {
		if (evt.fieldUID === 'is_home_page') {
			evt.state.fieldState('productData').disabled = Boolean(evt.fieldValue);
			evt.state.fieldState('productData').setValue('all_assessments');
			evt.state.fieldState('productData').setError(undefined);
			evt.state.fieldState('all_assessments').setValue(true);
			evt.state.fieldState('qualification_group_mapping').setValue(undefined);
			evt.state.fieldState('qualification_mapping').setValue(undefined);
			evt.state.fieldState('productData').refresh()
		}
	}
}

