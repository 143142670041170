import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { CMSObject } from './__CMSObject';

export class BusinessStream extends CMSObject {
	public static title = 'Business stream';
	public static autoPublish = true;
	public name: string;
	public id: number;

	constructor(item: any = {}) {
		super(item);

		this.name = Strings.default(item.name);
		this.id = Numbers.default(item.id);
	}
}
