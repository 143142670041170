import styled from "@emotion/styled";

export const PreviewWrapper = styled.div`
  border-top: 1px solid var(--color-border-light);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px; 
`

export const PreviewRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;

  &.vertical { 
    flex-direction: column;
  }
  
  label {
    display: block;
    font-family: var(--font-semi-bold);
    margin-bottom: 0;
  }
  
  span {
    font-family: var(--font-regular);
  }
  
  ul {
    margin: 0;
    padding: 0;
    
    li {
      padding: 0 0 12px 0;
    }
  }
  
  .faded {
    opacity: 0.3;
  }
`

export const PreviewColumn = styled.div`
  display: flex;
  flex-grow: 1
`

export const Regular = styled.span`
  font-family: var(--font-regular);
  font-weight: 400;
  color: var(--color-backdrop);
`

export const Faded = styled.span`
  font-family: var(--font-regular);
  font-weight: 400;
  color: var(--color-grey);
`