import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Browser} from '../../utils/browser';
import {fi, handleAction} from '../../utils/helpers';
import {Numbers} from '../../utils/numbers';
import ActionMenu from '../ActionMenu/ActionMenu';
import {ActionButton} from '../commons/ActionButton';
import {ITreeConfig} from './config';
import {toggleQuickAccess} from './utils';
import {Strings} from "../../utils/strings";
import Client from '../../cms/client';
import {cacheBuster, references} from '../../state/state';
import {ITreeItem, Tree} from "./Tree";
import {useSnackbar} from "notistack";
import {TreeObject} from "../../cms/models/__CMSObject";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  border: 1px solid transparent;
  width: 100%;
  padding-right: 2px;

  &.dragging, &.selected {
    border: 1px solid var(--color-blue);
    background-color: var(--color-selected) !important;
  }

  &.dragging {
    cursor: grabbing !important;
    opacity: 0.3;
  }

  &.drag-ghost, &.drag-placeholder {
    display: block;
    height: 34px;
    pointer-events: none;
  }

  &:hover {
    background-color: var(--color-box-shadow);
    cursor: pointer;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  height: 32px;
  padding: 0 2px;
`;

const Label = styled.div`
  padding: 0 2px;
  height: 26px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    line-height: 32px;
  }
`;

const RowActions = styled.div`
  margin: 0 0 0 8px;

  svg {
    font-size: 1.25rem;
  }
`;

const Icon = styled.div`
  width: 19px;
  min-width: 19px;
  height: 26px;
  padding: 0 2px;
  border: 0;

  svg {
    width: 16px;
    height: 26px;
    border: 0;
  }
`;

type TreeItemProps = {
    item: ITreeItem
    tree: Tree;
    depth: number;
    config: ITreeConfig;
    fixed?: boolean;
}

type DragState = {
    target: HTMLDivElement | null,
    ghost: HTMLDivElement | null,
    placeholder: HTMLDivElement | null,
    left: number,
    top: number

    currentIndex: number;
    currentDepth: number;
    currentParent: string | null;
}

const dragState: DragState = {
    target: null,
    ghost: null,
    placeholder: null,
    left: 0,
    top: 0,

    currentDepth: 0,
    currentParent: null,
    currentIndex: 0,
};


const TreeItem = ({item, depth, config, fixed, tree}: TreeItemProps) => {
    const depthWidth = 16;
    const key = `tree-${config.type}`;
    const invalidate = useSetRecoilState(cacheBuster(key))
    const reloadItems = useSetRecoilState(cacheBuster(config.type))

    // even though the object is part of the item, the object may be changed somewhere else and needs to be
    // reflected in the tree (the case for pages). So we rely on the references to fetch the latest version
    const object: TreeObject = useRecoilValue(references(item.id)) as TreeObject

    const searching = typeof item.matchChildren === 'number';
    const matchedChildren = Numbers.default(item.matchChildren);

    // force open because the user is seaching and there are children that match that search
    const forceOpen = (searching && matchedChildren > 0);
    // open state of the none as stored in the TreeItem
    const [open, setOpen] = useState(item.opened || forceOpen);
    // close a node if oppened but we're dragging it to move/reorder
    const [dragging, setDragging] = useState(false);

    // a node open state is given by multiple factors above
    const opened = (item.opened || open || forceOpen) && !dragging;

    const {enqueueSnackbar} = useSnackbar();
    const ref: any = useRef()

    useEffect(() => {
        if (object) {
            tree.setItem(object)
        } else {
            tree.delete(item.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object])

    useEffect(() => {
        if (open !== Boolean(item.opened)) {
            setOpen(Boolean(item.opened));
        }
    }, [open, item, setOpen]);


    // need to notify the parent to regenerate the tree with what is visible and what not
    const toggleOpened = useCallback(() => {
        tree.setOpened(item.id)
        invalidateCache()
        // eslint-disable-next-line
    }, [item]);

    const preventDefault = (e) => {
        e.preventDefault();
    }

    const invalidateCache = () => {
        invalidate(val => val + 1)
    }

    const onDelete = (item) => {
        tree.delete(item.id);
        if (item.selected) {
            if (item.parent) {
                Browser.navigate(Strings.default(item.parent.object.routes().edit));
            } else if (item.parentId) {
                const parent = tree.findNode(item.parentId)
                if (parent) {
                    Browser.navigate(Strings.default(parent.object.routes().edit));
                }
            } else {
                Browser.navigate(Strings.default(item.object.routes().list));
            }
        }
        invalidateCache();
    }

    const onRefresh = () => {
        reloadItems(val => val + 1)
    }

    const toggleSelected = () => {
        if (object) {
            Browser.navigate(Strings.default(object.routes().edit))
        }
    };

    const onDragStart = useCallback((e) => {
        if (fixed) {
            e.preventDefault();
            return false;
        }
        setDragging(true);
        const target = e.srcElement || e.target;

        dragState.target = target;

        dragState.ghost = target.cloneNode(true);
        dragState.placeholder = target.cloneNode(true);

        const bounds = target.getBoundingClientRect();
        dragState.left = bounds.left - e.clientX;
        dragState.top = bounds.top - e.clientY;

        if (dragState.ghost) {
            dragState.ghost.className = 'drag-ghost';
            dragState.ghost.style.top = (e.clientY + dragState.top) + 'px';
            dragState.ghost.style.left = (e.clientX + dragState.left) + 'px';
            dragState.ghost.style.width = bounds.width + 'px';
            dragState.ghost.style.height = bounds.height + 'px';

            target.parentNode.insertBefore(dragState.ghost, target);
        }

        if (dragState.placeholder) {
            dragState.placeholder.className = 'drag-placeholder';
        }

        // remove default ghost image added by browser
        e.dataTransfer.effectAllowed = 'copyMove';
        let dragImage = document.createElement('div');
        dragImage.style.visibility = 'hidden';
        e.dataTransfer.setDragImage(dragImage, 0, 0);

        setTimeout(() => {
            if (dragState.target) {
                dragState.target.style.height = '0px';
                dragState.target.style.visibility = 'hidden';
            }
        }, 0);

    }, [fixed]);

    const onDragEnd = useCallback((e) => {
        if (fixed) {
            e.preventDefault();
            return false;
        }
        setDragging(false);
        if (dragState.ghost) {
            dragState.ghost.parentNode?.removeChild(dragState.ghost);
            dragState.ghost = null;
        }
        if (dragState.placeholder) {
            dragState.placeholder.parentNode?.removeChild(dragState.placeholder);
            dragState.placeholder = null;
        }
        if (dragState.target) {
            dragState.target.removeAttribute('style');
        }
        dragState.target = null;
        if (
            (dragState.currentParent !== item.parent?.id) // different parent
            || (dragState.currentIndex !== item.order) // different order
        ) {
            let order: string[] = tree.move(item.id, dragState.currentParent, dragState.currentIndex);
            Client.reorderTree({parent: dragState.currentParent, uuids: order}).then(()=> {
                invalidateCache()
            }).catch((err) => {
                enqueueSnackbar('Error moving item: ' + err, {variant: 'error'});
            })
        }
        // eslint-disable-next-line
    }, [fixed, item]);

    const onDrag = useCallback((e) => {
        if (fixed) {
            e.preventDefault();
            return false;
        }
        if (dragState.ghost) {
            dragState.ghost.style.top = (e.clientY + dragState.top) + 'px';
            dragState.ghost.style.left = (e.clientX + dragState.left) + 'px';
        }
    }, [fixed]);

    const getNewParent = useCallback(() => {
        let parent: any = dragState.placeholder?.parentNode;
        while (parent) {
            if (typeof parent.dataset.depth !== 'undefined') {
                dragState.currentDepth = +parent.dataset.depth;
                dragState.currentParent = parent.dataset.parentid;
                dragState.currentIndex = Array.from(parent.children).filter(c => c !== dragState.target).indexOf(dragState.placeholder);

                if (dragState.placeholder && dragState.placeholder.firstChild) {
                    (dragState.placeholder.firstChild as any).style.marginLeft = (dragState.currentDepth * depthWidth) + 'px';
                }
                return;
            }
            parent = parent.parentNode;
        }
    }, []);

    const onDragOver = useCallback((e) => {
        if (fixed || !dragState.target) {
            e.preventDefault();
            return false;
        }

        e.preventDefault();
        e.stopPropagation();
        const target = e.currentTarget;

        if (target === dragState.target) {
            if (dragState.placeholder?.parentNode) {
                dragState.placeholder?.parentNode.removeChild(dragState.placeholder);
            }
            return;
        }

        const bounds = target.getBoundingClientRect();
        if (e.clientY - bounds.top > bounds.height / 2) {
            // after
            if (target.nextSibling) {
                target.parentNode.insertBefore(dragState.placeholder, target.nextSibling);
            } else {
                target.parentNode.appendChild(dragState.placeholder);
            }
        } else {
            // before
            target.parentNode.insertBefore(dragState.placeholder, target);
        }
        getNewParent();
    }, [fixed, getNewParent]);

    const onQuickAccess = useCallback((e) => {
        e.preventDefault();
        toggleQuickAccess(config.type, item.id);
    }, [config.type, item.id]);

    const hasChildren = item.children.length > 0;

    useEffect(() => {
        if (item.selected && ref && ref.current) {
            ref.current.scrollIntoViewIfNeeded && ref.current.scrollIntoViewIfNeeded();
        }
    }, [ref, item.selected])

    // Custom actions are additional buttons on a tree item row next to the ':' menu.
    // They are defined by type in the TreeMenu component in config.tsx file
    const customActions = useMemo(() => {
        if (!config.itemActions) {
            return null;
        }
        return  config.itemActions.map((action, index) => (
            <React.Fragment key={index}>
                {action({...item, object: object})}
            </React.Fragment>
        ))
    }, [object, item, config])

    if (searching && matchedChildren === 0) {
        return null;
    }

    if (!object) return null

    return (
        <>
            <Row data-itemid={object.getId()}
                 key={`${item.order}-${object.getId()}`}
                 data-fuck={ref.current && ref.current.dataset.itemid}
                 className={`${fi(dragging && !fixed, 'dragging', '')} ${fi(item.selected && !fixed, 'selected', '')}`}
                 onDragStart={onDragStart}
                 onDragEnd={onDragEnd}
                 onDrag={onDrag}
                 onDragEnter={preventDefault}
                 onDragLeave={preventDefault}
                 onDragOver={onDragOver}
                 ref={ref}
                 draggable>
                <LabelWrapper style={{marginLeft: depth * depthWidth}}>
                    <Icon title={fi(hasChildren, fi(opened, 'Close', 'Open'), '')}
                          tabIndex={fi(hasChildren, 0, undefined)}
                          {...handleAction(toggleOpened)}>
                        {fi(hasChildren, fi(opened, <ExpandMoreIcon/>, <KeyboardArrowRightIcon/>), <>&nbsp;</>)}
                    </Icon>
                    <Label title={object.displayLabel()} tabIndex={0} {...handleAction(toggleSelected)}>
                        <p>{object.displayLabel()}</p>
                    </Label>
                </LabelWrapper>
                <RowActions className="flex-row">
                    {customActions}
                    {fi(fixed,
                        <ActionButton variant="text" title="Unpin from quick access"
                                      data-testid={'item-quick-access-unpin'}
                                      onClick={onQuickAccess}>
                            <PushPinOutlinedIcon/>
                        </ActionButton>,
                        <ActionMenu item={object} onDelete={() => onDelete(item)} onRefresh={() => onRefresh()}/>
                    )}
                </RowActions>
            </Row>
            {(opened && item.children.length > 0) && (
                <div data-depth={depth + 1} data-parentid={object.getId()}>
                    {item.children.map((child, idx) => (
                        <TreeItem tree={tree} key={`${idx}-${item.id}`} item={child} config={config} depth={depth + 1}/>
                    ))}
                </div>
            )}
        </>
    );
};

export default TreeItem;
