import {NavigateOptions} from "react-router/dist/lib/context";
import {To} from "@remix-run/router";
import {Objects} from "./objects";
import {Strings} from "./strings";

export const Browser = {
	// This method is replaced by the useNavigate() hook in the App.tsx
	// and is used to navigate to a new page without requiring the use of the hook which will
	// unnecessarily refresh the component
	navigate: (_path: To, _args?: NavigateOptions) => {
		console.warn('navigator not replaced');
	},

	queryParams: (): { [key: string]: string } => {
		const params = new URLSearchParams(window.location.search);
		const result: { [key: string]: string } = {};
		// @ts-ignore
		for (const [ key, value ] of params.entries()) {
			result[key] = decodeURIComponent(value);
		}
		return result;
	},

	queryParam: (key: string): string | undefined => {
		return Browser.queryParams()[key];
	},

	hasQueryParam: (key: string): boolean => {
		return !!Browser.queryParam(key);
	},

	getParams: (routes: any, path: string = ''): any => {
		path = path || window.location.pathname;
		const pathParts = path.split('/');

		const matchRoute = (route: any): any => {
			const parts = (Strings.default(route.path)).split('/');
			let matched = true
			const result: any = {}

			for (let i = 0; i < parts.length; i++) {
				if (parts[i].startsWith(':') && pathParts[i]) {
					result[parts[i].substring(1)] = pathParts[i]
					continue
				}

				if (parts[i] !== pathParts[i] && parts[i] !== '*') {
					matched = false
					break
				}
			}

			if (matched && parts.length !== pathParts.length && parts[parts.length - 1] !== '*') {
				matched = false
			}

			if (matched) {
				if (route.children && route.children.length > 0) {
					for (let i = 0; i < route.children.length; i++) {
                        const matchedRoute = matchRoute(route.children[i])
						if (matchedRoute) {
                            return matchedRoute
                        }
                    }
				}
				return result
			}

			return null
		}

		const res = matchRoute({children: routes, path: '/*'} as any)
		return Objects.default(res)
	}
};
