import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Strings} from "../../utils/strings";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";
import {fi} from "../../utils/helpers";
import React, {ReactElement} from "react";
import {PreviewRow, PreviewWrapper} from "./_style";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {Objects} from "../../utils/objects";

const EVENT_TYPES = [
    {label: 'Active events', value: 'active_events'},
    {label: 'Past events', value: 'past_events'},
];

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Events",
    description: "Displays a list of events from EventsAIR\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class EventsWidget extends BaseWidget implements IFormEvents {
    @formField({
        name: "Event types",
        fieldtype: FieldType.List,
        order: 1,
        flags: {required: true},
        config: {options: EVENT_TYPES.map(i => Strings.localizedTxt(i.label)), isRadioGroup: true}
    })
    public eventType: string;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.Events

        const sel = EVENT_TYPES.find(i => i.value === Strings.default(item.eventType))
        this.eventType = fi(sel, sel?.label, 'Active events');
    }

    formOnBeforeSave(evt: FormEvent): any {
        // at this point 'item' is the label not the value, so we need to change that before saving
        const data = {...evt.state.values}
        data.eventType = Objects.default(EVENT_TYPES.find(i => i.label === data.eventType)).value;
        return data
    }

    preview(): ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <label>Event types</label>
                    <span>{this.eventType}</span>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}