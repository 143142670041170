import styled from "@emotion/styled";
import React, {useMemo} from "react";

interface IProgressBarParams {
    total: number;
    done: number;
    errors: number;
    label: string;
    width: string;
    height: string;
}

const Wrapper = styled.div<{width: string, height: string}>`
  border: 1px solid var(--color-border);
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  font-size: 0.8em;
`

const Label = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 3;
  text-shadow: 0.5px 0.5px 0.5px white, -0.5px -0.5px 0.5px white, -0.5px 0.5px 0.5px white, 0.5px -0.5px 0.5px white;
  font-family: var(--font-semi-bold);
  font-weight: bold;
`

const Progress = styled.div<{width: string}>`
  position: absolute;
  left: 0;
  width: ${props => props.width};
  top: 0;
  bottom: 0;
  z-index: 1;
  background: #0064A499;
`

const Errors = styled.div<{width: string}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${props => props.width};
  z-index: 2;
  background: #d9221c99;
`

const ProgressBar = (props: IProgressBarParams) => {
    const [label, pct, errs] = useMemo(() => {
        let msg = ''
        if (props.label) {
            msg = `${props.label} - `
        }

        if (props.total === 0) {
            return [`${msg} 100%`, '100%', '0%']
        }

        let done = Math.min(props.done, props.total)

        let errors = Math.min(props.total, props.errors)

        const pct = Math.ceil((done * 100) / props.total) + '%';
        const errs = Math.ceil((errors * 100) / props.total) + '%';

        return [`${msg} ${pct}`, pct, errs]
    }, [props])

    return (
        <Wrapper width={props.width} height={props.height}>
            <Progress data-testid={'progress'} width={pct} />
            <Errors data-testid={'errors'} width={errs} />
            <Label>{label}</Label>
        </Wrapper>
    )
}

export default ProgressBar