import styled from '@emotion/styled';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import Radio from '@mui/material/Radio';
import React, {useContext} from 'react';
import { cacheBuster } from '../../../state/state';
import { ColumnPreference } from '../preferences';
import { ColumnFilterProps } from './ColumnFilter';
import {TableContext} from "../state";

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer !important;
  user-select: none;

  label {
    flex-grow: 1;
    line-height: 45px;
    cursor: pointer !important;
  }
`;

const items = [{label: 'All', value: null}, {label: 'True', value: true}, {label: 'False', value: false}]

const BooleanFilter = (props: ColumnFilterProps & {preferences: ColumnPreference}) => {
	const ctx = useContext(TableContext)
	cacheBuster(ctx.type)
	const isChecked = (item): boolean => {
		let val = props.preferences.filter
		if (typeof val === 'undefined') {
			val = null
		}
		return val === item.value
	}

	return (
		<>
			{items.map((item, index) => (
				<Item key={index}>
					<Radio
						className="form-checkbox"
						aria-label={item.label}
						id={`item-${index}`}
						name={'boolean-filter'}
						checked={isChecked(item)}
						tabIndex={0}
						color="primary"
						checkedIcon={<RadioButtonChecked/>}
						size="medium"
						onChange={(_evt, _checked) => props.onChange!(item.value)}
						title={item.label}
						disableRipple
					/>
					<label htmlFor={`item-${index}`}>
						{item.label}
					</label>
				</Item>
			))}
		</>
	);
};

export default BooleanFilter;
