import React, {useMemo, useState} from "react";
import {createWidgetState} from "../../../../../state/state";
import {useForm} from "../../../state";
import {RenderFormFields} from "../../../utils";
import {fi} from "../../../../../utils/helpers";
import {Strings} from "../../../../../utils/strings";
import Spacer from "../../../../commons/Spacer";
import Button from '@mui/material/Button';
import styled from "@emotion/styled";
import {useRecoilValue} from "recoil";

const WidgetFormWrapper = styled.div`
  padding: 16px;
`

const FormActions = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--color-border-light);
  align-items: center;
`

const ErrorMessage = styled.div`
  color: var(--color-red);
`
const RenderWidgetForm = ({onChange, onCancel}: { onChange: (config: any) => void, onCancel: () => void}) => {
    const form = useForm();
    const [error, setError] = useState('');
    const createMode = useRecoilValue(createWidgetState);

    const fields = useMemo(() => {
        return RenderFormFields(form.state);
    }, [form]);

    const onSave = () => {
        setError('')
        // validate form
        if (!form.validate()) {
            setError('Please fix the errors and try again')
            return
        }
        const tmp = form.state.getSaveObject()
        Object.keys(form.state.values).forEach(key => {
            if (key.startsWith("__")) {
                delete tmp[key]
            }
        })
        onChange(tmp)
    }

    return (
        <WidgetFormWrapper>
            {fields}
            <FormActions>
                <ErrorMessage>
                    {fi(error, `${Strings.default(error)}`, ' ')}
                </ErrorMessage>
                <Spacer/>
                <Button variant="text" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" onClick={onSave}>{fi(createMode, 'Create', 'Save')}</Button>
            </FormActions>
        </WidgetFormWrapper>
    )
}
export default RenderWidgetForm