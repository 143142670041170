import styled from '@emotion/styled';
import Settings from '@mui/icons-material/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CONTROL_PANEL_FLAG } from '../../cms/models/Session';
import { setRecoil } from '../../state/recoilNexus';
import { sessionAtom } from '../../state/session';
import { cacheBuster } from '../../state/state';
import { Browser } from '../../utils/browser';
import { AUTH_TOKEN, ROUTE_CONTROL_PANEL, USER_SETTINGS, WIDGETS_SETTINGS } from '../../utils/constants';
import { useLocalStorage } from '../../utils/hooks';
import Toggle from '../Form/renderers/components/Toggle/Toggle';
import TimeZoneSettings from './TimeZoneSettings';

const Wrapper = styled.div`
  border-top: 1px solid var(--color-border-light);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  height: 40px;
  padding: 0 8px;
  color: var(--color-monocrome);
  user-select: none;
`;

const ContentWrapper = styled.div`
  padding: 16px;
  user-select: none;

  h6 {
    font-family: var(--font-semi-bold);
    font-size: 16px;
    background-color: var(--color-grey);
    padding: 8px;
    display: block;
    margin: -16px -16px 16px -16px
  }
`;

const UserSettings = () => {
	const session = useRecoilValue(sessionAtom);
	const popupState = usePopupState({variant: 'popover', popupId: 'user-settings-popup'});
	const [ value, setValue ] = useLocalStorage(WIDGETS_SETTINGS, false);

	const clearSettings = () => {
		const keys: string[] = [];
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key && key !== AUTH_TOKEN) {
				keys.push(key);
			}
		}
		keys.forEach(key => {
			setRecoil(cacheBuster(key), (val) => val + 1);
			localStorage.removeItem(key);
		});
		setRecoil(cacheBuster(USER_SETTINGS), val => val + 1)
		setValue(false);
	};

	if (!session) {
		return null;
	}

	return (
		<Wrapper>
			{session.withFeature(CONTROL_PANEL_FLAG, () => (
				<IconButton title="Control panel" data-testid="control-panel-button"
							onClick={() => Browser.navigate(ROUTE_CONTROL_PANEL.replace(':page', "status"))}>
					<TuneIcon/>
				</IconButton>
			))}

			<IconButton {...bindTrigger(popupState)} title="User settings" data-testid="user-settings-button">
				<Settings/>
			</IconButton>
			<Popover
				{...bindPopover(popupState)}
				anchorOrigin={{vertical: 'top', horizontal: 'left'}}
				transformOrigin={{vertical: 'bottom', horizontal: 'left'}}
				data-testid="user-settings-popover"
			>
				<ContentWrapper>
					<Typography variant="h6" gutterBottom>App settings</Typography>
					<TimeZoneSettings/>
					<Toggle id={'expand-widgets'} data-testid={'auto-expand-widgets-toggle'}
							label={'Auto-expand widgets when editing pages'} onChange={setValue} value={value}/>
					<Button data-testid="clear-tables-settings" variant={'outlined'}
							onClick={clearSettings}
							title={'Clears and sets to default all settings including table column configurations'}>
						Clear all user settings
					</Button>
				</ContentWrapper>
			</Popover>
		</Wrapper>
	);
};

export default UserSettings;
