import React, {useEffect, useMemo} from "react"
import VersionBox from "./VersionBox";
import MetaDataBox from "./MetaDataBox";
import FileInfoBox from "./FileInfoBox";
import ReferencesBox from "./ReferencesBox";
import NotesBox from "./NotesBox";
import {fi, StrictModeDroppable} from "../../utils/helpers";
import {useLocalStorage} from "../../utils/hooks";
import PageWidgetsBox from "./PageWidgetsBox";
import {DEFAULT_SIDE_PANEL_STATE} from "./components/BoxContainer";
import {useRecoilState, useRecoilValue} from "recoil";
import {dragAndDropState, selectedObject} from "../../state/state";
import RatingBox from "./RatingBox";

const SidePanel = () => {
    const [order, setOrder] = useLocalStorage('sidePanelOrder', DEFAULT_SIDE_PANEL_STATE);
    const selected = useRecoilValue(selectedObject);
    const [dragAndDrop, setDragAndDrop] = useRecoilState(dragAndDropState);

    useEffect(() => {
        if (!dragAndDrop || dragAndDrop.type !== 'side-widgets') {
            return
        }

        const {destination, source, draggableId} = dragAndDrop;
        if (!destination) {
            return;
        }
        // same position
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const newOrder = [...order];
        newOrder.splice(source.index, 1);
        newOrder.splice(destination.index, 0, order.find(w => w[0] === draggableId));
        setDragAndDrop(null)
        setOrder(newOrder);
        // eslint-disable-next-line
    }, [dragAndDrop])


    const widgets = useMemo(() => {
        const WidgetsList = {
            version: <VersionBox/>,
            metadata: <MetaDataBox/>,
            fileInfo: <FileInfoBox/>,
            references: <ReferencesBox/>,
            notes: <NotesBox/>,
            pageWidgets: <PageWidgetsBox/>,
            rating: <RatingBox />,
        }
        return fi(selected, order.filter(a => a).map((widgetId, idx) => <React.Fragment
            key={idx}>{WidgetsList[widgetId[0]]}</React.Fragment>), null);

    }, [order, selected])

    return (
        <div>
            <StrictModeDroppable key={'side-panel'} droppableId="side-panel" type={'side-widgets'}>
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {widgets}
                        {provided.placeholder}
                    </div>
                )}
            </StrictModeDroppable>
        </div>
    )
}

export default SidePanel