import {Strings} from "../../utils/strings";
import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";
import React, {ReactElement} from "react";
import {PreviewRow, PreviewWrapper, Regular} from "./_style";
import {fi} from "../../utils/helpers";

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Child pages",
    description: "Adds a section to automatically display all the child pages of the current page.\nIf there are no child pages, the section will not be displayed.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class ChildPagesWidget extends BaseWidget {
    @formField({
        name: "Title",
        fieldtype: FieldType.Text,
        order: 1,
    })
    public title: string;

    @formField({
        name: "Description",
        fieldtype: FieldType.Text,
        config: {isMultiline: true},
        order: 2,
    })
    public description: string;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.ChildPages;
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
    }

    getTitle(): ReactElement | string {
        const title = super.getTitle();
        return <>{title} {fi(this.title, <Regular> :: {this.title}</Regular>)}</>
    }

    preview(): ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <label>Title</label>
                    <span className={fi(!this.title, 'faded')}>{Strings.default(this.title, 'Not set')}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Description</label>
                    <span className={fi(!this.description, 'faded')}>{Strings.default(this.description, 'Not set')}</span>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}