import styled from "@emotion/styled";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import React, {useMemo} from "react";
import {Draggable} from 'react-beautiful-dnd';
import {useLocalStorage} from "../../../utils/hooks";
import {Lists} from "../../../utils/lists";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ActionButton} from "../../commons/ActionButton";
import {fi} from "../../../utils/helpers";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const BoxWrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-bottom: 24px;
  user-select: none;
`

const BoxTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 8px;
  
  h4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    svg {
      width: 16px;
      color: var(--color-border);
      margin-right: 4px;
    }
  }
`

type BoxContainerProps = {
    id: string,
    title: string,
    actions?: any;
    children: any;
    hidden?: boolean
}

export const DEFAULT_SIDE_PANEL_STATE = [
    ['additionalAction', false],
    ['version', false],
    ['metadata', false],
    ['fileInfo', false],
    ['references', false],
    ['productInfo', false],
    ['pageWidgets', false],
    ['notes', false],
    ['rating', false],
];

export const BoxContainer = (props: BoxContainerProps) => {
    const [order, setOrder] = useLocalStorage('sidePanelOrder', DEFAULT_SIDE_PANEL_STATE);

    const collapsed = useMemo(() => {
        return Boolean(Lists.default(order.find(w => w[0] === props.id))[1]);
    }, [order, props.id])

    const index = useMemo(() => {
        return order.findIndex(w => w[0] === props.id);
    }, [order, props.id])

    const toggleCollapse = () => {
        const newOrder = [...order];
        const item = newOrder[index];
        item[1] = !collapsed;
        setOrder(newOrder);
    }

    return (
        <Draggable draggableId={props.id} index={index} key={props.id}>
            {(provided) => (
                fi(props.hidden,
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}></div>,
                    <BoxWrapper data-testid={`meta-widget-${props.id}`} ref={provided.innerRef} {...provided.draggableProps}>
                        <BoxTitle {...provided.dragHandleProps}>
                            <Typography variant='h4'><DragIndicatorIcon /> {props.title}</Typography>
                            <div>
                                <ActionButton onClick={toggleCollapse}
                                              title={fi(collapsed, 'Expand widget', 'Collapse widget')}>
                                    {fi(collapsed, <KeyboardArrowDownIcon/>, <KeyboardArrowUpIcon/>)}
                                </ActionButton>
                            </div>
                        </BoxTitle>
                        <Divider/>
                        {fi(!collapsed, props.children)}
                    </BoxWrapper>
                )
            )}
        </Draggable>
    )
}
