import React, {useCallback, useEffect, useRef} from "react"
import {Field} from "../../../cms/models/__ModelInfo";
import {fi} from "../../../utils/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import styled from "@emotion/styled";
import {useFormField} from "../state";
import {Dates} from "../../../utils/dates";

const Wrapper = styled.div`
  &.disabled {
    pointer-events: none;

    input {
      color: var(--color-border);
    }
  }
  
  &.error {
    input {
      border-color: var(--color-red);
    }
  }
`

const DateComponent = styled(Datetime)`
  display: flex;
  .rdtPicker{
    position:fixed;
  }
  input {
    flex-grow: 1;
    border-radius: 4px;
    border: 1px solid var(--color-border);
    padding: 8px;
    font-size: 16px;
    font-family: var(--font-regular);
  }

  td {
    text-align: center;
    vertical-align: middle;
    padding-right: 6px;
  }

  .rdtSwitch, .rdtTimeToggle {
    background-color: var(--color-blue);
    color: white;
    cursor: pointer;

    &:hover {
      background-color: var(--color-blue) !important;
      color: white;
    }
  }

  th {
    vertical-align: middle;
  }
`

const DateRenderer = ({field, rkey}: { field: Field, rkey?: string }) => {
    const [timezone] = Dates.useTimeZone()
    const formField = useFormField(field.uid);
    const yesterday = moment().subtract(1, "day");
    const ref = useRef<HTMLDivElement>(null)
    const k = useRef('')

    useEffect(() => {
        if (formField.value && !k.current) {
            k.current = fi(formField.value, new Date(formField.value), '')
        }
    }, [formField.value]);

    const onChange = (val) => {
        let date = ""
        if (val.toString().length===1) {
            formField.setValue("")
            return
        }
        try {
            date = new Date(val.toString()).toISOString()
        } catch (e) {
            formField.setValue("")
            return
        }

        const dateParts = date.split('T')[0].split('-') // year - month - day
        const timeParts = date.split('T')[1].split('.')[0].split(':')

        const newDate = Dates.withTimeZone(+dateParts[0], +dateParts[1] - 1, +dateParts[2], +timeParts[0], +timeParts[1], 0)

        try {
            formField.setValue(newDate.toISOString())
        } catch (e) {
            formField.setValue(null)
        }
    }

    function valid(current) {
        return current.isAfter(yesterday);
    }

    const position = useCallback(() => {
        if (!ref.current) { return }
        const box = ref.current.getBoundingClientRect()
        const children = ref.current.querySelectorAll(".rdtPicker")
        if (children.length) {
            const item = (children[0] as any);
            const itemBox = item.getBoundingClientRect();
            if (box.top + box.height + itemBox.height > window.innerHeight) {
                item.style.top = `${box.top - itemBox.height - 4}px`
            } else {
                item.style.top = `${box.top + box.height + 4}px`
            }
        }
    }, [ref])

    const onOpen = () => {
        position();
        document.getElementsByTagName("main")[0].addEventListener('scroll', position);
        window.addEventListener('scroll', position);
        window.addEventListener('resize', position);
    }

    const onClose = () => {
        document.getElementsByTagName("main")[0].removeEventListener('scroll', position);
        window.removeEventListener('scroll', position);
        window.removeEventListener('resize', position);
    }

    return (
        <>
            <label className={fi(field.flags.required, 'required')}>{field.name}</label>
            <Wrapper className={`${fi(formField.previewMode, 'disabled', '')} ${fi(formField.showError(), 'error', '')}`} ref={ref}>
                <DateComponent key={timezone+`${k.current}+${rkey}`}
                               isValidDate={valid}
                               dateFormat={'DD/MM/YYYY'}
                               timeFormat={'HH:mm'}
                               closeOnClickOutside={true}
                               onOpen={() => onOpen()}
                               onClose={() => onClose()}
                               closeOnSelect={true}
                               value={fi(formField.value, Dates.local(new Date(formField.value)).replace(",", ''), '')}
                               initialValue={fi(formField.value, new Date(formField.value), '')}
                               onChange={onChange}/>
            </Wrapper>
        </>
    )
}

export default DateRenderer