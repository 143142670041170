import React, {useEffect, useMemo} from "react";
import {Field} from "../../../cms/models/__ModelInfo";
import styled from "@emotion/styled";
import {StrictModeDroppable} from "../../../utils/helpers";
import {DROPPABLE_PAGE_AND_WIDGETS_TYPE} from "../../SidePanel/PageWidgetsBox";
import {useFormField} from "../state";
import {atom, useRecoilState, useRecoilValue} from "recoil";
import {createWidgetState, dragAndDropState, editWidgetState, selectedObject} from "../../../state/state";
import WidgetItem, {WidgetState} from "./components/Widgets/WidgetItem";
import {BaseWidget} from "../../../cms/models/PageWidget";
import {resetRecoil, setRecoil} from "../../../state/recoilNexus";

const Wrapper = styled.div`
`

const WidgetListWrapper = styled.div`
  margin-top: 8px;
  border: 1px dashed var(--color-border);
  border-radius: 4px;
  padding: 16px 8px 0;
  min-height: 80px;

  &:not(:has(div)) {
    &:before {
      content: "Drop widgets here";
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-top: -8px;
      color: var(--color-border);
    }

    height: 80px;
    display: block;
  }
`

export const WidgetListState = atom<WidgetState[]>({
    key: 'widget-list-state',
    default: []
})

const WidgetListRenderer = ({field}: { field: Field }) => {
    const formField = useFormField(field.uid)
    const [list, setList] = useRecoilState<WidgetState[]>(WidgetListState)
    const [dragAndDrop, setDragAndDrop] = useRecoilState(dragAndDropState);
    const selected = useRecoilValue(selectedObject)

    const getFormValue = () => {
        let wList = formField.value;
        if (typeof formField.value === 'string') {
            wList = JSON.parse(formField.value)
        }
        return [...wList]
    }

    useEffect(() => {
        return() => {
            resetRecoil(createWidgetState);
            resetRecoil(editWidgetState);
        }
    }, []);

    useEffect(() => {
        if (!dragAndDrop || dragAndDrop.type !== DROPPABLE_PAGE_AND_WIDGETS_TYPE) {
            return
        }
        if (!dragAndDrop.destination) {
            return
        }

        const tmp = getFormValue()
        const widgetList = [...list];

        if (dragAndDrop.draggableId.startsWith("new-")) {
            // Create new widget
            const widgetType = dragAndDrop.draggableId.substring(4) // trim the 'new-' prefix

            const cfg = BaseWidget.widgetStore[widgetType];
            if (cfg) {
                const widgetInstance = new BaseWidget.widgetStore[widgetType].Class({})
                tmp.splice(dragAndDrop.destination.index, 0, {type: widgetType, id: widgetInstance.id})
                const widget = {
                    id: widgetInstance.getId(),
                    type: widgetType,
                    widget: widgetInstance,
                    expanded: false,
                    createMode: true
                }

                widgetList.splice(dragAndDrop.destination.index, 0, widget)
                setRecoil(editWidgetState, widget.id)
                setRecoil(createWidgetState, true)
            }

        } else {
            // Reorder widgets
            tmp.splice(dragAndDrop.destination.index, 0, tmp.splice(dragAndDrop.source.index, 1)[0])
            widgetList.splice(dragAndDrop.destination.index, 0, widgetList.splice(dragAndDrop.source.index, 1)[0])
        }

        formField.setValue(JSON.stringify(tmp))
        setList(widgetList)
        setDragAndDrop(null);
        // eslint-disable-next-line
    }, [dragAndDrop])

    useEffect(() => {
        let tmp = formField.value;
        if (typeof formField.value === 'string') {
            tmp = JSON.parse(formField.value)
        }
        setList(tmp.map((item, index) => {
            const widgetInstance = new BaseWidget.widgetStore[item.type].Class(item)
            return {
                id: widgetInstance.getId(),
                type: item.type,
                widget: widgetInstance,
                expanded: false,
                editMode: false
            }
        }))

        // eslint-disable-next-line
    }, [formField.value, selected])

    const onDelete = (widgetId: string) => {
        const idx = list.findIndex(item => item.id === widgetId)
        if (idx !== -1) {
            const tmp = getFormValue()
            const widgetList = [...list];

            tmp.splice(idx, 1)
            widgetList.splice(idx, 1)

            formField.setValue(JSON.stringify(tmp))
            setList(widgetList)
        }
    }

    const onChange = (widgetId: string, config: any) => {
        const idx = list.findIndex(item => item.id === widgetId)
        if (idx !== -1) {
            const widget = list[idx]

            const tmp = getFormValue()
            const widgetList = [...list];

            tmp[idx] = {type: widget.type, ...config}
            widgetList[idx] = {
                ...widget,
                widget: new BaseWidget.widgetStore[widget.type].Class(config),
                createMode: false
            }

            formField.setValue(JSON.stringify(tmp))
            setList(widgetList)
        }
    }

    const widgets = useMemo(() => {
        return list.map((w, idx) => {
            return <WidgetItem key={w.id} state={w} list={list} onChange={onChange} onDelete={onDelete} index={idx}/>
        })
        // eslint-disable-next-line
    }, [list, selected])

    return (
        <Wrapper>
            <label className={'required'}>Page Widgets</label>
            <StrictModeDroppable key={'page-widget-list'} droppableId={"page-widget-list"}
                                 type={DROPPABLE_PAGE_AND_WIDGETS_TYPE}>
                {(provided) => (
                    <WidgetListWrapper id={"page-widget-list"} {...provided.droppableProps} ref={provided.innerRef}>
                        {widgets}
                        <span style={{width: 0, height: 0}}>
                            {provided.placeholder}
                        </span>
                    </WidgetListWrapper>
                )}
            </StrictModeDroppable>
        </Wrapper>
    )
}

export default WidgetListRenderer