import React, {useMemo} from "react"
import {BoxContainer} from "./components/BoxContainer";
import {useRecoilValue} from "recoil";
import {references, selectedObject} from "../../state/state";
import styled from "@emotion/styled";
import {Dates} from "../../utils/dates";
import TextAction from "../commons/TextAction";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Strings} from "../../utils/strings";
import {fi} from "../../utils/helpers";
import Client from "../../cms/client";
import {useSnackbar} from "notistack";
import Loader from "../Loader/Loader";
import {LibraryItem} from "../../cms/models/__MediaLibrary";
import {getRecoil} from "../../state/recoilNexus";
import {Ratings} from "./components/Ratings";


const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    line-height: 1.7;
    vertical-align: top;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    
    span {
      font-weight: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    svg {
      height: 20px;
    }
  }

  .MuiCircularProgress-root {
    margin: 0 6px;
  }
`

const MetaDataBox = () => {
    Dates.useTimeZone()
    const {enqueueSnackbar} = useSnackbar();
    const selected = useRecoilValue<any>(selectedObject)
    const [downloading, setDownloading] = React.useState(false)

    const hidden = useMemo(() => {
        return !selected || !selected.getId()
    }, [selected]);

    const downloadActivityReport = () => {
        if (selected) {
            if (downloading) {
                return
            }
            setDownloading(true)
            Client.activityReport(selected.getId()).catch(err => {
                enqueueSnackbar(`Error downloading activity report: ${err}`, {variant: 'error'});
            }).finally(() => {
                setDownloading(false)
            })
        }
    }

    // Determine if we should show the download button for activity reports.
    // Only if it's some item that is part of the Library, and it has at least 1 version.
    const shouldDisplayDownloads = useMemo(() => {
        if (!selected) {
            return false
        }
        const isMediaLibrary = selected instanceof LibraryItem
        const base: any = getRecoil(references(selected.getId()))
        if (base) {
            return isMediaLibrary && base.getMeta().version > 0
        }
        return isMediaLibrary
    }, [selected])

    return (
        <BoxContainer title='Created/Modified' id='metadata' hidden={hidden}>
            {!hidden && (
                <Wrapper>
                    <div>
                        Created by:
                        <span data-testid='item-created-author'>{selected.getMeta().creator} on</span>
                        <span title={Dates.timeAgo(Dates.local(selected.getMeta().created))}
                              data-testid='item-created-date'>
                        {Dates.local(selected.getMeta().created)}
                    </span>
                    </div>
                    <div>
                        Last modified by:
                        <span data-testid='item-modified-author'>{selected.getMeta().author} on</span>
                        <span title={Dates.timeAgo(Dates.local(selected.getMeta().modified))}
                              data-testid='item-modified-date'>
                        {Dates.local(selected.getMeta().modified)}
                    </span>
                    </div>
                    {shouldDisplayDownloads && (
                        <>
                            <div>
                                Downloaded:
                                <span
                                    data-testid='item-download-count'>{Strings.pluralize(selected.getMeta().accessed, 'time', 'times')}</span>&nbsp;
                                <TextAction data-testid='download-activity-report'
                                            onClick={downloadActivityReport}
                                            className={fi(!selected.getMeta().accessed, 'disabled')}>
                                    {fi(downloading, <Loader size={16}/>, <FileDownloadIcon/>)}Download activity report
                                </TextAction>
                            </div>
                            <Ratings itemId={selected.getId()}/>
                        </>
                    )}
                </Wrapper>
            )}
        </BoxContainer>
    )
}

export default MetaDataBox