import {Types, UUID} from '../types';
import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from './PageWidget';
import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import {formField, linkedFormField} from '../../utils/decorators';
import React from 'react';
import {PreviewRow, PreviewWrapper} from './_style';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {LibraryItem} from './__MediaLibrary';
import {ContentType} from './ContentType';
import {fi} from '../../utils/helpers';
import {FieldType} from './__ModelInfo';
import type {FormEvent} from '../../components/Form/model';
import {IFormEvents} from '../../components/Form/model';
import {ISelectValue} from '../../components/Form/renderers/components/Select/SelectComponent';
import {itemTypes} from "../../utils/constants";

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Content Items",
    description: "Allows you to pick one or more items to be displayed as cards in a separate section."
})
export class ContentItemsWidget extends BaseWidget implements IFormEvents {
    // Item custom title
    @formField({
        fieldtype: FieldType.Text,
        name: "Title",
    })
    public title: string;
    // Item custom description
    @formField({
        fieldtype: FieldType.Text,
        name: "Description",
        config: {isMultiline: true}
    })
    public description: string;

    // content type selected to be displayed
    @formField({
        fieldtype: FieldType.Reference,
        name: "Filter by content type",
        config: {refModel: Types.CONTENT_TYPE}
    })
    public contentType: UUID;

    @formField({
        fieldtype: FieldType.Reference,
        name: "Add content item",
        flags: {required: true, multiple: true, hideValues: true},
        config: {refModels: itemTypes}
    })
    @linkedFormField('contentType')
    public items_list: UUID[];

    // customize the list of content types to be displayed
    @formField({
        fieldtype: FieldType.Reference,
        name: "Content Item",
        flags: {multiple: true, hideSelection: true},
        config: {refModels: itemTypes}
    })
    public items: UUID[];

    constructor(item: any = {}) {
        super(item);

        this.type = WidgetType.ContentItems;

        this.contentType = item.contentTypes;
        this.items = Lists.default(item.items);
        this.items_list = Lists.default(item.items);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
    }

    public formOnFilterDataset(evt: FormEvent): ISelectValue[] {
        const dataset = Lists.default<ISelectValue>(evt.dataset)
        if (evt.fieldUID !== 'items_list') {
            return dataset
        }
        return dataset.filter(i => i.object.content_type === evt.state.values.contentType)
    }

    public formOnFieldChange(evt: FormEvent) {
        switch (evt.fieldUID) {
            case 'items_list':
                evt.state.fieldState('items').setValue(Array.from(new Set([...evt.state.values.items, ...evt.fieldValue])))
                break;
            default:
        }
    }

    formOnBeforeSave(evt: FormEvent): any {
        const data = {...evt.state.values}
        delete(data.items_list);
        return data
    }

    public preview(): React.ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow className='vertical'>
                    <label>Title</label>
                    <span className={fi(!this.title, 'faded')}>{Strings.default(this.title, 'Not set')}</span>
                </PreviewRow>
                <PreviewRow className='vertical'>
                    <label>Description</label>
                    <span
                        className={fi(!this.description, 'faded')}>{Strings.default(this.description, 'Not set')}</span>
                </PreviewRow>
                {this.items.map((item, idx) => {
                    const ref = getRecoil(references(item)) as LibraryItem
                    if (!ref) return null;
                    const ct = getRecoil(references((ref as any).content_type)) as ContentType
                    return (
                        <PreviewRow className='vertical' key={`${idx}-${item}`}>
                            <label>{ref.displayLabel()}</label>
                            <span>from {ct?.displayLabel()}</span>
                        </PreviewRow>
                    )
                })}
            </PreviewWrapper>
        )
    }
}