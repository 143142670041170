import {Numbers} from '../../utils/numbers';
import {Strings} from '../../utils/strings';
import {UUID} from '../types';
import {CMSObject, DisplayMode} from './__CMSObject';
import {CTUnit} from "./CTUnit";
import {IFormEvents} from "../../components/Form/model";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {Qualification} from "./Qualification";

export class QualificationSize extends CMSObject implements IFormEvents {
	public static title = 'Qualification size';
	public static autoPublish = true;
	public status: boolean;
	public code: string;
	public glh: number;
	public name: string;
	public level: number;
	public qca_code: string;
	public qualification: UUID;

	public units: CTUnit[] = []

	constructor(item: any = {}) {
		super(item);

		this.status = Boolean(item.status);
		this.level = item.level;
		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.qca_code = Strings.default(item.qca_code);
		this.qualification = Strings.default(item.qualification);
		this.glh = Numbers.default(item.glh);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
                return this.name;
            default:
				const qual = getRecoil(references(this.qualification)) as Qualification;
				if (qual) {
					return `${qual.name} - ${this.name} - Level ${this.level}`;
				}
				return `${this.code} - ${this.name}`;
		}
	}
}
