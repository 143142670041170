import React, {Suspense, useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";
import {useRecoilState, useRecoilValue} from "recoil";
import {cacheBuster, cmsObjectLoader, dragAndDropState, references, selectedObject} from "../../state/state";
import Form from "../../components/Form/Form";
import {Types} from "../../cms/types";
import SidePanel from "../../components/SidePanel/SidePanel";
import styled from "@emotion/styled";
import {CMSObject} from "../../cms/models/__CMSObject";
import {getRecoil, setRecoil} from "../../state/recoilNexus";
import {Strings} from "../../utils/strings";
import PageLoader from "../../components/Loader/PageLoader";
import {DragDropContext} from 'react-beautiful-dnd';
import {WidgetListState} from "../../components/Form/renderers/WidgetListRenderer";
import {FormFields} from "./components/FormFields";
import {FormTitle} from "./components/FormTitle";


const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  column-gap: 32px;
`

const FormSide = styled.div`
  width: 100%;
  max-width: 1200px;
  min-width: 600px;
`

// FormPageComponent is the page displayed when editing/creating items
const FormPageComponent = () => {
    const params = useParams();
    // Load the object by id or create a new instance of the object we're trying to edit/create
    const obj = useRecoilValue(cmsObjectLoader(`${params.type}/${Strings.default(params.itemId)}`))
    const [selected, setSelected] = useRecoilState(selectedObject)

    const previewMode: boolean = useMemo(() => {
        if (selected) {
            const base = getRecoil(references(selected.getId())) as any as CMSObject
            if (base) {
                return base !== selected;
            }
        }
        return false
    }, [selected])

    useEffect(() => {
        setSelected(obj);

        // eslint-disable-next-line
    }, [obj])

    useEffect(() => {
        setSelected(obj);

        return () => {
            setSelected(null)
            setRecoil(WidgetListState, [])
            setRecoil(cacheBuster(`${params.type}/${Strings.default(params.itemId)}`), (val) => val + 1)
        }
        // eslint-disable-next-line
    }, [params])

    const fields = useMemo(() => {
        return <FormFields/>
    }, [params, selected])

    if (!selected) {
        return null;
    }

    const onDragEnd = (result) => {
        setRecoil(dragAndDropState, result)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <FormWrapper>
                <FormSide>
                    <FormTitle/>
                    <Suspense fallback={<PageLoader/>}>
                        <Form key={`${params.itemId}-${params.type}`} id='form-page' previewMode={previewMode}
                              object={selected}
                              model={params.type as Types}>
                            {fields}
                        </Form>
                    </Suspense>
                </FormSide>
                <SidePanel/>
            </FormWrapper>
        </DragDropContext>
    );
};

export default FormPageComponent;
