import React, {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {ActionButton} from "../commons/ActionButton";
import RefreshIcon from '@mui/icons-material/Refresh';
import Search from '@mui/icons-material/Search';
import Storage from '@mui/icons-material/Storage';
import styled from "@emotion/styled";
import Client from "../../cms/client";
import {IIndicesStatus, IOpenSearchStatus, IServerStatus} from "../../cms/models/__Interfaces";
import {Numbers} from "../../utils/numbers";
import TextAction from "../commons/TextAction";
import {Browser} from "../../utils/browser";

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
  
  &:last-of-type {
    margin-bottom: 0;
  }
  
  label {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;
  }

  & > div {
    padding-left: 16px;

    span {
      font-family: var(--font-semi-bold);
    }
  }
`

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid var(--color-border-light);
  font-size: 14px;
  font-weight: bold;
  background: var(--color-background);
  padding-left: 4px;
  
  span {
    font-weight: normal !important;
    font-size: 16px;
    padding: 4px;
    background: white;
    margin-left: 4px;
  }
  
  margin-right: 4px;
`

const ServerStatus = ({status}: { status: IServerStatus }) => {
    return (
        <InfoBox>
            <label><Storage/> Server Status</label>
            <div>Name: <span>{status.name}</span></div>
            <div>Distribution: <span>{status.version.distribution}</span></div>
            <div>Version: <span>{status.version.number}</span></div>
            <div>Lucene version: <span>{status.version.lucene_version}</span></div>
            <div>Build: <span>{status.version.build_date}</span></div>
        </InfoBox>
    )
}

const IndexStatus = ({status, name}: { status: IIndicesStatus, name: string }) => {
    return (
        <InfoBox>
            <label><Search/> {name} Index Status [ <TextAction onClick={() => Browser.navigate(`/control-panel/jobs/${name}SearchIndex`)}> Reindex </TextAction> ]</label>
            <div>UUID: <span>{status.uuid}</span></div>
            <div>Size: <span>{Numbers.asFileSize(status.total.store.size_in_bytes)}</span></div>
            <div>Documents - <Tag>Total: <span>{status.total.docs.count}</span></Tag> <Tag>Deleted: <span>{status.total.docs.deleted}</span></Tag></div>
            <div>Index - <Tag>Total: <span>{status.total.indexing.index_total}</span></Tag> <Tag>Failed: <span>{status.total.indexing.index_failed}</span></Tag> <Tag>Time (ms): <span>{status.total.indexing.index_time_in_millis}</span></Tag></div>
            <div>Query - <Tag>Current: <span>{status.total.search.query_current}</span></Tag> <Tag>Total: <span>{status.total.search.query_total}</span></Tag> <Tag>Time (ms): <span>{status.total.search.query_time_in_millis}</span></Tag></div>
        </InfoBox>
    )
}

const SearchIndex = () => {
    const [status, setStatus] = useState<IOpenSearchStatus>();

    const refresh = async () => {
        setStatus(await Client.getIndexStatus())
    }

    useEffect(() => {
        refresh().catch()
    }, []);

    if (!status) {
        return null
    }

    return (
        <div data-testid='feature-flags-container'>
            <div className='flex-row spaced'>
                <Typography variant={'h4'}>Search Index</Typography>
                <div>
                    <ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
                                  data-testid={'refresh'}>
                        <RefreshIcon/>
                    </ActionButton>
                </div>
            </div>
            <Divider className='mt8 mb24'/>
            <Content className='flex-row'>
                <div>
                    <ServerStatus status={status.serverStatus}/>
                    {status.ocr && <IndexStatus status={status.ocr} name={"OCR"}/>}
                    {status.international && <IndexStatus status={status.international} name={"International"}/> }
                </div>
            </Content>
        </div>
)
}

export default SearchIndex;
