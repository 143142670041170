import styled from '@emotion/styled';
import CheckBox from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import React, {useMemo} from 'react';
import {NavLink} from 'react-router-dom';
import {CMSObject} from '../../cms/models/__CMSObject';
import {Dates} from '../../utils/dates';
import {isClickable} from '../../utils/decorators';
import {fi} from '../../utils/helpers';
import {useLocalStorage} from '../../utils/hooks';
import ActionMenu from '../ActionMenu/ActionMenu';
import TextAction from '../commons/TextAction';
import {Renderer, renderers} from './renderers';
import {tableLoadingState, useTable} from './state';
import {Strings} from "../../utils/strings";
import {useRecoilValue} from "recoil";

const Row = styled.tr`
  border-bottom: 1px solid var(--color-grey);
  vertical-align: middle;

  &:hover {
    background-color: var(--color-box-shadow);

    .fixed, .fixed-start {
      background-color: var(--color-box-shadow);
    }
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 16px 16px;
    max-width: 135px;
    height: 58px;
    vertical-align: middle;
    cursor: default;

    & > svg {
      height: 23px;
    }

    .MuiCheckbox-root {
      padding: 0;
    }

    &.fixed, &.fixed-start {
      height: 59px;
      margin-top: -0.5px;
      border-top-color: transparent;
    }

    a {
      color: var(--color-blue);
      cursor: pointer;
    }
  }
`;

const LoaderTable = styled.tbody`
  tr {
    &:hover {
      background-color: transparent;
      .fixed, .fixed-start {
        background-color: transparent;
      }
    }
  }
`

const EmptyTable = styled.tbody`
  height: 256px;
  user-select: none;

  td {
    height: 256px;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;

    h3 {
      margin-bottom: 8px;
    }
  }
`;

const TableBody = () => {
    const {state, preferences, actions: {toggleSelected, onDelete, clearFilters, refresh}} = useTable();
    const loading = useRecoilValue(tableLoadingState);

    useLocalStorage(Dates.TimeZoneOption, Dates.TimeZoneDefault);

    const columns = useMemo(() => {
        return preferences.columns.filter(c => c.width !== 0);
    }, [preferences.columns]);

    const hasFilters = useMemo(() => {
        return preferences.columns.filter(c => c.filter).length > 0;
    }, [preferences.columns]);

    const renderValue = (item: CMSObject, field: string, plain?: boolean): string | React.ReactNode => {
        const definition = state.config.columns?.find(c => c.field === field);
        if (!definition) {
            return item[field] || '-';
        }

        if (definition.render) {
            return (definition.render as Renderer).apply(item, [plain]);
        }

        const renderer = renderers[definition.type];
        if (!renderer) {
            return item[field] || '-';
        }

        return renderer(item, field, plain);
    };

    if (loading) {
        let tmp: any[] = [];
        if (state.items.results.length) {
            tmp = state.items.results.map(item => null)
        } else {
            for (let i = 0; i < state.preferences.limit; i++) {
                tmp.push(null);
            }
        }

        return (
            <LoaderTable>
                {tmp.map((item, i) => (
                    <Row key={i}>
                        {state.config.manualPublish && <td className="fixed-start" height={60}>&nbsp;</td>}
                        <td height={53} colSpan={columns.length}><div className='placeholder-content'></div></td>
                        <td className="fixed" height={60}>&nbsp;</td>
                    </Row>
                ))}
            </LoaderTable>
        )
    }

    if (state.items.total === 0 && state.lastRefreshed) {
        return (
            <EmptyTable>
                <tr>
                    <td colSpan={columns.length}>
                        <Typography variant={'h3'}>No items
                            available {fi(hasFilters, 'matching your current filters', '')}</Typography>
                        {hasFilters &&
                            <p>Please adjust or <TextAction onClick={clearFilters}>clear</TextAction> your filters</p>}
                    </td>
                </tr>
            </EmptyTable>
        );
    }

    return (
        <tbody>
        {state.items.results.map((item, idx) => (
            <Row key={idx}>
                {state.config.manualPublish && (
                    <td className='fixed-start'>
                        <Checkbox
                            className="form-checkbox"
                            aria-label="Select all from table"
                            id={`select-row-${idx}`}
                            name={`select-all`}
                            checked={Boolean(state.selected[item.getId()])}
                            tabIndex={0}
                            color="primary"
                            checkedIcon={<CheckBox/>}
                            size="small"
                            onChange={() => toggleSelected(item.getId())}
                            disableRipple
                        />
                    </td>
                )}
                {columns.map((col, idx) => (
                    <td key={idx} title={renderValue(item, col.field, true) as string}>
                        {fi(isClickable(item, col.field), (
                            <NavLink to={Strings.default(item.routes().edit)}>{renderValue(item, col.field)}</NavLink>
                        ), renderValue(item, col.field))}
                    </td>
                ))}
                {!state.config.hideActionColumn&&<td className="fixed">
                    <ActionMenu item={item} onDelete={() => onDelete(item.getId())} onRefresh={() => refresh()}/>
                </td>}
            </Row>
        ))}
        </tbody>
    );
};

export default TableBody;
