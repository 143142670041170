import React, {useEffect, useState} from "react"
import {BoxContainer} from "./components/BoxContainer";
import {useSnackbar} from "notistack";
import {useRecoilValue} from "recoil";
import {selectedObject} from "../../state/state";
import Client from "../../cms/client";
import {Types} from "../../cms/types";
import {Strings} from "../../utils/strings";
import styled from "@emotion/styled";
import {Note} from "../../cms/models/Note";
import Button from '@mui/material/Button';
import Divider from "@mui/material/Divider";
import {fi} from "../../utils/helpers";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {Comment} from "./components/Comment";
import {BoxFooter, NoteForm} from "./components/NoteForm";

const Wrapper = styled.div`
  max-height: 100px;

  &.expanded {
    max-height: 400px;
  }

  overflow: auto;
`

const EmptyState = styled.p`
  text-align: center;
  padding: 16px;
  color: var(--color-backdrop)
`

const NotesBox = () => {
    const {enqueueSnackbar} = useSnackbar();
    const selected = useRecoilValue(selectedObject)
    const [showAllNotes, setShowAllNotes] = useState<boolean>(false);
    const [showNotesForm, setShowNotesForm] = useState<boolean>(false);
    const [notes, setNotes] = useState<Note[]>([]);


    useEffect(() => {
        if (selected && selected.getId()) {
            refreshNotes();
        }
        // eslint-disable-next-line
    }, [selected]);

    const refreshNotes = () => {
        Client.query<Note>({
            types: [Types.ITEM_NOTE],
            limit: -1,
            matching: {item: selected!.getId()},
            order: '__meta.modified',
            sort: 'desc',
        }).then(res => {
            setNotes(res.results);
        }).catch(e => {
            enqueueSnackbar('Error retrieving notes: ' + Strings.default(e.error), {variant: 'error'});
        });
    }

    const addNote = (shouldRefresh: boolean) => {
        if (shouldRefresh) {
            refreshNotes()
        }
        setShowNotesForm(false);
    }

    return (
        <BoxContainer title='Internal notes' id='notes' hidden={!selected || !selected.getId()}>
            <Wrapper className={fi(showAllNotes, 'expanded')}>
                {fi(showAllNotes, notes, notes.slice(0, 1)).map((note, idx) => (
                    <Comment key={idx} comment={note} onDelete={refreshNotes}/>
                ))}
                {!notes.length && (
                    <EmptyState>
                        There are no notes for this item.
                    </EmptyState>
                )}
            </Wrapper>
            <Divider/>
            {fi(showNotesForm, <NoteForm onClose={addNote}/>, (
                <BoxFooter>
                    {fi(notes.length > 1, (
                        <Button className='expand' variant='text' onClick={() => setShowAllNotes(!showAllNotes)}>
                            {fi(showAllNotes, <><ExpandLess/> Show less</>,
                                <><ExpandMore/> Show {notes.length - 1} more</>)}
                        </Button>
                    ))}
                    <div className='spacer'/>
                    <Button variant='contained' onClick={() => setShowNotesForm(true)}>Write note</Button>
                </BoxFooter>
            ))}
        </BoxContainer>
    )
}

export default NotesBox