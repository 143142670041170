import React, {useContext, useEffect, useState} from 'react';
import {ActionButton} from '../../commons/ActionButton';
import EditNote from '@mui/icons-material/EditNote';
import {selectedTableItemsSelector, TableContext, useTableActions} from '../state';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {Messages} from '../../../utils/messages';
import {Types} from '../../../cms/types';
import {fi} from '../../../utils/helpers';
import {modalAtom, ModalWrapperProps} from '../../ModalDialogs/ModalWrapper';
import BulkEditModal from '../../ModalDialogs/BulkEditModal';

const editableItemTypes: Types[] = [Types.TEXT, Types.IMAGE, Types.VIDEO, Types.AUDIO, Types.LINK, Types.DOCUMENT];
const editableItemNames: string = 'document, audio, image, video, link, text';

const EditActionButton = () => {
	const ctx = useContext(TableContext)
	const state = useRecoilValue(selectedTableItemsSelector(ctx.type))
	const [disabled, setDisabled] = useState(false);
	const [title, setTitle] = useState(Messages.BulkEdit);
	const setModalEdit = useSetRecoilState(modalAtom);
	const {refresh} = useTableActions();

	useEffect(() => {
		let shouldDisable = state.count === 0;
		let newTitle: string = fi(shouldDisable, Messages.NoSelectedItem, Messages.BulkEdit);

		if (state.count > 0) {
			if (state.objects.filter(item => !editableItemTypes.includes(item.getType())).length) {
				shouldDisable = true;
                newTitle = `${Messages.CantEditMultipleTypes}${editableItemNames}`;
			}
			const listOfTypes : Types[] = []
			state.objects.forEach(item => listOfTypes.includes(item.getType()) ? null : listOfTypes.push(item.getType()))
			if (listOfTypes.length > 1) {
				shouldDisable = true;
				newTitle = `${Messages.CantEditMultipleTypes}${editableItemNames}`;
			}
		}
		setTitle(newTitle as Messages);
		setDisabled(shouldDisable);

	},[state]);

	const onEditSelected = () => {
		setModalEdit({
			width: 800,
			component: (props: ModalWrapperProps) => {
				return <BulkEditModal {...props} ids={state.objects.map(item => item.getId())} onConfirm={() => refresh()}/>;
			}
		})
	}

	return (
		<ActionButton
			data-testid="table-bulk-edit-action-button"
			disabled={disabled}
			title={title}
			onClick={onEditSelected}
		>
			<EditNote />
		</ActionButton>
	)
}

export default EditActionButton;