import styled from '@emotion/styled';
import React, {useContext, useEffect, useMemo} from 'react';
import {Types} from '../../cms/types';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {cmsModelsSelector} from '../../state/models';
import {ModelInfo} from '../../cms/models/__ModelInfo';
import {CMSObject} from '../../cms/models/__CMSObject';
import {cacheBuster} from '../../state/state';
import {FormState} from './model';
import {Strings} from '../../utils/strings';
import {preventReloadAtom} from '../commons/PreventReload';
import {setRecoil} from '../../state/recoilNexus';
import {visibleFormStates} from "./state";
import {getFormConfig} from "../../utils/decorators";

export const FormWrapper = styled.div`
  width: 100%;
  flex-grow: 1
`

type FormProps = {
    id: string,
    model: string | Types | ModelInfo;
    previewMode?: boolean;
    object?: CMSObject | string | any;
    children?: any;
    noPreventReload?: boolean;
}

export type FormContextType = {
    id: string;
    uid: string;
    model: ModelInfo;
    noPreventReload?: boolean;
    previewMode?: boolean;
    createMode?: boolean;
    parentForm?: FormState
}

export const FormContext = React.createContext<FormContextType>({
    // Unique form id. If we have multiple forms on the page they need different ids so their state won't mix
    id: "",
    // Model information. If no form children are passed it will use the model information to create the rows
    model: new ModelInfo({}),
    // Object UUID being edited
    uid: "",
    // If in preview mode, the entire form will be read-only.
    previewMode: false,
    // Create mode
    createMode: false,
});

const FormContent = ({children, object}: { children: any, object?: CMSObject | string | any; }) => {
    const context = useContext(FormContext)
    const setVisibleForms = useSetRecoilState(visibleFormStates)

    const state = useMemo(() => {
        const s = new FormState(context)
        s.previewMode = Boolean(context.previewMode)
        s.createMode = Boolean(context.createMode)
        return s
    }, [context])

    useRecoilValue(cacheBuster(`${state.id}/redraw`))

    useEffect(() => {
        if (object) {
            if (object instanceof CMSObject) { // editing some object
                state.setObject(object);
            } else if (typeof object === "string") {
                state.load(object).catch((err) => {
                    // fatal loading error
                })
            } else if (typeof object === "object") {
                state.setObject(object);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [object])

    useEffect(() => {
        setRecoil(preventReloadAtom, false);

        return () => {
            state.destroy()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        const formConfig = getFormConfig(context.model.getType());
        setVisibleForms((prev: any) => {
            return {...prev, [context.id]: formConfig.buttons}
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            setVisibleForms((prev: any) => {
                const {[context.id]: _, ...rest} = prev;
                return rest
            })
        };
        // eslint-disable-next-line
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}

const FormModel = (props: FormProps) => {
    const key = useRecoilValue(cacheBuster('forceFormRedraw'))
    const parentContext = useContext(FormContext)
    const parentForm = useMemo(() => {
        if (parentContext.id) {
            return FormState.getFormState(parentContext)
        }
    }, [parentContext])

    const objectUID = useMemo(() => {
        if (props.object) {
            if (props.object instanceof CMSObject) {
                return props.object.getId()
            } else if (typeof props.object === "string") {
                return props.object;
            } else if (typeof props.object === "object") {
                return Strings.default(props.object.id)
            }
        }
        return '';
    }, [props])


    return (
        <FormContext.Provider value={{
            id: props.id,
            uid: objectUID,
            previewMode: props.previewMode,
            createMode: !Boolean(objectUID),
            noPreventReload: Boolean(props.noPreventReload),
            model: props.model as ModelInfo,
            parentForm,
        }} key={key}>
            <FormWrapper data-testid={props.id} id={props.id}>
                <FormContent object={props.object}>
                    {props.children}
                </FormContent>
            </FormWrapper>
        </FormContext.Provider>
    )
}

const FormModelLoader = (props: FormProps) => {
    const model = useRecoilValue(cmsModelsSelector(props.model as Types));
    return <FormModel {...props} model={model}>{props.children}</FormModel>
}

const Form = (props: FormProps) => {
    if (typeof props.model === 'string') {
        return <FormModelLoader {...props}>{props.children}</FormModelLoader>
    }
    return <FormModel {...props}>{props.children}</FormModel>
}

export default Form;