import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Strings} from "../../utils/strings";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";
import {fi} from "../../utils/helpers";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {Objects} from "../../utils/objects";
import React, {ReactElement} from "react";
import {PreviewRow, PreviewWrapper, Regular} from "./_style";

const FORM_TYPES = [
    {label: 'In house training', value: 'training'},
];

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Forms",
    description: "Adds a preconfigured form to the page that the user can fill.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class FormWidget extends BaseWidget implements IFormEvents {
    @formField({
        name: "Select form",
        fieldtype: FieldType.List,
        order: 1,
        flags: {required: true},
        config: {options: FORM_TYPES.map(i => Strings.localizedTxt(i.label))}
    })
    public formType: string;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.Form
        const sel = FORM_TYPES.find(i => i.value === Strings.default(item.formType))
        this.formType = fi(sel, sel?.label, '');
    }

    getTitle(): ReactElement | string {
        const title = super.getTitle();
        return <>{title} {fi(this.formType, <Regular> :: {this.formType}</Regular>)}</>
    }

    formOnBeforeSave(evt: FormEvent): any {
        // at this point 'item' is the label not the value, so we need to change that before saving
        const data = {...evt.state.values}
        data.formType = Objects.default(FORM_TYPES.find(i => i.label === data.formType)).value;
        return data
    }

    preview(): React.ReactElement | null {
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <label>Form</label>
                    <span className={fi(!this.formType, 'faded')}>{Strings.default(this.formType, 'Not set')}</span>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}