import {getRecoilPromise} from '../../state/recoilNexus';
import {productDataSelector} from '../../state/state';
import {tableConfig} from '../../utils/decorators';
import {Types} from "../types";
import React from "react";
import EditActionButton from '../../components/TableComponent/bulkActionButtons/Edit';
import MoveActionButton from '../../components/TableComponent/bulkActionButtons/Move';
import {GenericLibrary} from "./__GenericLibrary";
import BulkUpload from "../../components/TableComponent/bulkActionButtons/BulkUpload";

export const LibraryItemTypes: string[] = [
    Types.DOCUMENT, Types.IMAGE, Types.VIDEO, Types.AUDIO, Types.LINK, Types.TEXT, Types.EVENT_MATERIAL,
]

@tableConfig({
    additionalButtons: [<BulkUpload />],
    additionalActions: [<EditActionButton/>, <MoveActionButton/>],
    initialLoad: async () => {
        await getRecoilPromise(productDataSelector)
        return
    },
    itemTypes: LibraryItemTypes
})
export class Library extends GenericLibrary {
    public static title = 'Content item';

    constructor(item: any = {}) {
        super(item);
    }
}
