import {Field} from "../../../cms/models/__ModelInfo";
import React, {useEffect, useState} from "react";
import {useFormField} from "../state";
import styled from "@emotion/styled";
import {Lists} from "../../../utils/lists";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Spacer from "../../commons/Spacer";
import {DragDropContext, Draggable} from 'react-beautiful-dnd';
import {StrictModeDroppable} from "../../../utils/helpers";
import Client from "../../../cms/client";

const MultipleOptionsWrapper = styled.div`
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  background-color: white;
  padding: 8px 0;
  margin-top: 8px;
`;

const SelectionList = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px;
  cursor: default;

  &:hover {
    background-color: var(--color-box-shadow);
  }

  svg {
    color: var(--color-border);
    margin-top: 3px;
    width: 16px;
    height: 16px;
    transition: color 0.2s ease-in-out;
  }

  span:first-of-type {
    cursor: grab;
    margin-right: 3px;
  }
`;

const TagsListRenderer = ({field}: { field: Field }) => {
	useFormField(field.uid);
	const nameField = useFormField('name');
	const [value, setValue] = useState<string[]>([]);

	useEffect(() => {
		const tag = getTagFromName()
		if (tag) {
			Client.groupTagOrder(tag).then(response => {
				setValue(response);
			})
		} else {
			setValue([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nameField])

	const getTagFromName = () => {
		return nameField.value.split("#")[1]
	}

	const onDragEnd = (result) => {
		if (result.type !== `${field.uid}-tags-list`) {
			return;
		}
		const {destination, source} = result;
		if (!destination) {
			return;
		}
		if (!field.flags.multiple) {
			return;
		}
		// same position
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const newOrder: any[] = [...value];
		newOrder.splice(destination.index, 0, newOrder.splice(source.index, 1)[0]);
		setValue(newOrder)
		Client.updateGroupTagOrder(getTagFromName(), newOrder).then((response) => {
			setValue(response);
		})
	};

	if (!value || value.length === 0) {
		return null
	}

	return (
		<>
			<label>{field.name}</label>
			<MultipleOptionsWrapper>
				<DragDropContext onDragEnd={onDragEnd}>
					<StrictModeDroppable droppableId='droppable' type={`${field.uid}-tags-list`}>
						{(provided) => (
							<SelectionList {...provided.droppableProps} ref={provided.innerRef}>
								{Lists.default<string>(value).map((i, idx) => (
									<Draggable draggableId={i} index={idx} key={i}>
										{(provided) => (
											<Row key={idx}
												 ref={provided.innerRef} {...provided.draggableProps} >
												<span {...provided.dragHandleProps}><DragIndicatorIcon/></span>
												<span>{i}</span>
												<Spacer/>
											</Row>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</SelectionList>
						)}
					</StrictModeDroppable>
				</DragDropContext>
			</MultipleOptionsWrapper>
		</>
	)
}

export default TagsListRenderer;