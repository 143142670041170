import { fi } from '../../utils/helpers';
import { Strings } from '../../utils/strings';
import { CMSObject, DisplayMode } from './__CMSObject';
import {getRecoil, setRecoil} from "../../state/recoilNexus";
import {sessionAtom} from "../../state/session";
import {Messages} from "../../utils/messages";
import Client from "../client";
import {tableColumn, tableConfig} from "../../utils/decorators";
import {ColumnType} from "../../components/TableComponent/renderers";
import {pagesAtom} from "../../state/state";
import {Types} from "../types";
import {Page} from "./Page";
import {FormEvent} from '../../components/Form/model';

@tableConfig({
	initialLoad: async () => {
		// We need to load pages and content groups before the table loads,
		// so we can display on what pages is the topic referenced on
		const res = await Client.query<Page>({
			types: [Types.PAGE],
			limit: -1
		})
		setRecoil(pagesAtom, res.results);
	}
})
export class Topic extends CMSObject {
	public static title = 'Topic';
	public static autoPublish = true;

	public name: string;
	public description: string;
	public rateable_content: boolean;

	constructor(item: any = {}) {
		super(item);

		this.name = Strings.default(item.name);
		this.description = Strings.default(item.description);
		this.rateable_content = fi(typeof item.rateable_content === 'undefined', true, Boolean(item.rateable_content));
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		return this.name;
	}

	public async canDelete(): Promise<string> {
		const user = getRecoil(sessionAtom)
		if (!user || !user.isPublisher()) {
			return Messages.NoPermissionToDelete
		}
		const usage = await Client.checkUsage(this.getId());
		if (usage.items_count > 0) {
			return Messages.CantDeletePublishedAssociations;
		}
		return '';
	}

	public formOnSaveError(evt: FormEvent): Error | void {
		if (evt.error.message.toLowerCase().includes('value already exists: name')) {
			evt.state.setError('name', Messages.DuplicateValue)
			evt.error.message = Messages.FixErrors
		}
		return evt.error
	}

	@tableColumn({
		order: 3.5,
		label: 'Referenced on',
		field: 'referenced_on',
		type: ColumnType.Text,
	})
	public __referencedOn(_plain?: boolean): string | React.ReactNode {
		const pages = getRecoil(pagesAtom)
		const res = pages.filter(p => p.hasTopic(this.getId())).map(p => p.displayLabel())
		return res.join(', ')
	}
}
