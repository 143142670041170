import {Strings} from "../../utils/strings";
import {UUID} from "../types";
import {MediaItemFile} from "./__MediaLibrary";
import {columnDefinition} from "../../utils/decorators";

export class Document extends MediaItemFile {
    public static title = 'Document';
    public content_type: UUID;

    @columnDefinition({
        label: 'Available for trial'
    })
    public public_from?: Date;


    constructor(item: any = {}) {
        super(item);

        this.content_type = Strings.default(item.content_type);

        if (item.public_from) {
            this.public_from = new Date(item.public_from)
        }
    }
}