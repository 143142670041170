import React from "react";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import { Numbers } from '../../../utils/numbers';

export type Distribution = {
    min: number
    max: number
    avg: number
}

export type Roles = {
    count: number
    job_title: string
}

export type Visits = {
    count: number
    date: string
}

export type OrgDistribution = {
    accounts: number
    orgs: number
}

export type AccountsOverview = {
    accounts: number
    active: number
    orgcount: number
    accountorg: OrgDistribution
    favorites: Distribution
    orgs: Distribution
    qualifications: Distribution
    seen: Distribution
    roles: Roles[]
    logins: Visits[]
}

export const AvgBoxComponent = ({label, distribution}: { label: string, distribution: Distribution }) => {
    if (!distribution.avg|| !distribution.max || !distribution.min) {
        return null
    }
    return (
        <AvgBox>
            <div>{label}</div>
            <div>
                <div>
                    <span>max</span>
                    <span>{Numbers.default(distribution.max).toLocaleString()}</span></div>
                <div>
                    <span>avg</span>
                    <span>{Numbers.default(distribution.avg).toFixed(2)}</span>
                </div>
                <div>
                    <span>min</span>
                    <span>{Numbers.default(distribution.min).toLocaleString()}</span>
                </div>
            </div>
        </AvgBox>
    )
}


const AvgBox = styled(Paper)`
  display: flex;
  flex-direction: row;

  & > div:first-of-type {
    border-right: 1px solid var(--color-border-light);
    display: flex;
    align-items: center;
    padding: 8px;
    min-width: 150px;
    justify-content: flex-start;
    flex-grow: 1;
    font-size: 14px;
  }

  div:last-of-type {
    display: flex;
    flex-direction: column;

    div {
      flex: 1 1 0;
      border-bottom: 1px solid var(--color-border-light);
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      span:first-of-type {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        padding: 4px;
        border-right: 1px solid var(--color-border-light);
        min-width: 30px;
      }

      span:last-of-type {
        padding: 4px;
        font-size: 14px;
        text-align: center;
        flex-grow: 1;
        min-width: 40px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

  }
`