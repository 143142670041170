import {selectorFamily} from 'recoil';
import Client from '../cms/client';
import {ModelInfo} from '../cms/models/__ModelInfo';
import {Lists} from '../utils/lists';
import {Types} from "../cms/types";
import {cacheBuster} from "./state";

// Retrieves CMS models from the server or custom defined models through the addCustomModel method
export const cmsModelsSelector = selectorFamily<ModelInfo, string>({
    key: 'cmsModelsSelector',
    get: (modelId) => async ({get}) => {
        if (Object.values(Types).includes(modelId as any)) {
            const modelInfo = await Client.modelInfo(modelId);
            Lists.sort(modelInfo.fields, 'order');
            return modelInfo;
        }

        throw new Error('Model not found');
    },
});

