import {CMSObject} from "./__CMSObject";
import {QualificationGroupMapping} from "./QualificationGroupMapping";
import {Lists} from "../../utils/lists";
import {BaseClass} from "./__base";
import {InstanceOf} from "./index";
import {Objects} from "../../utils/objects";
import {SubjectGroupMapping} from "./SubjectGroupMapping";
import {QualificationSize} from "./QualificationSize";
import {Types} from "../types";
import {Assessment} from "./Assessment";
import {ChildAssessment} from "./ChildAssessment";
import {Component} from "./Component";
import {CTUnit} from "./CTUnit";
import {Pathway} from "./Pathway";
import {transaction} from "../../state/recoilNexus";
import {references} from "../../state/state";

export class ProductDataTree extends BaseClass {
    public qualifications: QualificationGroupMapping[] = [];

    constructor(data: any = {}) {
        super(data);
        const refs = Objects.default(data.references);
        const instanceReferences: { [key: string]: CMSObject } = {}

        // create instances of all the references
        for (let key in refs) {
            instanceReferences[key] = InstanceOf(refs[key]);
        }

        // do one more pass to link stuff with their references
        for (let key in instanceReferences) {
            const obj = instanceReferences[key];
            switch (obj.getType()) {
                case Types.CT_UNIT:
                    const unit = obj as CTUnit;
                    Lists.default<string>(unit.pathway).forEach(id => {
                        const pathway = instanceReferences[id] as Pathway;
                        if (pathway) {
                            unit.pathways.push(pathway);
                        }
                    })
                    Lists.default<string>(unit.optional_pathway).forEach(id => {
                        const pathway = instanceReferences[id] as Pathway;
                        if (pathway) {
                            unit.optionalPathways.push(pathway);
                        }
                    })

                    unit.qualificationSizes().forEach(id => {
                        const size = instanceReferences[id] as QualificationSize;
                        if (size) {
                            size.units.push(unit);
                        }
                    });

                    break;

                case Types.COMPONENT:
                    const component = obj as Component;
                    // push component to it's assessment
                    if (component.assessment) {
                        const a = instanceReferences[component.assessment] as Assessment;
                        if (a) {
                            a.components.push(component);
                        }
                    }
                    break;

                case Types.CHILD_ASSESSMENT:
                    const childAssessment = obj as ChildAssessment;
                    // push child assessment to all the assessments it belongs to
                    Lists.default<string>(childAssessment.assessment).forEach(id => {
                        const a = instanceReferences[id] as Assessment;
                        if (a) {
                            a.childAssessments.push(childAssessment);
                        }
                    })
                    break;
            }
        }


        Lists.default(data.qualifications).forEach((q: any) => {
            const qualificationGroupMapping = new QualificationGroupMapping(q)

            // subjects are set when the QualificationGroupMapping is part of the product data tree.
            Lists.default(q.subjects).forEach((s: any) => {
                const subjectMapping = new SubjectGroupMapping(s)
                if (qualificationGroupMapping.technicals) {
                    Lists.default<string>(subjectMapping.qualification_size).forEach(qsId => {
                        const qualificationSize = instanceReferences[qsId] as QualificationSize
                        if (!qualificationSize) {
                            return
                        }
                        Lists.sort(qualificationSize.units, 'alias')
                        subjectMapping.qualificationSizes.push(qualificationSize)
                    })
                    Lists.sort(subjectMapping.qualificationSizes, 'code')
                } else {
                    Lists.default<string>(subjectMapping.assessment).forEach(aId => {
                        const assessment = instanceReferences[aId] as Assessment
                        if (!assessment) {
                            return
                        }

                        Lists.sort(assessment.childAssessments, 'code')
                        Lists.sort(assessment.components, 'id')

                        subjectMapping.assessments.push(assessment)
                    })
                    Lists.sort(subjectMapping.assessment, 'code')
                }

                qualificationGroupMapping.subjects.push(subjectMapping);
            });

            Lists.sort(qualificationGroupMapping.subjects, 'title');
            this.qualifications.push(qualificationGroupMapping);
        })
        Lists.sort(this.qualifications, 'title')

        transaction(({set}) => {
            Object.values(instanceReferences).forEach((obj) => {
                set(references(obj.getId()), obj)
            })
        })
    }

    public getAssessment(idOrCode: string): [(Assessment | QualificationSize)?, SubjectGroupMapping?, QualificationGroupMapping?] {
        for (let i = 0; i < this.qualifications.length; i++) {
            const qualification = this.qualifications[i];
            for (let j = 0; j < qualification.subjects.length; j++) {
                const subject = qualification.subjects[j];
                const lookup: (Assessment | QualificationSize)[] = [...Lists.default<Assessment>(subject.assessments), ...Lists.default<QualificationSize>(subject.qualificationSizes)]

                for (let k = 0; k < lookup.length; k++) {
                    const assessment = lookup[k];
                    if (assessment.code.toString() === idOrCode.toString() || assessment.getId() === idOrCode) {
                        return [assessment, subject, qualification]
                    }
                }
            }
        }
        return [undefined, undefined, undefined]
    }

    public getUnit(idOrCode: string): [(CTUnit | ChildAssessment | Component)?, (Assessment | QualificationSize)?, SubjectGroupMapping?, QualificationGroupMapping?] {
        const parts = idOrCode.split('/')
        let assessmentCode: string, unitCode: string;

        if (parts.length === 2) {
            assessmentCode = parts[0];
            unitCode = parts[1];

            const [assessment, subject, qualification] = this.getAssessment(assessmentCode)
            if (assessment) {
                const lookup: any[] = [
                    ...Lists.default<ChildAssessment>((assessment as any).components),
                    ...Lists.default<QualificationSize>((assessment as any).childAssessments),
                    ...Lists.default<QualificationSize>((assessment as any).units),
                ]
                for (let i = 0; i < lookup.length; i++) {
                    const unit = lookup[i];
                    if (unit.code === unitCode || unit.alias === unitCode) {
                        return [unit, assessment, subject, qualification]
                    }
                }
            }
            return [undefined, undefined, undefined, undefined]
        }

        for (let i = 0; i < this.qualifications.length; i++) {
            const qualification = this.qualifications[i];
            for (let j = 0; j < qualification.subjects.length; j++) {
                const subject = qualification.subjects[j];
                const assessmentLookup: (Assessment | QualificationSize)[] = [...Lists.default<Assessment>(subject.assessments), ...Lists.default<QualificationSize>(subject.qualificationSizes)]

                for (let k = 0; k < assessmentLookup.length; k++) {
                    const assessment = assessmentLookup[k];

                    const lookup: any[] = [
                        ...Lists.default<Component>((assessment as any).components),
                        ...Lists.default<ChildAssessment>((assessment as any).childAssessments),
                        ...Lists.default<QualificationSize>((assessment as any).units),
                    ]

                    for (let i = 0; i < lookup.length; i++) {
                        const unit = lookup[i];
                        if (unit.code === idOrCode || unit.alias === idOrCode || unit.getId() === idOrCode) {
                            return [unit, assessment, subject, qualification]
                        }
                    }
                }
            }
        }
        return [undefined, undefined, undefined]
    }

}