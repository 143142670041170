import styled from "@emotion/styled";
import Input from "@mui/material/Input";
import React, {useEffect} from "react";
import {Strings} from "../../../../../utils/strings";
import {fi} from "../../../../../utils/helpers";

const InputFieldElement = styled(Input)`
  padding: 0;

  &:after {
    display: ${(props: any) => fi(typeof props['data-max'] === "undefined", 'none', 'block')};
    position: absolute;
    content: attr(data-length) "/" attr(data-max);
    font-size: 14px;
    color: var(--color-border);
    right: 4px;
  }

  input {
    padding: ${(props: any) => fi(typeof props['data-max'] === "undefined", '9px 8px', '9px 70px 9px 8px')};
  }
`

export const InputField = (props: any) => {
    const [length, setLength] = React.useState(0);

    useEffect(() => {
        setLength(Strings.default(props.value).length);
    }, [props.value])

    const onChange = (e) => {
        if (props.max && props.max < e.target.value.length) {
            e.preventDefault()
            return
        }

        setLength(e.target.value.length);
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <InputFieldElement
            {...props}
            onChange={onChange}
            data-length={length}
            data-max={props.max}/>
    )
}

