import styled from '@emotion/styled';
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
import { useTableActions, useTableState } from './state';
import {useHotkeys} from "react-hotkeys-hook";

const Wrapper = styled.div`
  height: 58px;
  padding-right: 16px;
`;

const Table = styled.table`
  width: 100%;
  margin-top: 8px;
  
  td.MuiTablePagination-root {
	border-bottom: none;
  }
  
  .MuiTablePagination-displayedRows{
    flex: 1;
    text-align: right;
  }
  .MuiTablePagination-spacer {
	display: none;
  }
`;

const TableFooter = () => {
	const state = useTableState();
	const {setPage, setLimit} = useTableActions();

	const onPageChange = (_event, newPage) => {
		setPage(newPage);
	};

	const onRowsPerPageChange = (_event) => {
		setLimit(parseInt(_event.target.value, 10));
	};

	useHotkeys('ArrowRight,PageDown,ArrowDown', (evt) => {
		evt.preventDefault();
		if (state.items.offset + state.preferences.limit < state.items.total) {
			setPage(state.preferences.page + 1)
		}
	});

	useHotkeys('ArrowLeft,PageUp,ArrowUp', (evt) => {
		evt.preventDefault();
		if (state.preferences.page > 0) {
			setPage(state.preferences.page - 1)
		}
	});

	useHotkeys('Home', (evt) => {
		evt.preventDefault();
		setPage(0)
	});

	useHotkeys('End', (evt) => {
		evt.preventDefault();
		setPage(Math.floor(state.items.total / state.preferences.limit))
	});


	if (state.items.total === 0) {
		return null
	}

	return (
		<Wrapper>
			<Table>
				<tbody>
				<tr>
					<TablePagination
						showFirstButton={true}
						showLastButton={true}
						rowsPerPageOptions={[ 10, 15, 50, 250, 1000 ]}
						count={state.items.total}
						page={Math.min(state.preferences.page, Math.ceil(state.items.total / state.preferences.limit))}
						rowsPerPage={state.preferences.limit}
						onRowsPerPageChange={onRowsPerPageChange}
						onPageChange={onPageChange}/>
				</tr>
				</tbody>
			</Table>
		</Wrapper>
	);
};

export default TableFooter;
