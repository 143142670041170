import styled from '@emotion/styled';
import React from 'react';
import Content from './Content';
import Header from './Header';
import Menu from './Menu';
import {useRecoilValue} from "recoil";
import {sessionAtom} from "../state/session";
import SelectStream from '../components/StreamSwitch/StreamSelect';

const LayoutElem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
    'nav header'
    'nav main';
  height: 100%;
  overflow: hidden;
`;

const PageLayout = ({children}: any) => {
    const session = useRecoilValue(sessionAtom);

    if (!session?.selectedBusinessStream) {
        return <SelectStream/>
    }

    return (
        <LayoutElem data-testid="layout-component">
            <Header/>
            <Menu/>
            <Content>
                {children}
            </Content>
        </LayoutElem>
    );
};

export default PageLayout;
