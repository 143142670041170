import CloudDone from '@mui/icons-material/CloudDone';
import CloudQueue from '@mui/icons-material/CloudQueue';
import React, {useContext, useEffect} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {fi} from '../../../utils/helpers';
import {Messages} from '../../../utils/messages';
import {ActionButton} from '../../commons/ActionButton';
import {selectedTableItemsSelector, TableContext, useTableActions} from '../state';
import {useModalDialog} from '../../ModalDialogs/effect';
import {useSnackbar} from 'notistack';
import {Strings} from '../../../utils/strings';
import Client from '../../../cms/client';
import PublishModal from '../../ModalDialogs/PublishModal';
import {modalAtom, ModalWrapperProps} from '../../ModalDialogs/ModalWrapper';

const PublishUnpublishActionButton = () => {
	const ctx = useContext(TableContext);
	const [disabled, setDisabled] = React.useState(false);
	const [title, setTitle] = React.useState(Messages.NoSelectedDraftItem);
	const [toPublish, setToPublish] = React.useState(false);
	const {confirmation} = useModalDialog();
	const {refresh} = useTableActions();
	const {enqueueSnackbar} = useSnackbar();
	const setModalEdit = useSetRecoilState(modalAtom);

	const state = useRecoilValue(selectedTableItemsSelector(ctx.type));

	useEffect(() => {
		let shouldDisable = state.count === 0;
		let newTitle: string = Messages.NoSelectedDraftItem;
		let buttonType = true;
		let published = 0, unpublished = 0;
		if (state.count) {
			state.objects.forEach(item => {
				if (!item) return;

				if (item.isPublished()) {
					published++;
				} else {
					unpublished++;
				}
			});

			if (published > 0 && unpublished > 0) {
				shouldDisable = true;
				newTitle = Messages.MixSelection;
			} else if (published > 0 && unpublished === 0) {
				buttonType = false;
				newTitle = 'Unpublish selected items';
			} else if (unpublished > 0 && published === 0) {
				buttonType = true;
				newTitle = 'Publish selected items';
			}
		}

		setTitle(newTitle as Messages);
		setDisabled(shouldDisable);
		setToPublish(buttonType);
	}, [state]);

	const onPublishUnpublish = () => {
		if (toPublish) {
			// trigger popup
			setModalEdit({
				component: (props: ModalWrapperProps) => {
					return <PublishModal {...props} items={state.objects} onConfirm={() => refresh()}/>;
				},
			});
		} else {
			const msg = `Are you sure you want to unpublish the ${Strings.pluralize(state.count, 'item', 'items')} selected?`;
			confirmation(msg, async () => {
				return Client.unpublish({uuids: state.ids}).then(() => {
					refresh();
					enqueueSnackbar(Strings.ofPlural(state.count, 'Item', 'Items') + ' unpublished successfully', {variant: 'success'});
				}).catch(error => {
					enqueueSnackbar(`Error unpublishing ${Strings.ofPlural(state.count, 'item', 'items')}: ${error}`, {variant: 'error'});
				});
			});
		}
	};

	return (
		<ActionButton
			data-testid='table-publish-unpublish-action-button'
			disabled={disabled}
			onClick={onPublishUnpublish}
			title={title}>
			{fi(toPublish, <CloudDone />, <CloudQueue />)}
		</ActionButton>
	);
};

export default PublishUnpublishActionButton;
