import React, { useEffect, useState } from 'react';
import { Field } from '../../../cms/models/__ModelInfo';
import { useForm, useFormField } from '../state';
import Client from '../../../cms/client';
import { Types } from '../../../cms/types';
import { KeyDates } from '../../../cms/models/KeyDates';
import { TimeTables } from '../../../cms/models/TimeTables';
import styled from '@emotion/styled';
import { Dates } from '../../../utils/dates';
import { Lists } from '../../../utils/lists';
import { useRecoilValue } from 'recoil';
import { keyDatesStatusAtom, loadingProcess } from '../../../cms/models/KeyDateFile';
import { useSnackbar } from 'notistack';
import { resetRecoil } from '../../../state/recoilNexus';

type ExamDateType = KeyDates | TimeTables;

const OptionsWrapper = styled.div`
  border: 1px solid var(--color-border);
  padding: 8px;
  padding-right: 0;
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 444px;
  overflow-y: auto;
`;

const Container = styled.div`
    display: grid;
    width: 49%;
    position: relative;

    border: 1px solid var(--color-border);
    border-radius: 6px;
    background-color: white;

    grid-template-areas: "date info"
						"series info";
    grid-template-columns: auto 1fr;
    grid-template-rows: 90px 45px;

    .flag {
        position: absolute;
        right: 0;
        font-family: var(--font-semi-bold);
        font-size: 12px;
        margin-top: -1px;
        line-height: 17px;

        span {
            background-color: var(--color-green);
            margin-right: 16px;
            border-radius: 0 0 4px 4px;
            color: var(--color-white);
            height: 18px;
            padding: 0 8px;
            display: inline-block;
        }
    }

    @media (max-width: 1200px) {
        width: 100%;
    }

    &.key_date > div:not(:last-of-type):not(.flag) {
        background: var(--color-blue-light);
    }

    &.timetable_date > div:not(:last-of-type):not(.flag) {
        background: var(--color-light-orange);
    }
`;

const Date = styled.div`
  grid-area: date;
  padding: 16px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border-right: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  border-radius: 6px 0 0 0;

  font-family: var(--font-regular);
  font-weight: bold;

  & > span:first-of-type {
    font-size: 14px;
  }

  & > span:last-of-type {
    font-family: var(--font-bolder);
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
  }
`;

const Series = styled.div`
  grid-area: series;
  padding: 16px;

  display: flex;
  align-items: center;

  border-right: 1px solid var(--color-border);
  border-radius: 0 0 0 6px;

  font-family: var(--font-regular);
  font-size: 12px;
  font-weight: 400;

  > span {
    font-family: var(--font-bold);
  }
`;

const Info = styled.div`
  grid-area: info;
  padding: 16px;
  color: var(--color-monochrome);

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  min-width: 270px;
  max-width: 470px;

  font-size: 14px;
  font-family: var(--font-regular);

  & > div > div:first-of-type {
    font-size: 16px;
    font-family: var(--font-bold);

    &:first-of-type {
      margin-bottom: 6px;
    }
  }

  & > div:last-of-type {
    span {
      font-weight: bold;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    line-height: initial;
  }
`;

const Card = ({keyDate}: {keyDate: ExamDateType}) => {
	return (
		<Container key={keyDate.getId()} data-testid={`${keyDate.getType()}-${keyDate.getId()}`}
				   className={keyDate.getType()}>
			<div data-testid='flag' className='flag'>{keyDate.getFlag()}</div>
			<Date>
				<span>{Dates.getMonth(keyDate.date)}</span>
				<span>{keyDate.date.getDate().toString().padStart(2, '0')}</span>
			</Date>
			<Series>Series: &nbsp; <span>{keyDate.getSeriesYear()}</span></Series>
			<Info>
				<div data-testid="title-section">
					{keyDate.getTitle()}
					{keyDate.getDate()}
					<div className="mb8">{keyDate.description}</div>
				</div>
				{keyDate.renderQualification()}
			</Info>

		</Container>
	);
};

const CardsListRenderer = ({field}: {field: Field}) => {
	useFormField(field.uid);
	const loading = useRecoilValue(loadingProcess);
	const fileField = useFormField('file');
	const form = useForm();
	const [list, setList] = useState<ExamDateType[]>([]);
	const keyDatesStatus = useRecoilValue(keyDatesStatusAtom);
	const {enqueueSnackbar} = useSnackbar();

	// get timetables and key-dates for keyDateFile object
	useEffect(() => {
		Client.query({
			limit: -1,
			matching: {key_dates_file: form.state.object.getId()},
			types: [Types.KEY_DATE, Types.TIMETABLE],
		}).then((response) => {
			setList(Lists.sort(Lists.default(response.results) as ExamDateType[], 'date'));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field, fileField]);

	useEffect(() => {
		if (!keyDatesStatus) return;
		enqueueSnackbar({...keyDatesStatus});
		resetRecoil(keyDatesStatusAtom);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyDatesStatus]);

	if (loading) {
		return (<div>Processing key dates file ...</div>);
	}


	if (!list || list.length === 0) {
		return null;
	}

	return (
		<>
			<label>{field.name}</label>
			<OptionsWrapper>
				{list.map((keyDate) => (
					<Card key={keyDate.getId()} keyDate={keyDate}/>
				))}
			</OptionsWrapper>
		</>);
};
export default CardsListRenderer;