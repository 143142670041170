import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { UUID } from "../types";
import { CMSObject } from './__CMSObject';

export class QualificationGroup extends CMSObject {
	public static title = 'Qualification group';
	public static autoPublish = true;
	public name: string;
	public id: number;
	public business_stream: UUID[];

	constructor(item: any = {}) {
		super(item);

		this.name = Strings.default(item.name);
		this.id = item.id || 0;
		this.business_stream = Lists.default(item.business_stream);
	}
}
