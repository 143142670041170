import React from "react";
import styled from "@emotion/styled";
import {Node} from "./utils";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {fi} from "../../../../../utils/helpers";
import {DisplayMode} from "../../../../../cms/models/__CMSObject";

const NodeWrapper = styled.li`
  cursor: default;
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
`

const ToggleWrapper = styled.div`
  width: 20px;

  svg {
    height: 20px;
  }
`

const Small = styled.span`
  font-size: 14px;
  margin-left: 16px;
  color: var(--color-border)
`

const CheckboxElem = styled.span`
  &.checkbox-img-unchecked, &.checkbox-img-checked, &.checkbox-img-intermediate {
    display: block;
    line-height: 38px;

    &:before {
      content: "";
      background-repeat: no-repeat;
      background-size: 18px 18px;
      background-position-x: center;
      background-position-y: center;
      width: 24px;
      height: 38px;
      cursor: pointer;
      padding-left: 30px;
    }
  }

  &.checkbox-img-unchecked:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIZJREFUeNpiZACBkr0CQDIBiAUYqAM+APEChh7nD4xQw/cDsQEDdcEFIHZkgrqc2oYzQM1MYEELlkKozZQa3A9lC7BgeKvH+QBFxpfsReEyMdAYjFowasGoBaMWjFpADcCCUVmgledkVjgoFnxA4vdT2QMfmMC1P+XVJK5KfwEjrZstAAEGACbGGyc6yLKfAAAAAElFTkSuQmCC);
  }

  &.checkbox-img-checked:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPhJREFUeNq0loENgyAQAF/iAI7iCG0cRNygJoxh4giyCLEjOAoj9Ck0BcFW8f3kjZh4pz4PFmBCqAqPHLMCmtCYEoZGFw4+Y9ZAGwvmnbknp4aDY/Jy9Vl6Zz4LHt15VUavNTTPU3ihgiGDi4NGINSIWV8jEGrC4+M9ExMSRgDnn4JitnSCEA6usXoaQRre7a+B+ZZC8bPwtMAWyiwdUyQ5CN96g9br7q8kA54W2EJJ74qRzDnw7RrYm33JLQf+exbFksPw/9M0lByG7+sDC+1y4PsbbWhkbsOX0WaxWs8zN5xAoL3xSLwdaOaKuAB9GKYsrv5teQkwAH7lWScXifqsAAAAAElFTkSuQmCC);
  }

  &.checkbox-img-intermediate:before {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAxQTFRFgbrfAXW9xN7v////XcKp6wAAAAR0Uk5T////AEAqqfQAAABFSURBVHjazJIxCgAwCAPT5P9/bgsOViJ09NZzOSJEGChwWc65F7gCLCAEVeAQkasfkdv+RO6eE+hFO5Sftn2G7n22AAMAhOAD3V6Bzy4AAAAASUVORK5CYII=);
  }
`

type ITreeNodeProps = {
    node: Node;
    query: string;
    onToggle: (node: Node) => void;
    onSelect: (node: Node) => void;
}

const TreeNode = ({node, onToggle, onSelect, query}: ITreeNodeProps) => {
    const childLength = node.children.length;

    const classNames = () => {
        const classes: string[] = [];
        if (node.selected) {
            classes.push("checkbox-img-checked")
        } else {
            if (node.selectedChildren > 0) {
                classes.push("checkbox-img-intermediate")
            } else {
                classes.push("checkbox-img-unchecked")
            }
        }
        return classes.join(" ")
    }

    return (
        <NodeWrapper data-uuid={node.id} style={{marginLeft: node.depth * fi(Boolean(query), 0, 16)}}>
            {!Boolean(query) &&
                <ToggleWrapper onClick={() => onToggle(node)}>
                    {fi(childLength, fi(node.expanded, <ExpandLess/>, <ExpandMore/>))}
                </ToggleWrapper>
            }
            <CheckboxElem className={classNames()} onClick={() => onSelect(node)}></CheckboxElem>
            <span>{node.object.displayLabel(DisplayMode.DETAILED)}</span>
            {Boolean(query) &&
                <Small>{fi(node.parents.length, node.parents[0].object.displayLabel(DisplayMode.DETAILED))}</Small>
            }
        </NodeWrapper>
    )
}

export default TreeNode