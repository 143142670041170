import React from 'react';
import {ActionButton} from '../../commons/ActionButton';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import Client from '../../../cms/client';
import {fi} from '../../../utils/helpers';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {usePopupState} from 'material-ui-popup-state/hooks';

const ExamDatesTemplates = () => {
	const popupState = usePopupState({variant: 'popover', popupId: 'examDatesTemplates'});

	return (
		<>
			<ActionButton
				{...bindTrigger(popupState)}
				data-testid='table-key-dates-action-button'
				disabled={false}
				title={'Download templates'}
			>
				<SystemUpdateAltIcon />
				{fi(popupState.isOpen, <ExpandLessIcon fontSize='small' />, <ExpandMoreIcon fontSize='small' />)}
			</ActionButton>
			<Menu {...bindMenu(popupState)}
				  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				  transformOrigin={{vertical: 'top', horizontal: 'left'}}
			>
				<MenuItem key={'keyDatesTemplate'} value={'Download key dates template'}
						  onClick={() => {
							  Client.downloadKeyDatesTemplate().catch();
							  popupState.close();
						  }}>
					Download key dates template
				</MenuItem>
				<MenuItem key={'timetableTemplate'} value={'Download timetable dates template'}
						  onClick={() => {
							  Client.downloadTimetableDatesTemplate().catch();
							  popupState.close();
						  }}>
					Download timetable dates template
				</MenuItem>
			</Menu>
		</>
	);
};

export default ExamDatesTemplates;