import styled from '@emotion/styled';
import React, {Suspense} from 'react';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ModalDialogs from '../components/ModalDialogs/ModalDialogs';
import PageLoader from "../components/Loader/PageLoader";
import {ErrorBoundary} from 'react-error-boundary';
import ActionButtons from './ActionButtons';
import usePreventReload from "../components/commons/PreventReload";

const ContentElem = styled.main`
  grid-area: main;
  padding: 24px 24px 150px 24px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ContentToolbar = styled.div`
    min-height: 48px;
`;

const ContentActions = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        white-space: nowrap;
    }
`;

const PageContent = styled.div`
  flex-grow: 1;
  margin-top: 24px;
  position: relative;
`;

const ErrorFallback = ({error, resetErrorBoundary}) => {
    console.error(error)

    return (
        <>Some error <button onClick={resetErrorBoundary}>Retry</button></>
    );
};

const Content = ({children}: any) => {
    usePreventReload()

    return (
        <ContentElem data-testid="content-component">
            <ContentToolbar className={'flex-row spaced'}>
                <Breadcrumbs/>
                <ContentActions>
                    <ActionButtons/>
                </ContentActions>
            </ContentToolbar>
            <PageContent>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<PageLoader/>}>
                        {children}
                    </Suspense>
                </ErrorBoundary>
            </PageContent>
            <ModalDialogs/>
        </ContentElem>
    );
};

export default Content;
