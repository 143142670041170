import {Types, UUID} from "../types";
import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Lists} from "../../utils/lists";
import {Strings} from "../../utils/strings";
import {formField} from "../../utils/decorators";
import {Faded, PreviewRow, PreviewWrapper, Regular} from "./_style";
import React, {ReactElement} from "react";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {DisplayMode} from "./__CMSObject";
import {fi} from "../../utils/helpers";
import {ContentGroup} from "./ContentGroup";
import {FieldType} from "./__ModelInfo";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {Objects} from "../../utils/objects";

const GROUPING = [
    {label: 'Content types', value: 'content_types'},
    {label: 'Units or components', value: 'units'},
];

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Content Group",
    description: "Displays library items grouped by [Content Types] that are associated with the selected [Content Group]."
})
export class ContentGroupWidget extends BaseWidget implements IFormEvents {
    // content group selected to be displayed
    @formField({
        fieldtype: FieldType.Reference,
        order: 1,
        name: "Select a content group",
        flags: {required: true},
        config: {refModel: Types.CONTENT_GROUP}
    })
    public contentGroup: UUID;

    // customize the list of content types to be displayed
    @formField({
        name: "Content Types",
        fieldtype: FieldType.Reference,
        flags: {required: true, hideSelection: true, noClear: true, multiple: true},
        order: 4,
        config: {linkedWith: "contentGroup", refModel: Types.CONTENT_TYPE}
    })
    public contentTypes: UUID[];

    // group child groups by card units or content types
    @formField({
        fieldtype: FieldType.List,
        name: "Group by",
        order: 3,
        config: {
            options: [Strings.localizedTxt('Content types'), Strings.localizedTxt('Units or components')],
            isRadioGroup: true
        }
    })
    public groupBy: 'units' | 'content_types'

    // whether to show the title of the content group
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Show title",
        group: "display-options",
        order: 2,
        groupTitle: "Display options"
    })
    public showTitle: boolean;

    // whether to show the description of the content group
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Show description",
        group: "display-options"
    })
    public showDescription: boolean;

    // sort order of the child groups
    @formField({
        fieldtype: FieldType.Boolean,
        name: "Sort descending",
        group: "display-options"
    })
    public showDescending: boolean;

    constructor(item: any = {}) {
        super(item);

        this.type = WidgetType.ContentGroup;

        this.contentGroup = Strings.default(item.contentGroup);
        this.contentTypes = Lists.default(item.contentTypes);
        const sel = GROUPING.find(i => i.value === Strings.default(item.groupBy))
        this.groupBy =  fi(sel, sel?.label, 'Content types');
        this.showDescription = Boolean(item.showDescription);
        this.showTitle = Boolean(item.showTitle);
        this.showDescending = Boolean(item.showDescending) || Boolean(item.sortDescending);
    }

    getTitle(): ReactElement | string {
        const title = super.getTitle();
        const contentGroup = getRecoil(references(this.contentGroup))
        if (!contentGroup) {
            return title
        }

        return <>{title} <Regular> :: {contentGroup.displayLabel()}</Regular></>
    }

    public formOnFieldChange(evt: FormEvent) {
        switch (evt.fieldUID) {
            case 'contentGroup':
                const contentGroup = getRecoil(references(evt.fieldValue)) as ContentGroup
                if (contentGroup) {
                    evt.state.fieldState('contentTypes').setValue(contentGroup.content_types)
                } else {
                    evt.state.fieldState('contentTypes').setValue([])
                }
                break;
            default:
        }
    }

    formOnBeforeSave(evt: FormEvent): any {
        // at this point 'item' is the label not the value, so we need to change that before saving
        const data = {...evt.state.values}
        data.groupBy = Objects.default(GROUPING.find(i => i.label === data.groupBy)).value;
        return data
    }

    public preview(): React.ReactElement | null {
        const group = getRecoil(references(this.contentGroup)) as ContentGroup
        let groupTitle = "missing";
        if (group) {
            groupTitle = group.displayLabel(DisplayMode.FULL);
        }

        return (
            <PreviewWrapper>
                <PreviewRow className='vertical'>
                    <label>{groupTitle}</label>
                    <span>{Strings.default(group.description, '-')}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Display options</label>
                    {fi(this.showTitle, <span>Show title</span>, <Faded>Show title</Faded>)} /
                    {fi(this.showDescription, <span>Show description</span>, <Faded>Show description</Faded>)} /
                    {fi(this.showDescending, <span>Sort descending</span>, <Faded>Sort descending</Faded>)} /
                    {' Group by ' + this.groupBy.toLowerCase()}
                </PreviewRow>
                <PreviewRow>
                    <label>Content types</label>
                    <ul>
                        {this.contentTypes.map((type, idx) => (
                            <li key={`${idx}-${type}`}>{getRecoil(references(type))?.displayLabel()}</li>
                        ))}
                    </ul>
                </PreviewRow>
                <PreviewRow>

                </PreviewRow>
            </PreviewWrapper>
        )
    }
}