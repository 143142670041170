import React, {useCallback, useEffect, useMemo} from 'react';
import {ModalActions, ModalWrapperProps} from './ModalWrapper';
import {UUID} from '../../cms/types';
import Form from '../Form/Form';
import {MoveItems} from '../../cms/models/_MoveItems';
import {useForm} from '../Form/state';
import {RenderFormFields} from "../Form/utils";
import {setRecoil} from "../../state/recoilNexus";
import {confirmationModalAtom} from "./ConfirmationModal";

const saveButton: ModalActions = {type: 'ok', hint: 'Move to', label: 'Move to', color: 'primary'};

const MoveModalContent = (props: ModalWrapperProps & { onConfirm: (targetId: UUID) => Promise<void> }) => {
	const form = useForm();

	const fields = useMemo(() => {
		return RenderFormFields(form.state);
	}, [form]);

	const onConfirm = useCallback(async () => {
		props.setError('');
		// validate form
		if (!form.validate()) {
			throw new Error('Please fix the errors and try again');
		}
		setRecoil(confirmationModalAtom, {
			message: <>Are you sure you want to move selected items?</>,
			onConfirm: async () => {
				return props.onConfirm(form.state.values.folderId);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	useEffect(() => {
		props.setTitle('Move items');
		props.setActions(saveButton);
		props.whenConfirm(onConfirm);
	}, [props, onConfirm])

	return <>{fields}</>;
};

const MoveModal = ({onConfirm, ...props}: ModalWrapperProps & { onConfirm: (targetId: UUID) => Promise<void> }) => {
	const formId = 'move-modal';

	return (
		<Form model={MoveItems.model} id={formId}>
			<MoveModalContent {...props} onConfirm={onConfirm}/>
		</Form>
	);
};

export default MoveModal;
