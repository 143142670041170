import { Lists } from '../../utils/lists';
import { Numbers } from '../../utils/numbers';
import { Strings } from "../../utils/strings";
import { UUID } from '../types';
import { CMSObject, DisplayMode } from './__CMSObject';

export class ChildAssessment extends CMSObject {
	public static title = 'Unit';
	public static autoPublish = true;
	public code: string;
	public name: string;
	public assessment: UUID[];
	public version: number;

	constructor(item: any = {}) {
		super(item);

		this.code = Strings.default(item.code);
		this.name = Strings.default(item.name);
		this.assessment = Lists.default(item.assessment);
		this.version = Numbers.default(item.version);
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
				return this.code;
			case DisplayMode.DETAILED:
				return `${this.code} - ${this.name}`;
			default:
				return `${this.code} - ${this.name}`;
		}
	}
}
