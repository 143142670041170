import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Key dates",
    description: "Full page widget used to display key dates.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class KeyDatesWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.KeyDates
    }

    public editable() {
        return false;
    }
}