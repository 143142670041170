import React from 'react';
import ConfirmationModal from './ConfirmationModal';
import ModalWrapper from "./ModalWrapper";
import styled from "@emotion/styled";

const PortalWrapper = styled.div`
  position: fixed;
  z-index: 9000;
  bottom: 0;
  background: transparent;
`

const ModalDialogs = () => {
    return (
        <>
            <ConfirmationModal/>
            <ModalWrapper/>
            <PortalWrapper id='portal-wrapper'></PortalWrapper>
            <PortalWrapper id='list-portal-wrapper'></PortalWrapper>
        </>
    )
}

export default ModalDialogs;
