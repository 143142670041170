import Button from '@mui/material/Button';
import React, {useMemo} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {atom, useRecoilState} from 'recoil';
import {cancelEvent} from '../../utils/helpers';
import GenericModal from './GenericModal';

type ConfirmationModalProps = {
	message: React.ReactNode,
	onConfirm: () => Promise<void>,
	onCancel?: () => void,
}

export const confirmationModalAtom = atom<ConfirmationModalProps | null>({
	key: 'confirmationModalAtom',
	default: null,
});

const ConfirmationModal = () => {
	const [ value, setValue ] = useRecoilState(confirmationModalAtom);

	const onCancel = (evt) => {
		cancelEvent(evt);
		if (!value) return;
		if (value.onCancel) {
			value.onCancel();
		}
		setValue(null);
	};

	const onConfirm = async (evt) => {
		cancelEvent(evt);
		if (!value) return;
		await value.onConfirm();
		setValue(null);
	};

	useHotkeys('enter', onConfirm);
	useHotkeys('esc', onCancel);

	const footer = useMemo(() => {
		return (
			<>
				<Button variant="contained" color="secondary" onClick={onCancel} data-testid={'modal-cancel'} title={'Cancel'}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={onConfirm} data-testid={'modal-ok'} title={'Ok'}>Ok</Button>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ value ]);

	if (!value) return null;

	return (
		<GenericModal title={'Confirmation'} footer={footer} onClose={onCancel}>
			{value.message}
		</GenericModal>
	);
};

export default ConfirmationModal;
