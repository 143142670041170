import { refreshRecoil, setRecoil } from '../state/recoilNexus';
import { sessionAtom, sessionSelector } from '../state/session';
import { cacheBuster } from '../state/state';

export const Process = {
	ENV: process.env.NODE_ENV,
	Authenticated: false,
	cleanup: () => {
		setRecoil(sessionAtom, null);
		refreshRecoil(sessionSelector);
		Process.Authenticated = false;
		setRecoil(cacheBuster('auth.session'), (val) => val + 1);
	},
};

