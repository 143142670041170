import {Strings} from "../../utils/strings";
import {UUID} from "../types";
import {columnDefinition, tableHidden} from '../../utils/decorators';
import {LibraryItem} from "./__MediaLibrary";

export class Text extends LibraryItem {
    public static title = 'Page content / Text';
    @tableHidden
    public text: string;
    @columnDefinition({
        label: 'Available for trial'
    })
    public public_from?: Date;
    @tableHidden
    public topic: UUID;

    constructor(item: any = {}) {
        super(item);

        this.text = Strings.default(item.text);
        this.topic = Strings.default(item.topic);

        if (item.public_from) {
            this.public_from = new Date(item.public_from)
        }
    }
}