import React from "react";
import Client from "../../cms/client";
import {DownloadReport} from "./components/DownloadReport";

const Reports = () => {
    return (
        <>
            <DownloadReport label='User status' callback={() => Client.userStatusReport()}/>
            <DownloadReport label='Feedback form' callback={() => Client.feedbackFormReport()}/>
            <DownloadReport label='Trial access survey' callback={() => Client.trialAccessSurveyReport()}/>
            <DownloadReport label='User subject details' callback={() => Client.userSubjectDetailsReport()}/>
            <DownloadReport label='Trial access users' callback={() => Client.trialAccessUsersReport()}/>
            <DownloadReport label='Rating' callback={() => Client.ratingReport()}/>
            <DownloadReport label='Subject updates emails' callback={() => Client.subjectUpdatesEmailsReport()}/>
        </>
    )
}

export default Reports;