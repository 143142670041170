import {defaultTo, isEqual, mergeWith} from 'lodash';

export const Objects = {
    default: (value: any, def: any = {}): any => {
        if (typeof value === 'object') {
            if (Array.isArray(value)) {
                return def;
            }
            if (value !== null) {
                return value;
            }
        }
        return def;
    },
    clone: (obj: any): any => {
        return JSON.parse(JSON.stringify(obj));
    },
    same: (obj1: any, obj2: any): boolean => {
        return isEqual(obj1, obj2);
    },
    isEmpty: (obj: any): boolean => {
        return !obj || Object.keys(obj).length === 0;
    },
    empty: (obj: any) => {
        for (const key in obj) {
            delete obj[key];
        }
    },
    extend: (obj1: any, obj2: any): any => {
        return mergeWith({...obj1}, {...obj2}, (objValue, srcValue) => {
            if (Array.isArray(objValue)) {
                return objValue.concat(srcValue);
            }

            if (typeof objValue === 'object') {
                return Objects.extend(objValue, srcValue);
            }
            return defaultTo(objValue, srcValue);
        })
    }
};
