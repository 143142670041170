import {useParams} from "react-router-dom";
import React, {useMemo} from "react";
import {ClassOf} from "../../../cms/models";
import Typography from "@mui/material/Typography";
import {fi} from "../../../utils/helpers";
import {Strings} from "../../../utils/strings";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styled from "@emotion/styled";

const FormTitleWrapper = styled.div`
  margin-bottom: 24px;
`

export const FormTitle = () => {
    const params = useParams();
    const cls = useMemo(() => ClassOf(params.type as string), [params.type]);

    const copyToClipboard = (evt) => {
        evt.target.select();
        evt.target.setSelectionRange(0, 99999);
        document.execCommand('copy');
        evt.target.setSelectionRange(0, 0);
        const elem = document.querySelector('.copied-to-clipboard');
        if (elem) {
            elem.classList.add('visible');
            setTimeout(() => {
                elem.classList.remove('visible');
            }, 1000);
        }
    };

    return (
        <FormTitleWrapper>
            <Typography
                variant='h2'>{`${fi(params.itemId, 'Edit', 'Create')} ${Strings.default(cls.title).toLowerCase()}`}</Typography>
            {params.itemId && (
                <CopyToClipboardSection>
                    <input type="text" value={params.itemId} id="object-id" onClick={copyToClipboard}
                           className={'object-id'}
                           readOnly={true}
                           title={'Click to copy to clipboard'}/>
                    <span className={'copied-to-clipboard flex-row'}><ContentCopyIcon/> Copied to clipboard</span>
                </CopyToClipboardSection>
            )}
        </FormTitleWrapper>
    )
}

const CopyToClipboardSection = styled.div`
  margin-top: 4px;
  font-family: Source Sans Pro, sans-serif;
  display: flex;
  align-content: center;

  input {
    background: transparent;
    border: none;
    box-shadow: none !important;
    color: gray;
    cursor: pointer;
    font-size: 16px;
    outline: none !important;
    width: 34ch;
  }

  svg {
    height: 12px;
    width: 14px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    opacity: 0;
    transition: all .2s ease-in-out;
    visibility: hidden;
    color: var(--color-green);

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
`