import Schedule from '@mui/icons-material/Schedule';
import React, {useContext, useEffect, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import { Messages } from '../../../utils/messages';
import { ActionButton } from '../../commons/ActionButton';
import {selectedTableItemsSelector, TableContext, useTableActions} from '../state';
import {modalAtom, ModalWrapperProps} from "../../ModalDialogs/ModalWrapper";
import PublishModal from "../../ModalDialogs/PublishModal";

const RescheduleActionButton = () => {
	const ctx = useContext(TableContext);
	const [disabled, setDisabled] = useState(false);
	const [title, setTitle] = useState(Messages.Reschedule);
	const state = useRecoilValue(selectedTableItemsSelector(ctx.type));
	const setModalEdit = useSetRecoilState(modalAtom);
	const {refresh} = useTableActions();

	useEffect(() => {
		let shouldDisable = state.count === 0;
		let newTitle: string = Messages.NoSelectedPublishedItem;

		if (state.count) {
			shouldDisable = false;
			for (const item of state.objects) {
				if (!item) continue
				if (!item.isPublished()) {
					shouldDisable = true
					newTitle = Messages.CantReschedule
					break;
				}
			}
		}

		setDisabled(shouldDisable)
		setTitle(newTitle as Messages)
	}, [ state ]);

	const onReschedule = () => {
		setModalEdit({
			component: (props: ModalWrapperProps) => {
				return <PublishModal {...props} items={state.objects} onConfirm={() => refresh()}/>;
			},
		});
	}

	return (
		<ActionButton
			data-testid="table-reschedule-action-button"
			disabled={disabled}
			onClick={onReschedule}
			title={title}>
			<Schedule/>
		</ActionButton>
	)
}

export default RescheduleActionButton;
