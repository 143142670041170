import {intersection} from "lodash";

type Sorter = (a: any, b: any) => number;

export const Lists = {
	default: <T>(value: any, def: T[] = []): T[] => {
		if (typeof value === 'undefined' || value === null  || (typeof value === 'string' && !value)) {
			return def;
		}

		if (Array.isArray(value)) {
			return value;
		}

		return [ value ];
	},

	intersection: <T>(a: T[], b: T[]): T[] => {
		return intersection<T>(a, b)
	},

	intersects: <T>(a: T[], b: T[]): boolean => {
		return intersection<T>(Lists.default(a), Lists.default(b)).length > 0
	},

	sort: (list: any[], property: string | Sorter, reverse: boolean = false): any[] => {
		if (typeof property === 'function') {
			list.sort(property);
		} else {
			list.sort((a: any, b: any): number => {
				let aValue = a[property];
				let bValue = b[property];
				if (aValue < bValue) {
					return -1;
				}
				if (aValue > bValue) {
					return 1;
				}
				return 0;
			});
		}
		if (reverse) {
			list.reverse();
		}
		return list;
	},
};
