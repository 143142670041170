import React from "react";
import Client from "../../cms/client";
import SendEmailNotificationModal from "../ModalDialogs/SendEmailNotification";
import {modalAtom, ModalWrapperProps} from "../ModalDialogs/ModalWrapper";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedObject} from "../../state/state";
import Button from '@mui/material/Button';

export const SubjectUpdatesByEmail = () =>{
    const setModalEdit = useSetRecoilState(modalAtom);
    const selected = useRecoilValue(selectedObject)

    const openSendEmailNotification = () => {
        if (selected) {
            setModalEdit({
                width: 800,
                component: (props: ModalWrapperProps) => {
                    return <SendEmailNotificationModal {...props} page={selected.getId()} />;
                }
            })
        }
    }
    if (selected && selected.getId()==='') {
        return null
    }

    return <Button variant="contained" onClick={openSendEmailNotification}>Set email notification</Button>
}

export const ExportRapportButtons = () =>{
    const selected = useRecoilValue(selectedObject)
    const viewsReport = () => {
        if(selected && selected.getId()){
            Client.downloadSubjectUpdatesViews({
                name: selected["title"],
                subject_update: selected.getId(),
                version: '' + selected.__meta.version,
            }).catch((e) => {
                console.log('Error: ', e);
            });
        }
    };
    if (selected && selected.getId()==='') {
        return null
    }
    return <Button onClick={viewsReport} >Download  views report</Button>
}