import { ALL_STREAMS, INTERNATIONAL_STREAM, OCR_STREAM } from '../../utils/constants';
import { Lists } from '../../utils/lists';
import { Strings } from '../../utils/strings';
import { BaseClass } from './__base';

export type FeatureFlag =
	| 'ControlPanel'
	| 'TrialAccess'

export const CONTROL_PANEL_FLAG: FeatureFlag = 'ControlPanel';
export const TRIAL_ACCESS_FLAG: FeatureFlag = 'TrialAccess';

export enum Roles {
	ContentEditor = 'TEACH_CAMBRIDGE_EDITOR',
	ContentPublisher = 'TEACH_CAMBRIDGE_PUBLISHER',
	ViewOnly = 'TEACH_CAMBRIDGE_ADMIN_VIEWER',
}

export type UserRole =
	| Roles.ContentEditor
	| Roles.ContentPublisher
	| Roles.ViewOnly

export type UserRoles = {
	isPublisher: boolean,
	isEditor: boolean,
	isViewer: boolean
}

export class Organization extends BaseClass {
	public bpid: string;
	public name: string;
	public productGroup: string[];
	public roles: UserRole[];

	constructor(data: any = {}) {
		super(data);
		this.bpid = Strings.default(data.bpid);
		this.name = Strings.default(data.name);
		this.productGroup = Lists.default(data.productGroup);
		this.roles = Lists.default<UserRole>(data.roles);
	}
}

export class UserSession extends BaseClass {
	public client_id: string;
	public token: string;
	public downloadToken: string;
	public name: string;
	public givenName: string;
	public email: string;
	public featureFlags: FeatureFlag[] = [];
	public organizations: Organization[] = [];
	public selectedBusinessStream: string;
	public businessStream: string;
	public error?: string;
	public redirectURL?: string;

	constructor(data: any = {}) {
		super(data);
		this.client_id = Strings.default(data.client_id);
		this.token = Strings.default(data.token);
		this.downloadToken = Strings.default(data.download_token);
		this.name = Strings.default(data.name);
		this.givenName = Strings.default(data.given_name);
		this.email = Strings.default(data.email);
		this.featureFlags = Lists.default<FeatureFlag>(data.flags);
		this.selectedBusinessStream = Strings.default(data.selectedBusinessStream);
		this.businessStream = Strings.default(data.businessStream);

		if (data.error) {
			this.error = data.error;
		}
		if (data.redirectURL) {
			this.redirectURL = data.redirectURL;
		}

		Lists.default(data.orgs).forEach(org => {
			this.organizations.push(new Organization(org));
		});
	}

	public withFeature(feature: FeatureFlag, fn: Function): any {
		if (this.featureFlags.includes(feature)) {
			return fn(this);
		}
		return null;
	}

	public availableStreams(): string[] {
		if (this.businessStream === ALL_STREAMS) {
			return [ OCR_STREAM, INTERNATIONAL_STREAM ];
		}
		return Array.from(new Set(this.organizations.map(o => Lists.default<string>(o.productGroup)).flat()));
	}

	public getRoles(): UserRoles {
		const roles: UserRoles = {
			isPublisher: false,
            isEditor: false,
            isViewer: false
		}
		this.organizations.forEach(org => {
			if (org.roles.includes(Roles.ContentPublisher)) {
				roles.isPublisher = true;
			}
			if (org.roles.includes(Roles.ContentEditor)) {
				roles.isEditor = true;
			}
			if (org.roles.includes(Roles.ViewOnly)) {
				roles.isViewer = true;
			}
		})
		return roles;
	}

	public isPublisher(): boolean {
		return this.getRoles().isPublisher;
	}

	public isEditor(): boolean {
		return this.getRoles().isEditor;
	}

	public isViewer(): boolean {
		return this.getRoles().isViewer && !this.isEditor() && !this.isPublisher();
	}
}
