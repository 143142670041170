import React, {useCallback, useEffect, useRef, useState} from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import RefreshIcon from '@mui/icons-material/Refresh';
import {ActionButton} from "../commons/ActionButton";
import Client from "../../cms/client";
import {Job} from "../../cms/models/Job";
import JobBox from "./components/JobBox";
import styled from "@emotion/styled";
import JobDetails from "./components/JobDetails";
import {getRecoil, setRecoil} from "../../state/recoilNexus";
import {atom, useRecoilState} from "recoil";
import {Browser} from "../../utils/browser";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  user-select: none;
`

let timeout: any;

export const jobAtom = atom<Job | undefined>({
    key: "jobAtom",
    default: undefined
})

const Jobs = () => {
    const params = useParams()
    const [key, setKey] = useState(0)
    const [jobs, setJobs] = useState<Job[]>([])
    const [job, setJob] = useRecoilState(jobAtom)
    const active = useRef<boolean>(true)

    useEffect(() => {
        if (params.job) {
            const tmp = jobs.find(j => j.name === params.job)
            if (tmp && job?.name !== tmp.name) {
                setActiveJob(tmp)
            }
        } else {
            setJob(undefined)
        }
        // eslint-disable-next-line
    }, [params, jobs]);

    const refresh = useCallback(async () => {
        Client.getJobs().then(res => {
            if (!active.current) return;
            setJobs(res);
            const jb = getRecoil<Job | undefined>(jobAtom)
            if (jb) {
                setKey((val) => val + 1)
                const j = res.find(j => j.name === jb.name)
                if (j) {
                    setRecoil(jobAtom, j)
                } else {
                    setRecoil(jobAtom, undefined)
                }
            }
        }).finally(() => {
            if (!active.current) return;
            timeout = setTimeout(refresh, 3000)
        })
        // eslint-disable-next-line
    }, [job])


    useEffect(() => {
        refresh().catch()
        active.current = true;

        return () => {
            active.current = false;
            setJob(undefined)
            clearTimeout(timeout)
        }
        // eslint-disable-next-line
    }, [])

    const setActiveJob = (job: Job) => {
        Browser.navigate(`/control-panel/jobs/${job.name}`);
        setJob(job);
    }

    return (
        <div data-testid='feature-flags-container'>
            {job && <JobDetails job={job} key={key.toString()}/>}
            {!job && (
                <>
                    <div className='flex-row spaced'>
                        <Typography className='flex-row' variant={'h4'}>Jobs</Typography>
                        <div>
                            <ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
                                          data-testid={'refresh'}>
                                <RefreshIcon/>
                            </ActionButton>
                        </div>
                    </div>
                    <Divider className='mt8 mb24'/>
                    <Wrapper>
                        {jobs.map((j, idx) => (
                            <JobBox job={j} key={idx} onClick={setActiveJob}/>
                        ))}
                    </Wrapper>
                </>
            )}
        </div>
    )
}

export default Jobs;
