// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';
import React, { Suspense, useEffect, useRef} from 'react';
// Toolbar icons
import 'tinymce/icons/default';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/wordcount';
import './ContentItem.plugin';
import './InfoPanel.plugin';

// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// Theme
import 'tinymce/themes/silver';
import styles from './styles';
import Client from '../../../../../cms/client';
import { Objects } from '../../../../../utils/objects';
import axios from 'axios';
import { getImagePath } from '../../../../../utils/helpers';
import InsertItemModalComponent from './InsertItemModalComponent';
import {useSetRecoilState} from "recoil";
import {modalAtom} from "../../../../ModalDialogs/ModalWrapper";

window['tinymce'] = tinymce;

interface WysiwygPropsInterface {
	isEmbeded?: boolean;
	id?: string;
	value: string;
	onChange: any;
	readOnly?: boolean;
	error?: boolean;
	inlineStyle?: boolean;
	loaded?: boolean;
	height?: number;
}

let folderId = 0;

const Wysiwyg = (props: WysiwygPropsInterface) => {
    const editorRef: any = useRef(null);
    const setModalEdit = useSetRecoilState(modalAtom);

	window['contentItemActions'].open = () => {
		setModalEdit({
            width: 1300,
            component: (props:any)=> {
				props.setTitle('Select cards');
                return <Suspense fallback={"loading"}><InsertItemModalComponent {...props} /></Suspense>
            } ,
            footer: null ,
        });
	};

	const getLibraryFolders = async () => {
		const folders = await Client.getLibraryFolders();
		folderId = Objects.default(folders.find(f => f.name === 'pageAssets')).folderid;
	};

	useEffect(() => {
		getLibraryFolders();
	}, []);

	const init: any = {
		plugins: [
			'table image code media imagetools nonbreaking hr fullscreen wordcount link noneditable',
			'autolink advlist lists preview searchreplace quickbars visualblocks infopanel contentitem template',
		],
		external_plugins: {
			'tiny_mce_wiris': 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
		},
		toolbar: 'bold italic underline strikethrough | styleselect | alignleft aligncenter alignright alignjustify | outdent indent | ' +
			'numlist bullist checklist | infopanel contentitem image link | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | removeformat preview fullscreen',
		menubar: 'file edit view insert table',
		htmlAllowedTags: ['.*'],
		htmlAllowedAttrs: ['.*'],
		height: props.height || 400,
		image_caption: true,
		toolbar_mode: 'wrap',
		browser_spellcheck: true,
		inline_styles: props.inlineStyle,
		contextmenu: 'link image table',
		content_style: styles,
		fix_list_elements: true,
		branding: false,
		readonly: props.readOnly,
		draggable_modal: true,
		accessibility_warnings: true,
		// auto-link plugin config
		default_link_target: '_blank',
		link_default_protocol: 'https',
		// image plugin config
		image_title: true,
		object_resizing: 'img',
		extended_valid_elements: 'card[class|data-uid|data-title],div[data-uid|data-title|class],span[data-uid|data-title|class],inline[class|data-uid|data-title]',
		custom_elements: 'card,~inline',
		image_advtab: true,
		resize_img_proportional: false,
		images_upload_handler: function(blobInfo, success, failure) {
			let formData = new FormData();
			formData.append('file', blobInfo.blob(), blobInfo.filename());
			formData.append('folderId', `${folderId}`);
			axios.post('/fileInFolder', formData, {headers: {'Content-Type': 'multipart/form-data'}})
				.then((response) => {
					success(getImagePath(response.data.fileuid));
				})
				.catch((e) => {
					console.log(e);
					// failure(e);
				});
		},
	};

	const onChange = (_value, editor) => {
		const val = editor.getContent({format: 'html'});
		if (!editor.isNotDirty||val!=="") {
			props.onChange(val);
		}
	};

	useEffect(() => {
		return () => {
			const htmlCollection = document.getElementsByClassName('wrs_stack');
			Array.from(htmlCollection).forEach(element => {
				element.classList.replace('wrs_stack', 'wrs_closed');
			});
		};
	}, []);

	return (
		<>
			<Editor
                onInit={(_evt, editor) => editorRef.current = editor}
                apiKey={'bjkmk872y8fyxneg5kj2yjx4dc484406fm39mr3i8k3b9zl9'}
                data-testid="wysiwyg-editor"
                value={props.value}
				disabled={props.readOnly || props.loaded === false}
				onEditorChange={onChange}
				init={init}
			/>
		</>
	);
};

export default Wysiwyg;