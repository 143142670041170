import PushPin from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import React from 'react';
import {CMSObject} from '../../cms/models/__CMSObject';
import {fi} from '../../utils/helpers';
import {isQuickAccess, toggleQuickAccess} from '../TreeMenu/utils';
import DownloadIcon from '@mui/icons-material/Download';
import Client from "../../cms/client";

export type CustomAction = {
    id: string,
    label: (item: CMSObject) => string,
    icon: (item: CMSObject) => React.ReactNode,
    disabled: (item: CMSObject) => string,
    onClick: (item: CMSObject) => void,
}

export enum PredefinedActions {
    move = 'move',
    download = 'download',
    activityReport = 'activity-report',
    quickAccess = 'quick-access'
}

export type CustomItemAction = PredefinedActions | CustomAction

export const actionDefinitions: { [key: string]: CustomAction } = {
    // Add/remove from quick access is for Tree menu items
    [PredefinedActions.quickAccess]: {
        id: PredefinedActions.quickAccess,
        label: (item: CMSObject) => fi(isQuickAccess(item.getType(), item.getId()), 'Unpin from quick access', 'Pin to quick access'),
        icon: (item: CMSObject) => fi(isQuickAccess(item.getType(), item.getId()), <PushPinOutlinedIcon/>, <PushPin/>),
        disabled: (item: CMSObject) => '',
        onClick: (item: CMSObject) => toggleQuickAccess(item.getType(), item.getId()),
    },

    [PredefinedActions.download]: {
        id: PredefinedActions.download,
        label: (_item: CMSObject) => 'Download',
        icon: (_item: CMSObject) => <DownloadIcon/>,
        disabled: (_item: CMSObject) => '',
        onClick: (item: CMSObject) => Client.download(item['file']),
    }
};
