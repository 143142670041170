import Divider from '@mui/material/Divider';
import React, {useEffect, useRef, useState} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {cacheBuster} from '../../state/state';
import {useLocalStorage} from '../../utils/hooks';
import {Lists} from '../../utils/lists';
import {ITreeConfig} from './config';
import TreeItem from './TreeItem';
import {ITreeItem, Tree} from "./Tree";

const QuickAccessList = ({config}: { config: ITreeConfig }) => {
    const [quickAccessItems] = useLocalStorage(`qa-${config.type}`, []);
    const [showQuickAccess] = useLocalStorage(`show-qa-${config.type}`, true);
    const key = `tree-${config.type}`;
    const items = useRef<Tree>();
    const [pins, setPins] = useState<ITreeItem[]>([]);
    const resetCache = useSetRecoilState(cacheBuster('action-menu'))
    const cache = useRecoilValue(cacheBuster(key))

    useEffect(() => {
        const tree = Tree.getTree(key)
        if (tree) {
            items.current = tree
        }
        // eslint-disable-next-line
    }, [cache])

    useEffect(() => {
        const accessList: ITreeItem[] = [];
        Lists.default<string>(quickAccessItems).forEach(id => {
            const node = items.current?.findNode(id);
            if (node) {
                const tmp = {...node};
                tmp.children = [];
                tmp.parent = undefined;
                accessList.push(tmp);
            }
        });
        setPins(accessList);
        resetCache(val => val + 1)
        // eslint-disable-next-line
    }, [resetCache, quickAccessItems, items.current, setPins]);


    if (pins.length === 0 || !showQuickAccess) {
        return null;
    }

    if (!items.current) {
        return null;
    }

    return (
        <>
            <div className={'flex-column'}>
                {pins.map((item, index) => (
                    <TreeItem tree={items.current!} key={index} item={item} depth={0} config={config} fixed={true}/>
                ))}
            </div>
            <Divider style={{width: '100%'}}/>
        </>
    );
};

export default QuickAccessList;
