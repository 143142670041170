import React, {useMemo} from "react";
import {Job, NodeJob} from "../../../cms/models/Job";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import {Dates} from "../../../utils/dates";
import {JobStatus} from "./JobBox";
import {Numbers} from "../../../utils/numbers";
import {fi} from "../../../utils/helpers";
import {Config, getTrigger, JobAction, JobConfig} from "./JobConfig";
import {ActionButton} from "../../commons/ActionButton";
import {Lists} from "../../../utils/lists";
import {Objects} from "../../../utils/objects";
import TextAction from "../../commons/TextAction";
import {Browser} from "../../../utils/browser";
import {setRecoil} from "../../../state/recoilNexus";
import {jobAtom} from "../Jobs";
import Spacer from "../../commons/Spacer";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useSnackbar} from "notistack";


const Wrapper = styled.div`
  cursor: default;

  h4 span {
    font-weight: normal !important;
    margin-left: 8px;
  }
  
  .backButton {
    margin-right: 16px;
    svg {
      width: 14px;
    }
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;

  &.current {
    background: rgba(162, 162, 162, 0.10);
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--color-border);
  padding-bottom: 4px;
  font-weight: bold;
  column-gap: 8px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  row-gap: 6px;
  font-family: var(--font-sans);
  font-size: 14px;

  label {
    width: 100px;
    font-weight: normal;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
  }

  span {
    font-family: var(--font-semi-bold);
    font-size: 15px;
  }
`

const Light = styled.span`
  color: var(--color-border);
  font-size: 14px;
  font-weight: normal;
`

const JobDetails = ({job}: { job: Job }) => {
    const config: Config = Objects.default(JobConfig[job.name])
    const {enqueueSnackbar} = useSnackbar();

    const duration = (n: NodeJob) => {
        if (!n.duration) return 0;
        const date = new Date(0);
        date.setSeconds(n.duration); // specify value for SECONDS here
        return date.toISOString().substring(11, 19)
    }

    const statusDetails = useMemo(() => {
        const currentNode = job.current()
        let message: string = currentNode.message;
        if (!message) {
            return null
        }
        if (typeof config.status === "function") {
            return config.status(message)
        } else {
            return <div><label>Message</label> {message}</div>
        }
        // eslint-disable-next-line
    }, [job])

    const goBack = () => {
        Browser.navigate(`/control-panel/jobs`);
        setRecoil(jobAtom, undefined)
    }

    const doActionButton = (a: JobAction) => {
        a.action().then(() => {
            enqueueSnackbar('Opperation successful', {variant: 'success'});
        }).catch((e) => {
            enqueueSnackbar(e, {variant: 'error'});
        })
    }

    return (
        <Wrapper>
            <div className='flex-row'>
                <TextAction className='backButton' onClick={goBack}><ArrowBackIosNewIcon /> Back</TextAction>
                <Typography className='flex-row' variant={'h4'}>
                    {job.name} <span> - {job.description}</span></Typography>
                <Spacer />
                <div>
                    {Lists.default<JobAction>(config.actions).map((a, i) => (
                        <ActionButton variant="text" title={a.name} key={i} onClick={() => doActionButton(a)} tabIndex={0}
                                      data-testid={a.name}>
                            {a.icon}
                        </ActionButton>
                    ))}
                </div>
            </div>
            <Divider className='mt8 mb24'/>
            <List>
                <Box>
                    <Body>
                        <div>
                            <label>Single instance:</label> {fi(config.singleInstance, 'Yes', 'No')}
                        </div>
                        <div>
                            <label>Trigger</label> {getTrigger(job)}
                        </div>
                        {statusDetails}
                    </Body>
                </Box>
                {job.nodes.map((n, idx) => (
                    <Box key={idx} className={fi(job.current().nodeId === n.nodeId, 'current')}>
                        <Header>
                            {n.node?.nodeName} <Light>( {n.nodeId} )</Light>
                        </Header>
                        <Body>
                            <div>
                                <label>Created:</label> <span>{Dates.local(n.created)}</span>
                            </div>
                            <div className={'flex-row'}>
                                <label>Status:</label> <JobStatus className={n.status}>{n.status}</JobStatus>
                            </div>
                            {n.started && (
                                <div>
                                    <label>Last
                                        run:</label> {Dates.local(n.started)} {n.ended && (<>→ {Dates.local(n.ended)}
                                    <Light>({duration(n)})</Light></>)}
                                </div>
                            )}
                            {n.lastError && (
                                <div>
                                    <label>Last error:</label> {n.lastError}
                                </div>
                            )}
                            {n.node && (
                                <>
                                    <div>
                                        <label>IP:</label> {n.node?.ip}
                                    </div>
                                    <div>
                                        <label>Memory:</label>
                                        Total <span>{Numbers.asFileSize(n.node?.hostInfo.memTotal)}</span> /
                                        Free: <span>{Numbers.asFileSize(n.node?.hostInfo.memFree)}</span> /
                                        Use: <span>{Math.ceil(((n.node!.hostInfo.memTotal - n.node!.hostInfo.memFree) * 100 / n.node!.hostInfo.memTotal))}%</span>
                                    </div>
                                    <div>
                                        <label>CPU:</label> <span>{n.node.hostInfo.cpuUsage.toFixed(2)}%</span>
                                    </div>
                                </>
                            )}
                        </Body>
                    </Box>
                ))}
            </List>
        </Wrapper>
    )
}

export default JobDetails