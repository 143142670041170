import React from 'react';
import {ColumnType, GenericRenderer, Renderer} from './renderers';

export type ColumnFilterDefinition = {
	noFilter?: boolean;
	getLabel?: (val: any) => string;
}

export type TableColumnDefinition = {
	// Column order
	order: number;
	// Column label
	label: string;
	// Field UID
	field: string;
	// May differ from field type. It's used to determine how the column should be rendered.
	type: ColumnType;
	// If column should be displayed by default.
	default?: boolean;
	// If column should be sortable
	sortable?: boolean;
	// only show this column if the object require manual publishing
	manualPublish?: boolean;
	// Override the default renderer.
	render?: GenericRenderer | Renderer;
	// Column filter definition.
	filter?: ColumnFilterDefinition;
}

// Explicit table definition for a type
export type TableConfig = {
	tableType: string;
	// Optional title, if not defined it will be inferred from the type
	title?: string;
	// For cases where the items to be displayed in the table are of a different type, or more than one type
	itemTypes?: string[];
	// Enforce the table to behave as a manual publish type. If not defined it will be inferred from the itemTypes
	manualPublish?: boolean;
	// List of additional table buttons
	additionalButtons?: React.ReactNode[];
	// List of additional table bulk actions
	additionalActions?: React.ReactNode[];
	// List of column definitions for the table. If not defined it will be inferred from the itemTypes.
	columns?: TableColumnDefinition[];
	// List of additional column definitions for the table. They will be appended to the columns list
	// regardless if the column definitions are defined or inferred from the itemTypes.
	additionalColumns?: TableColumnDefinition[];
	// List of columns that should not be displayed in the table
	blacklistColumns?: string[];
	// A method to call when loading a table of this type
	initialLoad?: () => Promise<void>;
	// show the action button on the top right of the table (disabled by default, but enable after a selection)
	hideTableButtons?: boolean;
	// hide the action column
	hideActionColumn?: boolean;
}

// A list of field UUIDs that should always become links to edit from in tables
export const ClickableColumns: string[] = [ '__uuid', 'title', 'name', 'label', 'code' ];

