import {ROUTE_LIBRARY_BROWSE} from '../../utils/constants';
import {fi} from '../../utils/helpers';
import {Messages} from '../../utils/messages';
import {Strings} from '../../utils/strings';
import Client from '../client';
import {DisplayMode, ItemRoutes, TreeObject} from './__CMSObject';
import React from "react";
import {ModalWrapperProps} from "../../components/ModalDialogs/ModalWrapper";
import {Types} from "../types";
import EditCreateFolderModal from "../../components/ModalDialogs/EditCreateFolderModal";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";

export class Folder extends TreeObject {
    public static title = 'Folder';
    public static autoPublish = true;
    public name: string;

    constructor(object: any = {}) {
        super(object);

        this.name = Strings.default(object.name);

    }

    public async canDelete(): Promise<string> {
        const tmp = await Client.checkUsage(this.getId());
        return fi(tmp.items_count !== 0, Messages.CantDeleteFolder, '');
    }

    public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
        if (options === DisplayMode.DETAILED) {
            const name: string[] = [this.name];
            let parent = this.parent;
            if (parent) {
                const parentPage = getRecoil(references(parent))
                if (parentPage) {
                    name.unshift(parentPage.displayLabel(DisplayMode.DETAILED));
                }
            }
            return name.join(' ▸ ');
        }
        return this.name
    }

    public routes(): ItemRoutes {
        return {
            edit: ROUTE_LIBRARY_BROWSE.replace(':folderId', this.getId()),
            editInline: (props: ModalWrapperProps) => (
                <EditCreateFolderModal {...props} item={this}/>
            ),
            createInline: (props: ModalWrapperProps) => (
                <EditCreateFolderModal {...props} item={new Folder({__type: Types.FOLDER})}/>
            ),
            createChildInline: (props: ModalWrapperProps) => (
                <EditCreateFolderModal {...props} item={new Folder({__type: Types.FOLDER, parent: this.getId()})}/>
            )
        }
    }
}
