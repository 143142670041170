import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import {ActionButton} from "../commons/ActionButton";

const APIKeys = () => {
    const refresh = async () => {

    }

    return (
        <div data-testid='feature-flags-container'>
            <div className='flex-row spaced'>
                <Typography variant={'h4'}>API Keys</Typography>
                <div>
                    <ActionButton variant="text" title={'Refresh'} onClick={refresh} tabIndex={0}
                                  data-testid={'refresh'}>
                        <RefreshIcon/>
                    </ActionButton>
                </div>
            </div>
            <Divider className='mt8 mb24'/>
        </div>
    )
}

export default APIKeys;
