import React, {useMemo} from "react"
import styled from "@emotion/styled";
import {FormState} from "../model";
import FormRow from "../FormRow";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 16px;

  & > div {
    margin-bottom: 0;
  }

  div.formRow.boolean {
    width: auto !important;
    & > label:first-of-type {
      margin-bottom: 0;
    }
    
    & > span:last-of-type {
      margin: 0;
    }
  }
`

const GroupRenderer = ({state, group}: { state: FormState, group: string }) => {

    const [fields, title] = useMemo(() => {
        let title = '';
        return [state.model.fields.filter(f => f.group === group).map(field => {
            if (field.flags.hidden) {
                return null;
            }
            if (field.groupTitle) {
                title = field.groupTitle;
            }
            return <FormRow key={`${state.id}/${field.uid}`} field={field.uid}/>
        }), title]
        // eslint-disable-next-line
    }, [state.model.fields])

    return (
        <>
            {title && <label>{title}</label>}
            <Wrapper>

                {fields}
            </Wrapper>
        </>
    )
}

export default GroupRenderer