export enum Messages {
	NoPermissionToEdit = 'You don\'t have permissions to create or edit this type of items.',
	NoPermissionToDelete = 'You don\'t have permissions to delete this item.',
	CantDeletePublishedAssociations = 'Item cannot be deleted because it has published items associated with it.',
	CantDeletePublishedItem = 'Published items cannot be deleted.',
	CantDeleteQualification = 'Qualification group cannot be deleted because it has a subject group or a page mapped to it.',
	CantDeleteSubject = 'Subject group cannot be deleted because it has a page mapped to it.',
	CantReschedule = 'Draft or unpublished items cannot be rescheduled.',
	CantDeleteParentPage = `You can't delete a parent page. Delete children pages first.`,
	CantDeleteFolder = `You can't delete this folder because it contains published content.\nPlease unpublish or move the content before deleting.`,
	NoSelectedPublishedItem = 'Please select at least one published item to reschedule',
	NoSelectedDraftItem = 'Please select at least one item to publish/unpublish',
	NoSelectedToDeleteItem = 'Please select at least one item to delete',
	MixSelection = 'You have selected both published and draft items. Please select only one kind in order to publish/unpublish.',
	Reschedule = 'Reschedule',
	Delete = 'Delete items',
	FieldIsRequired = 'This field is mandatory.',
	DuplicateValue='An item with this name already exists.',
	InvalidURLFormat = 'The URL must have a correct format.',
	InvalidCharacters = 'Invalid characters.',
	FixErrors = 'Please check the fields marked with error messages and try again.',
	FileToSmall = 'The file is to small.',
	FileTooLarge = 'The file is too large.',
	FormatNotSupported = 'The file format is not supported.',
	CheckFormForErrors = 'Please check the fields marked with error messages and try again',
	BulkEdit='Edit multiple items',
	MoveItems = 'Move items',
	NoSelectedItem = 'Please select at least one item',
	CantEditMultipleTypes = 'The selected items cannot be edited in bulk.\nYou can filter by item first.\nItem types that can be edited in bulk together: ',
	CantMixModularAndLinear= 'There are modular and linear assessments selected. The subject can have only one type of assessment.',
	QualificationDuplicate = 'The qualification code for this grouping already exists.',
	AssessmentDuplicate = 'The assessment code for this grouping already exists.',
	UnauthorizedError = 'You are not authorised to view this page.',
	NotFoundError = 'The page you are trying to reach was not found.',
	UnknownError = `The server encountered an internal error and was unable to complete your request.
                    Please go back and try again.`,
	MultipleItemsReport = 'Multi-item Activity Report',
}
