import {CMSObject} from "./__CMSObject";
import {UUID} from "../types";
import {Strings} from "../../utils/strings";

export class Note extends CMSObject {
    public comment: string;
    public item: UUID;

    constructor(item: any = {}) {
        super(item);
        this.comment = Strings.default(item.comment);
        this.item = Strings.default(item.item);
    }

}