import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Types, UUID} from "../types";
import {Lists} from "../../utils/lists";
import React, {ReactElement} from "react";
import {PreviewRow, PreviewWrapper} from "./_style";
import {Strings} from "../../utils/strings";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {UsefulLink} from "./UsefulLink";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Useful links",
    description: "Allows you to pick a list of [Useful Links] to display on page.\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class UsefulLinksWidget extends BaseWidget {
    @formField({
        fieldtype: FieldType.Reference,
        name: "Add useful links",
        config: {refModel: Types.USEFUL_LINK},
        flags: {required: true, multiple: true}
    })
    public links: UUID[];

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.UsefulLinks
        this.links = Lists.default(item.links)
    }

    preview(): ReactElement | null {
        return (
            <PreviewWrapper>
                {this.links.map((link, index) => {
                    const ref = getRecoil(references(link)) as UsefulLink
                    if (!ref) return null;
                    return (
                        <PreviewRow className='vertical' key={index}>
                            <label>{ref.displayLabel()}</label>
                            <span>{Strings.default(ref.description, '-')}</span>
                        </PreviewRow>
                    )
                })}
            </PreviewWrapper>
        )
    }
}