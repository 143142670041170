import React, {useMemo} from "react"
import {BoxContainer} from "./components/BoxContainer";
import styled from "@emotion/styled";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {useRecoilValue} from "recoil";
import {editWidgetState, selectedObject} from "../../state/state";
import {Types} from "../../cms/types";
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {fi} from "../../utils/helpers";
import {Page} from "../../cms/models/Page";
import {Objects} from "../../utils/objects";
import {WidgetListState} from "../Form/renderers/WidgetListRenderer";
import {BaseWidget, WidgetConstrains, WidgetGroup, WidgetType} from "../../cms/models/PageWidget";

const WidgetGroupWrapper = styled.fieldset`
  padding: 8px 16px;
  margin-top: 16px;
  border-left: 1px solid var(--color-border-light);
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
  position: relative;

  &:last-of-type {
    margin-bottom: 16px;
  }

  legend {
    text-orientation: mixed;
    writing-mode: vertical-rl;
    background: white;
    position: absolute;
    color: var(--color-backdrop);
    left: -6px;
    padding: 5px 0;
    font-size: 11px;
    letter-spacing: 0.5px;
    font-weight: 500;
    font-family: var(--font-sans), Arial, sans-serif;
    top: 50%;
    transform: rotate(180deg) translateY(50%);
  }
`

const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding: 8px 12px 8px 8px;
  background: var(--color-box-shadow);
  border-radius: 4px;
  white-space: nowrap;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  svg {
    height: 20px;
    width: 20px;
    color: var(--color-backdrop)
  }
`

const getItemStyle = (isDragging, draggableStyle, snapshot) => {
    let styleObj = {
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        // change background colour if dragging
        background: isDragging ? 'rgba(0, 116, 189, 0.16)' : '#f3f3f3',
        // styles we need to apply on draggables
        ...draggableStyle,
    };

    if (snapshot.isDropAnimating && snapshot.draggingOver !== DROPPABLE_WIDGET_ITEMS_ID) {
        const translate = `translate(0px, 0px)`;
        styleObj.transition = "all 10ms linear"
        styleObj.transform = `${translate}`;
        styleObj.opacity = 0
    }

    if (isDragging && snapshot.draggingOver === "page-widget-list") {
        let transform = draggableStyle.transform.substring(10).replace(" ", '').replaceAll("px", "")
        transform = transform.substring(0, transform.length - 1).split(",").map(a => +a)

        let list = document.getElementById('page-widget-list');
        if (list) {
            const box = list.getBoundingClientRect();
            const currentLeft = draggableStyle.left + transform[0]
            const currentWidth = box.right - currentLeft - 8;
            styleObj.width = Math.max(currentWidth, styleObj.width) + 'px';
            styleObj.height = 40;

        }
    }

    if (isDragging && snapshot.draggingOver === DROPPABLE_WIDGET_ITEMS_ID) {
        let widgetItem = document.getElementsByClassName('widgets-section')[0] as HTMLElement;
        if (widgetItem) {
            styleObj.width = widgetItem.offsetWidth + 'px';
            styleObj.height = '59px';
            styleObj.display = 'flex';
            styleObj.alignItems = 'center';
        }
    }

    return styleObj;
};

export const widgetColors = {
    [WidgetGroup.Filters]: 'var(--color-orange)',
    [WidgetGroup.Content]: 'var(--color-green)',
    [WidgetGroup.Other]: 'var(--color-darker-blue)',
}

const Widget = ({type, index}: { type: WidgetType, index: number }) => {
    // Widgets are disabled if there is one being edited on the page and this atom tells it that
    const editWidget = useRecoilValue(editWidgetState)
    // Object we're currently editing. We need to disable widget based on its constrains.
    const selected = useRecoilValue(selectedObject) as Page;
    const widget = BaseWidget.widgetStore[type]

    const widgetsOnPage = useRecoilValue(WidgetListState)

    // Determine if the widget should be disabled
    const isDisabled = useMemo(() => {
        if (!widget || editWidget) {
            return true;
        }
        // Check widget constrains
        const constrains: WidgetConstrains = Objects.default(widget.constrains)
        const widgetsOfType = widgetsOnPage.filter(w => w.widget.type === type)

        // check if is a home page widget and we're on a home page
        if (Boolean(constrains.onlyOnHomePage) && !selected.is_landing_page) {
            return true
        }

        if (constrains.once && widgetsOfType.length) {
            return true
        }

        if (constrains.oneOf) {
            const options = constrains.oneOf.options;
            const existing = widgetsOfType.map(w => w.widget[constrains.oneOf.field])
            return Lists.intersection(options, existing).length === options.length
        }

        return false;
        // eslint-disable-next-line
    }, [widget, editWidget, selected, widgetsOnPage])

    if (!widget) {
        return null
    }

    return (
        <Draggable draggableId={`new-${widget.type}`} index={index} key={widget.type} isDragDisabled={false}>
            {(providedItem, snapshotItem) => (
                <div title={widget.description} >
                    <WidgetWrapper key={widget.type} {...providedItem.dragHandleProps}
                                   ref={providedItem.innerRef} {...providedItem.draggableProps}
                                   className={fi(isDisabled, 'disabled')}
                                   style={getItemStyle(snapshotItem.isDragging, providedItem.draggableProps.style, snapshotItem)}
                                   data-testid={`widget-item-${widget.type}`}>
                        <DragIndicatorIcon /> {widget.name}
                    </WidgetWrapper>

                    {/* clone that stays in place of the dragged item */}
                    {snapshotItem.isDragging && (
                        <WidgetWrapper key={widget.type + 'clone'} aria-disabled={true}>
                            <DragIndicatorIcon/> {widget.name}
                        </WidgetWrapper>
                    )}
                </div>
            )}
        </Draggable>
    )
}

export const DROPPABLE_WIDGET_ITEMS_ID = 'DROPPABLE_PAGES_ID';
export const DROPPABLE_PAGE_AND_WIDGETS_TYPE = 'DROPPABLE_PAGE_AND_WIDGETS_TYPE';
export const DROPPABLE_WIDGET_SELECTOR_ID = 'DROPPABLE_WIDGETS_ID';

const PageWidgetsBox = () => {
    const selected = useRecoilValue(selectedObject);

    const widgets = useMemo(() => {
        const res: any = {
            [WidgetGroup.Filters]: [],
            [WidgetGroup.Content]: [],
            [WidgetGroup.Other]: [],
        }

        for (let id in BaseWidget.widgetStore) {
            const widgetConfig = BaseWidget.widgetStore[id];
            res[Strings.default(widgetConfig.group, WidgetGroup.Other)].push(widgetConfig)
        }

        for (let id in res) {
            Lists.sort(res[id], 'name')
        }

        return res;
    }, [])

    return (
        <BoxContainer title='Page widgets' id='pageWidgets'
                         hidden={!Boolean(selected) || selected?.getType() !== Types.PAGE}>
            <Droppable droppableId={DROPPABLE_WIDGET_ITEMS_ID} type={DROPPABLE_PAGE_AND_WIDGETS_TYPE}
                       isDropDisabled={true}>
                {(provided) => {
                    let widgetIndex: number = 0
                    return <>
                        {widgets[WidgetGroup.Filters].length > 0 && (
                            <WidgetGroupWrapper>
                                <legend style={{color: `${widgetColors[WidgetGroup.Filters]}`}}>Filters</legend>
                                {widgets[WidgetGroup.Filters].map((widget) => (
                                    <Widget data-widgetid={widget.type} index={++widgetIndex} key={widget.type}
                                            type={widget.type}/>
                                ))}
                            </WidgetGroupWrapper>
                        )}
                        {widgets[WidgetGroup.Content].length > 0 && (
                            <WidgetGroupWrapper>
                                <legend style={{color: `${widgetColors[WidgetGroup.Content]}`}}>Content</legend>
                                {widgets[WidgetGroup.Content].map((widget) => (
                                    <Widget data-widgetid={widget.type} index={++widgetIndex} key={widget.type}
                                            type={widget.type}/>
                                ))}
                            </WidgetGroupWrapper>
                        )}
                        {widgets[WidgetGroup.Other].length > 0 && (
                            <WidgetGroupWrapper>
                                <legend style={{color: `${widgetColors[WidgetGroup.Other]}`}}>Other</legend>
                                {widgets[WidgetGroup.Other].map((widget) => (
                                    <Widget data-widgetid={widget.type} index={++widgetIndex} key={widget.type}
                                            type={widget.type}/>
                                ))}
                            </WidgetGroupWrapper>
                        )}
                        <span style={{width:0, height:0}}>{provided.placeholder}</span>
                        <div  {...provided.droppableProps} ref={provided.innerRef}></div>
                    </>
                }}
            </Droppable>
        </BoxContainer>
    )
}

export default PageWidgetsBox