import React from "react";
import Button from '@mui/material/Button';
import {Browser} from "../../../utils/browser";

const BulkUpload = () => {
    return (
        <Button title={'Bulk upload/create documents'}
                variant="text"
                color="primary"
                onClick={() => Browser.navigate('/library/bulk')}>
            Bulk upload
        </Button>
    )
}

export default BulkUpload;