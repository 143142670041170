import {useEffect, useMemo, useState} from 'react';
import {useRecoilValue} from 'recoil';
import {getRecoil} from '../state/recoilNexus';
import {cacheBuster} from '../state/state';
import {USER_SETTINGS} from './constants';
import {fi} from "./helpers";

export const StorageEvents: any[] = [];

/**
 * Similar to useState but stores the values in localStorage
 *
 * @example
 *
 * const [name, setName] = useLocalStorage("name", "Bob");
 *
 * @param key LocalStorage key
 * @param initialValue initial valule if the key doesn't exist
 */
export const useLocalStorage = (key: string, initialValue: any) => {
    const getStoredValue = () => {
        try {
            const item = window.localStorage.getItem(key);
            if (!item) {
                const tmp = typeof initialValue === 'function' ? initialValue() : initialValue;
                window.localStorage.setItem(key, JSON.stringify(tmp));
                return tmp
            }
            return JSON.parse(item);
        } catch (error) {
            return initialValue;
        }
    };

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedRawValue, setStoredValue] = useState(() => getStoredValue());

    const storedValue = useMemo(() => {
        return getStoredValue();
        // eslint-disable-next-line
    }, [storedRawValue]);

    function checkUserData(_evt) {
        const item = window.localStorage.getItem(key);
        if (item) {
            setStoredValue(item);
        } else {
            setValue(initialValue);
        }
    }

    useEffect(() => {
        const callback = checkUserData.bind(this);
        window.addEventListener('storage', callback);
        StorageEvents.push(callback);

        return () => {
            window.removeEventListener('storage', callback);
            StorageEvents.splice(StorageEvents.indexOf(callback), 1);
        };
        // eslint-disable-next-line
    }, []);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
            const event = new StorageEvent('storage', {
                key: key,
                oldValue: storedValue,
                newValue: valueToStore,
            });
            window.dispatchEvent(event);
            StorageEvents.forEach((cb) => {
                cb();
            });
        } catch (error) {
            // A more advanced implementation would handle the error case
        }
    };

    return [storedValue, setValue];
};

export const useResetPreferences = (cb) => {
    const resetValue = useRecoilValue(cacheBuster(USER_SETTINGS))
    const [initialResetValue] = useState<number>(() => {
        const v = getRecoil(cacheBuster(USER_SETTINGS))
        return fi(v < resetValue, resetValue, v)
    })

    useEffect(() => {
        if (initialResetValue < resetValue) {
            cb()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialResetValue, resetValue])
}
