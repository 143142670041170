import {useSetRecoilState} from "recoil";
import {sessionAtom} from "../../state/session";
import Client from "../../cms/client";
import Typography from "@mui/material/Typography";
import {BusinessStreams} from "../../utils/constants";
import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  position: absolute;
  background-color: var(--color-white);
  padding: 16px;
  width: 600px !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;

  .option {
    margin: 6px 2px;
    cursor: pointer;
    padding: 16px;
    border: 1px solid gray;
    border-radius: 5px;
    text-align: center;

    &:hover {
      margin: 4px 0;
      border: 2px solid var(--color-blue);
    }
  }
`;

// Component is displayed instead of the website (in PageLayout) when there is no specific stream
// selected after login, and it forces the user to pick a stream they want to work with
const SelectStream = () => {
    const setSession = useSetRecoilState(sessionAtom)

    const handleBusinessStreamChange = (stream: string) => {
        Client.switchStream(stream).then(s => setSession(s)).catch(console.error);
    };

    return (
        <Wrapper>
            <Typography variant='h5'>Select business stream</Typography><br/>
            <Typography variant='caption'>
                Your account is able to manage content for multiple business streams.<br/>
                Please select the one you wish to work with. You can change it later from the website menu.
            </Typography>
            <Options>
                {BusinessStreams.slice(1).map(stream => (
                    <div className={`option ${stream.id}-option`} tabIndex={0} key={stream.id}
                         onClick={() => handleBusinessStreamChange(stream.id)}>
                        <img src={stream.logo} alt={stream.name}/>
                    </div>
                ))}
            </Options>
        </Wrapper>
    )
}

export default SelectStream;