import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Field} from "../../../cms/models/__ModelInfo";
import CreatableSelect from 'react-select/creatable';
import {useFormField} from "../state";
import {components, MultiValueRemoveProps} from "react-select";
import Clear from '@mui/icons-material/Clear';
import Client from "../../../cms/client";
import {ISelectValue} from "./components/Select/SelectComponent";
import {Lists} from "../../../utils/lists";
import styled from "@emotion/styled";
import TagsHelpSection from "./components/TagsHelper/TagsHelpSection";

const TagsList = styled<any>(CreatableSelect)`
  .react_select_tag__control {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    min-height: 39px;
  }

  .react_select_tag__multi-value {
    background: white;
    font-family: var(--font-regular);
    align-items: center;
    border: 1px solid var(--color-border-light);

    &:hover {
      background: var(--color-background);
    }

    .react_select_tag__multi-value__label {
      font-size: 16px;
    }
  }

  .react_select_tag__control--is-disabled {
    background: white;

    .react_select_tag__multi-value {
      opacity: 0.5;
    }
  }

  .react_select_tag__multi-value__remove {
    svg {
      width: 20px;
    }

    &:hover {
      background: var(--color-grey);
    }
  }
`
const LabelArea = styled.div`
  display: flex;
  justify-content: space-between;
`

const TagsRenderer = ({field}: { field: Field }) => {
    const formField = useFormField(field.uid)
    const [loading, setLoading] = useState<boolean>(true);
    const [existingOptions, setExistingOptions] = useState<string[]>([]);
    const [options, setOptions] = useState<ISelectValue[]>([]);

    const ref = useRef<any>()

    useEffect(() => {
        let mounted = true;
        Client.tags().then(res => {
            if (mounted) {
                setExistingOptions(Lists.default<string>(res)
                    .map(tag => tag.toString().trim()) // clean up the tags a bit
                    .filter(tag => tag) // drop empties
                    .sort()) // sort by label
            }
        }).finally(() => {
            if (mounted) {
                setLoading(false)
            }
        })
        return () => {
            mounted = false;
        };
    }, []);


    useEffect(() => {
        setOptions(
            Array.from(new Set([...existingOptions, ...Lists.default<string>(formField.value)]))
                .map(tag => ({ // convert to ISelectValue
                    value: tag,
                    label: tag,
                    object: tag
                })))
    }, [existingOptions, formField.value])

    const onChange = (inputValue, action) => {
        switch (action.action) {
            case 'create-option':
                setOptions(Lists.sort([...options, {...action.option, object: action.option.label}], 'label'));
                break;
            case 'remove-value': // remove with 'x'
            case 'pop-value': // remove with backspace
                break;
            case 'clear': // clear button
                formField.setValue([])
                return;
            default:
        }
        formField.setValue(inputValue.map(option => option.value))
    }

    const selections = useMemo(() => {
        if(formField.value) {
            return formField.value.map(v => options.find(o => o.value === v)).filter(o => o)
        }
        return []
    }, [formField.value, options])

    const MultiValueRemove = (mprops: MultiValueRemoveProps) => {
        return (
            <components.MultiValueRemove {...mprops}>
                <Clear/>
            </components.MultiValueRemove>
        );
    };

    const position = useCallback(() => {
        const input = document.querySelector(".react_select_tag__control") as any
        const portal = document.getElementById("list-portal-wrapper") as any
        if (input && portal) {
            const box = input.getBoundingClientRect();
            portal.style.left = `0px`
            portal.style.width = `${box.width}px`
            portal.style.top = `0px`
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])

    const onOpen = () => {
        position();
        document.getElementsByTagName("main")[0].addEventListener('scroll', position);
        window.addEventListener('scroll', position);
        window.addEventListener('resize', position);
    }

    const onClose = () => {
        const portal = document.getElementById("list-portal-wrapper") as any
        document.getElementsByTagName("main")[0].removeEventListener('scroll', position);
        window.removeEventListener('scroll', position);
        window.removeEventListener('resize', position);
        if (portal) {
            portal.style.width = '0px';
        }
    }

    return (
        <>
            <LabelArea>
                <label>Tags</label>
                <TagsHelpSection/>
            </LabelArea>
            <TagsList
                ref={ref}
                data-testid={'tag-select'}
                classNamePrefix={`react_select_tag`}
                className={`form-input custom-select-menu`}
                id={field.uid}
                isMulti
                onMenuOpen={onOpen}
                onMenuClose={onClose}
                isClearable
                menuPortalTarget={document.getElementById("list-portal-wrapper")}
                isLoading={loading}
                components={{DropdownIndicator: null, MultiValueRemove}}
                options={options} // all options
                value={selections} // selected values
                onChange={onChange}
                placeholder={''}
                menuPlacement={'auto'}
                isDisabled={formField.previewMode}
            />
        </>
    )
}

export default TagsRenderer;

