import {atom} from "recoil";

export type BulkTableState = {
    editMode: boolean,
    selectedRange: number[],
    selectedCell: string[]
}

export const bulkTableState = atom<BulkTableState>({
    key: 'bulkTableState',
    default: {
        editMode: false,
        selectedRange: [],
        selectedCell: [],
    }
})

export const selectedRows = atom<number[]>({
    key: "bulkSelectedRows",
    default: []
})

export const ConfigKey: string = `bulkTableColumnState`
export const DefaultColumnWidths: number[] = Array.from(new Array(9)).map(a => -1)