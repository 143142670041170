import {getRecoilPromise} from '../../state/recoilNexus';
import {productDataSelector} from '../../state/state';
import {tableConfig} from '../../utils/decorators';
import {Types} from "../types";
import {GenericLibrary} from "./__GenericLibrary";

const itemTypes: string[] = [
    Types.DOCUMENT, Types.IMAGE, Types.VIDEO, Types.AUDIO, Types.LINK,  Types.EVENT_MATERIAL,
]

@tableConfig({
    hideTableButtons: true,
    hideActionColumn : true,
    initialLoad: async () => {
        await getRecoilPromise(productDataSelector)
        return
    },
    itemTypes
})
export class ExtendedLibrary extends GenericLibrary {
    public static title = 'Select item';
    constructor(item: any = {}) {
        super(item);
    }
}
