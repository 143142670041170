import React from "react";
import { useRecoilValue } from 'recoil';
import { overviewSelector } from './AccountOverview';
import styled from '@emotion/styled';
import { Objects } from '../../utils/objects';
import { Lists } from '../../utils/lists';
import {Numbers} from "../../utils/numbers";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  position: relative;
  min-height: 34px;

  div:first-of-type {
    flex-grow: 1;
    min-width: 180px;
  }
    div:last-of-type {
        text-align: right;
    }
`

const Roles = () => {
    const overview = useRecoilValue(overviewSelector)

    return (
        <>
            {Lists.sort([...Lists.default(Objects.default(overview).roles)], "job_title").map((role, idx) => (
                <Row key={idx}>
                    <div>{role.job_title}</div>
                    <div>{Numbers.default(role.count).toLocaleString()}</div>
                </Row>
            ))}
        </>
    )
}

export default Roles;