import {Note} from "../../../cms/models/Note";
import {useRecoilValue} from "recoil";
import {sessionAtom} from "../../../state/session";
import {selectedObject} from "../../../state/state";
import {useSnackbar} from "notistack";
import React, {useMemo} from "react";
import {Objects} from "../../../utils/objects";
import Client from "../../../cms/client";
import {Types} from "../../../cms/types";
import TextAction from "../../commons/TextAction";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Typography from "@mui/material/Typography";
import {fi} from "../../../utils/helpers";
import Delete from "@mui/icons-material/Delete";
import {Dates} from "../../../utils/dates";
import styled from "@emotion/styled";
import {useModalDialog} from "../../ModalDialogs/effect";


const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border-light);

  &:last-of-type {
    border-bottom: none;
  }
`

const Content = styled.p`
  padding: 0 16px;
  line-height: 1.4;

  svg {
    height: 20px;
    width: 20px;
    margin-bottom: -5px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;

  h5 {
    font-size: 16px;
    font-family: var(--font-semi-bold);
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: var(--font-regular);
      font-weight: normal;
    }
  }

  h6 {
    font-size: 14px;
    color: var(--color-border);
  }
`

export const Comment = ({comment, onDelete}: { comment: Note, onDelete: () => void }) => {
    const session = useRecoilValue(sessionAtom);
    const item = useRecoilValue(selectedObject);
    const {enqueueSnackbar} = useSnackbar();
    const {confirmation} = useModalDialog();

    const canDeleteNote = useMemo((): boolean => {
        return comment.getMeta().author === Objects.default(session).name;
    }, [comment, session]);

    const deleteNote = () => {
        const msg = `Are you sure you want to delete this note?`
        confirmation(msg, async () => {
            Client.delete(comment.getId()).then(() => {
                enqueueSnackbar("Note deleted successfully", {variant: "success"});
                onDelete();
            }).catch(err => {
                enqueueSnackbar("Error deleting note: " + err, {variant: "error"});
            })
        });
    }

    const downloadReport = (evt, item) => {
        evt.preventDefault();
        Client.downloadSubjectUpdatesViews(item).catch((e) => {
            enqueueSnackbar('Error: ' + e, {variant: 'error'});
        });
    };

    const addDownloadReport = (): any => {
        if (item && item.getType() === Types.SUBJECT_UPDATES && comment.comment.includes('Email for subject update')) {
            const matches = comment.comment.match(/Email for subject update version (\d+) sent at (\d+\/\d+\/\d+, \d+:\d+)/);
            if (matches && matches.length === 3) {
                const req = {
                    name: comment.displayLabel(),
                    subject_update: item.getId(),
                    version: matches[1],
                    date: matches[2].replace(/[^\d/ :]/g, '').replace(/[/ :]/g, '-'),
                };
                return (
                    <>
                        <br/>
                        For more details <TextAction onClick={(evt) => downloadReport(evt, req)}
                                                     data-testid='subject-update-report'><FileDownloadIcon/> download
                        report</TextAction>
                    </>
                );
            }
        }
    };

    const author = useMemo(() => {
        const tmp = comment.getMeta().author;
        if (tmp === 'Service user') {
            const today = new Date();
            if (today.getMonth() === 11 && today.getDate() >= 20) { // little Easter egg for Christmas
                return `🎄 ${tmp}`
            }
            if (today.getDate() === 31 && today.getMonth() === 9) { // little Easter egg for Halloween
                return `👻 ${tmp}`
            }
            return `🤖 ${tmp}`
        }
        return tmp;
    }, [comment])

    return (
        <CommentWrapper>
            <Header>
                <Typography variant='h5'>{author}
                    {fi(canDeleteNote, <TextAction onClick={deleteNote}><Delete
                        fontSize="small"/> Delete</TextAction>)}</Typography>
                <Typography variant='h6' title={Dates.local(comment.getMeta().modified)}>
                    {Dates.timeAgo(comment.getMeta().modified)}
                </Typography>
            </Header>
            <Content>
                {comment.comment}
                {addDownloadReport()}
            </Content>
        </CommentWrapper>
    )
}