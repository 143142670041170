import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import Client from '../cms/client';
import TextAction from "../components/commons/TextAction";
import Visibility from "@mui/icons-material/Visibility";
import axios from "axios";
import {useRecoilValue} from "recoil";
import {sessionAtom} from "../state/session";
import {Strings} from "../utils/strings";
import {fi} from '../utils/helpers';

const HeaderElem = styled.header`
  user-select: none;
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  border-bottom: 1px solid var(--color-border-light);
`;

const LogoutButton = styled(Button)`
  padding: 8px;
  margin-left: 16px;
`;

const PreviewButton = styled(TextAction)`
  margin-top: 4px;
  font-size: 14px;
  font-family: var(--font-semi-bold);
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  svg {
    width: 18px;
    margin-right: 8px;
  }
`

export const logOutOfSSO = (clientID: string): string => {
    let url = 'sso.cambridge.org/logout';
    let redirectParam = `?redirect_uri=${window.location.origin}&client_id=${clientID}`;
    if (['localhost', 'dev.'].find(d => window.location.hostname.includes(d))) {
        url = 'proxy.dev.internal.' + url;
    } else if (['test.', 'pp1.'].find(d => window.location.hostname.includes(d))) {
        url = 'proxy.qa.internal.' + url;
    }
    return 'https://' + url + redirectParam;
};

const Header = () => {
    const session = useRecoilValue(sessionAtom);
    const doLogout = () => {
        const clientId = Strings.default(session?.client_id);
        Client.logout().then(() => {
            window.location.assign(logOutOfSSO(clientId));
        }).catch()
    }

    const previewWebsite = () => {
        const referer = encodeURIComponent(document.location.toString());
        window.open(`${axios.defaults.baseURL}/preview?page=/&token=${session?.token}&referer=${referer}`, '_blank');
    }

    return (
        <HeaderElem data-testid="header-component">
            <div className='flex-column'>
                <Typography variant={'h1'}>Teach Cambridge - CMS</Typography>
                {fi(session,<PreviewButton onClick={previewWebsite}><Visibility/> Preview Website</PreviewButton>)}
            </div>
            <div className='flex-row'>
                <Divider orientation="vertical" variant="middle" flexItem/>
                <LogoutButton variant={'text'} onClick={doLogout}>Log out</LogoutButton>
            </div>
        </HeaderElem>
    );
};

export default Header;
