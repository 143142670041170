import {Objects} from '../../utils/objects';
import {InstanceOf} from './index';

// Base class is inherited by everything and is used to hold the initial object returned from the server
// which helps to restore the state of the object or clone it.
export class BaseClass {
    public __data: any;

    constructor(data: any = {}) {
        this.__data = Objects.default(data);
    }

    public clone(): any {
        return InstanceOf(JSON.parse(JSON.stringify(this.__data)))
    }
}
