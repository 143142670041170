import {result} from 'lodash';
import React from 'react';
import {CMSObject, DisplayMode} from '../../cms/models/__CMSObject';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {Dates} from '../../utils/dates';
import {fi, flatten} from '../../utils/helpers';
import {Lists} from '../../utils/lists';

export enum ColumnType {
    List = 'list',
    Text = 'text',
    Number = 'number',
    Date = 'date',
    Boolean = 'boolean',
    Link = 'link',
    Reference = 'reference',
    Duration = 'duration',
    File = 'file',
}

export type GenericRenderer = (item: CMSObject, property: string, plain?: boolean) => string | React.ReactNode
export type Renderer = (plain?: boolean) => string | React.ReactNode

export const renderers: { [key: string]: GenericRenderer } = {
    [ColumnType.List]: (item: CMSObject, property: string, _plain?: boolean) => {
        return flatten(result(item as any, property), val => val);
    },
    [ColumnType.Text]: (item: CMSObject, property: string, _plain?: boolean) => {
        return flatten(result(item as any, property), val => val);
    },
    [ColumnType.Number]: (item: CMSObject, property: string, _plain?: boolean) => {
        return flatten(result(item as any, property), val => val);
    },
    [ColumnType.Date]: (item: CMSObject, property: string, plain?: boolean) => {
        return flatten(result(item as any, property), val => fi(plain, Dates.timeAgo(val), Dates.local(val)));
    },
    [ColumnType.Boolean]: (item: CMSObject, property: string, _plain?: boolean) => {
        return fi(Boolean(result(item as any, property)), 'Yes', 'No');
    },
    [ColumnType.Link]: (item: CMSObject, property: string, plain?: boolean) => {
        const val: any = result(item as any, property);
        if (plain) {
            return val;
        }
        return <a href={val} target="_blank" rel="noreferrer">{val}</a>;
    },
    [ColumnType.Reference]: (item: CMSObject, property: string, _plain?: boolean) => {
        const val: any = result(item as any, property);
        if (Array.isArray(val)) {
            return val.map(i => {
                const ref = getRecoil(references(i));
                if (ref) {
                    return ref.displayLabel();
                }
                return '';
            }).filter(a => a).join(', ');
        }
        const ref = getRecoil(references(val));
        if (ref) {
            return ref.displayLabel();
        }
        return '-';
    },
    [ColumnType.Duration]: (item: CMSObject, property: string, _plain?: boolean) => {
        return flatten(result(item as any, property), val => Dates.timeAgo(val));
    },
    [ColumnType.File]: (item: CMSObject, property: string, _plain?: boolean) => {
        return flatten(result(item as any, property), val => {
            const ref = getRecoil(references(val));
            if (ref) {
                return ref.displayLabel();
            }
            return '';
        });
    },
};

export const unitsOrComponentsRenderer = (item: any, _property: string, _plain?: boolean) => {
    return [
        ...Lists.default<string>(item.child_assessment).map(id => getRecoil(references(id))).filter(c => c).map(c => c?.displayLabel(DisplayMode.FULL)),
        ...Lists.default<string>(item.component).map(id => getRecoil(references(id))).filter(c => c).map(c => c?.displayLabel(DisplayMode.SHORT)),
    ].join(', ');
};
