export class HostInfo {
    hostname: string;
    nodeName: string;
    os: string;
    platform: string;
    arch: string;
    memTotal: number;
    memFree: number;
    cpu: string;
    cpuUsage: number;
    cpuSpeed: number;
    cpuCores: number;

    constructor(item: any = {}) {
        this.hostname = item.hostname
        this.nodeName = item.nodeName;
        this.os = item.os;
        this.platform = item.platform
        this.arch = item.arch;
        this.memTotal = item.memTotal;
        this.memFree = item.memFree;
        this.cpu = item.cpu
        this.cpuUsage = item.cpuUsage;
        this.cpuCores = item.cpuCores;
        this.cpuSpeed = item.cpuSpeed;
    }
}

export class Node {
    id: string;
    environment: string;
    nodeName: string;
    ip: string;
    started: Date;
    hostInfo: HostInfo

    constructor(item: any = {}) {
        this.id = item.id
        this.environment = item.environment;
        this.nodeName = item.nodeName;
        this.ip = item.ip;
        this.started = new Date(item.started);
        this.hostInfo = new HostInfo(item.hostInfo)
    }
}

export class Service {
    name: string;
    environment: string;
    nodes: Node[];

    constructor(node: Node) {
        this.environment = node.environment
        this.name = node.nodeName;
        this.nodes = [node]
    }

    addNode(node: Node) {
        this.nodes.push(node)
    }
}

export class Environment {
    name: string;
    services: Service[];

    constructor(service: Service) {
        this.name = service.environment;
        this.services = [service]
    }

    addService(service: Service) {
        this.services.push(service)
    }
}