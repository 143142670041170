import styled from '@emotion/styled';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, {useMemo} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {fi} from '../../utils/helpers';
import {MENU_ITEMS} from './config';

const ListWrapper = styled(List)`
  height: 100%;
  overflow-y: auto;
`;

const Item = styled(ListItem)`
  cursor: pointer;
  height: 56px;
  padding: 0;
  position: relative;

  &.selected {
    background-color: var(--color-selected);
  }

  &.selected:before, &:hover:before, &:focus-within:before {
    background-color: var(--color-blue);
    content: ' ';
    height: 100%;
    width: 5px;
    left: 0;
    top: 0;
    position: absolute;
  }

  a {
    margin-top: 1px;
    margin-right: 1px;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 0 0 24px;
    align-items: center;
    text-decoration: none;
    font-family: var(--font-semi-bold);

    &:hover {
      color: var(--color-blue);
    }
  }
`;

const AppMenu = () => {
    const location = useLocation();

    const selected = useMemo(() => {
        return MENU_ITEMS.find(item => item.path === location.pathname || location.pathname.includes(item.path));
    }, [location.pathname]);

    return (
        <ListWrapper disablePadding>
            {MENU_ITEMS.map((item, index) => (
                <Item key={index} disableGutters dense className={fi(selected === item, 'selected')}
                      data-testid={`${item.label.toLowerCase().replace(/\s/g, '-')}-menu-item`}>
                    <NavLink to={item.path}>{item.label}</NavLink>
                </Item>
            ))}
        </ListWrapper>
    );
};

export default AppMenu;
