import {createContext, useContext, useMemo} from 'react';
import {atom, atomFamily, selectorFamily, useRecoilValue, useSetRecoilState} from 'recoil';
import Client from '../../cms/client';
import {InstanceOf} from '../../cms/models';
import {CMSObject} from '../../cms/models/__CMSObject';
import {IQueryResult, QueryContentRequest, Sort, UUID} from '../../cms/types';
import {getRecoil, getRecoilPromise} from '../../state/recoilNexus';
import {cacheBuster, references} from '../../state/state';
import {DEFAULT_QUERY_RESULT, USER_SETTINGS} from '../../utils/constants';
import {fi} from '../../utils/helpers';
import {Lists} from '../../utils/lists';
import {Objects} from '../../utils/objects';
import {TableColumnDefinition, TableConfig} from './config';
import {ColumnPreference, tableConfigSelector, TablePreferences, tablePreferencesSelector} from './preferences';
import {ColumnType} from './renderers';
import {cmsModelsSelector} from '../../state/models';

interface ITableContext {
	type: string;
}

export const TableContext = createContext<ITableContext>({
	type: '',
});

export type TableState = {
	// table configuration
	config: TableConfig;
	preferences: TablePreferences;
	// CMS Objects
	items: IQueryResult<CMSObject>
	selected: {[key: UUID]: boolean};
	objects: {[key: UUID]: CMSObject};
	lastRefreshed: Date | null;
}

export const currentTableState = atom<TableState | null>({
    key: 'currentTableState',
    default: null,
})

// Holds the state of a table
export const tableStateAtom = atomFamily<TableState | null, string>({
	key: 'tableStateAtom',
	default: null,
});

// Gets or creates the table state
const tableStateSelector = selectorFamily<TableState, string>({
	key: 'tableStateSelector',
	get: (tableType) => async ({get}) => {
		// if we have a state, return that
		const state = get(tableStateAtom(tableType));
		if (state) {
			return state;
		}

		// otherwise, create a new state. The state is put back into the tableStateAtom
		// by the table refresh method and whenever setting table preferences (columns, filters, etc)
		const config = get(tableConfigSelector(tableType));
		const preferences = get(tablePreferencesSelector(tableType));

		return {
			config,
			preferences,
			items: DEFAULT_QUERY_RESULT,
			selected: {},
			objects: {},
			lastRefreshed: null,
		};
	},
});

type TableSelectedItems = {
	// Total number of existing items
	outOf: number
	// Number of selected items
	count: number,
	// List of selected item ids
	ids: string[],
	// List of selected item objects
	objects: CMSObject[],
	// Reference to the table state
	state: TableState,
}

// A convenience selector on top of the table state to return information about selected items
// to enable/disable bulk table actions and the global select all checkbox
export const selectedTableItemsSelector = selectorFamily<TableSelectedItems, string>({
	key: 'selectedTableItemsSelector',
	get: (tableType) => async ({get}) => {
		const state = await get(tableStateSelector(tableType));
		const result: TableSelectedItems = {
			outOf: state.items.total,
			count: 0,
			ids: [],
			objects: [],
			state,
		};
		for (const key in state.selected) {
			if (state.selected[key]) {
				result.count++;
				result.ids.push(key);
				result.objects.push(state.objects[key]);
			}
		}
		return result;
	},
});


export const skipMatchingFolder = atom({
	key: 'skipFilterTable',
	default: false,
})

// Returns a matching object usable in a CMSQueryContentRequest for the current state.
// It basically applies folder id and other column filters
export const tableFilterSelector = selectorFamily<any, string>({
	key: 'tableFilterSelector',
	get: (tableType) => async ({get}) => {
		get(cacheBuster(tableType));
		get(cacheBuster(USER_SETTINGS));

		const state = get(tableStateSelector(tableType));

		if (!state) return {};
		const filters: any = {};

		// iterate over all columns
		state.preferences.columns.forEach(column => {
			// column not visible or it has no filter
			if (column.width === 0 || typeof column.filter === 'undefined' || column.filter === null) return;

			// retrieve column definition. we need to know the column type in order to know how to apply the filter
			const definition = Lists.default<TableColumnDefinition>(state.config.columns).find(c => c.field === column.field);
			if (!definition) {
				return;
			}

			// apply the filter on the field
			switch (definition.type) {
				case ColumnType.Boolean:
					filters[column.field] = column.filter;
					break;
				case ColumnType.Date:
				case ColumnType.Duration:
				case ColumnType.Number:
					if (Array.isArray(column.filter)) {
						if (column.filter.length === 2) {
							filters[column.field] = {
								'$and': [
									{[column.filter[0][0]]: fi(definition.type === ColumnType.Number, +column.filter[0][1], column.filter[0][1])},
									{[column.filter[1][0]]: fi(definition.type === ColumnType.Number, +column.filter[1][1], column.filter[1][1])},
								],
							};
						} else {
							filters[column.field] = {[column.filter[0][0]]: fi(definition.type === ColumnType.Number, +column.filter[0][1], column.filter[0][1])};
						}
					}

					break;

				case ColumnType.List:
				case ColumnType.Reference:
					filters[column.field] = {'$in': column.filter};
					break;

				case ColumnType.Link:
				case ColumnType.Text:
					filters[column.field] = {'$regex': column.filter};
			}
		});

		// append whatever other filters the type may need (for example, 'folder' for library items)
		const instance: CMSObject = InstanceOf(tableType);
		const instanceFilters = instance.applyFilters();
		return {...filters, ...instanceFilters};
	},
});

// Just a nice React way of using the tableStateSelector by wrapping it in a hook,
// so you won't need to remember the selector name
export const useTableState = () => {
	const ctx = useContext(TableContext);
	const state = useRecoilValue<TableState>(tableStateSelector(ctx.type));

	return useMemo(() => {
		return state;
	}, [state]);
};

let tableFilters: any = null;

export const tableLoadingState = atom<boolean>({
	key: 'tableLoadingState',
	default: false,
});

// A bunch of methods you need to interact with the table, wrapped in a hook, so they don't require
// explicit exporting, and they can make use of other hooks and states.
export const useTableActions = () => {
	const ctx = useContext(TableContext);
	const state = useRecoilValue(tableStateSelector(ctx.type));
	const setState = useSetRecoilState(tableStateAtom(ctx.type));
	const setPreferences = useSetRecoilState(tablePreferencesSelector(ctx.type));
	const resetCache = useSetRecoilState(cacheBuster(ctx.type));
	const setLoadingState = useSetRecoilState(tableLoadingState);

	// Applies column filters and type filters (matching folder for library items)
	// and returns true if current filter matches the previous filter. If they differ that
	// means we need to clear the state objects and selected objects because we're looking at
	// a different table
	const applyFilters = async (query: QueryContentRequest): Promise<[QueryContentRequest, boolean]> => {
		// apply column filters
		const queryFilter = {...await getRecoil(tableFilterSelector(ctx.type))}
		const filtersOff = getRecoil(skipMatchingFolder)

		if (filtersOff) {
			delete queryFilter.folder
		}

		const matching: any[] = [];
		for (let k in queryFilter) {
			matching.push({[k]: queryFilter[k]});
		}

		query.matching = fi(matching.length > 1, {'$and': matching}, queryFilter) as any;

		const instance: CMSObject = InstanceOf(ctx.type);
		const instanceFilters = instance.applyFilters();
		if (Object.keys(instanceFilters).length > 0) {
			query.withindex = instanceFilters;
		}

		if (Object.keys(query.matching as any).length === 0) {
			delete (query.matching);
		}

		let shouldReset = false;
		if (tableFilters) {
			shouldReset = !Objects.same(tableFilters, query.matching);
		}
		tableFilters = query.matching;

		if(filtersOff&&query.matching){
			delete query.matching.folder
		}
		return [query, shouldReset];
	};

	const refresh = async (currentState: TableState = state) => {
		const [query, shouldReset] = await applyFilters({
			types: Lists.default(currentState.config.itemTypes),
			start: currentState.preferences.page * currentState.preferences.limit,
			limit: currentState.preferences.limit,
			order: currentState.preferences.order,
			sort: currentState.preferences.sort,
			references: true,
		});

		let timer = setTimeout(() => {
			setLoadingState(true);
		}, 100)

		let items: IQueryResult<CMSObject>;
		try {
			items = await Client.query<CMSObject>(query);
		} catch (e) {
			clearTimeout(timer)
			setLoadingState(false);
			return;
		}

		clearTimeout(timer)
		setLoadingState(false);

		const objects: {[key: UUID]: CMSObject} = {};

		//const newObjects = produce(currentState.objects, (draft) => {
			items.results.forEach((item) => {
			//	draft[item.getId()] = item; // add to current list of objects
				objects[item.getId()] = item; // ad to a new list of objects if needs resetting
			});
		//});

		setState({
			...currentState,
			objects: objects,
			selected: fi(shouldReset, currentState.selected, {}),
			lastRefreshed: new Date(),
			items,
		});

		if (shouldReset) {
			const preferences = {...currentState.preferences, page: 0};
			setPreferences(preferences);
		}
	};

	// Select all queries for all items matching the current filter and only retrieves
	// their metadata which is enough to determine if an object is published or not which is needed
	// by the bulk actions
	const selectAll = async () => {
		// use same matching rules as refresh()
		const [query] = await applyFilters({
			types: Lists.default(state.config.itemTypes),
			start: 0,
			limit: -1,
			order: state.preferences.order,
			sort: state.preferences.sort,
			metadataonly:true
		});

		const items: IQueryResult<CMSObject> = await Client.query<CMSObject>(query);
		const objects: {[key: UUID]: CMSObject} = {};
		items.results.forEach((item) => {
			objects[item.getId()] = item;
		});
		const selected = {};
		items.results.forEach((item) => {
			selected[item.getId()] = true;
		});

		setState({...state, objects, selected});
		return items;
	};

	const deselectAll = () => {
		const newState = {...state, selected: {...state.selected}}
		for (let key in newState.selected) {
			newState.selected[key] = false;
		}
		setState(newState);
	};

	const resetPreferences = async () => {
		window.localStorage.removeItem(ctx.type);
		resetCache(val => val + 1);
		const defaultPreferences = await getRecoilPromise(tablePreferencesSelector(ctx.type));
		setPreferences(defaultPreferences);
		refresh({...state, preferences: defaultPreferences}).catch();
	};

	const setColumnPreferences = (columns: ColumnPreference[]) => {
		resetCache(val => val + 1);
		setPreferences({...state.preferences, columns});
	};

	const setOrder = async (property: string) => {
		let sort: Sort = 'asc';
		if (property === state.preferences.order) {
			sort = fi(state.preferences.sort === 'asc', 'desc', 'asc');
		}
		resetCache(val => val + 1);
		const preferences = {...state.preferences, order: property, sort};
		setPreferences(preferences);
		refresh({...state, preferences}).catch();
	};

	const toggleSelected = (id: UUID) => {
		const newState = {...state, selected: {...state.selected, [id]:!state.selected[id]}}
		setState(newState);
	};

	const onDelete = (...id: UUID[]) => {
		const newState = {...state, selected: {...state.selected}, objects: {...state.objects}}
		id.forEach((id) => {
			delete newState.selected[id];
			delete newState.objects[id];
		});
		refresh(newState).catch();
	};

	const setPage = (page: number) => {
		const preferences = {...state.preferences, page: page};
		setPreferences(preferences);
		refresh({...state, preferences}).catch();
	};

	const exportTable = async (): Promise<any> => {
		const [query] = await applyFilters({
			types: Lists.default(state.config.itemTypes),
			start: 0,
			limit: -1,
			order: state.preferences.order,
			sort: state.preferences.sort,
		});
		const cols = Lists.default<ColumnPreference>(state.preferences.columns).filter(c => c.width !== 0);

		return Client.exportTable({
			query,
			type: ctx.type,
			columns: cols.map(c => c.field),
			titles: cols.map(c => state.config.columns?.find(col => col.field === c.field)?.label).filter(a => a),
		});
	};

	const setLimit = (limit: number) => {
		const preferences = {...state.preferences, page: 0, limit: limit};
		setPreferences(preferences);
		refresh({...state, preferences}).catch();
	};
	const getCurrentFilters = ()=>{
		return {...state.preferences, columns: [...state.preferences.columns]}
	}
	const restoreFilters = (preferences:any) => {
		setPreferences(preferences);
		refresh({...state, preferences}).catch();
	}
	const clearFilters = () => {
		const newPreferences = {...state.preferences, columns: [...state.preferences.columns]}
		newPreferences.columns.forEach((c, idx) => {
			newPreferences.columns[idx] = {...c, filter: null}
		})
		setPreferences(newPreferences);
		refresh({...state, preferences: newPreferences}).catch();
	};

	const setFilter = (field: string, value: any) => {
		const newPreferences = {...state.preferences, columns: [...state.preferences.columns]}
		newPreferences.columns.forEach((c, idx) => {
			if (c.field === field) {
				newPreferences.columns[idx] = {...c, filter: value}
			}
		})
		setPreferences(newPreferences);
		refresh({...state, selected: {}, preferences: newPreferences}).catch();
	};

	const getFieldDistinctValues = async (field: string): Promise<any[]> => {
		const filter = await getRecoil(tableFilterSelector(ctx.type));
		const queryFilter = {...filter};
		const filtersOff = getRecoil(skipMatchingFolder)

		if (filtersOff) {
			delete queryFilter.folder
		}

		delete (queryFilter[field]);
		const matching: any[] = [];
		for (let k in queryFilter) {
			matching.push({[k]: queryFilter[k]});
		}
		const tmp: any[] = Lists.default<any>(await Client.distinctValues({
			field: field,
			matching: fi(matching.length > 1, {'$and': matching}, queryFilter),
			types: Lists.default(state.config.itemTypes),
		}));

		const columnConfig = Lists.default<TableColumnDefinition>(state.config.columns).find((c) => c.field === field);
		if (columnConfig && columnConfig.type === 'reference') {
			const missingReferences = tmp.filter((r) => !getRecoil(references(r)));
			if (missingReferences.length > 0) {
				const models: string[] = [];
				state.config.itemTypes?.forEach(type => {
					const model = getRecoil(cmsModelsSelector(type));
					if (model) {
						const f = model.getField(field);
						if (f && f.config.refModel) {
							models.push(f.config.refModel!);
						}
					}
				});

				await Client.query<any>({
					uuids: missingReferences,
					types: models,
					limit: -1,
				});
			}
		}

		tmp.sort();
		return tmp;
	};

	return useMemo(() => ({
			getCurrentFilters,
			restoreFilters,
			refresh,
			resetPreferences,
			toggleSelected,
			selectAll,
			deselectAll,
			onDelete,
			setPage,
			setLimit,
			clearFilters,
			exportTable,
			setFilter,
			setColumnPreferences,
			setOrder,
			getFieldDistinctValues,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[state, ctx.type]);
};

// Just a nice React way of using all the table hooks
export const useTable = () => {
	const state = useTableState();
	const actions = useTableActions();

	return useMemo(() => ({
		state,
		preferences: state.preferences,
		actions,
	}), [state, actions]);
};
