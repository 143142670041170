import {Numbers} from '../../utils/numbers';
import {Strings} from '../../utils/strings';
import {CMSObject} from './__CMSObject';

export class Subject extends CMSObject {
    public static title = 'Subject';
    public static autoPublish = true;
    public name: string;
    public id: number;

    constructor(item: any = {}) {
        super(item);

        this.name = Strings.default(item.name);
        this.id = Numbers.default(item.id);
    }
}
