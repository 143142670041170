import React from "react"
import {Field} from "../../../cms/models/__ModelInfo";
import RadioGroupRenderer from './RadioGroupRenderer';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useFormField} from "../state";
import styled from "@emotion/styled";


const Label = styled(FormControlLabel)`
  margin-bottom: 0 !important;
  
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 0 8px;
  }
`

const CheckBoxRenderer = ({field}: { field: Field }) => {
    const formField = useFormField(field.uid);
    const onChange = (_e, val) => {
        formField.setValue(val);
    }

    return (
        <Label required={field.flags.required} control={<Checkbox disableRipple={true}/>} label={field.name}
               checked={Boolean(formField.value)}
               disabled={formField.isDisabled()}
               onChange={(e, val) => onChange(e, val)}/>
    )
}

const BooleanRenderer = ({field}: { field: Field }) => {
    if (field.config.options && field.config.options.length > 0) {
        return <RadioGroupRenderer field={field}/>
    }
    return <CheckBoxRenderer field={field}/>
}

export default BooleanRenderer