import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Global Announcements",
    description: "Displays a list of [Global Announcements].\n• It can only be added to the page once.",
    constrains: {
        once: true,
    }
})
export class GlobalAnnouncementsWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.GlobalAnnouncements
    }

    public editable() {
        return false;
    }
}