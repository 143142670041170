import React, {Suspense, useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useRecoilValue} from 'recoil';
import PageLoader from './components/Loader/PageLoader';
import {sessionAtom} from './state/session';
import {Lists} from './utils/lists';
import {AppRoute, ROUTES} from './utils/routes';

// PageRouter injects all the main page routes and guards against private routes
const PageRouter = () => {
    const session = useRecoilValue(sessionAtom);
    const renderRoute = useMemo(() => (route: AppRoute, index: number) => {
        if (route.private && !session) return null;
        const children = Lists.default<AppRoute>(route.children);

        if (children.length) {
            return (
                <Route key={index} path={route.path} element={
                    <route.component>
                        <Routes>
                            {children.map((child, index) => renderRoute(child, index))}
                        </Routes>
                    </route.component>
                }/>
            );
        }

        if (route.component) {
            return (
                <Route key={index} path={route.path} element={
                    <Suspense fallback={<PageLoader/>}>
                        <route.component/>
                    </Suspense>
                }/>
            );
        }
    }, [session]);

    return (
        <Routes>
            {ROUTES.map(renderRoute)}
        </Routes>
    );
};

export default PageRouter;
