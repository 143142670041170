import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {FormContext} from "./Form";
import {FormState} from "./model";
import {CMSObject} from "../../cms/models/__CMSObject";
import {Objects} from "../../utils/objects";
import {atom, useRecoilValue} from "recoil";
import {cacheBuster} from "../../state/state";
import {ISelectValue} from "./renderers/components/Select/SelectComponent";

type useFormObject = {
    state: FormState;
    setMeta: (data?: any) => void;
    validate: (data?: any) => boolean;
    validateField: (fieldUID: string, data?: any) => string;
    setValues: (values: any) => void;
    setValue: (fieldUID: string, value: any) => void;
    setObject: (object: CMSObject) => void;
    cleanup: () => void;
}

export const visibleFormStates = atom<{[keys:string]:React.ReactNode[]}>({
    key: 'visibleFormStates',
    default: {},
})

export const useForm = () => {
    const context = useContext(FormContext)
    const [changed] = useState<number>(0)
    const [state, setState] = useState<FormState>(FormState.getFormState(context))

    useEffect(() => {
        let newState = FormState.getFormState(context);
        if (!newState) {
            newState = new FormState(context)
        }
        if (newState && state && newState._guid !== state._guid) {
            setState(newState)
        }
        // eslint-disable-next-line
    }, [context, state]);

    const cache = useRecoilValue(cacheBuster(state.id));

    const validate = useCallback((data?: any): boolean => {
        return state.validate(data)
    }, [state]);

    const validateField = useCallback((fieldUID: string, data?: any): string => {
        return state.validateField(fieldUID, data)
    }, [state]);

    const setValues = useCallback((values: any): void => {
        return state.setValues(values)
    }, [state]);

    const setValue = useCallback((fieldUID: string, value: any): void => {
        return state.setValue(fieldUID, value)
    }, [state]);

    const setObject = useCallback((object: CMSObject): void => {
        return state.setObject(object)
    }, [state]);

    const cleanup = useCallback(() => {
        state.destroy();
        // eslint-disable-next-line
    }, [])

    const setMeta = useCallback((val: any) => {
        state.setMeta(val);
        // eslint-disable-next-line
    }, [state])

    return useMemo((): useFormObject => {
        return {
            state,
            validate,
            setMeta,
            validateField,
            setValues,
            setValue,
            setObject,
            cleanup,
        }
        // eslint-disable-next-line
    }, [state, validate, validateField, setValues, setValue, setObject, changed, cache])
}

export const useFormField = (fieldUID: string) => {
    const context = useContext(FormContext)
    const [state] = useState<FormState>(FormState.getFormState(context))

    const refresh = useRecoilValue(cacheBuster(`${state.id}/${fieldUID}`))

    const field = useMemo(() => {
        return state.fieldState(fieldUID);
    }, [state, fieldUID])

    const setError = useCallback((error: string): void => {
        field.setError(error)
    }, [field])

    const setValue = useCallback((value: any): void => {
        field.setValue(value)
    }, [field])

    const canRender = useCallback((): boolean => {
        return field.canRender()
    }, [field])

    const showError = useCallback((): boolean => {
        return field.showError()
    }, [field])

    const getWarning = useCallback((): boolean => {
        return field.getWarning()
    }, [field])

    const filterDataset = useCallback((dataset: ISelectValue[]): ISelectValue[] => {
        return field.filterDataSet(dataset)
    }, [field])

    const hasError = useCallback((): boolean => {
        return Boolean(field.error) && Boolean(field.touched);
    }, [field]);

    const isDisabled = useCallback((): boolean => {
        const disabledFields = state.object.disabledFields()
        return disabledFields.includes(field.uid)
    }, [field]);

    return useMemo(() => {
        return {
            id: `${state.id}/${fieldUID}`,
            ...Objects.default(field),
            setError,
            setValue,
            showError,
            getWarning,
            canRender,
            filterDataset,
            hasError,
            isDisabled,
            previewMode: Boolean(state.previewMode),
        }
        // eslint-disable-next-line
    }, [state, setError, setValue, hasError, showError, refresh, state.id])
}