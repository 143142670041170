import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {BoxContainer} from "./components/BoxContainer"
import {useRecoilValue} from "recoil";
import {selectedObject, uploadedFile} from '../../state/state';
import styled from "@emotion/styled";
import {CMSFile} from "../../cms/models/File";
import {Dates} from "../../utils/dates";
import Client from "../../cms/client";
import {useSnackbar} from "notistack";
import TextAction from "../commons/TextAction";
import {fi} from "../../utils/helpers";
import {Objects} from "../../utils/objects";

const Wrapper = styled.div<{ missingLinks: number }>`
  padding: 16px;
  display: flex;
  flex-direction: row;
  background: ${props => fi(props.missingLinks, 'rgba(255, 0, 0, 0.03)', 'white')};
  
  .missing-links {
    color: var(--color-red);
    margin-top: 8px;
  }
`

const Icon = styled.div`
  margin-right: 16px;

  svg {
    height: 48px;
    width: 48px;
  }
`

const FileInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
    line-height: 1.5;
    display: inline-block;
    vertical-align: top;

    span {
      font-weight: normal;
    }
  }
`

const FileInfoBox = () => {
    const timezone = Dates.useTimeZone()
    const {enqueueSnackbar} = useSnackbar();
    const selected = useRecoilValue<any>(selectedObject)
    const uploaded = useRecoilValue<any>(uploadedFile)
    const ref = useRef<any>(true)
    const [missingLinks, setMissingLinks] = useState(0)

    const fileInfo: CMSFile | null = useMemo(() => {
        if (uploaded) {
            return uploaded
        }

        if (selected && selected.fileInfo) {
            return selected.fileInfo()
        }

        return null
    }, [selected, uploaded])

    const checkMissingLinks = useCallback(async () => {
        if (!fileInfo) return
        const count = await Client.missingLinksCount(fileInfo.hash).then((res) => {
            return Object.keys(Objects.default(res)).length
        })
        if (ref.current) {
            setMissingLinks(count)
            if (!count) {
                clearTimeout(ref.current)
                ref.current = setTimeout(() => {
                    checkMissingLinks()
                }, 10000)
            }
        }
    }, [fileInfo]);

    useEffect(() => {
        return () => {
            ref.current && clearTimeout(ref.current)
            ref.current = false
        }
    }, []);

    useEffect(() => {
        checkMissingLinks().catch()
    }, [checkMissingLinks]);

    const downloadFile = useCallback((e) => {
        e.preventDefault();
        if (fileInfo) {
            Client.download(fileInfo.fileuid).catch((err) => {
                enqueueSnackbar(`Error downloading file: ${err}`, {variant: 'error'});
            });
        }
    }, [fileInfo, enqueueSnackbar]);

    const downloadReport = useCallback((e) => {
        e.preventDefault();
        if (fileInfo) {
            Client.getItemLinksReport(fileInfo.hash).catch((err) => {
                enqueueSnackbar(`Error downloading file: ${err}`, {variant: 'error'});
            });
        }
    }, [fileInfo, enqueueSnackbar])

    const content = useMemo(() => {
        if (!fileInfo) {
            return null
        }
        return (
            <Wrapper missingLinks={missingLinks}>
                <Icon data-testid='file-icon'>
                    {fileInfo.icon()}
                </Icon>

                <FileInfo>
                    <div data-testid='file-name'>File name: <TextAction title={fileInfo.filename}
                                                                        onClick={downloadFile}>{fileInfo.filename}</TextAction>
                    </div>

                    <div data-testid='file-size'>File size: <span
                        title={`${fileInfo.size} bytes`}>{fileInfo.formattedSize}</span></div>

                    <div data-testid='file-creator'>Created by: <span>{fileInfo.author} on</span> <span
                        title={Dates.timeAgo(Dates.local(fileInfo.created))}>{Dates.local(fileInfo.created)}</span>
                    </div>

                    {missingLinks > 0 && (
                        <div data-testid='missing-links' className='missing-links'>
                            <span>We've identified <TextAction title={'Download report'} onClick={downloadReport}>{missingLinks} invalid link(s)</TextAction> in this file</span>
                        </div>
                    )}
                </FileInfo>
            </Wrapper>
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileInfo, timezone, downloadFile])

    return (
        <BoxContainer title='File info' id='fileInfo' hidden={!Boolean(fileInfo)}>
            {content}
        </BoxContainer>
    )
}

export default FileInfoBox