import PageLayout from '../layout/PageLayout';
import ControlPanelComponent from '../pages/ControlPanel/ControlPanelComponent';
import HomepageComponent from '../pages/Home/HomepageComponent';
import ErrorPageComponent from '../pages/Error/ErrorPageComponent';
import FormPageComponent from '../pages/Form/FormPageComponent';
import TablePageComponent from '../pages/Table/TablePageComponent';
import {
    ROUTE_CONTROL_PANEL,
    ROUTE_CP_API_KEYS,
    ROUTE_CP_FLAGS,
    ROUTE_CP_JOBS,
    ROUTE_CP_MISC,
    ROUTE_CP_SEARCH,
    ROUTE_CP_STATUS,
    ROUTE_DASHBOARD,
    ROUTE_LIBRARY_ADD,
    ROUTE_LIBRARY_BROWSE, ROUTE_LIBRARY_BULK,
    ROUTE_LIBRARY_EDIT,
    ROUTE_LIST_ROUTE,
    ROUTE_NOT_FOUND,
    ROUTE_OBJECT_ADD,
    ROUTE_OBJECT_EDIT, ROUTE_PAGE_ADD_CHILD,
    ROUTE_SERVER_ERROR,
    ROUTE_UNAUTHORIZED,
} from './constants';
import Status from "../components/ControlPanel/Status";
import FeatureFlags from "../components/ControlPanel/FeatureFlags";
import SearchIndex from "../components/ControlPanel/SearchIndex";
import APIKeys from "../components/ControlPanel/APIKeys";
import Jobs from "../components/ControlPanel/Jobs";
import Misc from "../components/ControlPanel/Misc";
import BulkComponent from '../components/Bulk/BulkComponent';

export type AppRoute = {
    path: string;
    component: (props?: any) => JSX.Element | null;
    errorPage?: boolean;
    private?: boolean;
    children?: AppRoute[];
}

export const ROUTES: AppRoute[] = [
    {
        path: ROUTE_NOT_FOUND,
        component: ErrorPageComponent,
        errorPage: true,
    },
    {
        path: ROUTE_UNAUTHORIZED,
        component: ErrorPageComponent,
        errorPage: true,
    },
    {
        path: ROUTE_SERVER_ERROR,
        component: ErrorPageComponent,
        errorPage: true,
    },
    {
        path: '/*',
        component: PageLayout,
        private: true,
        children: [
            {
                path: ROUTE_DASHBOARD,
                component: HomepageComponent,
            },
            {
                path: ROUTE_CONTROL_PANEL,
                component: ControlPanelComponent,
                children: [
                    {
                        path: ROUTE_CP_STATUS,
                        component: Status
                    },
                    {
                        path: ROUTE_CP_FLAGS,
                        component: FeatureFlags
                    },
                    {
                        path: ROUTE_CP_SEARCH,
                        component: SearchIndex
                    },
                    {
                        path: ROUTE_CP_API_KEYS,
                        component: APIKeys
                    },
                    {
                        path: ROUTE_CP_JOBS,
                        component: Jobs,
                    },
                    {
                        path: ROUTE_CP_MISC,
                        component: Misc
                    },
                ]
            },
            {
                path: `${ROUTE_CONTROL_PANEL}/:job`,
                component: ControlPanelComponent,
                children: [
                    {
                        path: '/:job',
                        component: Jobs,
                    }
                ]
            },
            {
                path: ROUTE_LIBRARY_BULK,
                component: BulkComponent,
            },
            {
                path: ROUTE_LIBRARY_BROWSE,
                component: TablePageComponent,
            },
            {
                path: ROUTE_LIBRARY_ADD,
                component: FormPageComponent,
            },
            {
                path: ROUTE_LIBRARY_EDIT,
                component: FormPageComponent,
            },
            {
                path: ROUTE_OBJECT_ADD,
                component: FormPageComponent,
            },
            {
                path: ROUTE_PAGE_ADD_CHILD,
                component: FormPageComponent,
            },
            {
                path: ROUTE_OBJECT_EDIT,
                component: FormPageComponent,
            },
            {
                path: ROUTE_LIST_ROUTE,
                component: TablePageComponent,
            },
        ],
    },
];
