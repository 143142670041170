import React, { useContext, useEffect, useState } from 'react';
import { ActionButton } from '../../commons/ActionButton';
import { selectedTableItemsSelector, TableContext, useTableActions } from '../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Messages } from '../../../utils/messages';
import { fi } from '../../../utils/helpers';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import { modalAtom, ModalWrapperProps } from '../../ModalDialogs/ModalWrapper';
import MoveModal from '../../ModalDialogs/MoveModal';
import Client from '../../../cms/client';
import { Strings } from '../../../utils/strings';
import { enqueueSnackbar } from 'notistack';
import { UUID } from '../../../cms/types';

const MoveActionButton = () => {
	const ctx = useContext(TableContext);
	const state = useRecoilValue(selectedTableItemsSelector(ctx.type));
	const [disabled, setDisabled] = useState<boolean>(false);
	const [title, setTitle] = useState<string>(Messages.MoveItems);
	const setModalMove = useSetRecoilState(modalAtom);
	const {refresh} = useTableActions();

	useEffect(() => {
		let shouldDisable = state.count === 0;
		let newTitle: string = fi(shouldDisable, Messages.NoSelectedItem, Messages.MoveItems);
		setTitle(newTitle);
		setDisabled(shouldDisable);
	}, [state]);

	const onConfirm = async (targetId: UUID): Promise<void> => {
		const ids =  state.objects.map(item => item.getId())
		return Client.moveItems({uuids: ids, parent: targetId}).then(() => {
			enqueueSnackbar(Strings.ofPlural(ids.length, 'item', 'items') + ' moved successfully', {variant: 'success'})
		}).finally(() => {
			refresh().catch()
		})
	}

	const onMoveSelected = () => {
		setModalMove({
			component: (props: ModalWrapperProps) => {
				return <MoveModal {...props} onConfirm={onConfirm} />;
			},
		});
	};

	return (
		<ActionButton
			data-testid='table-move-items-action-button'
			disabled={disabled}
			title={title}
			onClick={onMoveSelected}
		>
			<DriveFileMoveOutlinedIcon />
		</ActionButton>
	);
};
export default MoveActionButton;