import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {formField} from '../../utils/decorators';
import {FieldType} from './__ModelInfo';
import {Types} from '../types';
import {IFormEvents} from '../../components/Form/model';
import {Strings} from '../../utils/strings';
import React, {ReactElement} from 'react';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {DisplayMode} from './__CMSObject';
import {PreviewRow, PreviewWrapper} from './_style';

@widgetConfig({
	group: WidgetGroup.Other,
	name: "My Subjects",
	description: "Displays a table with user's subjects.\n• It can only be added to the page once.",
	constrains: {
		once: true,
	}
})
export class MySubjectsWidget extends BaseWidget implements IFormEvents{
	@formField({
		name: "Page link",
		fieldtype: FieldType.Reference,
		order: 1,
		flags: {required: true},
		config: {refModel: Types.PAGE}
	})
	public pageLink: string;

	constructor(item: any = {}) {
		super(item);
		this.type = WidgetType.MySubjects
		this.pageLink = Strings.default(item.pageLink)
	}

	public editable() {
		return true;
	}

	preview(): ReactElement | null {
		const page = getRecoil(references(this.pageLink))
		let pageTitle = "";
		if (page) {
			pageTitle = page.displayLabel(DisplayMode.FULL);
		}
		return (
			<PreviewWrapper>
				<PreviewRow>
					<label>Page link</label>
					<span>{pageTitle}</span>
				</PreviewRow>
			</PreviewWrapper>
		)
	}
}