import styled from '@emotion/styled';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Divider from '@mui/material/Divider';
import {useSnackbar} from 'notistack';
import React, {Suspense, useEffect, useMemo, useRef, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {useHotkeys} from 'react-hotkeys-hook';
import {NavLink, useParams} from 'react-router-dom';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {getRecoil, resetRecoil, setRecoil} from '../../state/recoilNexus';
import {cacheBuster, selectedMenuItem, selectedTreeItem} from '../../state/state';
import {Browser} from '../../utils/browser';
import Loader from '../Loader/Loader';
import {ITreeConfig} from './config';
import QuickAccessList from './QuickAccess';
import TreeActions from './TreeActions';
import TreeItem from './TreeItem';
import {treeObjectsSelector} from './utils';
import {ITreeItem, Tree} from "./Tree";
import {Lists} from "../../utils/lists";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const BackButton = styled(NavLink)`
  display: flex;
  align-items: center;
  color: var(--color-blue);
  border-radius: 4px;
  font-family: var(--font-semi-bold);
  line-height: 27px;
  margin-right: 8px;
  padding-right: 8px;

  &:hover {
    text-decoration: none;
    background-color: var(--color-box-shadow);
  }
`;

const MenuTitle = styled.h2`
  font-family: var(--font-semi-bold);
  line-height: 27px;
  font-size: 16px;
  padding-left: 8px;
`;

const ItemsWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  width: 100%;
`;

const LoaderWrapper = styled.div`
  margin-top: 20%;
  width: 100%;
`;

const ErrorLoading = styled.div`
  margin-top: 20%;
  font-size: 14px;
  color: var(--color-red);
  line-height: 28px;
  width: 100%;

  div {
    width: 100%;
  }
`;

const ItemsList = ({config}: { config: ITreeConfig }) => {
    const key = `tree-${config.type}`;
    const params = useParams();
    const tree = useRef<Tree>()
    const objects = useRecoilValue(treeObjectsSelector(config.type));
    const [items, setItems] = useState<ITreeItem[]>([])

    const paramId = useMemo(() => {
        if (typeof config.param ==='string') {
            return params[config.param]
        } else {
            for (let i = 0; i < config.param.length; i++) {
                if (params[config.param[i]] !== 'create') {
                    return params[config.param[i]]
                }
            }
        }
    }, [config.param, params]);

    const cache = useRecoilValue(cacheBuster(key))

    useEffect(() => {
        return () => {
            const selectedItem = getRecoil(selectedTreeItem);
            if (selectedItem) {
                tree.current!.setSelected(selectedItem.id, false)
            }
            setRecoil(selectedTreeItem, null)
        }
    }, []);

    useEffect(() => {
        if (tree.current) {
            const list = tree.current!.getTree()
            Lists.sort(list, "order")
            setItems(list)
        }
    }, [cache])

    const setSelected = () => {
        const selectedItem = getRecoil(selectedTreeItem);
        if (paramId === "undefined" || paramId === 'create' || (!selectedItem && !paramId) || (selectedItem && paramId && selectedItem.id === paramId)) {
            return;
        }

        if (!items.length) {
            return;
        }

        if (selectedItem) {
            tree.current!.setSelected(selectedItem.id, false)
        }

        const recursiveExpand = (node) => {
            if (!node) {
                return
            }
            const parent = tree.current!.setOpened(node.id, true);
            if (parent) {
                recursiveExpand(parent.parent);
            }
        }
        const node = tree.current!.setSelected(paramId!, true);
        if (node) {
            recursiveExpand(node.parent)
        }

        const selectedNode = tree.current!.cloneNode(paramId!);
        if (selectedNode) {
            setRecoil(selectedTreeItem, selectedNode);
        } else {
            setRecoil(selectedTreeItem, null);
        }
        if (selectedItem) {
            setItems(tree.current!.getTree());
        }
    };

    useEffect(() => {
        setSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, paramId, config.type]);

    useEffect(() => {
        resetRecoil(selectedTreeItem)
        tree.current = new Tree(key, objects)
        const list = tree.current!.getTree()
        setItems(list);
        setRecoil(cacheBuster(key), (val) => val + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [objects]);

    return (
        <ListWrapper data-depth={0} data-parentid={null}
                     onDragOver={e => e.preventDefault()}
                     onDragLeave={e => e.preventDefault()}>
            {items.filter(i => i.object).map((item, index) => (
                <TreeItem tree={tree.current!} key={`${index}-${item.id}`} item={item} depth={0} config={config}/>
            ))}
        </ListWrapper>
    );
};

const TreeComponent = ({config}: { config: ITreeConfig }) => {
    const {enqueueSnackbar} = useSnackbar();
    const setCacheBuster = useSetRecoilState(cacheBuster(config.type));
    const [menuItem, setMenuItem] = useRecoilState(selectedMenuItem);

    const ErrorFallback = ({error, resetErrorBoundary}) => {
        enqueueSnackbar('Error: ' + error, {variant: 'error'});
        return (
            <ErrorLoading>
                <div className={'flex-row centered'}>
                    <ErrorOutline className={'inline-icon'}/> Error loading items.
                </div>
                <div className={'centered link'} onClick={() => {
                    resetErrorBoundary();
                    setCacheBuster(val => val + 1);
                }}>Retry
                </div>
            </ErrorLoading>
        );
    };

    useHotkeys('ctrl+home', (evt) => {
        evt.preventDefault();
        Browser.navigate('/');
        setMenuItem(null);
    });

    return (
        <Wrapper>
            <div className={'flex-row spaced mb8'}>
                <MenuTitle>{menuItem?.label}</MenuTitle>
                <BackButton color="primary" to={'/'} title={'Back [Ctrl+Home]'} onClick={() => setMenuItem(null)}>
                    <ChevronLeft/> Back
                </BackButton>
            </div>
            <Divider/>
            <TreeActions treeType={config.type}/>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={(
                    <LoaderWrapper className={'flex-row centered'}>
                        <Loader size={20}/>
                    </LoaderWrapper>
                )}>
                    <QuickAccessList config={config}/>
                    <ItemsWrapper>
                        <ItemsList config={config}/>
                    </ItemsWrapper>
                </Suspense>
            </ErrorBoundary>
        </Wrapper>
    );
};

export default TreeComponent;
