import {CMSObject} from './__CMSObject';
import {Types, UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import React, {ReactElement} from 'react';
import {Dates, timeOptions} from '../../utils/dates';

export class TimeTables extends CMSObject {
	public key_dates_file: UUID;
	public date: Date;
	public title: string;
	public board: string;
	public series: string;
	public qualification: string;
	public qualification_mapping: string;
	public subject: string;
	public subject_mapping: string;
	public code: string;
	public duration: string;
	public time: string;
	public exam_year: number;
	public type: string;
	public description: string;
	public updated: string;
	public created: string;
	constructor(item: any = {}) {
		super(item);

		this.key_dates_file = Strings.default(item.key_dates_file);
		this.date = new Date(item.date);
		this.title = Strings.default(item.title);
		this.series = Strings.default(item.series);
		this.board = Strings.default(item.board);
		this.qualification = Strings.default(item.qualification);
		this.qualification_mapping = Strings.default(item.qualification_mapping);
		this.subject = Strings.default(item.subject);
		this.subject_mapping = Strings.default(item.subject_mapping);
		this.code = Strings.default(item.code);
		this.duration = Strings.default(item.duration);
		this.time = Strings.default(item.time);
		this.exam_year = Numbers.default(item.exam_year);
		this.description = '';
		this.type = 'Exam Date';
		this.updated = Strings.default(item.updated);
		this.created = Strings.default(item.created);
	}

	public getType(): Types {
		return Types.TIMETABLE;
	}

	public getSeriesYear(): string {
		return Strings.capitalize(`${this.series.substring(0, 3)} ${this.exam_year.toString()}`);
	}

	public getTitle(): ReactElement {
		return (
			<div>
				<p>{this.type}</p>
				<p>{this.qualification}, {this.subject}</p>
			</div>
		);
	}

	public getDate(): ReactElement {
		return (
			<div data-testid='date-info' className='mt-8'>
				<div data-testid='date'>
					{Dates.format(this.date, true, true)} | <span
					className='text-semiBold'>{timeOptions[this.time]}</span>
				</div>
				<div data-testid='duration' className='mt-8'>
					Duration: {this.duration}
				</div>
			</div>
		);
	}

	public renderQualification(): ReactElement {
		return (
			<div data-testid='qualification-info' title={`${this.code}, ${this.title}`}>
				<span>{this.code}, {this.title}</span>
			</div>
		);
	}
	public getFlag(): ReactElement[] {
		const result: ReactElement[] = [];
		let date = this.created;
		let label: string = 'New';
		if (this.updated) {
			label = 'Updated';
			date = this.updated;
		}

		const keyDate = new Date(date);
		if (keyDate.toString() === 'Invalid Date') {
			return result;
		}

		const now = new Date();
		const elapsed = (now.getTime() - keyDate.getTime()) / 1000 / 60 / 60 / 24;
		if (elapsed <= 30) {
			result.push(<span key={this.getId()}>{label}</span>) ;
		}
		return result;
	}
}