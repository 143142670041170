import React, {useMemo} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Types} from '../../cms/types';
import TableComponent from '../../components/TableComponent/TableComponent';
import {ROUTE_LIBRARY} from '../../utils/constants';
import {fi} from '../../utils/helpers';
import {TableContext} from "../../components/TableComponent/state";

let key = 0;

const TablePageComponent = () => {
    const params = useParams();
    const location = useLocation()

    return useMemo(() => {
        const type = fi(location.pathname.startsWith(ROUTE_LIBRARY), Types.LIBRARY, params.type)
        key++;
        return (
            <TableContext.Provider value={{type}} key={key}>
                <TableComponent key={key}/>
            </TableContext.Provider>
        )
    }, [location.pathname, params]);
};

export default TablePageComponent;
