import styled from '@emotion/styled';
import React from 'react';
import { handleAction } from '../../utils/helpers';
import { Objects } from '../../utils/objects';

const Wrapper = styled.span`
  color: var(--color-blue);
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &.disabled {
    cursor: default;
    color: var(--color-grey);
    pointer-events: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const TextAction = (props: any) => {
	const onClick = (evt: any) => {
		if (props.onClick) {
			props.onClick(evt);
		}
	};

	return <Wrapper {...Objects.default(props)} {...handleAction(onClick)}>{Objects.default(props).children}</Wrapper>;
};

export default TextAction;

