import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Landing page resources",
    description: "A generic section used to display user favorites and other resources.\n• It can only be added to the page once.\n• It can only be added to the Homepage.",
    constrains: {
        once: true,
        onlyOnHomePage: true,
    }
})
export class HomepageResourcesWidget extends BaseWidget {
    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.HomepageResources
    }

    public editable() {
        return false;
    }
}