import styled from '@emotion/styled';
import DragIndicator from '@mui/icons-material/DragIndicator';
import React, { useEffect, useState } from 'react';
import { MENU_WIDTH_DEFAULT, MENU_WIDTH_MAX, MENU_WIDTH_SETTING } from '../../utils/constants';
import { useLocalStorage } from '../../utils/hooks';

const Splitter = styled.div`
  width: 8px;
  margin-left: -4px;
  height: 100%;
  cursor: col-resize;
  z-index: 500;
  position: fixed;
  color: var(--color-border);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    opacity: 1;
    background-color: var(--color-grey);
  }

  &.dragging {
    opacity: 1;
    background: var(--color-border);
  }

  svg {
    pointer-events: none;
    width: 16px;
    margin-left: -1px;
  }
`;

let ref, x;

const MenuSplitter = () => {
	const [ width, setWidth ] = useLocalStorage(MENU_WIDTH_SETTING, MENU_WIDTH_DEFAULT);
	const [ localWidth, setLocalWidth ] = useState<number>(width);

	const onMouseUp = (e) => {
		e.preventDefault();
		if (ref) {
			ref.classList.remove('toggled');
		}
		setWidth(x); // persist on mouse up
		window.removeEventListener('mouseup', onMouseUp);
		window.removeEventListener('mousemove', onMouseMove);
	};

	const onMouseMove = (e: MouseEvent) => {
		e.preventDefault();
		if (e.clientX > MENU_WIDTH_DEFAULT && e.clientX < MENU_WIDTH_MAX) {
			x = e.clientX;
			setLocalWidth(e.clientX);
		}
	};

	const onMouseDown = (e: any) => {
		e.preventDefault();
		ref = e.target;
		e.target.classList.add('toggled');
		window.addEventListener('mouseup', onMouseUp);
		window.addEventListener('mousemove', onMouseMove);
	};

	useEffect(() => {
		setLocalWidth(width)
	}, [width])

	return (
		<Splitter onMouseDown={onMouseDown} style={{left: localWidth + 'px'}}>
			<DragIndicator/>
		</Splitter>
	);
};

export default MenuSplitter;
