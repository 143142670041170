import styled from '@emotion/styled';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Strings} from '../../utils/strings';
import {FormContext} from './Form';
import {Lists} from '../../utils/lists';
import {FieldType} from '../../cms/models/__ModelInfo';
import BooleanRenderer from './renderers/BooleanRenderer';
import DateRenderer from './renderers/DateRenderer';
import FileRenderer from './renderers/FileRenderer';
import ListRenderer from './renderers/ListRenderer';
import NumberRenderer from './renderers/NumberRenderer';
import ReferenceRenderer from './renderers/ReferenceRenderer';
import TextRenderer from './renderers/TextRenderer';
import {useFormField} from './state';
import RadioGroupRenderer from './renderers/RadioGroupRenderer';
import ProductDataRenderer from './renderers/ProductDataRenderer';
import TagsRenderer from './renderers/TagsRenderer';
import IconListRenderer from './renderers/IconListRenderer';
import WidgetListRenderer from './renderers/WidgetListRenderer';
import {fi} from '../../utils/helpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import TagsListRenderer from './renderers/TagsListRenderer';
import TabRenderer from "./renderers/TabRender";
import CardsListRenderer from './renderers/CardsListRenderer';
import ErrorsRenderer from './renderers/ErrorsRenderer';

const Row = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 18px;

    &:last-of-type {
        margin-bottom: 0;
    }

    &.icon-list {
        width: 140px;
    }

    label {
        font-family: var(--font-semi-bold);
        margin-bottom: 8px;

        &.required {
            &:after {
                content: "*";
                margin-left: 4px;
                color: var(--color-red);
            }
        }
    }
`;

const ErrorMessage = styled.span`
    margin-top: 8px;
    font-size: 14px;
    color: var(--color-red)
`;

const WarningMessage = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin-top: 8px;
    font-size: 14px;
    color: var(--color-orange);

    svg {
        height: 16px;
    }
`;

type FormRowProps = {
	field: string,
	error?: string;
	children?: any,
	className?: string,
	style?: Object,
}
// A form row consists of a label, a field, and an optional error message.
// If children are provided, then the children will be rendered inside the row.
// If no children are provided, then the field will be rendered inside the row based on model definition.
const FormRow = (props: FormRowProps) => {
	const ctx = useContext(FormContext);
	const formField = useFormField(props.field);
	const warnings = formField.getWarning();
	const [children, setChildren] = useState<any>([]);

	if (!formField.field || !formField.field.fieldtype) {
		debugger;
	}

	useEffect(() => {
		const tmp = Lists.default(props.children);
		if (!formField.field) return;
		if (tmp.length !== 0) {
			// There are children defined in props, so we render those.
			setChildren(tmp);
		} else {
			// There are no children defined in props, so we need to determine what to do

			// Ask the class if we can render this field
			if (!formField.canRender()) {
				setChildren(null);
				return;
			}
			// Determine the renderer based on field type
			switch (formField.field.fieldtype) {
				case FieldType.Boolean:
					if (formField.field.config.isRadioGroup) {
						setChildren(<RadioGroupRenderer field={formField.field} />);
						return;
					}
					setChildren(<BooleanRenderer field={formField.field} />);
					return;
				case FieldType.Date:
					setChildren(<DateRenderer field={formField.field} />);
					return;
				case FieldType.File:
					if (formField.previewMode) {
						setChildren(null);
					} else {
						setChildren(<FileRenderer field={formField.field} />);
					}
					return;
				case FieldType.List:
					if (formField.field.config.isRadioGroup) {
						setChildren(<RadioGroupRenderer field={formField.field} />);
						return;
					}
					setChildren(<ListRenderer field={formField.field} />);
					return;
				case FieldType.Number:
					setChildren(<NumberRenderer field={formField.field} />);
					return;
				case FieldType.IconList:
					setChildren(<IconListRenderer field={formField.field} />);
					return;
				case FieldType.WidgetList:
					setChildren(<WidgetListRenderer field={formField.field} />);
					return;
				case FieldType.Tags:
					setChildren(<TagsRenderer field={formField.field} />);
					return;
				case FieldType.Tab:
                    setChildren(<TabRenderer field={formField.field}/>)
                    return
                case FieldType.TagsList:
					setChildren(<TagsListRenderer field={formField.field} />);
					return;
				case FieldType.CardsList:
					setChildren(<CardsListRenderer field={formField.field} />);
					return;
				case FieldType.ErrorsList:
					setChildren(<ErrorsRenderer field={formField.field} />);
					return;
				case FieldType.Reference:
					setChildren(<ReferenceRenderer field={formField.field} />);
					return;
				case FieldType.ProductData:
					setChildren(<ProductDataRenderer field={formField.field} />);
					return;
				case FieldType.Text:
					if (formField.field.config.isRadioGroup) {
						setChildren(<RadioGroupRenderer field={formField.field} />);
						return;
					}
					if (Lists.default(formField.field.config.options).length) {
						setChildren(<ListRenderer field={formField.field} />);
						return;
					}
					setChildren(<TextRenderer field={formField.field} />);
					return;
			}
		}
	}, [props.children, formField]);

	// We need to do this for cases where the error comes from props or it comes from the field renderer based
	// on how the row was defined (with or without children)
	const error = useMemo(() => {
		return Strings.default(props.error, formField.error);
	}, [props.error, formField.error]);

	if (children === null || !formField.field) {
		return null;
	}

	return (
		<Row className={`formRow ${formField.field.fieldtype}`} data-testid={`${ctx.id}-${props.field}-field`}>
			{children}
			<ErrorMessage>{error}</ErrorMessage>
			{warnings && (
				<WarningMessage title={warnings.join('\n')}>
					<WarningAmberIcon />
					{fi(warnings.length === 1, 'Invalid link identified in field', `${warnings.length} invalid links identified in content`)}
				</WarningMessage>
			)}
		</Row>
	);
};

export default FormRow;