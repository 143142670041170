import {CMSObject, DisplayMode, ItemRoutes} from './__CMSObject';
import {Types, UUID} from '../types';
import {CMSFile} from './File';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';
import {Strings} from '../../utils/strings';
import {Lists} from '../../utils/lists';
import {formConfig, formField, linkedFormField, tableHidden} from '../../utils/decorators';
import {FieldType} from './__ModelInfo';
import {fi} from '../../utils/helpers';
import {FormEvent, IFormEvents} from '../../components/Form/model';
import {Messages} from '../../utils/messages';
import {Browser} from '../../utils/browser';
import {ROUTES} from '../../utils/routes';
import {CreateMediaLibraryItem} from "../../components/CreateMediaLibraryItem/CreateMediaLibraryItem";
import React from "react";
import BulkUpload from "../../components/TableComponent/bulkActionButtons/BulkUpload";
const noFolder = 'na';
export class EventsAir extends CMSObject {
    constructor(item: any = {}) {
        super(item);
    }

    public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
        return this.__data.code;
    }
}

@formConfig({
    buttons:[<BulkUpload />, <CreateMediaLibraryItem />]
})
export class EventMaterial extends CMSObject implements IFormEvents {
    public static title = 'Event Material';

    public title: string;
    public description: string;
    public folder: UUID;
    @linkedFormField('event_material_type')
    public file: UUID;
    @tableHidden
    @linkedFormField('event_material_type')
    public link: string;
    @tableHidden
    @linkedFormField('event_material_type')
    public display_text: string;
    @tableHidden
    @formField({
        fieldtype: FieldType.Reference,
        config: {refModel: Types.EVENT},
    })
    public event_id: UUID[];

    @formField({
        name: 'Material type',
        fieldtype: FieldType.List,
        defaultvalue: [{lang: 'en', text: 'File'}],
        order: 3,
        uid: 'event_material_type',
        config: {
            isRadioGroup: true,
            options: [[{lang: 'en', text: 'File'}], [{lang: 'en', text: 'Link'}]],
        },
    })
    public event_material_type: string;

    constructor(item: any = {}) {
        super(item);

        this.file = Strings.default(item.file);
        this.folder = Strings.default(item.folder);
        this.title = Strings.default(item.title);
        this.description = Strings.default(item.description);
        this.link = Strings.default(item.link);
        this.display_text = Strings.default(item.display_text);
        this.event_id = Lists.default(item.event_id);

        this.event_material_type = fi(Boolean(this.file), 'File', 'Link');
    }

    public routes(): ItemRoutes {
        return {
            list: `/library/${Browser.getParams(ROUTES).folderId === noFolder? "":Strings.default(this.folder, Browser.getParams(ROUTES).folderId)}`,
            edit: `/library/${Strings.default(this.folder,noFolder)}/${this.getType()}/${this.getId()}`,
            create: `/library/${Browser.getParams(ROUTES).folderId}/${this.getType()}/create`,
        };
    }

    public fileInfo(): CMSFile | null {
        return getRecoil(references(this.file)) as any as CMSFile;
    }

    public formOnValidate(evt: FormEvent): any {
        const errors: any = {};
        if (!evt.state.values.title.trim()) {
            errors.title = Messages.FieldIsRequired;
        }
        if (!evt.state.values.event_id.length) {
            errors.event_id = Messages.FieldIsRequired;
        }
        if (evt.state.values.event_material_type === 'File') {
            if (!evt.state.values.file.trim()) {
                errors.file = Messages.FieldIsRequired;
            }
        } else {
            if (!evt.state.values.display_text.trim()) {
                errors.display_text = Messages.FieldIsRequired;
            }
            if (!evt.state.values.link.trim()) {
                errors.link = Messages.FieldIsRequired;
            }
        }
        return errors;
    }

    public formOnRenderField(evt: FormEvent): boolean {
        if (evt.fieldUID === 'file') {
            return evt.state.values.event_material_type === 'File';
        }
        if (evt.fieldUID === 'link' || evt.fieldUID === 'display_text') {
            return evt.state.values.event_material_type === 'Link';
        }
        return true;
    }

    public formOnBeforeSave(evt: FormEvent): any {
        const res = {...evt.state.values};
        if (!res.folder) {
            res.folder = Browser.getParams(ROUTES).folderId;
            if (res.folder === 'undefined' || !res.folder) {
                delete res.folder;
            }
        }
        if (res.event_material_type === 'File') {
            delete (res.link);
            delete (res.display_text);
        } else if (res.event_material_type === 'Link') {
            delete (res.file);
        }
        return res;
    }
}