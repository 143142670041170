import {FormState} from "./model";
import GroupRenderer from "./renderers/GroupRenderer";
import FormRow from "./FormRow";
import React from "react";

export const RenderFormFields = (state: FormState): any => {
    const groups: string[] = [];
    if (!state) {
        return []
    }
    return state.model.fields.map((field) => {
        if (field.flags.hidden) {
            return null;
        }
        if (field.group) {
            if (!groups.includes(field.group)) {
                groups.push(field.group);
                return <GroupRenderer key={`${state.id}/${field.group}`} group={field.group} state={state}/>
            }
            return null;
        }
        return <FormRow key={`${state.id}/${field.uid}`} field={field.uid}/>
    })
}
