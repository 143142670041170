import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { UUID } from '../types';
import { CMSObject } from './__CMSObject';

export class Qualification extends CMSObject {
	public static title = 'Qualification';
	public static autoPublish = true;
	public name: string;
	public id: number;
	public qualification_group: UUID;

	constructor(item: any = {}) {
		super(item);

		this.name = Strings.default(item.name);
		this.id = Numbers.default(item.id);
		this.qualification_group = Strings.default(item.qualification_group);
	}
}
