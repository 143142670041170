import warningIcon from '../../../../../assets/images/warning-notification.svg';
import infoIcon from '../../../../../assets/images/informative-notification.svg';
import criticalIcon from '../../../../../assets/images/critical-notification.svg';
import pdfIconSmall from '../../../../../assets/images/PDF.svg';
import docIconSmall from '../../../../../assets/images/DOC.svg';
import csvIconSmall from '../../../../../assets/images/CSV.svg';
import xlsIconSmall from '../../../../../assets/images/XLS.svg';
import imageIconSmall from '../../../../../assets/images/IMAGE.svg';
import pptIconSmall from '../../../../../assets/images/PPT.svg';
import zipIconSmall from '../../../../../assets/images/ZIP.svg';
import audioIconSmall from '../../../../../assets/images/AUDIO.svg';
import xlsmIconSmall from '../../../../../assets/images/XLSM.svg';
import videoIconSmall from '../../../../../assets/images/VIDEO.svg';
import textIconSmall from '../../../../../assets/images/TEXT.svg';


import pdfIconCard from '../../../../../assets/images/pdf-card.svg';
import csvIconCard from '../../../../../assets/images/csv-card.svg';
import docIconCard from '../../../../../assets/images/doc-card.svg';
import imageIconCard from '../../../../../assets/images/image-card.svg';
import exlIconCard from '../../../../../assets/images/exl.svg';
import pptIconCard from '../../../../../assets/images/ppt-card.svg';
import videoIconCard from '../../../../../assets/images/video-card.svg';
import xlsmIconCard from '../../../../../assets/images/xlsm-card.svg';
import zipIconCard from '../../../../../assets/images/zip-card.svg';
import audioIconCard from '../../../../../assets/images/audio-card.svg';
import linkIconCard from '../../../../../assets/images/external.svg';
import pptAlternativeIconCard from '../../../../../assets/images/PPT.svg';

import cardPlaceholder from '../../../../../assets/images/card-placeholder.png';

const fixPath = /^\/|\.\//;

export const styles = `
    html {
        background: #f7f7f7;
        box-sizing: border-box;
        max-width: 960px;
        border-right: 1px dashed #c6c4c4;
    }

    body {
        max-width: 960px;
        height: 100%;
        font-family: "sourceSansProRegular", sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: #212121;
        position: relative; 
    }

    * {
        box-sizing: border-box;
        resize: none;
    }

    hr {
        margin: 40px 0;
        border: none;
        border-top: 1px solid #e0e0e0;
    }

    a {
        font-family: 'sourceSansProSemiBold', sans-serif;
        font-weight: 600;
        color: #0074bd;
    }

    a:hover {
        color: #0064a4;
    }

    a:visited {
        color: #004b7b;
    }

    p {
        line-height: 20px;
        margin: 0 0 8px 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    h1 {
        font-family: 'sourceSansProBold', sans-serif;
        font-size: 40px;
        line-height: 50px;
        margin: 0 0 32px 0;
    }

    h2 {
        font-family: 'sourceSansProBold', sans-serif;
        font-size: 32px;
        line-height: 40px;
        margin: 0 0 28px 0;
    }

    h3 {
        font-family: 'sourceSansProBold', sans-serif;
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 24px 0;
    }

    h4 {
        font-family: 'sourceSansProBold', sans-serif;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 16px 0;
    }

    h5 {
        font-family: 'sourceSansProBold', sans-serif;
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 8px 0;
    }

    h6 {
        font-family: 'sourceSansProSemiBold', sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 8px 0;
    }

    blockquote {
        padding: 8px 8px 8px 36px;
        line-height: 20px;
        color: #212121;
        font-size: 16px;
        font-style: italic;
        font-weight: 600;
        font-family: 'sourceSansProSemiBold', sans-serif;
        background: white;
        margin: 0 0 32px 0;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
    }

    blockquote:before {
        content: "“";
        float: left;
        margin-left: -31px;
        font-size: 40px;
        opacity: 0.3;
        margin-top: 8px;
    }

    pre {
        background: white;
        margin: 0 0 32px 0;
        padding: 8px;
        border-radius: 6px;
        border: 1px solid #e0e0e0;
    }

	.block-notification {
        border-radius: 4px;
        color: #212121;
        padding: 16px 16px 16px 48px;
        margin-bottom: 40px;
	}
    .block-notification.info {
        background: url(/${infoIcon.replace(fixPath, '')}) no-repeat 16px 18px #F5F7FC;
        border: solid 1px #0274B6;
	}
    .block-notification.warning {
		background: url(/${warningIcon.replace(fixPath, '')}) no-repeat 16px 18px #FCF8F3;
        border: solid 1px #AD5F00;
    }
    .block-notification.critical {
        background: url(/${criticalIcon.replace(fixPath, '')}) no-repeat 16px 18px #fff0ed;
        border: solid 1px #d9221c;
    }
    .block-notification.regular {
        background: #fff;
		padding: 16px;
        border: solid 1px transparent;
    }
	
	.embedded-content-item.item-inline {
        text-decoration: underline;
        color: #0074bd;
        display: inline-flex;
        vertical-align: bottom;
        font-size: 0;
    }
	
    .embedded-content-item.item-inline[data-mce-selected],.embedded-content-item.item-card[data-mce-selected] {
        outline: 1px solid #4099ff;
    }

    .embedded-content-item.item-inline::before {
        content: '';
        margin-right: 1px;
        width: 21px;
        height: 21px;
        display: inline-block;
        margin-top: -1px;
		background-repeat: no-repeat;
		background-position: center center;
        background-size: contain !important;
	}

    .embedded-content-item.item-inline::after,.embedded-content-item.item-card::after {
		content: attr(data-title);
	    font-size: 16px;
	}

    .mce-offscreen-selection {
        left: -2000000px;
        max-width: 1000000px;
        position: absolute;
    }

    .embedded-content-item.item-inline.PDF_document_item::before {
		background-image: url(/${pdfIconSmall.replace(fixPath, '')});
	}
    .embedded-content-item.item-inline.Word_document_item::before {
		background-image:url(/${docIconSmall.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.Excel_document_item::before {
		background-image:url(/${xlsIconSmall.replace(fixPath, '')})
    } 
    .embedded-content-item.item-inline.Excel_macro_document_item::before {
		background-image:url(/${xlsmIconSmall.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.CSV_document_item::before {
		background-image:url(/${csvIconSmall.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.Image_content_item::before {
		background-image:url(/${imageIconSmall.replace(fixPath, '')})
    } 
    .embedded-content-item.item-inline.PPT_content_item::before {
		background-image:url(/${pptIconSmall.replace(fixPath, '')})
    } 
    .embedded-content-item.item-inline.ZIP_content_item::before {
		background-image:url(/${zipIconSmall.replace(fixPath, '')})
    } 
    .embedded-content-item.item-inline.Audio_document_item::before {
		background-image:url(/${audioIconSmall.replace(fixPath, '')})
    }   
    .embedded-content-item.item-inline.Link_item::before {
		background-image:url(/${linkIconCard.replace(fixPath, '')})
    }   
    .embedded-content-item.item-inline.Video_item::before {
		background-image:url(/${videoIconSmall.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.Audio_item::before {
		background-image:url(/${audioIconSmall.replace(fixPath, '')})
    } 
    .embedded-content-item.item-inline.Free_Text_item::before {
		background-image:url(/${textIconSmall.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.PowerPoint_document_item::before {
		background-image:url(/${pptAlternativeIconCard.replace(fixPath, '')})
    }
    .embedded-content-item.item-inline.ZIP_document_item::before {
		background-image:url(/${zipIconSmall.replace(fixPath, '')})
    }
    
	.embedded-content-item.item-card {
		background: url(${cardPlaceholder.replace(fixPath, '')}) no-repeat;
        width: 470px;
        height: 275px;
        display: inline-block;
        background-size: contain;
		position: relative;
		font-size: 0;
	}
    .embedded-content-item.item-card::before {
        content: '';
        margin-right: 1px;
        width: 55px;
        height: 55px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain !important;
        margin-left: 25px;
        margin-top: 25px;
    }
    .embedded-content-item.item-card::after {
        margin-left: 12px;
        position: absolute;
        top: 33px;
        width: 340px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
		font-weight: bold;
    }
    .embedded-content-item.item-card.PDF_document_item::before {
		background-image:url(/${pdfIconCard.replace(fixPath, '')})
    } 
    .embedded-content-item.item-card.Word_document_item::before {
		background-image:url(/${docIconCard.replace(fixPath, '')})
    }
    .embedded-content-item.item-card.Excel_document_item::before {
		background-image:url(/${exlIconCard.replace(fixPath, '')})
    } 
    .embedded-content-item.item-card.Excel_macro_document_item::before {
		background-image:url(/${xlsmIconCard.replace(fixPath, '')})
    }
    .embedded-content-item.item-card.CSV_document_item::before {
		background-image:url(/${csvIconCard.replace(fixPath, '')})
    }
    .embedded-content-item.item-card.Image_content_item::before {
		background-image:url(/${imageIconCard.replace(fixPath, '')})
    } 
    .embedded-content-item.item-card.PPT_contentt_item::before {
		background-image:url(/${pptIconCard.replace(fixPath, '')})
    } 
    .embedded-content-item.item-card.ZIP_content_item::before {
		background-image:url(/${zipIconCard.replace(fixPath, '')})
    } 
    .embedded-content-item.item-card.Audio_document_item::before {
		background-image:url(/${audioIconCard.replace(fixPath, '')})
    }   
    .embedded-content-item.item-card.Link_item::before {
		background-image:url(/${linkIconCard.replace(fixPath, '')})
    }   
    .embedded-content-item.item-card.Video_item::before {
		background-image:url(/${videoIconCard.replace(fixPath, '')})
    }
    .embedded-content-item.item-card.Audio_item::before {
		background-image:url(/${audioIconCard.replace(fixPath, '')})
    }
	
    .mce-content-body img::-moz-selection {
        background: none;
    }

    .mce-content-body img::selection {
        background: none;
    }

    .mce-content-body img[data-mce-selected],
    .mce-content-body video[data-mce-selected],
    .mce-content-body audio[data-mce-selected],
    .mce-content-body object[data-mce-selected],
    .mce-content-body embed[data-mce-selected],
    .mce-content-body table[data-mce-selected] {
        outline: 3px solid #b4d7ff;
    }

    .mce-content-body div.mce-resizehandle {
        background-color: #4099ff;
        border-color: #4099ff;
        border-style: solid;
        border-width: 1px;
        box-sizing: border-box;
        height: 10px;
        position: absolute;
        width: 10px;
        z-index: 10000;
    }

    .mce-content-body div.mce-resizehandle:hover {
        background-color: #4099ff;
    }

    .mce-content-body div.mce-resizehandle:nth-of-type(1) {
        cursor: nwse-resize;
    }

    .mce-content-body div.mce-resizehandle:nth-of-type(2) {
        cursor: nesw-resize;
    }

    .mce-content-body div.mce-resizehandle:nth-of-type(3) {
        cursor: nwse-resize;
    }

    .mce-content-body div.mce-resizehandle:nth-of-type(4) {
        cursor: nesw-resize;
    }

    .mce-content-body .mce-resize-backdrop {
        z-index: 10000;
    }

    .mce-content-body .mce-clonedresizable {
        cursor: default;
        opacity: 0.5;
        outline: 1px dashed black;
        position: absolute;
        z-index: 10001;
    }

    .mce-content-body .mce-clonedresizable.mce-resizetable-columns th,
    .mce-content-body .mce-clonedresizable.mce-resizetable-columns td {
        border: 0;
    }

    .mce-content-body .mce-resize-helper {
        background: #555;
        background: rgba(0, 0, 0, 0.75);
        border: 1px;
        border-radius: 3px;
        color: white;
        display: none;
        font-family: sans-serif;
        font-size: 12px;
        line-height: 14px;
        margin: 5px 10px;
        padding: 5px;
        position: absolute;
        white-space: nowrap;
        z-index: 10002;
    }

    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
        color: rgba(34, 47, 62, 0.7);
        content: attr(data-mce-placeholder);
        position: absolute;
    }

    .mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
        left: 1px;
    }

    .mce-content-body[dir=rtl][data-mce-placeholder]:not(.mce-visualblocks)::before {
        right: 1px;
    }

    .mce-visualblocks p,
    .mce-visualblocks h1,
    .mce-visualblocks h2,
    .mce-visualblocks h3,
    .mce-visualblocks h4,
    .mce-visualblocks h5,
    .mce-visualblocks h6,
    .mce-visualblocks div:not([data-mce-bogus]),
    .mce-visualblocks section,
    .mce-visualblocks article,
    .mce-visualblocks blockquote,
    .mce-visualblocks address,
    .mce-visualblocks pre,
    .mce-visualblocks figure,
    .mce-visualblocks figcaption,
    .mce-visualblocks hgroup,
    .mce-visualblocks aside,
    .mce-visualblocks ul,
    .mce-visualblocks ol,
    .mce-visualblocks dl {
        background-repeat: no-repeat;
        border: 1px dashed #bbb;
        margin-left: 3px;
        padding-top: 10px;
    }

    .mce-visualblocks p {
        background-image: url(data:image/gif;base64,R0lGODlhCQAJAJEAAAAAAP///7u7u////yH5BAEAAAMALAAAAAAJAAkAAAIQnG+CqCN/mlyvsRUpThG6AgA7);
    }

    .mce-visualblocks h1 {
        background-image: url(data:image/gif;base64,R0lGODlhDQAKAIABALu7u////yH5BAEAAAEALAAAAAANAAoAAAIXjI8GybGu1JuxHoAfRNRW3TWXyF2YiRUAOw==);
    }

    .mce-visualblocks h2 {
        background-image: url(data:image/gif;base64,R0lGODlhDgAKAIABALu7u////yH5BAEAAAEALAAAAAAOAAoAAAIajI8Hybbx4oOuqgTynJd6bGlWg3DkJzoaUAAAOw==);
    }

    .mce-visualblocks h3 {
        background-image: url(data:image/gif;base64,R0lGODlhDgAKAIABALu7u////yH5BAEAAAEALAAAAAAOAAoAAAIZjI8Hybbx4oOuqgTynJf2Ln2NOHpQpmhAAQA7);
    }

    .mce-visualblocks h4 {
        background-image: url(data:image/gif;base64,R0lGODlhDgAKAIABALu7u////yH5BAEAAAEALAAAAAAOAAoAAAIajI8HybbxInR0zqeAdhtJlXwV1oCll2HaWgAAOw==);
    }

    .mce-visualblocks h5 {
        background-image: url(data:image/gif;base64,R0lGODlhDgAKAIABALu7u////yH5BAEAAAEALAAAAAAOAAoAAAIajI8HybbxIoiuwjane4iq5GlW05GgIkIZUAAAOw==);
    }

    .mce-visualblocks h6 {
        background-image: url(data:image/gif;base64,R0lGODlhDgAKAIABALu7u////yH5BAEAAAEALAAAAAAOAAoAAAIajI8HybbxIoiuwjan04jep1iZ1XRlAo5bVgAAOw==);
    }

    .mce-visualblocks div:not([data-mce-bogus]) {
        background-image: url(data:image/gif;base64,R0lGODlhEgAKAIABALu7u////yH5BAEAAAEALAAAAAASAAoAAAIfjI9poI0cgDywrhuxfbrzDEbQM2Ei5aRjmoySW4pAAQA7);
    }

    .mce-visualblocks section {
        background-image: url(data:image/gif;base64,R0lGODlhKAAKAIABALu7u////yH5BAEAAAEALAAAAAAoAAoAAAI5jI+pywcNY3sBWHdNrplytD2ellDeSVbp+GmWqaDqDMepc8t17Y4vBsK5hDyJMcI6KkuYU+jpjLoKADs=);
    }

    .mce-visualblocks article {
        background-image: url(data:image/gif;base64,R0lGODlhKgAKAIABALu7u////yH5BAEAAAEALAAAAAAqAAoAAAI6jI+pywkNY3wG0GBvrsd2tXGYSGnfiF7ikpXemTpOiJScasYoDJJrjsG9gkCJ0ag6KhmaIe3pjDYBBQA7);
    }

    .mce-visualblocks blockquote {
        background-image: url(data:image/gif;base64,R0lGODlhPgAKAIABALu7u////yH5BAEAAAEALAAAAAA+AAoAAAJPjI+py+0Knpz0xQDyuUhvfoGgIX5iSKZYgq5uNL5q69asZ8s5rrf0yZmpNkJZzFesBTu8TOlDVAabUyatguVhWduud3EyiUk45xhTTgMBBQA7);
    }

    .mce-visualblocks address {
        background-image: url(data:image/gif;base64,R0lGODlhLQAKAIABALu7u////yH5BAEAAAEALAAAAAAtAAoAAAI/jI+pywwNozSP1gDyyZcjb3UaRpXkWaXmZW4OqKLhBmLs+K263DkJK7OJeifh7FicKD9A1/IpGdKkyFpNmCkAADs=);
    }

    .mce-visualblocks pre {
        background-image: url(data:image/gif;base64,R0lGODlhFQAKAIABALu7uwAAACH5BAEAAAEALAAAAAAVAAoAAAIjjI+ZoN0cgDwSmnpz1NCueYERhnibZVKLNnbOq8IvKpJtVQAAOw==);
    }

    .mce-visualblocks figure {
        background-image: url(data:image/gif;base64,R0lGODlhJAAKAIAAALu7u////yH5BAEAAAEALAAAAAAkAAoAAAI0jI+py+2fwAHUSFvD3RlvG4HIp4nX5JFSpnZUJ6LlrM52OE7uSWosBHScgkSZj7dDKnWAAgA7);
    }

    .mce-visualblocks figcaption {
        border: 1px dashed #bbb;
    }

    .mce-visualblocks hgroup {
        background-image: url(data:image/gif;base64,R0lGODlhJwAKAIABALu7uwAAACH5BAEAAAEALAAAAAAnAAoAAAI3jI+pywYNI3uB0gpsRtt5fFnfNZaVSYJil4Wo03Hv6Z62uOCgiXH1kZIIJ8NiIxRrAZNMZAtQAAA7);
    }

    .mce-visualblocks aside {
        background-image: url(data:image/gif;base64,R0lGODlhHgAKAIABAKqqqv///yH5BAEAAAEALAAAAAAeAAoAAAItjI+pG8APjZOTzgtqy7I3f1yehmQcFY4WKZbqByutmW4aHUd6vfcVbgudgpYCADs=);
    }

    .mce-visualblocks ul {
        background-image: url(data:image/gif;base64,R0lGODlhDQAKAIAAALu7u////yH5BAEAAAEALAAAAAANAAoAAAIXjI8GybGuYnqUVSjvw26DzzXiqIDlVwAAOw==);
    }

    .mce-visualblocks ol {
        background-image: url(data:image/gif;base64,R0lGODlhDQAKAIABALu7u////yH5BAEAAAEALAAAAAANAAoAAAIXjI8GybH6HHt0qourxC6CvzXieHyeWQAAOw==);
    }

    .mce-visualblocks dl {
        background-image: url(data:image/gif;base64,R0lGODlhDQAKAIABALu7u////yH5BAEAAAEALAAAAAANAAoAAAIXjI8GybEOnmOvUoWznTqeuEjNSCqeGRUAOw==);
    }
`;

export default styles;