import React, {useCallback, useMemo} from "react"
import {Field, FieldType} from "../../../cms/models/__ModelInfo";
import {fi} from "../../../utils/helpers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {Strings} from "../../../utils/strings";
import {LocalizedString} from "../../../cms/types";
import {useFormField} from "../state";

const RadioGroupRenderer = ({field}: { field: Field }) => {
    const formField = useFormField(field.uid);

    const options = useMemo(() => {
        // We need to display
        if (field.config.options && field.config.options.length) {
            return field.config.options;
        }
        if (field.fieldtype === FieldType.Boolean) {
            // When a boolean field is marked to be displayed as RadioGroup, then we should display `( ) Yes ( ) No`
            // radio buttons instead of a single `[ ] label` checkbox so there
            return Strings.localized("Yes", "No")
        }

        return [];
    }, [field])

    const getValue = useCallback((option: LocalizedString): any => {
        if (field.fieldtype === "boolean" && !field.config.options) {
            return Strings.en(option) === "Yes" || Strings.en(option) === "true";
        }
        return Strings.en(option)
    }, [field])

    return (
        <>
            <label className={fi(field.flags.required, 'required')}>{field.name}</label>
            <RadioGroup row
                        aria-readonly={formField.previewMode}
                        name={field.uid}
                        defaultValue={field.zeroValue()}
                        value={formField.value}
                        onChange={(_, val) => formField.setValue(val)}>
                {options.map((option, idx) => (
                    <FormControlLabel key={idx}
                                      value={getValue(option)}
                                      label={Strings.en(option)}
                                      control={<Radio size="small"/>}/>
                ))}
            </RadioGroup>
        </>
    )
}

export default RadioGroupRenderer