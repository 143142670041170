import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Types, UUID} from '../types';
import {formField} from '../../utils/decorators';
import {FieldType} from './__ModelInfo';
import {Lists} from '../../utils/lists';
import {IFormEvents} from '../../components/Form/model';

@widgetConfig({
	group: WidgetGroup.Content,
	name: "Resource finder",
	description: "Used for searching resources.\n• It can only be added to the page once.",
	constrains: {
		once: true,
	}
})
export class ResourceFinderWidget extends BaseWidget implements IFormEvents{
	@formField({
		name: "Content Types",
		fieldtype: FieldType.Reference,
		flags: {multiple: true},
		config: {refModel: Types.CONTENT_TYPE}
	})
	public contentTypes: UUID[];
	constructor(item: any = {}) {
		super(item);
		this.type = WidgetType.ResourceFinder
		this.contentTypes = Lists.default(item.content_types)
	}

	public editable() {
		return true;
	}
}