import styled from "@emotion/styled";
import React, { useEffect } from "react";
import {ColumnFilterProps} from './ColumnFilter';
import Input from "@mui/material/Input";
import {ColumnPreference} from "../preferences";
import {Strings} from "../../../utils/strings";
import {fi} from "../../../utils/helpers";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
`

const SearchBox = styled(Input)`
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border-light);
  border-radius: 0;
`

let timer: any;

const TextFilter = (props: ColumnFilterProps & { preferences: ColumnPreference }) => {
    const [value, setValue] = React.useState(Strings.default(props.preferences.filter));

    useEffect(() => {
        setValue(Strings.default(props.preferences.filter));
    }, [props.preferences.filter])

    const onChange = (e) => {
        clearTimeout(timer);
        setValue(e.target.value)
        timer = setTimeout(() => {
            const val = e.target.value.toLowerCase().trim()
            props.onChange!(fi(val, val, null));
        }, 500);
    }

    return (
        <Wrapper>
            <SearchBox type='search' value={value} autoFocus name='filter-options'
                       placeholder='Search ...' onInput={onChange} data-testid='text-filter-input'/>
        </Wrapper>
    );
};

export default TextFilter;
