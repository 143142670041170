import LogoCI from '../assets/images/international.svg';
import LogoOCR from '../assets/images/ocr.svg';
import LogoDefault from '../assets/images/logo.png';
import {Types} from "../cms/types";

export const OCR_STREAM = 'OCR';
export const INTERNATIONAL_STREAM = 'International';
export const ALL_STREAMS = 'CSD';

export const BusinessStreams = [
	{
		id: 'default',
		logo: LogoDefault,
		name: 'Cambridge Assessment'
	},
	{
		id: OCR_STREAM,
		logo: LogoOCR,
		name: 'Cambridge Assessment - OCR'
	},
	{
		id: INTERNATIONAL_STREAM,
		logo: LogoCI,
		name: 'Cambridge Assessment - International'
	}
]

// Page routes

export const ROUTE_NOT_FOUND = '/error-not-found';
export const ROUTE_UNAUTHORIZED = '/error-unauthorized';
export const ROUTE_SERVER_ERROR = '/error-unknown';
export const ROUTE_CONTROL_PANEL = '/control-panel/:page';
export const ROUTE_DASHBOARD = '/';
export const ROUTE_LIST_ROUTE = '/:type';
export const ROUTE_OBJECT_ADD = '/:type/create'
export const ROUTE_OBJECT_EDIT = '/:type/:itemId'
export const ROUTE_LIBRARY = '/library';
export const ROUTE_LIBRARY_BROWSE = '/library/:folderId'
export const ROUTE_LIBRARY_BULK = '/library/bulk'
export const ROUTE_LIBRARY_ADD = '/library/:folderId/:type/create';
export const ROUTE_LIBRARY_EDIT = '/library/:folderId/:type/:itemId';


export const ROUTE_PAGES = '/page';
export const ROUTE_PAGE_ADD = '/page/create'
export const ROUTE_PAGE_ADD_CHILD = '/:type/:parentId/:itemId'
export const ROUTE_PAGE_EDIT = '/page/:itemId'
export const ROUTE_CONTENT_TYPE = '/content_type'
export const ROUTE_SUBJECT_UPDATES = '/subject_information'
export const ROUTE_USEFUL_LINKS = '/useful_link'
export const ROUTE_CONTENT_GROUPS = '/content_group'
export const ROUTE_PRODUCT_MAPPING = '/product'
export const ROUTE_NOTIFICATIONS = '/notification'
export const ROUTE_KEY_DATES = '/key_dates_file'


// control panel routes
export const ROUTE_CP_STATUS = "/status"
export const ROUTE_CP_SEARCH = "/search"
export const ROUTE_CP_FLAGS = "/flags"
export const ROUTE_CP_API_KEYS = "/keys"
export const ROUTE_CP_JOBS = "/jobs"
export const ROUTE_CP_MISC = "/misc"

// Setting keys and defaults
export const AUTH_TOKEN = 'token'
export const USER_SETTINGS = 'user_settings'
export const WIDGETS_SETTINGS = 'widgetExpanded';
export const MENU_WIDTH_SETTING = 'menu';
export const MENU_WIDTH_DEFAULT = 255;
export const MENU_WIDTH_MAX = 1000;

export const DEFAULT_PAGE_SIZE = 50;
export const DEFAULT_QUERY_RESULT = {results: [], total: 0, offset: 0};

export const itemTypes: string[] = [
	Types.DOCUMENT, Types.IMAGE,Types.LINK , Types.EVENT_MATERIAL,Types.AUDIO,Types.VIDEO
]


// eslint-disable-next-line no-use-before-define
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
