import React, {useCallback, useMemo, useState} from 'react';
import {useRecoilState} from 'recoil';
import {createWidgetState, editWidgetState} from '../../../../../state/state';
import {useModalDialog} from '../../../../ModalDialogs/effect';
import {ModelInfo} from '../../../../../cms/models/__ModelInfo';
import {ActionButton} from '../../../../commons/ActionButton';
import Create from '@mui/icons-material/Create';
import Delete from '@mui/icons-material/Delete';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import {fi} from '../../../../../utils/helpers';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Spacer from '../../../../commons/Spacer';
import Form from '../../../Form';
import {BaseWidget} from '../../../../../cms/models/PageWidget';
import styled from '@emotion/styled';
import RenderWidgetForm from './WidgetForm';
import {Draggable} from 'react-beautiful-dnd';
import {getRecoil, resetRecoil} from '../../../../../state/recoilNexus';

const WidgetItemWrapper = styled.div`
    border: 1px solid var(--color-border);
    border-radius: 4px;
    background: white;
    margin-bottom: 16px;
    cursor: default;
    user-select: none;

    button:disabled {
        opacity: 0.1;
    }
`;

const DragHandler = styled.span`
    line-height: 100%;

    svg {
        height: 18px;
        margin-top: 4px;
        color: var(--color-backdrop);
    }
`;

const Header = styled.div<{group: string}>`
    display: flex;
    align-items: center;
    padding: 16px 8px;
    border-left: 4px solid ${props => `${{
        filters: '#ad5f0066',
        content: '#2b800066',
        other: '#0064A466',
    }[props.group]}`};
        //background: ${props => `${{filters: '#ad5f0003', content: '#2b800003', other: '#0064A403'}[props.group]}`};

    label {
        margin-bottom: 0;
    }

    button {
        svg {
            height: 18px;
        }
    }
`;

export type WidgetState = {
	id: string,
	type: string,
	widget: BaseWidget,
	expanded: boolean,
	createMode: boolean
}

interface WidgetItemProps {
	state: WidgetState,
	index: number,
	list: WidgetState[],
	onDelete: (id: string) => void
	onChange: (id: string, config: any) => void
}

const WidgetItem = ({state, index, onDelete, onChange}: WidgetItemProps) => {
	const [expanded, setExpanded] = useState(state.expanded);
	const [editWidget, setEditWidget] = useRecoilState(editWidgetState);

	const {confirmation} = useModalDialog();
	const formId = 'edit-widget-form';
	const editModel = useMemo(() => {
		return new ModelInfo({
			model: {
				uid: state.widget.type + '_widget',
				name: 'Configure widget',
			},
			fields: [],
		});
	}, [state.widget]);

	const onCancel = useCallback(() => {
		if (getRecoil(createWidgetState)) {
			onDelete(state.id);
		}

		resetRecoil(createWidgetState);
		resetRecoil(editWidgetState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleWidget = (evt) => {
		evt.preventDefault();
		evt.stopPropagation();
		if (!state.widget.editable()) {
			return;
		}
		setExpanded((val) => !val);
	};

	const onEdit = useCallback(() => {
		setEditWidget(state.id);
		// eslint-disable-next-line
	}, [state.widget]);

	const handleDelete = useCallback(() => {
		confirmation(<span>Are you sure you want to remove widget <strong>{state.widget.getConfig().name}</strong>?</span>, async () => {
			onDelete(state.id);
		});
		// eslint-disable-next-line
	}, [state.widget]);

	const onChanged = useCallback((config: any) => {
		onChange(state.id, config);

		resetRecoil(createWidgetState);
		resetRecoil(editWidgetState);

		// eslint-disable-next-line
	}, [state.widget]);

	const actionButtons = useMemo(() => {
		const buttons: any[] = [];
		if (!editWidget) {
			buttons.push(
				<ActionButton key={1} disabled={!state.widget.editable()} onClick={() => onEdit()}
							  data-testid={'edit-widget'}
							  title={fi(state.widget.editable(), 'Edit widget', 'This widget is not configurable')}>
					<Create fontSize='small' />
				</ActionButton>,
			);

			buttons.push(
				<ActionButton key={2} onClick={() => handleDelete()} data-testid={'delete-widget'}
							  title='Delete widget'>
					<Delete fontSize='small' />
				</ActionButton>,
			);
		}

		buttons.push(
			<ActionButton key={3} onClick={toggleWidget} disabled={!state.widget.editable()}
						  title={fi(state.widget.editable(), 'Show/hide configuration', 'This widget is not configurable')}>
				{fi(expanded, <ExpandLessRounded />, <ExpandMoreRounded />)}
			</ActionButton>,
		);
		return buttons;
		// eslint-disable-next-line
	}, [state.widget, editWidget]);

	return (
		<Draggable draggableId={state.id} index={index} key={`${index}-${state.id}`} isDragDisabled={false}>
			{(providedItem, snapshotItem) => (
				<WidgetItemWrapper key={`${index}-${state.id}`} {...providedItem.draggableProps}
								   ref={providedItem.innerRef}
								   title={state.widget.getConfig().description}
								   data-testid={`widget-item-${state.widget.type}`}>
					<Header group={state.widget.getConfig().group!} key={`header-${index}-${state.id}`}>
						<DragHandler {...providedItem.dragHandleProps} title='Drag widget to reorder'>
							<DragIndicatorIcon />
						</DragHandler>
						<label>{state.widget.getTitle()}</label>
						<Spacer />
						{actionButtons}
					</Header>
					{expanded && (editWidget !== state.id) && state.widget.preview()}
					{editWidget === state.id && (
						<Form model={editModel} id={formId} object={state.widget}>
							<RenderWidgetForm onChange={onChanged} onCancel={onCancel} />
						</Form>
					)}
				</WidgetItemWrapper>
			)}
		</Draggable>
	);
};

export default WidgetItem;