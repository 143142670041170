import React, {useMemo, useRef} from "react"
import {BoxContainer} from "./components/BoxContainer";
import {selector, useRecoilValue} from "recoil";
import Client from "../../cms/client";
import {Types, UUID} from "../../cms/types";
import {selectedObject} from "../../state/state";
import {CMSObject} from "../../cms/models/__CMSObject";
import styled from "@emotion/styled";
import {Page} from "../../cms/models/Page";
import {ContentGroup} from "../../cms/models/ContentGroup";
import TextAction from "../commons/TextAction";
import NavigateNext from '@mui/icons-material/NavigateNext';
import {Browser} from "../../utils/browser";
import {Tree} from "../TreeMenu/Tree";
import {findTreeNode} from "../TreeMenu/utils";

const EmptyState = styled.p`
  text-align: center;
  padding: 16px;
  color: var(--color-backdrop)
`

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

const ReferenceRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 20px;
    color: var(--color-border)
  }

  span {
    opacity: 0.5;
  }

  span:last-of-type {
    opacity: 1;

    & + svg {
      display: none;
    }
  }
`

const displayForTypes = [
    Types.TOPIC, Types.CONTENT_GROUP, Types.CONTENT_TYPE, Types.SUBJECT_UPDATES, Types.USEFUL_LINK,
    Types.DOCUMENT, Types.VIDEO, Types.AUDIO, Types.TEXT, Types.IMAGE, Types.LINK, Types.EVENT_MATERIAL,
]

const dependencies = selector({
    key: 'reference-dependencies',
    get: async ({get}) => {
        const selected = get(selectedObject)
        if (!selected){
            return [];
        }

        if (!displayForTypes.includes(selected.getType())) {
            return [];
        }

        const res = await Client.query<CMSObject>({
            types: [Types.PAGE, Types.CONTENT_GROUP],
            limit: -1
        })
        return res.results;
    }
})

const ReferencesBox = () => {
    const deps = useRecoilValue(dependencies)
    const item = useRecoilValue(selectedObject)
    const ref = useRef<Tree>()

    const tree = useMemo(() => {
        ref.current = new Tree('page', deps.filter(dep => dep.getType() === Types.PAGE) as Page[])
        return ref.current?.getTree()
    }, [deps])

    const hidden = useMemo(() => {
        if (!item) {
            return true;
        }
        return !displayForTypes.includes(item.getType())
    }, [item])

    const references: Page[] = useMemo(() => {
        if (hidden || !item) return [];
        let result: Page[] = [];

        const pages: Page[] = deps.filter(dep => dep.getType() === Types.PAGE) as Page[];
        const groups: ContentGroup[] = deps.filter(dep => dep.getType() === Types.CONTENT_GROUP) as ContentGroup[];


        const contentTypeOnPage = (contentTypeId: UUID) => {
            return pages.filter(page => (
                page.configList().find(widget => (
                    ( // is a straight content type widget
                        widget.type === 'content_type' &&
                        widget.contentType === contentTypeId
                    )
                    ||
                    ( // is a content group widget
                        widget.type === 'content_group' &&
                        groups.find(group => (
                            group.getId() === widget.contentGroup &&
                            group.content_types.includes(contentTypeId)
                        ))
                    )
                ))
            ))
        }


        switch (item.getType()) {
            case Types.TOPIC:
                result = pages.filter(page => page.configList().find(widget => widget.type === 'text' && widget.topic === item.getId()))
                break;
            case Types.CONTENT_GROUP:
                result = pages.filter(page => page.configList().find(widget => widget.type === 'content_group' && widget.contentGroup === item.getId()));
                break
            case Types.CONTENT_TYPE:
                result = contentTypeOnPage(item.getId());
                break
            case Types.SUBJECT_UPDATES:
                result = pages.filter(page => page.configList().find(widget => widget.type === 'subject_update'))
                break
            case Types.USEFUL_LINK:
                result = pages.filter(page => page.configList().find(widget => widget.type === 'links' && widget.links.includes(item.getId())))
                break
            case Types.DOCUMENT:
            case Types.VIDEO:
            case Types.AUDIO:
            case Types.IMAGE:
            case Types.LINK:
                result = Array.from(new Set([
                    ...pages.filter(page => page.configList().find(widget => widget.type === 'content_items' && widget.items.includes(item.getId()))),
                    ...contentTypeOnPage((item as any).content_type)
                ]))
                break;
            case Types.EVENT_MATERIAL:
                result = pages.filter(page => page.configList().find(widget => widget.type === 'events'))
        }
        return result;
    }, [hidden, item, deps])

    const renderPageReference = (page: Page, idx: number) => {
        const tmp = [page];
        const item = findTreeNode(tree, page.getId());
        if (item) {
            let parent = item.parent
            while (parent) {
                tmp.unshift(parent.object)
                parent = parent.parent
            }
        }

        return (
            <ReferenceRow key={idx}>
                {tmp.map((p, i) => (
                    <React.Fragment key={i}>
                        <TextAction onClick={() => Browser.navigate(p.routes().edit!)}>{p.displayLabel()}</TextAction>
                        <NavigateNext />
                    </React.Fragment>
                ))}
            </ReferenceRow>
        )
    }

    return (
        <BoxContainer title='Pages displayed on' id='references' hidden={hidden}>
            {!references.length && (
                <EmptyState>There are no pages displaying this item.</EmptyState>
            )}
            {references.length > 0 && (
                <Wrapper>
                    {references.map(renderPageReference)}
                </Wrapper>
            )}
        </BoxContainer>
    )
}

export default ReferencesBox