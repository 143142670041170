import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import React from "react";
import {Lists} from "../../utils/lists";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {usePopupState} from 'material-ui-popup-state/hooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from "@emotion/styled";
import {Strings} from "../../utils/strings";

type MenuItem = {
    id: string;
    label: string | any;
}

type MenuButtonProps = {
    label: any;
    title?: string;
    disabled?: boolean;
    onClick: (id: string) => void;
    menuItems: Array<MenuItem>;
}

const StyledMenu = styled(Menu)`
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      padding: 16px 8px;
      width: 100%;
      min-width: 200px;
      font-family: var(--font-semi-bold);
      justify-content: flex-start;
      color: var(--color-blue);
      cursor: pointer;

      &:hover {
        background: var(--color-background);
      }
    }
  }
`

const MenuButton = (props: MenuButtonProps) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'item-action-menu',
    });

    return (
        <>
            <Button key={'1'}
                    title={Strings.default(props.title)}
                    disabled={props.disabled}
                    variant='contained'
                    color='primary'
                    {...bindTrigger(popupState)}>
                {props.label} <ExpandMoreIcon/>
            </Button>
            <StyledMenu key={2} {...bindMenu(popupState)}>
                {Lists.default<MenuItem>(props.menuItems).map((item, idx) => (
                    <li key={`${item}-${idx}`} onClick={() => props.onClick(item.id)}>
                        {item.label}
                    </li>
                ))}
            </StyledMenu>
        </>
    )
}

export default MenuButton;