import React, {useEffect} from 'react';
import {Browser} from '../utils/browser';
import {ClassOf, InstanceOf} from '../cms/models';
import {Lists} from '../utils/lists';
import Button from '@mui/material/Button';
import {Strings} from '../utils/strings';
import MenuButton from '../components/commons/MenuButton';
import {useRecoilValue} from 'recoil';
import {currentTableState} from '../components/TableComponent/state';
import {visibleFormStates} from '../components/Form/state';

const ActionButtons = () => {
    const tableState = useRecoilValue(currentTableState);
    const formState = useRecoilValue(visibleFormStates);
    const [buttons, setButtons] = React.useState<any[]>([]);

    const navigateTo = (itemType: string) => {
        const objectInstance = InstanceOf(itemType, false);
        Browser.navigate(objectInstance.routes().create);
    };

    const resolveButtons = async () => {
        let buttons: any[] = [];
        if (tableState) {
            const objectClass = ClassOf(tableState.config.tableType, false);
            const objectInstance = InstanceOf(tableState.config.tableType, false);

            if (!objectClass) {
                return [];
            }

            if (tableState.config.additionalButtons) {
                buttons = buttons.concat(tableState.config.additionalButtons);
            }

            const cantCreateReason = objectInstance.canCreate();

            const label = <>Create {Strings.default(objectClass.title).toLowerCase()}</>;
            if (Lists.default(tableState.config.itemTypes).length > 1) {
                const menuItems: any[] = tableState.config.itemTypes!.map(item => {
                    const itemInstance = ClassOf(item);
                    return {id: item, label: itemInstance.title};
                });
                buttons.push(
                    <MenuButton key={'1'}
                                disabled={Boolean(cantCreateReason)}
                                title={Strings.default(cantCreateReason, 'Select the type of item you wish to create')}
                                onClick={(item) => navigateTo(item)}
                                label={label}
                                menuItems={menuItems}
                    />,
                );

            } else {
                buttons.push(
                    <Button key={'1'}
                            disabled={Boolean(cantCreateReason)}
                            title={Strings.default(cantCreateReason, 'Create a new item of this type')}
                            variant="contained"
                            color="primary"
                            onClick={() => navigateTo(tableState.config.tableType)}>
                        {label}
                    </Button>,
                );
            }
        }

        if (formState && Object.keys(formState).length > 0) {
            Object.keys(formState).forEach((key) => {
                const buttonsReferences = formState[key];
                if (buttonsReferences && buttonsReferences.length > 0) {
                    buttons = buttons.concat(buttonsReferences);
                }
            });
        }

        setButtons(buttons);
    };

    useEffect(() => {
        resolveButtons().catch();
        return () => {
            setButtons([]);
        };
        // eslint-disable-next-line
    }, [tableState, formState]);

    if (!tableState && (formState && Object.keys(formState).length === 0)) {
        return null;
    }

    return (
        <>{buttons.map((b, idx) => (<React.Fragment key={idx}>{b}</React.Fragment>))}</>
    );
};

export default ActionButtons;