import styled from "@emotion/styled";
import React from "react";

const Element = styled.div`
    flex-grow: 1;
`

const Spacer = () => {
    return <Element />
}

export default Spacer;