import {CMSObject} from "./__CMSObject";
import {tableColumn, tableConfig, tableIgnored, tableHidden} from "../../utils/decorators";
import {Types, UUID} from "../types";
import {ModelInfo} from "./__ModelInfo";
import {getRecoil, getRecoilPromise} from "../../state/recoilNexus";
import {cmsModelsSelector} from "../../state/models";
import {ColumnType} from "../../components/TableComponent/renderers";
import React from "react";
import {fi} from "../../utils/helpers";
import {sessionAtom} from "../../state/session";
import {Messages} from "../../utils/messages";
import Client from "../client";

const itemTypes: string[] = [Types.QUALIFICATION_GROUP_MAPPING, Types.SUBJECT_GROUP_MAPPING]

// ProductMapping is a wrapper around our product mappings to display them in a single table
@tableConfig({
    itemTypes
})
export class ProductMapping extends CMSObject {
    public static title = "Mapping";
    public static autoPublish = true;

    @tableIgnored // we don't show this field, we show through __itemAvailable()
    public disabled: boolean = false;

    public qualification_group_mapping: UUID = '';

    @tableHidden
    public specification_group: UUID[] = [];
    @tableHidden
    public qualification: UUID[] = [];

    @tableHidden
    public assessment: UUID[] = [];
    @tableHidden
    public qualification_size: UUID[] = [];

    constructor(item: any = {}) {
        super(item);
    }

    @tableColumn({
        order: 2,
        label: 'Disabled',
        default: true,
        sortable: true,
        field: 'disabled',
        type: ColumnType.Boolean,
    })
    public __itemAvailable(plain?: boolean): string | React.ReactNode {
        return fi(this.disabled, 'Yes', 'No')
    }

    @tableColumn({
        order: 0.5,
        label: 'Type',
        default: true,
        sortable: true,
        field: '__type',
        type: ColumnType.List,
    })
    public __itemType(plain?: boolean): string | React.ReactNode {
        return fi(this.getType() === Types.QUALIFICATION_GROUP_MAPPING, "Qualification group", "Subject group")
    }

    public async modelInfo(): Promise<ModelInfo> {
        const models = await Promise.all(itemTypes.map(async (type) => {
            return getRecoilPromise(cmsModelsSelector(type));
        }))

        const model: ModelInfo = new ModelInfo({
            model: {
                uid: "product",
                name: "Product mapping",
                description: "Product mapping"
            }
        })
        models.forEach(m => {
            m.fields.forEach(f => {
                if (!model.fields.find(fld => fld.uid === f.uid)) {
                    model.fields.push(f);
                }
            })
        })
        return model
    }

    public async canDelete(): Promise<string> {
        const user = getRecoil(sessionAtom)
        if (!user || !user.isPublisher()) {
            return Messages.NoPermissionToDelete
        }
        const usage = await Client.checkUsage(this.getId());
        if (usage.items_count > 0) {
            return Messages.CantDeletePublishedAssociations;
        }
        return '';
    }
}