import { atom, DefaultValue, selector } from 'recoil';
import Client from '../cms/client';
import { UserSession } from '../cms/models/Session';
import { Browser } from '../utils/browser';
import { cacheBuster } from './state';

export const sessionAtom = atom<UserSession | null>({
	key: 'sessionAtom',
	default: null,
});

export const sessionSelector = selector<UserSession | null>({
	key: 'sessionSelector',
	get: async ({get}) => {
		get(cacheBuster('auth.session'))
		const storedSession = get(sessionAtom);

		if (storedSession) {
			return storedSession;
		}

		let session: UserSession | null = null;
		// with these 2 query params present it means we just returned from SAP CDC after logging in
		if (Browser.hasQueryParam('code') && Browser.hasQueryParam('state')) {
			try {
				session = await Client.login();
			} catch (e) {
				console.error(e);
			}
			return session;
		}

		// restoring session from storage
		const storedToken = Client.getAuthToken();
		if (storedToken) {
			Client.setAuthToken(storedToken);
		}
		sessionStorage.setItem('redirectURL', document.location.pathname);
		try {
			session = await Client.getAccountDetails();
		} catch (error) {
			console.log(error);
		}
		return session;
	},
	set: ({set}, value) => {
		if (value instanceof DefaultValue) {
			return;
		}

		if (value) {
			Client.setAuthToken(value.token);
			set(sessionAtom, value);
		}
	},
});
