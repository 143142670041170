import React from "react"
import {Field} from "../../../cms/models/__ModelInfo";
import {fi} from "../../../utils/helpers";

const NumberRenderer = ({field}: { field: Field }) => {
    return (
        <>
            <label className={fi(field.flags.required, 'required')}>{field.name}</label>

        </>
    )
}

export default NumberRenderer