import { Types } from '../../cms/types';
import {
	ROUTE_CONTENT_GROUPS,
	ROUTE_CONTENT_TYPE,
	ROUTE_LIBRARY,
	ROUTE_KEY_DATES,
	ROUTE_NOTIFICATIONS,
	ROUTE_PAGES,
	ROUTE_PRODUCT_MAPPING,
	ROUTE_SUBJECT_UPDATES,
	ROUTE_USEFUL_LINKS,
} from '../../utils/constants';

export const MENU_ITEMS = [
	{label: 'Media Library', path: ROUTE_LIBRARY},
	{label: 'Content Types', path: ROUTE_CONTENT_TYPE},
	{label: 'Subject Updates', path: ROUTE_SUBJECT_UPDATES},
	{label: 'Useful Links', path: ROUTE_USEFUL_LINKS},
	{label: 'Content Groups', path: ROUTE_CONTENT_GROUPS},
	{label: 'Pages', path: ROUTE_PAGES},
	{label: 'Product Data Mapping', path: ROUTE_PRODUCT_MAPPING},
	{label: 'Notifications', path: ROUTE_NOTIFICATIONS},
	{label: 'Key Dates', path: ROUTE_KEY_DATES},
	{label: 'Topics', path: `/${Types.TOPIC}`},
	{label: 'Help Info', path: `/${Types.HELP_INFO}`},
];
