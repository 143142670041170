import {UUID} from "../types";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {formField, formHidden, linkedFormField} from "../../utils/decorators";
import {CMSObject} from "./__CMSObject";
import {FieldType} from "./__ModelInfo";
import { Messages } from '../../utils/messages';

enum globalOptions {
	"GLOBAL"= "Global notification area",
	"PAGE" = "On page notification"
}
export class Notification extends CMSObject implements IFormEvents {
	public static title = "Notifications";

	public title: string;
	public description: string;
	public type: string;
	@formHidden()
	public closable: boolean = true;

	@formField({
		fieldtype: FieldType.Text,
		name: "Display notification as",
		config: {
			isRadioGroup: true,
			options:[
				[{lang:"en",text:globalOptions.GLOBAL}],
				[{lang:"en",text:globalOptions.PAGE}]
			]
		}
	})
	maskClosable: string = globalOptions.GLOBAL;


	public available_for_restricted: boolean;

	@formHidden()
	public qualification_group_mapping: string[];
	@formHidden()
	public qualification_mapping: string[];

	@formField({
		name: 'Product mapping',
		fieldtype: FieldType.ProductData,
		order: 5.5,
		flags: {required: true, subjectOnly: true}
	})
	public productData: string = '';

	@formField({
		fieldtype: FieldType.Boolean,
		flags: {hidden: true}
	})
	public all_assessments: boolean = false;

	@linkedFormField('maskClosable')
	public page: UUID[];


	constructor(obj: any = {}) {
		super(obj);

		this.title = Strings.default(obj.title);
		this.description = Strings.default(obj.description);
		this.type = Strings.default(obj.type, 'Information');

		this.qualification_mapping = Lists.default(obj.qualification_mapping);
		this.qualification_group_mapping = Lists.default(obj.qualification_group_mapping);

		this.maskClosable = obj.closable?globalOptions.GLOBAL:globalOptions.PAGE;

		this.available_for_restricted = Boolean(obj.available_for_restricted)
		this.page = Lists.default(obj.page, []);

		if (this.qualification_mapping.length === 0 && this.qualification_group_mapping.length === 0) {
			this.all_assessments = true;
			this.productData = 'all_assessments';
		} else {
			this.productData = [...this.qualification_group_mapping, ...this.qualification_mapping].join(',')
		}
	}

	public async canDelete(): Promise<string> {
		if (this.isPublished()) {
			return Messages.CantDeletePublishedItem;
		}
		return '';
	}

	public formOnRenderField(evt: FormEvent): boolean {
		if (evt.fieldUID === "page") {
			// we only render page field if is closable
			return evt.state.values.maskClosable === globalOptions.PAGE
		}
		return true;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const result = {...evt.state.values}
		// closable notifications don't have a page

		if (Lists.default(result.qualification_mapping).length === 0) {
			delete(result.qualification_mapping)
		}
		if (Lists.default(result.qualification_group_mapping).length === 0) {
			delete(result.qualification_group_mapping)
		}
		result.closable = result.maskClosable === globalOptions.GLOBAL

		if (Boolean(result.closable)) {
			delete(result.page)
		}
		delete(result.all_assessments)
		delete(result.productData)
		return result;
	}
}