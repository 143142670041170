import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {useRecoilState, useRecoilValue} from 'recoil';
import {BulkTableState, bulkTableState, ConfigKey, DefaultColumnWidths} from './state';
import {fi} from '../../utils/helpers';
import {getRecoil, setRecoil} from '../../state/recoilNexus';
import {BulkFile} from '../../cms/models/BulkFile';
import {Lists} from '../../utils/lists';
import {ProductMapped} from '../../cms/models/__ProductMapped';
import {useLocalStorage} from '../../utils/hooks';
import {bulkUploadScrollState} from "../../state/state";

const SelectedCell = styled.div`
  position: fixed;
  border: 2px solid var(--color-blue);
  pointer-events: none;
  z-index: 1;
`;

const Handler = styled.div`
  position: absolute;
  cursor: crosshair;
  right: -4px;
  bottom: -4px;
  pointer-events: all;
  background-color: var(--color-blue);
  border: 1px solid white;
  z-index: 2;
  width: 8px;
  height: 8px;
`;

const range:number[] = [];

const CellHighlight = () => {
    const [cfg] = useLocalStorage(ConfigKey, DefaultColumnWidths);
    const cellRef = useRef<any>();
    const handlerRef = useRef<any>();
    const tableRef = useRef<any>();
    const [state, setState] = useRecoilState(bulkTableState);
    const stateScroll = useRecoilValue(bulkUploadScrollState);
    const [refresh, setRefresh] = useState<number>(0);
    const [dragging, setDragging] = useState<boolean>(false);
    const selectCellHandler = useCallback((e) => {
        if (e.target.dataset.cell === 'true') {
            if (e.type === 'dblclick') {
                setState((val) => ({...val, editMode: true}));
            } else {
                setState((val) => {
                    const tmp: BulkTableState = {
                        ...val,
                        selectedRange: [],
                        selectedCell: [...Lists.default<string>(val.selectedCell)],
                    };

                    if (!tmp.selectedCell.length || (tmp.selectedCell[0] !== e.target.dataset.row || tmp.selectedCell[1] !== e.target.dataset.col)) {
                        tmp.editMode = false;
                        tmp.selectedRange = [];
                        tmp.selectedCell = [e.target.dataset.row, e.target.dataset.col];
                    }
                    return tmp;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (stateScroll){
            cellRef.current.style.display = 'none';
        } else {
            cellRef.current.style.display = 'unset';
            setRefresh((val:number)=>val+1)
        }
    }, [stateScroll]);

    useEffect(() => {
        if (!state.selectedCell || !state.selectedCell.length || !tableRef.current) return;
        const cell = tableRef.current.querySelector(`[data-row='${state.selectedCell[0]}'][data-col='${state.selectedCell[1]}']`);

        if (!cell) return;

        const cellBox = cell.getBoundingClientRect();
        cellRef.current.style.left = (cellBox.left - 1) + 'px';
        cellRef.current.style.top = (cellBox.top - 1) + 'px';
        cellRef.current.style.width = (cellBox.width + 2) + 'px';
        cellRef.current.style.height = (cellBox.height + 2) + 'px';
    }, [state, cfg, refresh]);

    const moveHandler = useCallback((e) => {
        if (!dragging) return;
        const sourceCell = tableRef.current.querySelector(`[data-row='${state.selectedCell[0]}'][data-col='${state.selectedCell[1]}']`);
        const cellIndex = BulkFile.columns.indexOf(state.selectedCell[1]) + 2;
        const currentCell = e.target;
        const currentRow = currentCell.parentElement;
        const targetCell = currentRow.children.item(cellIndex)

        const cellBox = sourceCell.getBoundingClientRect();
        const targetBox = targetCell.getBoundingClientRect();

        range[1] = BulkFile.files.findIndex(f => f.bulkid === +currentRow.dataset.fileid);

        if (cellBox.top < targetBox.top) { // dragging down
            cellRef.current.style.height = (targetBox.top - cellBox.top + cellBox.height + 2) + 'px';
        } else { // dragging up
            cellRef.current.style.top = (targetBox.top - 1) + 'px';
            cellRef.current.style.height = (cellBox.top - targetBox.top + cellBox.height + 2) + 'px';
        }

    }, [dragging, state]);

    useEffect(() => {
        if (dragging) {
            tableRef.current.addEventListener('mousemove', moveHandler);
        }
        return () => {
            tableRef.current.removeEventListener('mousemove', moveHandler);
        }
    }, [dragging, moveHandler]);



    const upHandler = useCallback((e) => {
        e.preventDefault();
        setDragging(false);
        setState((val) => ({
            ...val,
        }));
        document.removeEventListener('mouseup', upHandler);
        if (range.length !== 2 || range[0] === range[1]) {
            return
        }

        const sourceFile = BulkFile.files[range[0]]
        const val = sourceFile.data[state.selectedCell[1]];
        const obj = {[state.selectedCell[1]]: val}

        if (state.selectedCell[1] === 'productData') {
            ProductMapped.productDataFields.forEach(f => {
               obj[f] = sourceFile.data[f];
            });
        }

        for (let i = range[0] + 1; i <= range[1]; i++) {
            BulkFile.files[i].setData(obj, true)
        }
        range.splice(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    const downHandler = useCallback((e) => {
        e.preventDefault();
        setDragging(true);
        range[0] = BulkFile.files.findIndex(f => f.bulkid === +state.selectedCell[0])
        document.addEventListener('mouseup', upHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upHandler]);

    const stopEvent = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.stopImmediatePropagation) {
            e.stopImmediatePropagation();
        }
    };

    useEffect(() => {
        const handlerElem = handlerRef.current;
        handlerElem.addEventListener('mousedown', downHandler);
        return () => {
            handlerElem.removeEventListener('mousedown', downHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlerRef, state]);

    useEffect(() => {
        const columns = BulkFile.columns;
        const keyPressHandler = (e) => {
            const s = getRecoil(bulkTableState);
            if (s.selectedCell.length === 0) return;
            let idx: number = 0;
            switch (e.key) {
                case 'Delete':
                    if (s.editMode || !s.selectedCell) {
                        return;
                    }
                    const file = BulkFile.files.find(f => f.bulkid === +s.selectedCell[0]);
                    if (!file) return;

                    delete (file.data[s.selectedCell[1]]);
                    if (s.selectedCell[1] === 'productData') {
                        ProductMapped.productDataFields.forEach(f => {
                            delete (file.data[f]);
                        });
                    }
                    file.setData({}, true);
                    stopEvent(e);
                    break;
                case 'ArrowRight':
                    if (s.editMode) {
                        return;
                    }
                    idx = Math.min(columns.indexOf(s.selectedCell[1]) + 1, columns.length - 1);
                    setRecoil(bulkTableState, (val) => ({...val, selectedCell: [s.selectedCell[0], columns[idx]]}));
                    stopEvent(e);
                    break;

                case 'ArrowLeft':
                    if (s.editMode) return;
                    idx = Math.max(columns.indexOf(s.selectedCell[1]) - 1, 0);
                    setRecoil(bulkTableState, (val) => ({...val, selectedCell: [s.selectedCell[0], columns[idx]]}));
                    stopEvent(e);
                    break;

                case 'ArrowDown':
                    if (s.editMode) return;
                    idx = Math.min(BulkFile.files.findIndex(f => f.bulkid === +s.selectedCell[0]) + 1, BulkFile.files.length - 1);
                    setRecoil(bulkTableState, (val) => ({
                        ...val,
                        selectedCell: [BulkFile.files[idx].bulkid.toString(), s.selectedCell[1]],
                    }));
                    stopEvent(e);
                    break;

                case 'ArrowUp':
                    if (s.editMode) return;
                    idx = Math.max(BulkFile.files.findIndex(f => f.bulkid === +s.selectedCell[0]) - 1, 0);
                    setRecoil(bulkTableState, (val) => ({
                        ...val,
                        selectedCell: [BulkFile.files[idx].bulkid.toString(), s.selectedCell[1]],
                    }));
                    stopEvent(e);
                    break;

                case 'Escape':
                    if (s.editMode) {
                        setRecoil(bulkTableState, (val) => ({...val, editMode: false}));
                    } else {
                        setRecoil(bulkTableState, (val) => ({editMode: false, selectedRange: [], selectedCell: []}));
                    }
                    stopEvent(e);
                    break;

                case 'Enter':
                    if (s.editMode) {
                        if (s.selectedCell[1] === 'description') {
                            return;
                        }
                        setRecoil(bulkTableState, (val) => ({...val, editMode: false}));
                        return;
                    }
                    setRecoil(bulkTableState, (val) => ({...val, editMode: true}));
                    stopEvent(e);
                    break;
            }
        };


        tableRef.current = cellRef.current.parentElement.firstChild;

        tableRef.current.addEventListener('click', selectCellHandler);
        tableRef.current.addEventListener('dblclick', selectCellHandler);
        window.addEventListener('keyup', keyPressHandler);

        return () => {

            tableRef.current.removeEventListener('click', selectCellHandler);
            tableRef.current.removeEventListener('dblclick', selectCellHandler);

            window.removeEventListener('keyup', keyPressHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SelectedCell id={'cell-highlight'} ref={cellRef}
                      style={{display: fi(!Lists.default(state.selectedCell).length, 'none', 'unset')}}>
            <Handler ref={handlerRef}></Handler>
        </SelectedCell>
    );
};

export default CellHighlight;