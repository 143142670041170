import {IFormEvents} from "../../components/Form/model";
import {formField} from "../../utils/decorators";
import {FieldType, ModelInfo} from "./__ModelInfo";
import {Types, UUID} from "../types";
import {emailRegex} from "../../utils/constants";

const Model = new ModelInfo({
    model: {
        uid: Types.PREVIEW_MAIl,
        name: 'Preview mail',
    },
    fields: [],
});
export class PreviewMail implements IFormEvents {
    public static model = Model;
    private __uuid: UUID =  crypto.randomUUID()
    public getId(): UUID {
        return this.__uuid;
    }

    @formField({
        uid: 'email',
        fieldtype: FieldType.Tab,
        validations: [{
            type: 'pattern',
            config: {
                pattern: emailRegex,
                message: 'One ore more emails address supplied are not valid',
            }
        }],
        flags: {required: true ,multiple: true},
        name: 'Send preview email',
        order: 1,
    })
    public previewEmailList: any[] = [];
}
