import styled from "@emotion/styled";
import {useRecoilValue} from "recoil";
import {ratingsSelectors, selectedObject} from "../../../state/state";
import React from "react";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import StarHalfRoundedIcon from '@mui/icons-material/StarHalfRounded';

const RatingsWrapper = styled.span`
  svg {
    color: var(--color-border);
    width: 20px;
    height: 20px;
  }
`

export const Ratings = ({itemId}: { itemId: string }) => {
    const ratings = useRecoilValue(ratingsSelectors(itemId));
    const selected = useRecoilValue<any>(selectedObject)

    if (ratings.length === 0) {
        return null
    }

    const getRating = (): any => {
        const version = selected.getMeta().version
        const items = ratings.filter(r => r.item_version === version)
        const sum = items.reduce((a, b) => a + b.rating, 0) / items.length


        const stars: any[] = [];
        for (let i = 0; i < 5; i++) {
            stars.push(<StarBorderRoundedIcon key={i} />);
        }
        const to = Math.floor(sum);
        for (let i = 0; i < to; i++) {
            stars[i] = <StarRateRoundedIcon key={i}  />;
        }
        if (to !== sum) { // half
            stars[to] = <StarHalfRoundedIcon key={to}  />;
        }
        return (
            <RatingsWrapper title={`${sum}/5 from ${items.length} votes`}>
                {stars.map(r => r)}
            </RatingsWrapper>
        )
    }


    return (
        <div>
            User ratings: {getRating()}
        </div>
    )
}