import {CMSObject} from './__CMSObject';
import {Types, UUID} from '../types';
import {Strings} from '../../utils/strings';
import {Numbers} from '../../utils/numbers';
import React, {ReactElement} from 'react';
import {fi} from '../../utils/helpers';
import {Dates} from '../../utils/dates';

export class KeyDates extends CMSObject {
	public key_dates_file: UUID;
	public type: string;
	public date: Date;
	public description: string;
	public exam_year: number;
	public series: string;
	public qualifications: string;
	public ab: string;
	public subject: string;
	public updated: string;
	public created: string;

	constructor(item: any = {}) {
		super(item);
		this.key_dates_file = Strings.default(item.key_dates_file);
		this.type = Strings.default(item.type);
		this.date = new Date(item.date);
		this.description = Strings.default(item.description);
		this.exam_year = Numbers.default(item.exam_year);
		this.series = Strings.default(item.series);
		this.qualifications = Strings.default(item.qualifications);
		this.ab = Strings.default(item.ab);
		this.subject = Strings.default(item.subject);
		this.updated = Strings.default(item.updated);
		this.created = Strings.default(item.created);
	}

	public getType(): Types {
		return Types.KEY_DATE;
	}

	public getTitle(): ReactElement {
		return (
			<div>
				{this.type}
			</div>
		);
	}

	public getDate(): ReactElement {
		return fi(this.updated,
			<div className='mb8'>Updated - {Dates.format(this.updated)}</div>,
			fi(this.created, <div className='mb8'>Added - {Dates.format(this.created)}</div>, ''));
	}

	public getSeriesYear(): string {
		return Strings.capitalize(`${this.series.substring(0, 3)} ${this.exam_year.toString()}`);
	}

	public renderQualification(): ReactElement {
		const subjects = fi(this.qualifications.split(',').length === 1, fi(this.subject, `: ${this.subject}`, ''), '');
		return <div title={this.qualifications + subjects}><span>{this.qualifications}</span>{subjects}</div>;
	}

	public getFlag(): ReactElement[] {
		const result: ReactElement[] = [];
		let date = this.created;
		let label: string = 'New';
		if (this.updated) {
			label = 'Updated';
			date = this.updated;
		}

		const keyDate = new Date(date);
		if (keyDate.toString() === 'Invalid Date') {
			return result;
		}

		const now = new Date();
		const elapsed = (now.getTime() - keyDate.getTime()) / 1000 / 60 / 60 / 24;
		if (elapsed <= 30) {
			result.push(<span key={this.getId()}>{label}</span>) ;
		}
		return result;
	}
}