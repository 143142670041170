import React, {useContext, useEffect} from "react";
import {ColumnFilterProps} from './ColumnFilter';
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import styled from "@emotion/styled";
import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import {cacheBuster} from "../../../state/state";
import {ColumnPreference} from "../preferences";
import {Lists} from "../../../utils/lists";
import {Numbers} from "../../../utils/numbers";
import {Strings} from "../../../utils/strings";
import {fi} from "../../../utils/helpers";
import {TableContext} from "../state";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 150px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer !important;
  user-select: none;

  label {
    flex-grow: 1;
    line-height: 45px;
    cursor: pointer !important;
  }
`

const Select = styled.select`
  padding: 4px 8px;
  border: 1px solid var(--color-border);
`

const Input = styled.input`
  padding: 4px 8px;
  border: 1px solid var(--color-border);
  flex-grow: 1;

  &.small {
    width: 47px;
  }
`

const singleValueModes = [
    {label: '=', value: '$eq'},
    {label: '>', value: '$gt'},
    {label: '>=', value: '$gte'},
    {label: '<', value: '$lt'},
    {label: '<=', value: '$lte'},
    {label: '!=', value: '$ne'},
]

let timer: any;

const NumberFilter = (props: ColumnFilterProps & { preferences: ColumnPreference }) => {
    const ctx = useContext(TableContext)
    cacheBuster(ctx.type)

    const determineMode = (): string => {
        let filter = props.preferences.filter
        if (!filter) {
            return 'all'
        }
        if (Array.isArray(filter)) {
            return fi(filter.length === 2, 'range', 'value')
        }
        return 'value'
    }

    const [mode, setMode] = React.useState(determineMode)
    const [val, setVal] = React.useState<any>(props.preferences.filter)

    useEffect(() => {
        setVal(props.preferences.filter)
        setMode(determineMode())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.preferences.filter])

    const onChange = (_event) => {
        clearTimeout(timer);

        setMode(_event.target.value)
        timer = setTimeout(() => {
            switch (_event.target.value) {
                case 'all':
                    props.onChange!(null)
                    break;
                case 'value':
                    props.onChange!([['$eq', 0]])
                    break;
                case 'range':
                    props.onChange!([['$gt', 0], ['$lt', 1]])
                    break;
            }
        }, 500)
    }

    const onValueModeChange = (evt) => {
        const tmp = Lists.default(Lists.default(val)[0])
        props.onChange!([[evt.target.value, tmp[1]]])
    }

    const onValueChange = (evt) => {
        clearTimeout(timer);
        const tmp = Lists.default(Lists.default(val)[0])
        const newVal = [[tmp[0], +evt.target.value]]
        setVal(newVal)
        timer = setTimeout(() => {
            props.onChange!(newVal)
        }, 500)
    }

    const onRangeModeChange = (evt, mode) => {
        const tmp: any[] = [...Lists.default<any[]>(val)]
        tmp[mode] = [evt.target.value, tmp[mode][1]]
        clearTimeout(timer);
        timer = setTimeout(() => {
            props.onChange!(tmp)
        }, 500);
    }

    const onRangeValueChange = (evt, mode: number) => {
        clearTimeout(timer);
        const tmp: any[] = [...Lists.default<any[]>(val)]
        tmp[mode] = [tmp[mode][0], evt.target.value]
        setVal(tmp)
        timer = setTimeout(() => {
            props.onChange!(tmp)
        }, 500)
    }

    return (
        <Wrapper>
            <Row>
                <Radio
                    className="form-checkbox"
                    aria-label='Value'
                    id='item-any'
                    name='number-filter'
                    tabIndex={0}
                    color="primary"
                    checked={mode === 'all'}
                    checkedIcon={<RadioButtonChecked/>}
                    size="medium"
                    value='all'
                    onChange={onChange}
                    title='Value'
                    disableRipple
                />
                <label htmlFor={`item-any`}>
                    All
                </label>
            </Row>
            <Divider/>
            <Row>
                <Radio
                    className="form-checkbox"
                    aria-label='Value'
                    id='item-value'
                    name='number-filter'
                    tabIndex={0}
                    color="primary"
                    checked={mode === 'value'}
                    checkedIcon={<RadioButtonChecked/>}
                    size="medium"
                    value='value'
                    onChange={onChange}
                    title='Value'
                    disableRipple
                />
                <label htmlFor={`item-value`}>
                    Value
                </label>
            </Row>
            {mode === 'value' && (
                <Row>
                    <Select onChange={onValueModeChange}
                            value={Strings.default(Lists.default(Lists.default(val)[0])[0], '$eq')}>
                        {singleValueModes.map((mode, idx) => (
                            <option value={mode.value} key={idx}>{mode.label}</option>
                        ))}
                    </Select>
                    <Input type='number' id='value-min' value={Numbers.default(Lists.default(Lists.default(val)[0])[1])}
                           onChange={onValueChange}/>
                </Row>
            )}
            <Divider/>
            <Row>
                <Radio
                    className="form-checkbox"
                    aria-label='Value'
                    id='item-range'
                    name='number-filter'
                    tabIndex={0}
                    color="primary"
                    checked={mode === 'range'}
                    checkedIcon={<RadioButtonChecked/>}
                    size="medium"
                    value='range'
                    onChange={onChange}
                    title='Value'
                    disableRipple
                />
                <label htmlFor={`item-range`}>
                    Range
                </label>
            </Row>
            {mode === 'range' && (
                <Row>
                    <Select onChange={(evt) => onRangeModeChange(evt, 0)}
                            value={Strings.default(Lists.default(Lists.default(val)[0])[0], '$eq')}>
                        <option value='$gt'>{'>'}</option>
                        <option value='$gte'>{'>='}</option>
                    </Select>
                    <Input className='small' type='number' id='value-min'
                           value={Numbers.default(Lists.default(Lists.default(val)[0])[1])}
                           onInput={(evt) => onRangeValueChange(evt, 0)}/>
                    <span>-</span>
                    <Select onChange={(evt) => onRangeModeChange(evt, 1)}
                            value={Strings.default(Lists.default(Lists.default(val)[1])[0], '$eq')}>
                        <option value='$lt'>{'<'}</option>
                        <option value='$lte'>{'<='}</option>
                    </Select>
                    <Input className='small' type='number' id='value-min'
                           value={Numbers.default(Lists.default(Lists.default(val)[1])[1])}
                           onInput={(evt) => onRangeValueChange(evt, 1)}/>
                </Row>
            )}
        </Wrapper>
    );
};

export default NumberFilter;
