import {TreeObject} from "../../cms/models/__CMSObject";
import {UUID} from "../../cms/types";
import {Lists} from "../../utils/lists";
import {debounce, fi} from "../../utils/helpers";
import {setRecoil} from "../../state/recoilNexus";
import {cacheBuster} from "../../state/state";
import {walkTreeDown, walkTreeUp} from "./utils";
import {Numbers} from "../../utils/numbers";
import {Strings} from "../../utils/strings";
import {Objects} from "../../utils/objects";

export interface ITreeItem {
    object: TreeObject;
    index: number;
    id: UUID;
    order: number;
    parentId: string | null | undefined;
    parent?: ITreeItem;
    opened?: boolean;
    selected?: boolean;
    matchChildren?: number;
    children: Array<ITreeItem>;
}

type TreeState = {
    items: TreeObject[]
    index: { [key: UUID]: ITreeItem }
    roots: ITreeItem[]
    search: string
}

export class Tree {
    public static states: { [key: string]: TreeState } = {};
    private state: TreeState;
    public key: string;
    public static filterNodes: (query: string) => void;

    constructor(key: string, items?: TreeObject[]) {
        this.key = key;

        let old: TreeState | undefined;
        if (Tree.states[key]) {
            old = {...Tree.states[key]};
        }

        this.state = {
            items: [],
            roots: [],
            index: {},
            search: Strings.default(Objects.default(old).search),
        }
        if (old && !items) {
            console.log("old", old);
            this.state = old
        }

        if (items && items.length) {
            this.state.items = [...items]
            Tree.states[key] = this.state;
        }

        if (!Tree.filterNodes) {
            Tree.filterNodes = debounce(this.search.bind(this), 500);
        }

        this.index(old)
    }

    public static getTree(key: string): Tree | undefined {
        const state = Tree.states[key];
        if (state) {
            return new Tree(key, state.items);
        }
        return
    }

    public setItem(object: TreeObject) {
        const item = this.findNode(object.getId());
        if (item) {
            this.updateNode(object);
        } else {
            this.addNode(object)
        }
    }

    private index(old?: TreeState) {
        this.state.items.forEach((item, idx) => {
            let opened = false;

            if (old && old.index) {
                const oldItem = old.index[item.getId()]
                if (oldItem) {
                    opened = Boolean(oldItem.opened);
                }
            }

            this.state.index[item.getId()] = {
                index: idx,
                object: item,
                parentId: item.parent,
                id: item.getId(),
                opened: opened,
                order: item.order,
                children: [],
            };
        });
        Tree.states[this.key] = this.state;
    }

    public search(search: string) {
        Tree.states[this.key].search = search;
        this.state.search = search;
        this.refresh()
    }

    public updateNode(item: TreeObject) {
        const tmp = this.state.index[item.getId()];
        if (tmp) {
            tmp.object = item
        }
        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].getId() === item.getId()) {
                this.state.items[i] = item;
                break
            }
        }
        Tree.states[this.key] = this.state;
        this.refresh()
    }

    public addNode(item: TreeObject) {
        this.state.items.push(item);
        this.state.index[item.getId()] = {
            index: this.state.items.length,
            object: item,
            parentId: item.parent,
            id: item.getId(),
            opened: false,
            order: item.order,
            children: [],
        };
        Tree.states[this.key] = this.state;
        this.refresh()
    }

    public collapseAll() {
        for (let id in this.state.index) {
            this.state.index[id].opened = false;
        }
        Tree.states[this.key] = {...this.state};
        this.refresh()
    }

    public expandAll() {
        for (let id in this.state.index) {
            this.state.index[id].opened = true;
        }

        Tree.states[this.key] = {...this.state};
        this.refresh()
    }

    public refresh() {
        setRecoil(cacheBuster(this.key), (val) => val + 1)
    }

    public cloneNode(id: string) {
        const node = this.findNode(id)
        if (!node) {
            return
        }
        const clone = {...node}
        clone.children = [];
        if (clone.parent) {
            clone.parent = this.cloneNode(clone.parent.id)
            if (clone.parent) {
                clone.parent.children.push(clone)
            }
        }
        return clone
    }

    public findNode(id: string) {
        return this.state.index[id];
    }

    public setSelected(id: string, val: boolean = true) {
        const node = this.state.index[id]
        if (node) {
            node.selected = val;
            Tree.states[this.key] = this.state;
            return node
        }
        return null
    }

    public setOpened(id: string, val?: boolean) {
        const node = this.state.index[id]
        if (node) {
            node.opened = fi(typeof val === "boolean", val, !node.opened)
            Tree.states[this.key] = this.state;
            return node
        }
        return null
    }

    public delete(id: string) {
        const node = this.state.index[id]
        if (node) {
            if (node.parent) {
                node.parent.children.splice(node.parent.children.indexOf(node), 1);
            }
            delete this.state.index[id];
        }
        Tree.states[this.key] = this.state;
    }

    public move(itemId: string, targetParentId: string | null, targetIndex: number) {
        const node = this.state.index[itemId]
        const targetParent = this.findNode(targetParentId!)
        if (!targetParent) { // move to root
            if (node.parent) { // from a parent
                node.parent.children.splice(node.parent.children.indexOf(node), 1);
                this.state.roots.splice(targetIndex, 0, node)

                node.parent.children.forEach((n, idx) => {
                    n.order = idx
                })

            } else { // between root nodes
                const idx = this.state.roots.indexOf(node)
                this.state.roots.splice(targetIndex, 0,
                    this.state.roots.splice(idx, 1)[0]);
            }
            node.parentId = undefined
            node.parent = undefined

            return this.state.roots.map((c, i) => {
                c.order = i
                return c.id
            })
        }

        // move to another node
        if (node.parent) {
            const currentIndex = node.parent.children.indexOf(node)
            if (targetIndex > currentIndex && targetParentId === node.parent.id) {
                targetIndex--
            }
            targetParent.children.splice(targetIndex, 0,
                node.parent.children.splice(currentIndex, 1)[0]);

            node.parent!.children.forEach((n, idx) => {
                n.order = idx
            })

        } else {
            targetParent.children.splice(targetIndex, 0,
                this.state.roots.splice(this.state.roots.indexOf(node), 1)[0]);

            this.state.roots.forEach((n, idx) => {
                n.order = idx
            })
        }

        node.order = targetIndex
        node.parentId = targetParentId
        node.parent = targetParent

        Tree.states[this.key] = this.state;

        return targetParent.children.map((c, i) => {
            c.order = i
            return c.id
        })
    }

    public childOrder(parentId: string | null | undefined) {
        this.getTree()
        if (!parentId) {
            return this.state.roots.map(item => item.id)
        } else {
            return this.findNode(parentId).children.map(item => item.id)
        }
    }

    public rootNodes(): number {
        return this.state.roots.length
    }

    public getTree(): ITreeItem[] {
        this.state.roots = [];
        const s = Tree.states[this.key]

        for (let id in this.state.index) {
            const node = this.state.index[id];
            node.children = [];
            node.parent = undefined;
            node.opened = s.index[id].opened
            node.matchChildren = s.index[id].matchChildren
            this.state.index[id] = {...node}
        }

        // Link children to their parents
        for (let id in this.state.index) {
            const node = this.state.index[id];
            if (!node.parentId) {
                this.state.roots.push(node);
                Lists.sort( this.state.roots, 'order');
                continue;
            }

            const parent = this.state.index[node.parentId];
            if (parent) {
                node.parent = parent;
                parent.children.push(node);
                Lists.sort(parent.children, 'order');
            }
        }

        let tmp = [...this.state.roots]
        Lists.sort(tmp, 'order');

        const search = Tree.states[this.key].search

        if (search) {
            tmp = tmp.map(node => walkTreeDown(node, (node: ITreeItem) => {
                if (node.object.displayLabel().toLowerCase().includes(search)) {
                    walkTreeUp(node, (node: ITreeItem) => {
                        node.matchChildren = Numbers.default(node.matchChildren) + 1;
                        s.index[node.id].matchChildren = Numbers.default(node.matchChildren) + 1;
                    });
                } else {
                    s.index[node.id].matchChildren = fi(search, 0, undefined);
                    node.matchChildren = fi(search, 0, undefined);
                }
            }, true) as ITreeItem);
            return tmp
        }
        return tmp;
    }


}

window['tree'] = Tree