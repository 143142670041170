import React, {Suspense, useEffect} from "react"
import styled from "@emotion/styled";
import {skipMatchingFolder, TableContext, useTable} from "../../../../TableComponent/state";
import TableComponent from "../../../../TableComponent/TableComponent";
import {Types} from "../../../../../cms/types";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useSetRecoilState} from "recoil";
import {modalAtom} from "../../../../ModalDialogs/ModalWrapper";

export const ModalFooter = styled.div`
    border-top: 1px solid var(--color-grey);
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    bottom: -24px;
    background: var(--color-white);
`;

const getType = (file:any) => {
    if (typeof file['fileInfo'] === 'function') {
        const info = file['fileInfo']();
        if (info) {
            return info.icon(true);
        }
    }
    switch (file.getType()) {
        case Types.TEXT:
            return 'Free Text item'
        case Types.VIDEO:
            return 'Video item'
        case Types.EVENT_MATERIAL:
        case Types.LINK:
            if (file.__meta.tags?.join('').includes('#online_course')) {
                return 'Online course link'
            }
            return 'Link item'
        default:
            return 'Unknown content item'
    }
}

const generateCardList = (items: {[key:string]:any}):string => {
    let list: string = ''
    Object.keys(items).forEach((key) => {
        list = list + `<card data-uid="${items[key].getId()}" class="mceNonEditable embedded-content-item item-card ${items[key].getType()}" data-title="${items[key].title || items[key].name}">&nbsp;</card>`
    })
    return list
}
const generateInlineList = (items: {[key:string]:any}):string => {
    let list: string = ''
    Object.keys(items).forEach((key) => {
        list = list + `<inline data-uid='${items[key].getId()}' class='mceNonEditable embedded-content-item item-inline ${items[key].getType()} ${getType(items[key]).split(" ").join("_")}' data-title="${items[key].title || items[key].name}">&nbsp;</inline>`
    })
    return list
}



type FooterProps = {
    onClose: () => void,
}
export const FooterMedia = (props:FooterProps) => {
    const table = useTable();
    const [selection, setSelection] = React.useState<{[key:string]:any}>({});

    const insertInline = ()=>{
        // @ts-ignore
        tinymce.activeEditor.execCommand('mceInsertTemplate', true, generateInlineList(selection));
        props.onClose()
    }

    const insertCards = ()=>{
        // @ts-ignore
        tinymce.activeEditor.execCommand('mceInsertTemplate', true, generateCardList(selection));
        props.onClose()
    }
    useEffect(()=>{
        const newSelection: {[key:string]:any} = {...selection}
        for (const key in table.state.selected) {
            if (table.state.selected.hasOwnProperty(key)) {
                if(table.state.selected[key]){
                    const item = table.state.selected[key];
                    if(item&&newSelection[key]===undefined){
                        newSelection[key] = table.state.objects[key]
                    }
                }else {
                    delete newSelection[key]
                }
            }
        }
        setSelection(newSelection)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[table.state.selected])

    useEffect(() => {
        return () => {
            table.actions.deselectAll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <Button disabled={Object.keys(selection).length===0} variant="contained"
                onClick={insertInline} >
            Add Inline
        </Button>
        <Button disabled={Object.keys(selection).length===0} variant="contained"
                onClick={insertCards} >
            Add Cards
        </Button>
    </>
}

const InsertItemModalComponent = ({onClose}: any) => {
    const table = useTable();
    const setModalEdit = useSetRecoilState(modalAtom);
    let key = 0;
    const type = Types.EXTENDED_LIBRARY
    const setFiltersOff = useSetRecoilState(skipMatchingFolder)

    useEffect(() => {
        const previousFilters = table.actions.getCurrentFilters()
        table.actions.clearFilters()
        setFiltersOff(true)
        return () => {
            setFiltersOff(false)
            table.actions.restoreFilters(previousFilters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <Suspense fallback={<>loading</>} >
            <TableContext.Provider value={{type}} key={key}>
                <TableComponent key={key}/>
                <ModalFooter>
                    <Divider />
                    <Suspense fallback={"test"} > <FooterMedia onClose={() => setModalEdit(null)} /></Suspense>
                </ModalFooter>
            </TableContext.Provider>
        </Suspense>
    </>
}

export default InsertItemModalComponent