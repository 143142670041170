import styled from '@emotion/styled';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import React from 'react';

const Wrapper = styled(FormControl)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled(FormLabel)`
  font-weight: normal;
  font-family: var(--font-regular);
  margin-bottom: 0;
`

interface IProps {
	label: string;
	value: boolean;
	id: string;
	onChange: (value: boolean) => void;
}

const Toggle = (props: IProps) => {
	return (
		<Wrapper>
			<Switch checked={props.value} onChange={(_evt, value) => props.onChange(value)}/>
			<Label htmlFor={props.id}>{props.label}</Label>
		</Wrapper>
	);
};

export default Toggle;

