import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import { fi } from '../../utils/helpers';

const CustomLoader = styled(CircularProgress)`
  svg {
    color: var(--color-blue);
  }
  &.inverted {
	svg {
      color: var(--color-white);
    }
  }
`;

const Loader = ({size = 40, inverted = false}: { size?: number, inverted?: boolean }) => {
	return <CustomLoader size={size} className={fi(inverted, 'inverted')}/>;
};

export default Loader;
