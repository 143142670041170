import styled from "@emotion/styled";
import {useRecoilValue} from "recoil";
import {ratingsSelectors, references, selectedObject} from "../../state/state";
import React, {useMemo} from "react";
import {BoxContainer} from "./components/BoxContainer";
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import Spacer from "../commons/Spacer";
import Typography from "@mui/material/Typography";
import {Dates} from "../../utils/dates";
import {fi} from "../../utils/helpers";
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import {LibraryItem} from "../../cms/models/__MediaLibrary";
import {getRecoil} from "../../state/recoilNexus";

const Wrapper = styled.div`
  max-height: 100px;

  &.expanded {
    max-height: 400px;
  }

  overflow: auto;

  svg {
    width: 20px;
    height: 20px;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 2px 8px;
  margin: 3px 0;

  svg {
    color: var(--color-border)
  }

  h6 {
    font-size: 14px;
    color: var(--color-border);
  }
`

const Name = styled.div`
  padding-left: 8px;

  span {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--color-border);
    margin-right: 8px;
  }
`

const EmptyState = styled.p`
  text-align: center;
  padding: 16px;
  color: var(--color-backdrop)
`

const WidgetContent = ({itemId}: { itemId: string }) => {
    const selected = useRecoilValue(selectedObject)
    const ratings = useRecoilValue(ratingsSelectors(itemId));

    if (ratings.length === 0 || !selected) {
        return (
            <Wrapper>
                <EmptyState>There are no ratings for this item.</EmptyState>
            </Wrapper>
        )
    }

    const getRating = (value: number): any => {
        const res: any[] = [];
        for (let i = 0; i < 5; i++) {
            res.push(<StarBorderRoundedIcon key={i}/>);
        }
        const to = Math.floor(value);
        for (let i = 0; i < to; i++) {
            res[i] = <StarRateRoundedIcon key={i}/>;
        }
        return (
            <>
                {res.map(r => r)}
            </>
        )
    }

    return (
        <Wrapper>
            {ratings.filter(r => r.item_version === selected.getMeta().version).map((rating, idx) => (
                <Row key={idx}>
                    <span title={rating.rating.toString()}>{getRating(rating.rating)}</span>
                    <Name>{rating.full_name} <span>{rating.job_title}</span></Name>
                    {fi(rating.feedback, <span title={rating.feedback}><MessageRoundedIcon/></span>, null)}
                    <Spacer/>
                    <Typography variant='h6' title={Dates.local(rating.created)}>
                        {Dates.timeAgo(rating.created)}
                    </Typography>
                </Row>
            ))}
        </Wrapper>
    )
}

const RatingBox = () => {
    const selected = useRecoilValue(selectedObject)

    const content = useMemo(() => {
        if (!selected || !(selected instanceof LibraryItem)) {
            return null
        }
        return <WidgetContent itemId={selected.getId()}/>
    }, [selected])

    const shouldDisplay = useMemo(() => {
        if (!selected) {
            return false
        }
        const isLibraryItem = selected instanceof LibraryItem
        const base: any = getRecoil(references(selected.getId()))
        if (base && isLibraryItem) {
            return base.getMeta().version > 0
        }
        return isLibraryItem
    }, [selected])

    return (
        <BoxContainer title='User ratings' id='rating' hidden={!shouldDisplay}>
            {content}
        </BoxContainer>
    )
}

export default RatingBox;