import {Lists} from '../../utils/lists';
import {Objects} from '../../utils/objects';
import {LocalizedText, Types} from '../types';
import {BaseClass} from './__base';
import {Strings} from '../../utils/strings';
import {fi} from '../../utils/helpers';
import {Messages} from '../../utils/messages';
import {CMSFile} from './File';
import {getRecoil} from '../../state/recoilNexus';
import {references} from '../../state/state';

// List of field UIDs that belong to objects that have APIKeys mappings
export const ProductDataFields: string[] = [
	'assessment',
	'child_assessment',
	'qualification',
	'component',
	'subject',
	'specification_group',
	'qualification_group',
	'all_assessments',
	'all_child_assessments',
];

// List of field UIDs that belong to objects that have Subject mappings
export const ProductMappingFields: string[] = [
	'qualification_group_mapping',
	'qualification_mapping',
];

// List of field UIDs that belong to objects that have Series mappings
export const SeriesFields: string[] = [
	'series',
	'exam_year',
];

export type FieldConfig = {
	isMultiline: boolean;
	isURL: boolean;
	isRadioGroup: boolean;
	isEmail: boolean;
	isHTML: boolean;
	isMarkdown: boolean;
	isIP: boolean;
	isCIDR: boolean;
	decimalPlaces: number;
	positiveOnly: boolean;
	options: LocalizedText[][];
	multipleSelections: boolean;
	refModel: string;
	refModels: string[];
	refType: string;
	onDeleteRef: string;
	onUpdateRef: string;
	colorSet: boolean;
	availableColors: string[];
    height: number;

	// Additional configurations added by user decorations
	linkedWith: string;
	uploadURL: string;
}

export type FieldFlags = {
	// Unused. Used by backend to determine if the field should be sent to public front-end or not.
	private: boolean;
	// Marks the field as mandatory
	required: boolean;
	// Unused. Used by backend to determine if the field should be indexed to be searched by
	searchable: boolean;
	// Checks the value of the field to be unique across all items of the same type
	unique: boolean;
	// Don't render the field in the form ('parent' field for example for a page, or 'folder' for an item)
	hidden: boolean;
	// Value should be an array of things. Allows for multiple selections.
	multiple: boolean;
	// FE only flag indicating the ProductData fields how many levels of product to show in the tree
	// If subjectOnly is set to true, the tree will stop after rendering subjects, otherwise it will render all the
	// way down to components/units
	subjectOnly: boolean;
	// FE only flag for References type fields indicating that should only render the values that are selected
	// and allow for drag-and-drop reordering, and should not show the select box to add more things to the list
	hideSelection: boolean;
	// FE only flag for References type fields indicating that should only render the select box to add more things
	// to the list but not to render the selected values
	hideValues: boolean;
	// FE only flag for References type fields indicating that should not allow clearing selection
	noClear: boolean;
	// mark the input as required but wit no validation
	dummyRequired: boolean;
}

export type FieldValidation = {
	type: string;
	config: any;
}

export enum FieldType {
	Text = 'text',
	Number = 'number',
	Boolean = 'boolean',
	File = 'file',
	Date = 'date',
	List = 'list',
	Reference = 'ref',

	// These are additional filed types handled only by UI
	ProductData = 'product_data',
	Tags = 'tags',
	Tab = 'Tab',
    WidgetList = 'widget-list',
	IconList = 'icon-list',
	TagsList = 'tags-list',
	CardsList = 'cards-list',
	ErrorsList = 'error-list',
}

export class Field extends BaseClass {
	uid: string;
	name: string;
	placeholder?: string;
	defaultvalue?: any;
	fieldtype: FieldType;
	flags: Partial<FieldFlags>;
	config: Partial<FieldConfig>;
	order: number;
	group: string;
	groupTitle: string;
	validations: FieldValidation[];

	constructor(item: any = {}) {
		super(item);

		this.uid = item.uid;
		this.name = fi(typeof item.name === 'string', item.name, Strings.en(item.name));
		this.order = item.order;
		this.fieldtype = item.fieldtype;
		this.flags = Objects.default(item.flags);
		this.config = Objects.default(item.config);
		this.validations = Lists.default(item.validations);
		this.group = Strings.default(item.group);
		this.groupTitle = Strings.default(item.groupTitle);

		if (item.placeholder) {
			this.placeholder = Strings.en(item.placeholder);
		}

		if (item.defaultvalue) {
			const en = Strings.en(item.defaultvalue);
			switch (item.fieldtype) {
				case FieldType.Boolean:
					this.defaultvalue = fi(en.toLowerCase() === 'true', true, false);
					break;
				case FieldType.Number:
					const enValue = parseInt(en, 10);
					if (!isNaN(enValue)) {
						this.defaultvalue = enValue;
					}
					break;
				default:
					this.defaultvalue = en;
			}
		}
	}

	public isProductField(): boolean {
		return ProductDataFields.includes(this.uid);
	}

	public isProductMappingField(): boolean {
		return ProductMappingFields.includes(this.uid);
	}

	public isSeriesField(): boolean {
		return SeriesFields.includes(this.uid);
	}

	public validateFile(value: any): string {
		return this.validations.map(v => {
			let fileInfo: CMSFile | undefined;

			if (typeof value === 'string') {
				fileInfo = getRecoil(references(value)) as CMSFile;
			} else if (value instanceof CMSFile) {
				fileInfo = value;
			}

			if (!fileInfo) {
				return '';
			}

			if (fileInfo.size === 0) {
				return 'The file you are trying to upload is blank.';
			}

			if (typeof v.config.maxSize !== 'undefined' && fileInfo.size > v.config.maxSize) {
				return Strings.en(v.config.maxSizeErr, Messages.FileTooLarge);
			}

			if (typeof v.config.minSize !== 'undefined' && fileInfo.size < v.config.minSize) {
				return Strings.en(v.config.maxSizeErr, Messages.FileToSmall);
			}

			if (typeof v.config.allow !== 'undefined') {
				const extensions = v.config.allow.split(',');
				const fileExtension = fileInfo.filename.split('.').pop();
				if (!extensions.includes(fileExtension)) {
					return Strings.en(v.config.minSizeErr, Messages.FormatNotSupported);
				}
			}

			return '';
		}).filter(v => Boolean(v)).find(v => v) || '';
	}

	public validate(value: any): string {
		let isEmptyList = false;
		if (this.flags.multiple) {
			value = Lists.default(value).filter(f => Boolean(f));
			isEmptyList = value.length === 0;
		}

		// check if it needs to be a string and if the string is empty
		let isEmptyString = false;
		if (this.fieldtype === FieldType.Text || this.fieldtype === FieldType.Reference || this.fieldtype === FieldType.Date || this.fieldtype === FieldType.ProductData) {
			if (this.flags.multiple) {
				isEmptyString = isEmptyList;
			} else if (Strings.default(value).trim() === '') {
				isEmptyString = true;
			}
		}

		// field is not defined and is required
		if (typeof value === 'undefined' || value === null || isEmptyList || isEmptyString) {
			if (this.flags.required) {
				return Messages.FieldIsRequired;
			}
			return '';
		}

		const checkType = (objectType: string, v: any = value): string => {
			if (this.flags.multiple) {
				const nonConforming = Lists.default(v).map(v => typeof v).find(v => v !== objectType);
				if (nonConforming) {
					return `Invalid ${objectType} value`;
				}
			} else if (typeof v !== objectType) {
				return `${Strings.startCase(objectType)} value required.`;
			}
			return '';
		};

		// preliminary validations based on type
		switch (this.fieldtype) {
			case FieldType.Number:
				const nonNumberError = checkType('number');
				if (nonNumberError) {
					return nonNumberError;
				}
				break;
			case FieldType.Boolean:
				const nonBoolError = checkType('boolean');
				if (nonBoolError) {
					return nonBoolError;
				}
				break;
			case FieldType.List:
				if (this.config.options) {
					const option = this.config.options.find(o => Strings.en(o) === Strings.default(value));
					if (!option) {
						return `Invalid option`;
					}
				}
				break;
			case FieldType.Date:
				if (typeof value === 'string') {
					try {
						value = new Date(value).toISOString();
					} catch (e) {
						return `Invalid date`;
					}
				} else if (!(value instanceof Date)) {
					return 'Date required';
				}
				break;
			case FieldType.ProductData:
				return '';
			case FieldType.IconList:
				return '';
			default:
		}

		if (this.config.isURL && !Strings.isLink(value)) {
			return Messages.InvalidURLFormat;
		}

        const error = this.validations.map(v => {
            switch (v.type) {
                case 'pattern':
                    if(Array.isArray(value)){
                        const errorList = value.filter((val)=>{
                            return !(new RegExp(v.config.pattern, 'mg')).test(val)
                        })
                        if(errorList.length>0) {
                            return Strings.en(v.config.patternErr, Messages.InvalidCharacters);
                        }
                    } else {
                        if (!(new RegExp(v.config.pattern, 'mg')).test(value)) {
                            return Strings.en(v.config.patternErr, Messages.InvalidCharacters);
                        }
					}
					break;
				case 'text':
					if (typeof v.config.minLen !== 'undefined' && value.length < v.config.minLen) {
						return Strings.en(v.config.minLenErr, `Minimum of ${v.config.minLen} character(s) required.`);
					}
					if (typeof v.config.maxLen !== 'undefined' && value.length > v.config.maxLen) {
						return Strings.en(v.config.maxLenErr, `Maximum of ${v.config.maxLen} characters allowed.`);
					}
					break;
				case 'number':
					if (typeof v.config.min !== 'undefined' && value < v.config.min) {
						return Strings.en(v.config.minErr, `Value cannot be less than ${v.config.min}.`);
					}
					if (typeof v.config.max !== 'undefined' && value > v.config.max) {
						return Strings.en(v.config.maxErr, `Value cannot be greater than ${v.config.max}.`);
					}
					break;
				case 'file':
					return this.validateFile(value);
			}
			return '';
		}).filter(v => Boolean(v)).find(v => v);

		return Strings.default(error);
	}

	public zeroValue(): any {
		if (typeof this.defaultvalue !== 'undefined') {
			return this.defaultvalue;
		}

		switch (this.fieldtype) {
			case FieldType.Number:
				return 0;
			case FieldType.Boolean:
				return false;
			default:
				return '';
		}
	}
}

export type FieldGroup = {
	groupType: 'group' | 'inline';
	name: string;
	order: number;
	fields: string[];
}

export type CMSModel = {
	uid: Types;
	name: string;
	description: string;
	mapping?: string;
	private: boolean;
	usergenerated: boolean;
}

export class ModelInfo extends BaseClass {
	public model: CMSModel;
	public autoPublish: boolean;
	public fields: Field[];

	constructor(obj: any) {
		super(obj);
		this.autoPublish = Boolean(obj.autoPublish);
		this.model = Objects.default(obj.model);
		this.fields = [];
		Lists.sort([...Lists.default(obj.fields)], 'order').forEach((field) => {
			this.fields.push(new Field(field));
		});
	}

	public getType(): Types {
		return this.model.uid;
	}

	public getField(uid: string): Field | undefined {
		return this.fields.find(f => f.uid === uid);
	}

	public fileField(): Field | undefined {
		return this.fields.find(f => f.fieldtype === FieldType.File);
	}

	public validate(data: any): any {
		const errors = {};
		this.fields.forEach(f => {
			const err = f.validate(data[f.uid]);
			if (err) {
				errors[f.uid] = err;
			}
		});
		return errors;
	}

	public clone(): any {
		return new ModelInfo(this.__data);
	}
}
