import styled from '@emotion/styled';
import CheckBox from '@mui/icons-material/CheckBox';
import DragHandle from '@mui/icons-material/DragHandle';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import {bindPopover, bindTrigger} from 'material-ui-popup-state';
import {usePopupState} from 'material-ui-popup-state/hooks';
import React, {useContext, useMemo} from 'react';
import {DragDropContext, Draggable} from 'react-beautiful-dnd';
import {useRecoilValue} from 'recoil';
import {fi, StrictModeDroppable} from '../../utils/helpers';
import {Lists} from '../../utils/lists';
import {Objects} from '../../utils/objects';
import {Strings} from '../../utils/strings';
import {ActionButton} from '../commons/ActionButton';
import TextAction from '../commons/TextAction';
import DeleteActionButton from './bulkActionButtons/Delete';
import PublishUnpublishActionButton from './bulkActionButtons/PublishUnpublish';
import RescheduleActionButton from './bulkActionButtons/Reschedule';
import {TableColumnDefinition} from './config';
import {selectedTableItemsSelector, TableContext, useTableActions, useTableState} from './state';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import AsyncActionButton from '../commons/AsyncActionButton';
import ItemsReportButton from './bulkActionButtons/ItemsReport';
import {FilterAltOff} from "@mui/icons-material";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 16px;
`;

const Count = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Actions = styled.div`
    column-gap: 2px;

    hr {
        margin: 6px 8px;
        height: 20px;
    }
`;

const ColumnSettingsElement = styled.div`
    min-width: 250px;
    max-height: 280px;
    display: grid;
    grid-template: "header" "list";
    grid-template-rows: 36px 1fr;
    user-select: none;
    font-size: 16px;
`;

const ColumnSettingsItems = styled.div`
    max-height: 280px;
    overflow: auto;
    grid-area: list;
`;

const ColumnSettingsItem = styled.div`
    padding: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .drag-handle {
        svg {
            width: 20px;
        }
    }

    &:hover {
        background-color: var(--color-background)
    }

    &:hover .drag-handle {
        color: var(--color-border);
    }

    &.dragging {
        border: 1px solid var(--color-blue);
        background: var(--color-selected);
    }

    .MuiCheckbox-root {
        padding: 0;

        svg {
            width: 20px;
            margin-right: 4px;
        }
    }
`;

const ColumnLabel = styled.div`
    display: flex;
    align-items: center;

    label {
        cursor: pointer;
    }
`;

const ColumnSettingsHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid var(--color-grey);
    grid-area: header;
`;

const DragHandleWrapper = styled.div`
    cursor: grab;
    color: var(--color-box-shadow);
`;

const TableColumns = () => {
    const state = useTableState();
    const {setColumnPreferences, resetPreferences, setFilter} = useTableActions();

    const onDragEnd = (result) => {
        const {destination, source} = result;
        if (!destination) {
            return;
        }
        // same position
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const cols = [...state.preferences.columns];
        const column = cols[source.index];
        cols.splice(source.index, 1);
        cols.splice(destination.index, 0, column);
        setColumnPreferences(cols);
    };

    const onColumnToggle = (evt, column) => {
        const cols = [...state.preferences.columns];
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].field === column.field) {
                cols[i] = {...cols[i], width: fi(evt.target.checked, -1, 0)};
                break;
            }
        }
        setColumnPreferences(cols);
    };

    const onSelectAll = () => {
        const cols = [...state.preferences.columns];
        for (let i = 0; i < cols.length; i++) {
            if (cols[i].width === 0) {
                cols[i] = {...cols[i], width: -1};
            }
        }
        setColumnPreferences(cols);
    };

    const onSelectDefault = () => {
        const cols = [...state.preferences.columns];
        for (let i = 0; i < cols.length; i++) {
            const column = {...cols[i]};
            cols[i] = column;
            const columnConfig = state.config.columns?.find(c => c.field === column.field);
            if (columnConfig && !columnConfig.default) {
                column.width = 0;
            } else {
                column.width = fi(column.width, column.width, -1);
            }
        }
        setColumnPreferences(cols);
    };

    const columns: any[] = useMemo(() => {
        return state.preferences.columns.map(c => {
            const column = Lists.default<TableColumnDefinition>(state.config.columns).find(i => i.field === c.field);
            return {
                column: c,
                hasFilter: typeof c.filter !== 'undefined' && c.filter !== null,
                field: c.field,
                enabled: c.width !== 0,
                label: Strings.default(Objects.default(column).label, c.field),
            };
        });
    }, [state.config, state.preferences]);

    const clearColumnFilter = (evt, col) => {
        evt.preventDefault();
        evt.stopPropagation();
        setFilter(col.field, null);
    };

    return (
        <ColumnSettingsElement>
            <ColumnSettingsHeading>
                <div>
                    Select: <TextAction onClick={onSelectAll}>All</TextAction> / <TextAction
                    onClick={onSelectDefault}>Default</TextAction>
                </div>
                <TextAction onClick={resetPreferences}>Reset</TextAction>
            </ColumnSettingsHeading>
            <ColumnSettingsItems>
                <DragDropContext onDragEnd={onDragEnd}>
                    <StrictModeDroppable droppableId="droppable">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {columns.map((col, idx) => (
                                    <Draggable draggableId={`c${idx}`} index={idx} key={`c${idx}`}>
                                        {(provided, snapshot) => (
                                            <ColumnSettingsItem
                                                ref={provided.innerRef}
                                                key={`cc${idx}`}
                                                data-testid={`toggle-column-${col.field}`}
                                                className={fi(snapshot.isDragging, 'dragging', '')}
                                                {...provided.draggableProps}
                                            >
                                                <ColumnLabel>
                                                    <Checkbox
                                                        className="form-checkbox"
                                                        aria-label={`column-${idx}`}
                                                        id={`column-${idx}`}
                                                        name={`column-${idx}`}
                                                        checked={col.enabled}
                                                        value={col.enabled}
                                                        tabIndex={0}
                                                        disabled={col.enabled && col.hasFilter}
                                                        color="primary"
                                                        checkedIcon={<CheckBox/>}
                                                        size="medium"
                                                        onChange={(evt) => onColumnToggle(evt, col)}
                                                        title={col.label}
                                                        disableRipple
                                                    />
                                                    <label htmlFor={`column-${idx}`}>
                                                        {col.label} {fi(col.enabled && col.hasFilter, <>(<TextAction
                                                        onClick={(e) => clearColumnFilter(e, col)}>clear
                                                        filter</TextAction>)</>, null)}
                                                    </label>
                                                </ColumnLabel>

                                                <DragHandleWrapper
                                                    className={'drag-handle'} {...provided.dragHandleProps}>
                                                    <DragHandle/>
                                                </DragHandleWrapper>

                                            </ColumnSettingsItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </StrictModeDroppable>
                </DragDropContext>
            </ColumnSettingsItems>
        </ColumnSettingsElement>
    );
};

const FilterCount = styled.span`
    font-size: 12px;
    color: var(--color-black);
`

const TableHeading = () => {
    const ctx = useContext(TableContext);
    const selected = useRecoilValue(selectedTableItemsSelector(ctx.type));
    const popupState = usePopupState({variant: 'popover', popupId: 'item-action-menu'});
    const {refresh, exportTable, clearFilters, getCurrentFilters} = useTableActions();

    const filterCount = (() => {
        return Lists.default(Objects.default(getCurrentFilters()).columns).filter((c: any) => Boolean(c.filter) || c.filter === false).length;
    })()


    const selectedCount = useMemo(() => {
        if (!selected.state.config.manualPublish || !selected.count) {
            return <>&nbsp;</>;
        }
        return `${selected.count} item(s) selected out of ${selected.outOf}`;
    }, [selected]);

    const exportResults = () => {
        return exportTable();
    };

    return (
        <Wrapper>
            <Count>{selectedCount}</Count>
            <Actions className="flex-row">
                {filterCount > 0 && (
                    <ActionButton
                        data-testid="table-clear-filter-action-button"
                        onClick={clearFilters}
                        title="Clear all active filters">
                        <FilterCount>({filterCount})</FilterCount>
                        <FilterAltOff/>
                    </ActionButton>
                )}
                {fi(Lists.default(selected.state.config.additionalActions).length > 0, (
                    <>
                        {Lists.default(selected.state.config.additionalActions).map((action: any, index: number) => (
                            <React.Fragment key={index}>{action}</React.Fragment>
                        ))}
                        <Divider orientation="vertical" variant="middle" flexItem/>
                    </>
                ), null)}
                {fi(selected.state.config.manualPublish && !selected.state.config.hideTableButtons, (
                    <>
                        <RescheduleActionButton/>
                        <PublishUnpublishActionButton/>
                        <DeleteActionButton/>
                        <Divider orientation="vertical" variant="middle" flexItem/>
                    </>
                ), null)}

                {selected.state.config.tableType === 'library' && <ItemsReportButton/>}

                <AsyncActionButton
                    onClick={() => exportResults()}
                    hotKey={'ctrl+x'}
                    data-testid="export-table"
                    title="Export table results to CSV [Ctrl-X]">
                    <SimCardDownloadIcon/>
                </AsyncActionButton>

                <AsyncActionButton
                    onClick={() => refresh()}
                    hotKey={'ctrl+r'}
                    data-testid="table-refresh-action-button"
                    title="Refresh [Ctrl-R]">
                    <RefreshIcon/>
                </AsyncActionButton>

                <ActionButton
                    {...bindTrigger(popupState)}
                    data-testid="table-columns-action-button"
                    title="Choose what columns to display and their order">
                    <SettingsIcon/>
                </ActionButton>

                <Popover {...bindPopover(popupState)}
                         anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                         transformOrigin={{vertical: 'top', horizontal: 'left'}}
                         data-testid="table-columns-action-menu"
                >
                    <TableColumns/>
                </Popover>

            </Actions>
        </Wrapper>
    );
};

export default TableHeading;
