import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import React from "react";

const TabPanelWrapper = styled.div`
  flex: 1;
  border-left: 1px solid var(--color-border);
  overflow: hidden;
  overflow-y: auto;
`

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

export const TabPanel = (props: TabPanelProps) => {
    const {children, value, index, ...other} = props;
    return (
        <TabPanelWrapper
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </TabPanelWrapper>
    );
}