import {ModalWrapperProps} from "./ModalWrapper";
import {Types} from "../../cms/types";
import React, {useCallback, useEffect, useRef} from "react";
import {Folder} from "../../cms/models/Folder";
import {fi} from "../../utils/helpers";
import FormRow from "../Form/FormRow";
import Form from "../Form/Form";
import {useForm} from "../Form/state";
import {useSnackbar} from "notistack";
import {Tree} from "../TreeMenu/Tree";
import {setRecoil} from "../../state/recoilNexus";
import {cacheBuster} from "../../state/state";

const Content = ({item, formId, ...props}: ModalWrapperProps & { item: Folder, formId: string }) => {
    const form = useForm()
    const {enqueueSnackbar} = useSnackbar();
    const editMode = item.getId() !== '';
    const tree = useRef<Tree>();

    useEffect(() => {
        tree.current = Tree.getTree(`tree-${Types.FOLDER}`)
    }, [])

    const onConfirm = useCallback(async () => {
        props.setError('')
        // validate form
        if (!form.validate()) {
            throw new Error('Please fix the errors and try again')
        }

        // determine folder order based on parent if any
        let order = tree.current?.rootNodes()
        if (item.parent) {
            const parent = tree.current!.findNode(item.parent)
            if (parent) {
                order = parent.children.length
            }
        }

        // we need to clone this because the item we received is sealed by recoil and we can't change it
        const tmp = item.clone({...form.state.values, order});
        return tmp.save().then((res: Folder) => {
            // either update the current node in the tree or append the created one
            if (editMode) {
                tree.current?.updateNode(res)
            } else {
                tree.current?.addNode(res)
                setRecoil(cacheBuster(Types.FOLDER), (val) => val + 1)
            }
            enqueueSnackbar(`Folder saved successfully`, {variant: 'success'});
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form])

    useEffect(() => {
        props.whenConfirm(onConfirm)
    }, [props, onConfirm])

    useEffect(() => {
        props.setTitle(`${fi(editMode, 'Edit', 'Create')} folder`)
    }, [editMode, props])

    return (
        <FormRow field='name'/>
    )
}

const EditCreateFolderModal = ({item, ...props}: ModalWrapperProps & { item: Folder }) => {
    const formId = 'edit-create-folder'
    return (
        <Form model={Types.FOLDER} id={formId} object={item}>
            <Content {...props} item={item} formId={formId}/>
        </Form>
    )
}

export default EditCreateFolderModal