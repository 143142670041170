import styled from '@emotion/styled';
import Link from '@mui/material/Link';
import React, {useEffect, useMemo} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import AppMenu from '../components/AppMenu/AppMenu';
import {MENU_ITEMS} from '../components/AppMenu/config';
import MenuSplitter from '../components/AppMenu/MenuSplitter';
import StreamSwitch from '../components/StreamSwitch/StreamSwitch';
import {TreeConfig} from '../components/TreeMenu/config';
import TreeComponent from '../components/TreeMenu/TreeComponent';
import UserSettings from '../components/UserSettings/UserSettings';
import {sessionAtom} from '../state/session';
import {selectedMenuItem} from '../state/state';
import {BusinessStreams, MENU_WIDTH_DEFAULT, MENU_WIDTH_SETTING} from '../utils/constants';
import {useLocalStorage} from '../utils/hooks';
import {Objects} from '../utils/objects';
import {Types} from "../cms/types";

const MenuElem = styled.nav`
  user-select: none;
  grid-area: nav;
  background: var(--color-white);
  border-right: 1px solid var(--color-border-light);
  box-shadow: -11px 0 9px 8px var(--color-border);
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin: 16px 16px 24px;
  align-items: center;

  a {
    padding: 8px;
  }
`;

const MenuItemsWrapper = styled.div`
  height: calc(100vh - 130px);
`;

const Menu = () => {
    const [width] = useLocalStorage(MENU_WIDTH_SETTING, MENU_WIDTH_DEFAULT);
    const session = useRecoilValue(sessionAtom);
    const setSelected = useSetRecoilState(selectedMenuItem);
    const location = useLocation();

    const selected = useMemo(() => {
        if (location.pathname.includes(Types.QUALIFICATION_GROUP_MAPPING) || location.pathname.includes(Types.SUBJECT_GROUP_MAPPING)) {
            return MENU_ITEMS.find((e)=>e.path.includes(Types.PRODUCT_MAPPING) );
        }
        return MENU_ITEMS.find(item => {
            return item.path === location.pathname || location.pathname.startsWith(item.path)
        });
    }, [location.pathname]);

    const businessStream = useMemo(() => {
        const defaultStream = BusinessStreams[0];
        if (session && session.selectedBusinessStream) {
            return Objects.default(BusinessStreams.find(stream => stream.id === session.selectedBusinessStream), defaultStream);
        }
        return defaultStream;
    }, [session]);

    const treeMenuRoutes = useMemo(() => {
        return TreeConfig.map((config, i) => (
            config.routes.map((route, j) => (
                <Route key={`${i}-${j}`} path={route} element={<TreeComponent config={config}/>}/>
            ))
        )).flat();
    }, []);

    useEffect(() => {
        setSelected(selected);
    }, [setSelected, selected]);

    if (!session) return null;

    return (
        <>
            <MenuElem data-testid="menu-component" style={{width: `${width}px`}}>
                <LogoContainer>
                    <Link href={'/'} data-testid="business-stream-logo">
                        <img src={businessStream.logo} alt={businessStream.name}/>
                    </Link>
                    <StreamSwitch/>
                </LogoContainer>
                <MenuItemsWrapper>
                    <Routes>
                        {treeMenuRoutes}
                        <Route path={'/*'} element={<AppMenu/>}/>
                    </Routes>
                </MenuItemsWrapper>
                <UserSettings/>
            </MenuElem>
            <MenuSplitter/>
        </>
    );
};

export default React.memo(Menu, () => true);
