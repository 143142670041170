import {Strings} from "../../utils/strings";
import {Objects} from "../../utils/objects";
import {Numbers} from "../../utils/numbers";
import {Node} from "./Node";

export class Job {
    public name: string;
    public description: string;
    public nodes: NodeJob[] = [];

    constructor(job: NodeJob) {
        this.name = job.name
        this.description = job.description
        this.nodes = [job];
    }

    addNode(job: NodeJob) {
        this.nodes.push(job)
        this.nodes.sort((a, b): number => {
            const aName = Objects.default(a.node).nodeName;
            const bName = Objects.default(b.node).nodeName;
            if (aName < bName) {
                return -1
            } else if (aName > bName) {
                return 1
            }
            return 0
        })
    }

    current(): NodeJob {
        let node: NodeJob | undefined;
        for (let i = 0; i < this.nodes.length; i++) {
            const n = this.nodes[i];
            if (n.status === "running") {
                return n;
            }
            if (!node) {
                node = n
            } else {
                if (n.ended && node.ended) {
                    if (n.ended.getTime() > node.ended.getTime()) {
                        node = n
                    }
                } else if (n.ended && !node.ended) {
                    node = n
                }
            }
        }
        return node as NodeJob;
    }

    lastRun(): Date | undefined {
        const list = this.nodes.map(n => n.ended).filter(n => n).sort().reverse()
        return list[0]
    }

    status(): string {
        const list = this.nodes.filter(n => n.started).sort().reverse()
        const last = list[0]
        if (!last) {
            return 'idle'
        }
        const ended = Numbers.default(last.ended?.getTime())
        const started = Numbers.default(last.started?.getTime())

        if (ended > started && ended < (new Date()).getTime() - 3600) {
            return 'idle'
        }

        return last.status
    }
}


export class NodeJob {
    public created: Date;
    public name: string;
    public description: string;
    public status: string;
    public message: string;
    public duration?: number;
    public started?: Date;
    public ended?: Date;
    public lastError: string;
    public nodeId: string;
    public node?: Node;

    constructor(item: any = {}) {
        this.created = new Date(item.created);
        this.description = Strings.default(item.description)
        this.status = Strings.default(item.status);
        this.message = Strings.default(item.message);
        this.lastError = Strings.default(item.lastError);
        this.nodeId = Strings.default(item.nodeId);
        this.name = Strings.default(item.name);

        if (item.started) {
            this.started = new Date(item.started)
        }
        if (item.ended && item.status !== "running") {
            this.ended = new Date(item.ended)
        }

        if (item.duration) {
            this.duration = item.duration;
        }
        if (item.node) {
            this.node = new Node(item.node)
        }
    }
}