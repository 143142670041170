import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Types, UUID} from "../types";
import {Strings} from "../../utils/strings";
import React, {ReactElement} from "react";
import {fi} from "../../utils/helpers";
import {Faded, PreviewRow, PreviewWrapper, Regular} from "./_style";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {Topic} from "./Topic";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";

@widgetConfig({
    group: WidgetGroup.Content,
    name: "Page content",
    description: "Displays [Page Content] items that match the specified [Topic].",
    constrains: {
        distinctProperty: "topic"
    }
})
export class PageContentWidget extends BaseWidget {
    @formField({
        name: "Select topic",
        fieldtype: FieldType.Reference,
        config: {refModel: Types.TOPIC},
        flags: {required: true}
    })
    public topic: UUID;

    @formField({
        name: "Show title",
        fieldtype: FieldType.Boolean,
        groupTitle: "Topic config",
        group: "topic-group"
    })
    public topicTitle: boolean
    @formField({
        name: "Show description",
        fieldtype: FieldType.Boolean,
        group: "topic-group"
    })
    public topicDescription: boolean;

    @formField({
        name: "Show title",
        fieldtype: FieldType.Boolean,
        groupTitle: "Page content items",
        group: "content-group"
    })
    public itemTitle: boolean
    @formField({
        name: "Show description",
        fieldtype: FieldType.Boolean,
        group: "content-group"
    })
    public itemDescription: boolean;

    @formField({
        name: "Show metadata",
        fieldtype: FieldType.Boolean,
        group: "meta-group",
        groupTitle: "Metadata"
    })
    public metadata: boolean = true;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.FreeText

        this.topic = Strings.default(item.topic)
        this.topicTitle = Boolean(item.topicTitle)
        this.topicDescription = Boolean(item.topicDescription)
        this.itemTitle = Boolean(item.itemTitle)
        this.itemDescription = Boolean(item.itemDescription)
        if (typeof item.metadata !== "undefined") {
            this.metadata = Boolean(item.metadata)
        }
    }

    getTitle(): ReactElement | string {
        const title = super.getTitle();
        const topic = getRecoil(references(this.topic))
        if (!topic) return title;
        return <>{title} {fi(topic, <Regular>:: {topic?.displayLabel()}</Regular>)}</>
    }

    preview(): ReactElement | null {
        const topic = getRecoil(references(this.topic)) as Topic
        return (
            <PreviewWrapper>
                <PreviewRow className='vertical'>
                    <label>{topic.displayLabel()}</label>
                    <span>{topic.description}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Topic display options</label>
                    {fi(this.topicTitle, <span>Show title</span>, <Faded>Show title</Faded>)} /
                    {fi(this.topicDescription, <span>Show description</span>, <Faded>Show description</Faded>)}
                </PreviewRow>
                <PreviewRow>
                    <label>Content display options</label>
                    {fi(this.itemTitle, <span>Show title</span>, <Faded>Show title</Faded>)} /
                    {fi(this.itemDescription, <span>Show description</span>, <Faded>Show description</Faded>)}
                </PreviewRow>
                <PreviewRow>
                    <label>Show metadata</label>
                    <span>{fi(this.metadata, 'Yes', 'No')}</span>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}