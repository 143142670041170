import {FormEvent, IFormEvents} from "../../components/Form/model";
import {formField} from "../../utils/decorators";
import {FieldType, ModelInfo} from "./__ModelInfo";
import {Types, UUID} from "../types";
import type {ISelectValue} from "../../components/Form/renderers/components/Select/SelectComponent";
import {Lists} from "../../utils/lists";

const Model = new ModelInfo({
    model: {
        uid: Types.SCHEDULE_EMAIL,
        name: 'Schedule email notification',
    },
    fields: [],
});
export class SubjectEmailNotification implements IFormEvents {
    public static model = Model;


    private __uuid: UUID =  crypto.randomUUID()
    public getId(): UUID {
        return this.__uuid;
    }

    @formField({
        uid: 'text',
        fieldtype: FieldType.Text,
        name: 'Email body',
        flags: {required: true},
        order: 1,
        config: {isHTML: true,height: 220},
    })
    public emailBody: string = "";

    @formField({
        uid: 'date',
        fieldtype: FieldType.Date,
        name: 'Date and time',
        order: 2,
    })

    @formField({
        uid: 'page',
        fieldtype: FieldType.Reference,
        name: 'Page',
        config: { refModel: Types.PAGE },
        flags: {required: true},
        order: 3,
    })

    public date: Date = new Date();

    public formOnFilterDataset(evt: FormEvent): ISelectValue[] {
        const dataset = Lists.default<ISelectValue>(evt.dataset)
        if (evt.fieldUID !== 'page') {
            return dataset
        }
        return dataset.filter(i => i.object.hasSubjectUpdates())
    }
}