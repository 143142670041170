import React, {useMemo, useRef, useState} from "react";
import {Field} from "../../../cms/models/__ModelInfo";
import CreatableSelect from 'react-select/creatable';
import {useFormField} from "../state";
import {components, MultiValueRemoveProps} from "react-select";
import Clear from '@mui/icons-material/Clear';
import {ISelectValue} from "./components/Select/SelectComponent";
import {Lists} from "../../../utils/lists";
import styled from "@emotion/styled";
import {fi} from "../../../utils/helpers";

const TagsList = styled<any>(CreatableSelect)`
  .react_select_tag__control {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    min-height: 39px;
  }

  .react_select_tag__multi-value {
    background: white;
    font-family: var(--font-regular);
    align-items: center;
    border: 1px solid var(--color-border-light);

    &:hover {
      background: var(--color-background);
    }

    .react_select_tag__multi-value__label {
      font-size: 16px;
    }
  }

  .react_select_tag__control--is-disabled {
    background: white;

    .react_select_tag__multi-value {
      opacity: 0.5;
    }
  }

  .react_select_tag__multi-value__remove {
    svg {
      width: 20px;
    }

    &:hover {
      background: var(--color-grey);
    }
  }
`
const LabelArea = styled.div`
  display: flex;
  justify-content: space-between;
`

const TabRenderer = ({field}: { field: Field }) => {
    const formField = useFormField(field.uid)
    const [options, setOptions] = useState<ISelectValue[]>([]);
    const ref = useRef<any>()

    const onChange = (inputValue, action) => {
        switch (action.action) {
            case 'create-option':
                setOptions(Lists.sort([...options, {...action.option, object: action.option.label}], 'label'));
                break;
            case 'remove-value': // remove with 'x'
            case 'pop-value': // remove with backspace
                break;
            case 'clear': // clear button
                formField.setValue([])
                return;
            default:
        }
        formField.setValue(inputValue.map(option => option.value))
    }

    const selections = useMemo(() => {
        if(formField.value) {
            return formField.value.map(v => options.find(o => o.value === v)).filter(o => o)
        }
        return []
    }, [formField.value, options])

    const MultiValueRemove = (mprops: MultiValueRemoveProps) => {
        return (
            <components.MultiValueRemove {...mprops}>
                <Clear/>
            </components.MultiValueRemove>
        );
    };

    return (
        <>
            <LabelArea>
                <label className={fi(field.flags.required, 'required')}>{field.name}</label>
            </LabelArea>
            <TagsList
                ref={ref}
                data-testid={'tag-select'}
                classNamePrefix={`react_select_tag`}
                className={`form-input custom-select-menu`}
                id={field.uid}
                isMulti
                isClearable
                menuPortalTarget={document.getElementById("list-portal-wrapper")}
                components={{DropdownIndicator: null, MultiValueRemove}}
                value={selections} // selected values
                onChange={onChange}
                placeholder={''}
                isDisabled={formField.previewMode}
            />
        </>
    )
}

export default TabRenderer;

