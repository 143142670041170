import { CMSObject } from './__CMSObject';
import { Strings } from '../../utils/strings';
import { Lists } from '../../utils/lists';
import { UUID } from '../types';
import { columnDefinition, formField, formHidden, tableHidden } from '../../utils/decorators';
import { ColumnType } from '../../components/TableComponent/renderers';
import { FieldType } from './__ModelInfo';
import { generateYearRange } from '../../utils/helpers';

// ProductMapped is the base class for all classes that have product data (Documents, Image, etc.)
export class ProductMapped extends CMSObject {
	public static productDataFields: string[] = [
		'assessment', 'child_assessment', 'qualification', 'subject', 'specification_group', 'qualification_group',
		'ct_unit', 'all_assessments', 'all_child_assessments', 'component', 'qualification_size',
	];

	@formHidden()
	public assessment: UUID[];
	@formHidden()
	public child_assessment: UUID[];
	public qualification: UUID[];
	public subject: UUID[];
	public specification_group: UUID[];
	public qualification_group: UUID[];
	@formHidden()
	public component: UUID[];
	@formHidden()
	public qualification_size: UUID[];
	@formHidden()
	public ct_unit: UUID[];
	public all_assessments: boolean;
	public all_child_assessments: boolean;

	@formField({
		name: 'Product mapping',
		fieldtype: FieldType.ProductData,
		flags: {required: true},
		order: 2.8,
	})
	public productData: string = '';

	@tableHidden
	@formField({
		group: 'series-year',
		fieldtype: FieldType.List,
		config: {
			options: generateYearRange(),
		},
	})
	public exam_year?: number;

	@tableHidden
	@columnDefinition({
		type: ColumnType.List,
	})
	@formField({
		group: 'series-year',
	})
	public series?: string;

	constructor(item: any = {}) {
		super(item);
		this.assessment = Lists.default(item.assessment);
		this.child_assessment = Lists.default(item.child_assessment);
		this.qualification = Lists.default(item.qualification);
		this.subject = Lists.default(item.subject);
		this.specification_group = Lists.default(item.specification_group);
		this.qualification_group = Lists.default(item.qualification_group);
		this.component = Lists.default(item.component);
		this.qualification_size = Lists.default(item.qualification_size);
		this.ct_unit = Lists.default(item.ct_unit);
		this.all_assessments = Boolean(item.all_assessments);
		this.all_child_assessments = Boolean(item.all_child_assessments);

		if (item.exam_year) {
			this.exam_year = Number(item.exam_year);
		}
		if (item.series) {
			this.series = Strings.default(item.series);
		}

		if (this.all_assessments) {
			this.productData = 'all_assessments';
		} else {
			this.productData = [...this.assessment, ...this.child_assessment, ...this.component, ...this.qualification_size, ...this.ct_unit].join(',');
		}
	}
}