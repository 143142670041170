import tinymce from 'tinymce/tinymce';

window['contentItemActions'] = {
	open: () => {
		// default
	},
	callback: () => {
		// default
	},
}

tinymce.PluginManager.add('contentitem', function (editor, _url) {
	editor.ui.registry.addIcon('contentitemicon', '<svg width="20" height="20" viewBox="2 2 20 20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">' +
		'<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-8-2h2v-4h4v-2h-4V7h-2v4H7v2h4z"></path>' +
		'</svg>');

	window['contentItemActions'].callback = (item: any, inline: boolean = false, itemType: string = '') => {
		if (inline) {
			editor.insertContent(`<inline data-uid='${item.__uuid}' class='mceNonEditable embedded-content-item item-inline ${item.__type} ${itemType}' data-title="${item.title || item.name}">&nbsp;</inline>`, {format: 'raw'});
		} else {
			editor.insertContent(`<card data-uid='${item.__uuid}' class='mceNonEditable embedded-content-item item-card ${item.__type} ${itemType}' data-title="${item.title || item.name}">&nbsp;</card>`, {format: 'raw'});
		}
	}

	editor.ui.registry.addButton('contentitem', {
		icon: 'contentitemicon',
		tooltip: 'Insert content item',
		onAction: (_api: any) => {
			window['contentItemActions'].open()
		}
	})

	return {
		getMetadata: () => ({
			name: 'Content Item plugin',
			url: 'https://teachcambridge.org/',
		}),
	};
})
