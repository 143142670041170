import styled from '@emotion/styled';
import FilterAlt from '@mui/icons-material/FilterAlt';
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, {Suspense, useMemo} from 'react';
import { fi } from '../../../utils/helpers';
import TextAction from '../../commons/TextAction';
import PageLoader from '../../Loader/PageLoader';
import { TableColumnDefinition } from '../config';
import { ColumnType } from '../renderers';
import { useTableActions, useTableState } from '../state';
import BooleanFilter from './BooleanFilter';
import DateFilter from './DateFilter';
import ListFilter from './ListFilter';
import NumberFilter from './NumberFilter';
import TextFilter from './TextFilter';

const Wrapper = styled.div`
  cursor: pointer;
  opacity: 0.1;

  &:hover {   
    opacity: 0.7;
  }

  &.active {
    opacity: 0.9;
    color: var(--color-blue)
  }
`;

const FilterWrapperElement = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 240px;
  min-height: 50px;
  border: 1px inset solid var(--color-border-light);
  user-select: none;

  h6 {
    background-color: var(--color-background);
    font-family: var(--font-semi-bold);
    font-size: 16px;
    padding: 8px;
    display: block;
    width: 100%;

    span {
      float: right;
    }
  }

  label {
    font-size: 14px;
    font-family: var(--font-regular);
  }
`;

const FilterComponentWrapper = styled.div`
  position: relative;
`;

export type ColumnFilterProps = {
	definition: TableColumnDefinition
	onChange?: (val: any) => void;
}

const ColumnFilter = (props: ColumnFilterProps) => {
	const popupState = usePopupState({variant: 'popover', popupId: 'column-filter-popover'});
	const {preferences} = useTableState();
	const {setFilter} = useTableActions();

	const fieldPreferences = useMemo(() => {
		return preferences.columns.find(c => c.field === props.definition.field) as any;
	}, [ props.definition, preferences ]);

	const onClearFilter = () => {
		setFilter(props.definition.field, null);
	};

	const onChange = (value) => {
		setFilter(props.definition.field, value);
	};

	const filterContent = useMemo(() => {
		switch (props.definition.type) {
			case ColumnType.List:
			case ColumnType.Reference:
				return <ListFilter {...props} preferences={fieldPreferences} onChange={onChange}/>;
			case ColumnType.File:
			case ColumnType.Link:
			case ColumnType.Text:
				return <TextFilter preferences={fieldPreferences} {...props} onChange={onChange}/>;
			case ColumnType.Number:
				return <NumberFilter preferences={fieldPreferences}  {...props} onChange={onChange}/>;
			case ColumnType.Duration:
			case ColumnType.Date:
				return <DateFilter preferences={fieldPreferences} {...props} onChange={onChange}/>;
			case ColumnType.Boolean:
				return <BooleanFilter {...props} preferences={fieldPreferences} onChange={onChange}/>;
			default:
				return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ props ]);

	if ((props.definition.filter && props.definition.filter.noFilter) || !filterContent) {
		return null;
	}

	const isDefined = typeof fieldPreferences.filter !== 'undefined' && fieldPreferences.filter !== null;

	return (
		<>
			<Wrapper className={fi(isDefined, 'active')} {...bindTrigger(popupState)}>
				{fi(isDefined, <FilterAlt/>, <FilterAltOutlined/>)}
			</Wrapper>
			<Popover {...bindPopover(popupState)}
					 anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
					 transformOrigin={{vertical: 'top', horizontal: 'left'}}
					 data-testid="column-filter-popover-wrapper"
			>
				<FilterWrapperElement>
					<Typography variant={'h6'}>Filter column <TextAction onClick={onClearFilter}
																		 data-testid={'clear-filter-action'}>Clear
						filter</TextAction></Typography>
					<FilterComponentWrapper>
						<Suspense fallback={<PageLoader size={24}/>}>
							{filterContent}
						</Suspense>
					</FilterComponentWrapper>
				</FilterWrapperElement>

			</Popover>
		</>
	);
};

export default ColumnFilter;
