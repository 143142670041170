import styled from '@emotion/styled';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useRecoilState } from 'recoil';
import Client from '../../cms/client';
import { sessionAtom } from '../../state/session';
import { BusinessStreams } from '../../utils/constants';

const StreamOptions = styled.div`
  padding: 1px;
  display: flex;
  flex-direction: column;
  background: var(--color-grey);
  gap: 1px;
`;

const StreamOption = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  width: 100%;
  align-items: center;
  background: var(--color-white);

  &:last-of-type {
    padding-bottom: 8px;
  }

  &:hover {
    background: var(--color-grey);
  }
`;

const StreamSwitch = () => {
	const {enqueueSnackbar} = useSnackbar();
	const popupState = usePopupState({variant: 'popover', popupId: 'stream-select-popup'});
	const [ session, setSession ] = useRecoilState(sessionAtom);

	if (!session) return null;

	const handleStreamChange = (stream: any) => {
		popupState.close();
		Client.switchStream(stream.id).then(s => {
			setSession(s);
			window.location.assign('/');
		}).catch((err) => {
			enqueueSnackbar('Error switching stream: ' + err, {variant: 'error'});
		});
	};

	if (session.availableStreams().length > 1) {
		return (
			<>
				<IconButton {...bindTrigger(popupState)} title="Switch stream" data-testid="switch-stream-button">
					<ArrowDropDownRoundedIcon/>
				</IconButton>
				<Popover {...bindPopover(popupState)}
						 anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
						 transformOrigin={{vertical: 'top', horizontal: 'right'}}
						 data-testid="switch-stream-popover">
					<StreamOptions>
						{BusinessStreams.slice(1).map((stream, idx) => (
							<StreamOption tabIndex={0} onClick={() => handleStreamChange(stream)}
										  key={idx}
										  data-testid={`switch-stream-${stream.id}`}>
								<img src={stream.logo} title={stream.name} alt={stream.name}/>
							</StreamOption>
						))}
					</StreamOptions>
				</Popover>
			</>
		);
	}

	return null;
};

export default StreamSwitch;
