import {ProductMapped} from "./__ProductMapped";
import {tableColumn} from "../../utils/decorators";
import {ColumnType} from "../../components/TableComponent/renderers";
import {Types} from "../types";
import {fi} from "../../utils/helpers";
import Photo from "@mui/icons-material/Photo";
import {ModelInfo} from "./__ModelInfo";
import {getRecoil, getRecoilPromise} from "../../state/recoilNexus";
import {cmsModelsSelector} from "../../state/models";
import {references, selectedTreeItem} from "../../state/state";
import React from "react";
import {ReactComponent as TEXTIcon} from "../../assets/images/TEXT.svg";
import {ReactComponent as VIDEOIcon} from "../../assets/images/VIDEO.svg";
import {ReactComponent as LINKIcon} from "../../assets/images/LINK.svg";
import {ReactComponent as EVENTIcon} from "../../assets/images/LINK-ONLINE-COURSE.svg";

const itemTypes: string[] = [
    Types.DOCUMENT, Types.IMAGE, Types.VIDEO, Types.AUDIO, Types.LINK, Types.TEXT, Types.EVENT_MATERIAL,
]

export class GenericLibrary extends ProductMapped {
    public static title = 'Content item';

    constructor(item: any = {}) {
        super(item);
    }

    @tableColumn({
        order: 0.2,
        label: 'Type',
        field: '__type',
        default: true,
        type: ColumnType.List,
        filter: {
            noFilter: true
        }
    })
    public __typeColumn(plain?: boolean) {
        if (typeof this['fileInfo'] === 'function') {
            const info = this['fileInfo']();
            if (info) {
                return info.icon(plain);
            }
        }
        switch (this.getType()) {
            case Types.TEXT:
                return fi(plain, 'Page content item', <TEXTIcon title='Page content item'/>)
            case Types.VIDEO:
                return fi(plain, 'Video link item', <VIDEOIcon title='Video link item'/>)
            case Types.EVENT_MATERIAL:
            case Types.LINK:
                if (this.__meta.tags?.join('').includes('#online_course')) {
                    return fi(plain, 'Online course link', <EVENTIcon title='Online course link'/>)
                }
                return fi(plain, 'Link item', <LINKIcon title='Link item'/>)
            default:
                return fi(plain, 'Unknown content item', <Photo
                    style={{fontSize: '36px', color: 'grey', fill: 'grey'}}/>)
        }
    }

    public async modelInfo(): Promise<ModelInfo> {
        const models = await Promise.all(itemTypes.map(async (type) => {
            return getRecoilPromise(cmsModelsSelector(type));
        }))
        const model: ModelInfo = new ModelInfo({
            model: {
                uid: "library",
                name: "Media Library",
                description: "Media Library"
            }
        })
        models.forEach(m => {
            m.fields.forEach(f => {
                if (!model.fields.find(fld => fld.uid === f.uid)) {
                    model.fields.push(f);
                }
            })
        })
        return model
    }

    // Send folder as filter
    public applyFilters(): any {
        const folder: any = getRecoil(selectedTreeItem)
        if (folder) {
            return {folder: folder.id}
        } else {
            const match = document.location.pathname.match(/library\/(.[^/]*)/)
            if (match && match[1]) {
                return {folder: match[1]}
            }
        }
        return {}
    }

    @tableColumn({
        order: 2,
        label: 'Qualification Mapping',
        field: 'qualification',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __QualificationMapping(plain?: boolean): any {
        const labels: string[] = []
        if (this.qualification) {
            this.qualification.forEach((id:string) => {
                const data = getRecoil(references(id))
                if (data) {
                    labels.push(data.displayLabel())
                }
            })
        }
        return labels.join(', ')
    }

    @tableColumn({
        order: 3.5,
        label: 'Subject Mapping',
        field: 'subject',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __SubjectMapping(plain?: boolean): any {
        const labels: string[] = []
        if(this.subject){
            this.subject.forEach((id:string) => {
                const data = getRecoil(references(id))
                if (data) {
                    labels.push(data.displayLabel())
                }
            })
        }
        return labels.join(', ')
    }
}
