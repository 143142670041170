import {BaseWidget, widgetConfig, WidgetGroup, WidgetType} from "./PageWidget";
import {Strings} from "../../utils/strings";
import {fi} from "../../utils/helpers";
import {formField} from "../../utils/decorators";
import {FieldType} from "./__ModelInfo";
import {Types} from "../types";
import {ReactElement} from "react";
import React from "react";
import {PreviewRow, PreviewWrapper, Regular} from "./_style";
import {getRecoil} from "../../state/recoilNexus";
import {references} from "../../state/state";
import {DisplayMode} from "./__CMSObject";
import {FormEvent, IFormEvents} from "../../components/Form/model";
import {Objects} from "../../utils/objects";

const HOMEPAGE_ITEMS = [
    {label: 'Subject updates', value: 'subject_updates'},
    {label: 'Key dates', value: 'key_dates'},
    {label: 'Upcoming events', value: 'upcoming_events'},
    {label: 'Online courses', value: 'online_courses'},
    {label: 'Global announcements', value: 'global_announcements'},
];

@widgetConfig({
    group: WidgetGroup.Other,
    name: "Landing page",
    description: "Displays one of four summary sections to the page.\n• It can only be added to the page once with the same dataset.\n• It can only be added to the Homepage.",
    constrains: {
        onlyOnHomePage: true,
        oneOf: {field: 'item', options: HOMEPAGE_ITEMS.map(i => i.label)}
    }
})
export class HomepageWidget extends BaseWidget implements IFormEvents {
    @formField({
        name: "Dataset",
        fieldtype: FieldType.List,
        order: 1,
        flags: {required: true},
        config: {options: HOMEPAGE_ITEMS.map(i => Strings.localizedTxt(i.label))}
    })
    public item: string;

    @formField({
        name: "Number of items",
        fieldtype: FieldType.List,
        order: 2,
        group: "group",
        flags: {required: true},
        config: {options: [Strings.localizedTxt("2"), Strings.localizedTxt("4")]}
    })
    public numberOfItems: string;

    @formField({
        name: "Page link",
        fieldtype: FieldType.Reference,
        order: 3,
        group: "group",
        flags: {required: true},
        config: {refModel: Types.PAGE}
    })
    public pageLink: string;

    constructor(item: any = {}) {
        super(item);
        this.type = WidgetType.Homepage
        const sel = HOMEPAGE_ITEMS.find(i => i.value === Strings.default(item.item))
        this.item = fi(sel, sel?.label, Strings.default(item.item));
        this.numberOfItems = Strings.default(item.numberOfItems)
        this.pageLink = Strings.default(item.pageLink)
    }


    getTitle(): ReactElement | string {
        const title = super.getTitle();
        return <>{title} {fi(this.item, <Regular>:: {this.item}</Regular>)}</>
    }

    formOnValidate(evt: FormEvent): any | void {
        // need to check if there is another widget with the same 'item'
        let existingWidgets: any[] = [];
        if (typeof evt.state.parentForm?.values.config === "string") {
            existingWidgets = JSON.parse(evt.state.parentForm?.values.config)
        } else {
            existingWidgets = JSON.parse(JSON.stringify(evt.state.parentForm?.values.config))
        }

        const item = Objects.default(HOMEPAGE_ITEMS.find(i => i.label === evt.state.values.item)).value;
        const existing = existingWidgets.filter(w => w.type === this.type && w.item === item && w.id !== this.id);
        if (existing.length) {
            return {item: "This item is already added to the homepage"}
        }
    }

    formOnBeforeSave(evt: FormEvent): any {
        // at this point 'item' is the label not the value, so we need to change that before saving
        const data = {...evt.state.values}
        data.item = Objects.default(HOMEPAGE_ITEMS.find(i => i.label === data.item)).value;
        return data
    }

    preview(): ReactElement | null {
        const page = getRecoil(references(this.pageLink))
        let pageTitle = "";
        if (page) {
            pageTitle = page.displayLabel(DisplayMode.FULL);
        }
        return (
            <PreviewWrapper>
                <PreviewRow>
                    <label>Selected item</label>
                    <span>{this.item}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Number of items</label>
                    <span>{this.numberOfItems}</span>
                </PreviewRow>
                <PreviewRow>
                    <label>Page link</label>
                    <span>{pageTitle}</span>
                </PreviewRow>
            </PreviewWrapper>
        )
    }
}