import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Form from '../Form/Form';
import {PublishSchedule} from '../../cms/models/_PublishSchedule';
import {CMSObject} from '../../cms/models/__CMSObject';
import {useForm, useFormField} from '../Form/state';
import {ModalActions, ModalWrapperProps} from './ModalWrapper';
import Client from '../../cms/client';
import {setRecoil} from '../../state/recoilNexus';
import {cacheBuster} from '../../state/state';
import {RenderFormFields} from '../Form/utils';
import {fi} from '../../utils/helpers';
import {Dates} from "../../utils/dates";
import { Types } from '../../cms/types';

const cancelButton: ModalActions = {type: 'cancel', hint: 'Cancel', label: 'Cancel', color: 'secondary'};
const publishButton: ModalActions = {type: 'ok', hint: 'Publish', label: 'Publish', color: 'primary'};
const scheduleButton: ModalActions = {type: 'ok', hint: 'Schedule', label: 'Schedule', color: 'primary'};
const continueButton: ModalActions = {type: 'ok', hint: 'Continue', label: 'Continue', color: 'primary'};

const PublicModalContent = (props: ModalWrapperProps & { items: CMSObject[], onConfirm?: () => void }) => {
    const form = useForm();
    const typeField = useFormField('type');
    const [inReview, setInReview] = useState(false);

    const onConfirm = useCallback((): Promise<void> => {
        if (inReview) {
            return new Promise((resolve, reject) => {
                try {
                    const uuids = props.items.map(i => i.getId())
                    const obj = form.state.getSaveObject();
                    Client.publish({...obj, uuids}).then(() => {
                        if (props.onConfirm) {
                            props.onConfirm();
                        }
                        Client.references<CMSObject>(uuids).forEach((i) => {
                            setRecoil(cacheBuster(`${i.getType()}/${i.getId()}`), (val) => val + 1);
                        });
                        resolve();
                    }).catch(reject);
                } catch (e: any) {
                    reject(e);
                    return;
                }
            });
        } else {
            if (form.validate()) {
                setInReview(true);
            }
            return new Promise((resolve, reject) => {
                reject({message: ''});
            })
        }
    }, [inReview, form]);

    useEffect(() => {
        props.setTitle('Publish/Schedule');
        props.setActions(cancelButton, publishButton);
        // eslint-disable-next-line
    }, [props]);

    useEffect(() => {
        props.whenConfirm(onConfirm);
    }, [onConfirm]);

    useEffect(() => {
        if (inReview) {
            if (typeField.value === 'Schedule') {
                props.setActions(cancelButton, scheduleButton);
            } else {
                props.setActions(cancelButton, publishButton);
            }
        } else {
            props.setActions(cancelButton, continueButton);
        }
        // eslint-disable-next-line
    }, [typeField.value, inReview]);

    const fields = useMemo(() => {
        if (inReview) {
            const total = props.items.length;
            const publicItems = props.items.filter(f => {
                if ([Types.DOCUMENT, Types.AUDIO, Types.VIDEO, Types.TEXT].includes(f.getType())) {
                    return (f as any).public_from
                }
                return false;
            }).map(f => (f as any).public_from);
            return (
                <>
                    You are about to {fi(typeField.value === 'Schedule', <><strong>schedule</strong> for publishing</>,
                    <strong>publish</strong>)} {props.items.length} item{fi(props.items.length > 1, 's', '')}.
                    <p style={{padding: 16, lineHeight: 1.4}}>
                        Start
                        date: <strong>{typeField.value === 'Schedule' && Dates.local(form.state.values.published) || 'Now'}</strong> {typeField.value === 'Schedule' &&
                        <span
                            style={{color: 'var(--color-border)'}}>({Dates.timeAgo(form.state.values.published)})</span>}
                        <br/>
                        {(typeField.value === 'Schedule' && form.state.values.unpublish) && (
                            <>
                                Expiry date: <strong>{Dates.local(form.state.values.unpublish)}</strong> <span
                                style={{color: 'var(--color-border)'}}>({Dates.timeAgo(form.state.values.unpublish)})</span>
                            </>
                        ) || (
                            <>
                                No expiration date
                            </>
                        )}
                    </p>
                    <p style={{color: 'var(--color-orange)'}}>
                        {total === 1 && (
                            <>
                                {publicItems.length !== 0 && (
                                    <>Item is set to be publicly available (accessed by trial users)
                                        on <strong>{Dates.local(publicItems[0])} ({Dates.timeAgo(publicItems[0])})</strong></>
                                )}
                            </>
                        )}
                        {total > 1 && (
                            <>
                                {publicItems.length !== 0 && (
                                    <>{publicItems.length} out of {total} items are set to be publicly available
                                        (accessed by trial users)</>
                                )}
                            </>
                        )}
                    </p>
                </>
            )
        } else {
            return RenderFormFields(form.state);
        }
    }, [form, inReview]);

    return <>{fields}</>;
};

const PublishModal = ({items, onConfirm, ...props}: ModalWrapperProps & {
    items: CMSObject[],
    onConfirm?: () => void
}) => {
    const formId = 'publish-schedule-folder';

    return (<Form model={PublishSchedule.model} id={formId}
                  object={fi(items.length === 1, new PublishSchedule(items[0]), new PublishSchedule())}>
            <PublicModalContent {...props} items={items} onConfirm={onConfirm}/>
        </Form>
    );
};

export default PublishModal;
