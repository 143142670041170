import {selectorFamily} from 'recoil';
import Client from '../../cms/client';
import {TreeObject} from '../../cms/models/__CMSObject';
import {UUID} from '../../cms/types';
import {cacheBuster} from '../../state/state';
import {Lists} from '../../utils/lists';
import {Strings} from '../../utils/strings';
import {ITreeItem} from "./Tree";

export const findTreeNode = (nodes: ITreeItem[], predicate: string | ((node: ITreeItem) => boolean)) => {
	for (let i = 0; i < nodes.length; i++) {
		const node = nodes[i];
		if (typeof predicate === 'string') {
			if (node.id === predicate) {
				return node
			}
		} else if (typeof predicate === 'function') {
			if (predicate(node)) {
				return {...node};
			}
		}
		if (node.children.length > 0) {
			const matched = findTreeNode(node.children, predicate);
			if (matched) {
                return {...matched};
            }
		}
	}
	return null;
}

export const addNode = (nodes: ITreeItem[], object: TreeObject) => {
	const node: ITreeItem = {
		id: object.getId(),
		opened: false,
		parentId: object.parent,
		order: object.order,
		selected: false,
		children: [],
		object: object,
		index: 0,
	}

    if (object.parent) {
		const parent = findTreeNode(nodes, object.parent);
		if (parent) {
			node.parent = parent;
			parent.children.push(node);
			Lists.sort(parent.children, 'order');
		}
	} else {
		nodes.push(node);
		Lists.sort(nodes, 'order');
	}
}

export const updateNode = (nodes: ITreeItem[], object: TreeObject) => {
	const node = findTreeNode(nodes, (i) => i.id === object.getId());
	if (node) {
		node.object = object.clone();
	}
}

export const walkTreeDown = (node: ITreeItem, cb: (node: ITreeItem) => void, clone: boolean = false) => {
	if (clone) {
		const newNode = {...node};
		cb(newNode);

		if (newNode.children) {
			newNode.children = [ ...newNode.children.map(child => walkTreeDown({
				...child,
				parent: newNode,
			}, cb, clone)) ] as any[];
		}
		return newNode;
	}

	cb(node);
	if (node.children) {
		node.children.forEach(child => walkTreeDown(child, cb));
	}
};

export const walkTreeUp = (node: ITreeItem, cb: (node: ITreeItem) => void) => {
	if (!node) return

	cb(node);
	if (node.parent) {
		walkTreeUp(node.parent, cb);
	}
};

export const isQuickAccess = (type: string, id: UUID): boolean => {
	const list = JSON.parse(Strings.default(localStorage.getItem(`qa-${type}`), '[]'))
	return list.includes(id)
}

export const toggleQuickAccess = (type: string, id: UUID): void => {
	const key = `qa-${type}`;
	const oldList = JSON.parse(Strings.default(window.localStorage.getItem(key), '[]'))
	const newList = [...oldList];
	if (oldList.includes(id)) {
		newList.splice(oldList.indexOf(id), 1);
	} else {
		newList.push(id);
	}
	window.localStorage.setItem(key, JSON.stringify(newList));
	const event = new StorageEvent('storage', {
		key: key,
		oldValue: JSON.stringify(oldList),
		newValue: JSON.stringify(newList),
	});
	window.dispatchEvent(event);
}

export const treeObjectsSelector = selectorFamily<TreeObject[], string>({
	key: 'treeObjectsSelector',
	get: (treeType) => async ({get}) => {
		get(cacheBuster(treeType))
		const response = await Client.query<TreeObject>({
			limit: -1,
			types: [ treeType ]
		})
		return response.results
	},
})
