import {ModalActions, ModalWrapperProps} from './ModalWrapper';
import {useForm} from '../Form/state';
import {BulkEdit} from '../../cms/models/_BulkEdit';
import React, {useCallback, useEffect, useMemo} from 'react';
import Form from '../Form/Form';
import {RenderFormFields} from '../Form/utils';
import {UUID} from '../../cms/types';
import Client from '../../cms/client';
import {useSnackbar} from 'notistack';
import {Strings} from '../../utils/strings';

const saveButton: ModalActions = {type: 'ok', hint: 'Save', label: 'Save', color: 'primary'};

const BulkEditModalContent = (props: ModalWrapperProps & {ids: UUID[], onConfirm: () => void}) => {
	const form = useForm();
	const {enqueueSnackbar} = useSnackbar();

	const onConfirm = useCallback(async () => {
		props.setError('');
		// validate form
		if (!form.validate()) {
			throw new Error('Please fix the errors and try again');
		}

		const obj = form.state.getSaveObject();
		delete obj.__type;

		return Client.bulkUpdate(props.ids, obj).then(() => {
			props.onConfirm();
			enqueueSnackbar(Strings.ofPlural(props.ids.length, 'item', 'items') + ' edited successfully', {variant: 'success'});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form]);

	useEffect(() => {
		props.setTitle('Edit multiple items');
		props.setActions(saveButton);
		props.whenConfirm(onConfirm);
		// eslint-disable-next-line
	}, [props, onConfirm]);

	const fields = useMemo(() => {
		return RenderFormFields(form.state);
	}, [form]);

	return <>{fields}</>;
};

const BulkEditModal = ({ids, onConfirm, ...props}: ModalWrapperProps & {ids: UUID[], onConfirm: () => void}) => {
	const formId = 'bulk-edit-modal';

	return (
		<Form model={BulkEdit.model} id={formId}>
			<BulkEditModalContent {...props} ids={ids} onConfirm={onConfirm} />
		</Form>
	);
};

export default BulkEditModal;