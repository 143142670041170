import Delete from '@mui/icons-material/Delete';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {useRecoilValue} from 'recoil';
import Client from '../../../cms/client';
import {Messages} from '../../../utils/messages';
import {ActionButton} from '../../commons/ActionButton';
import {useModalDialog} from '../../ModalDialogs/effect';
import {selectedTableItemsSelector, TableContext, useTableActions} from '../state';
import {Strings} from "../../../utils/strings";

const DeleteActionButton = () => {
    const ctx = useContext(TableContext)
    const [disabled, setDisabled] = useState(false);
    const [title, setTitle] = useState(Messages.Delete);
    const state = useRecoilValue(selectedTableItemsSelector(ctx.type));
    const {onDelete} = useTableActions()
    const {confirmation} = useModalDialog();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        let shouldDisable = state.count === 0;
        let newTitle: string = Messages.NoSelectedToDeleteItem;

        if (state.count > 0) {
            state.objects.forEach(item => {
                if (!item) return

                if (item.isPublished()) {
                    shouldDisable = true;
                    newTitle = Messages.CantDeletePublishedItem;
                }
            });
        }

        setTitle(newTitle as Messages);
        setDisabled(shouldDisable);
    }, [state]);

    const onDeleteSelected = () => {
        const msg = `Are you sure you want to delete the ${Strings.pluralize(state.count, 'item', 'items')} selected?`
        confirmation(msg, async () => {
            return Client.deleteMore(state.ids)
                .then(() => {
                    onDelete(...state.ids)
                    enqueueSnackbar(Strings.ofPlural(state.count, 'Item', 'Items') + ' deleted successfully', {variant: 'success'});
                })
                .catch((error) => {
                    enqueueSnackbar(`Error deleting ${Strings.ofPlural(state.count, 'item', 'items')}: ${error}`, {variant: 'error'});
                });
        });
    };

    return (
        <ActionButton
            data-testid="table-delete-action-button"
            onClick={onDeleteSelected}
            disabled={disabled}
            title={title}>
            <Delete/>
        </ActionButton>
    );
};

export default DeleteActionButton;
