import { getRecoil } from '../../state/recoilNexus';
import { references } from '../../state/state';
import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { UUID } from '../types';
import { CMSObject, DisplayMode } from './__CMSObject';

export class Component extends CMSObject {
	public static title = 'Component';
	public static autoPublish = true;

	public id: number;
	public type: string;
	public name: string;
	public assessment?: UUID;
	public child_assessment?: UUID;

	constructor(obj: any = {}) {
		super(obj);

		this.id = Numbers.default(obj.id);
		this.type = Strings.default(obj.type);
		this.name = Strings.default(obj.name);
		this.assessment = obj.assessment;
		this.child_assessment = obj.child_assessment;
	}

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		const id = this.id.toString().padStart(2, '0')
		let short = `Unit ${id}`;
		let parent: string = Strings.default(this.assessment, this.child_assessment);
		if (parent) {
			const assessment = getRecoil(references(parent));
			if (assessment) {
				short = `${assessment.displayLabel()}/${id}`;
			}
		}

		switch (options) {
			case DisplayMode.DETAILED:
			case DisplayMode.FULL:
				return `${short} - ${this.name}`;
			case DisplayMode.SHORT:
			default:
				return short;
		}

	}
}
