import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;

  canvas {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: -12px;
  }
`

export const Box = styled(Paper)`
  padding: 8px 16px;
  margin-bottom: 16px;

  h1 {
    margin-bottom: 16px;
    color: var(--color-backdrop);
  }
`