import {FieldType, ModelInfo} from './__ModelInfo';
import {Types, UUID} from '../types';
import {BaseClass} from './__base';
import {formField} from '../../utils/decorators';
import {IFormEvents} from '../../components/Form/model';

const Model = new ModelInfo({
	model: {
		uid: Types.MOVE_ITEMS,
		name: 'Move items',
	},
	fields:[]
})

export class MoveItems extends BaseClass implements IFormEvents  {
	public static model = Model;

	@formField({
		fieldtype: FieldType.Reference,
		name: "Select folder",
		flags: {required: true, multiple: false},
		config: {refModel: Types.FOLDER}
	})
	public folderId: UUID = '';

	constructor(item: any = {}) {
		super(item);
	}
}