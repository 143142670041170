import React from "react";
import Typography from "@mui/material/Typography";
import Stats from "../../components/Homepage/Stats";
import AccountOverview from "../../components/Homepage/AccountOverview";
import Reports from "../../components/Homepage/Reports";
import {Box, Wrapper} from "./styling";
import Roles from '../../components/Homepage/Roles';


// HomepageComponent is the page that shows on '/', displaying the stats and graphs
// All the subcomponents used here are defined in /components/Homepage
const HomepageComponent = () => {
    return (
        <Wrapper>
            <div className='flex-row' style={{alignItems: 'flex-start', columnGap: 16}}>
                <div className='flex-column'>
                    <AccountOverview/>
                </div>
                <Box>
                    <Typography variant='h1'>User roles</Typography>
                    <Roles/>
                </Box>
                <Box>
                    <Typography variant='h1'>Uploaded files overview</Typography>
                    <Stats/>
                </Box>
                <Box>
                    <Typography variant='h1'>Downloadable reports</Typography>
                    <Reports/>
                </Box>
            </div>

        </Wrapper>
    );
};

export default HomepageComponent;
