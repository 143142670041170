import {CMSObject} from "./__CMSObject";
import {UUID} from "../types";
import {Strings} from "../../utils/strings";
import {Lists} from "../../utils/lists";
import {formField, linkedFormField} from "../../utils/decorators";
import {FormEvent} from "../../components/Form/model";
import {Messages} from '../../utils/messages';

export class HelpInfo extends CMSObject {
	public static title = "Help Info";
	public title: string;
	public description: string;
	@formField({
		flags: {
			required: true
		}
	})
	public selector: string;
	public all_pages: boolean;
	@linkedFormField('all_pages')
	@formField({
		flags: {
			dummyRequired: true
		}
	})
	public page: UUID[];

	constructor(obj: any = {}) {
        super(obj);

		this.title = Strings.default(obj.title);
		this.description = Strings.default(obj.description);
		this.selector = Strings.default(obj.selector);
		this.all_pages = Boolean(obj.all_pages);
		this.page = Lists.default(obj.page)
    }

	public formOnRenderField(evt: FormEvent): boolean {
		if (evt.fieldUID === "page") {
			return !Boolean(evt.state.values.all_pages)
		}
		return true;
	}

	public formOnBeforeSave(evt: FormEvent): any {
		const result = {...evt.state.values}
		if (Boolean(result.all_pages)) {
			delete(result.page)
		}
		return result;
	}

	public async canDelete(): Promise<string> {
		if (this.isPublished()) {
			return Messages.CantDeletePublishedItem;
		}
		return '';
	}

	formOnValidate(evt: FormEvent): any {
		const errors: any = {};
		const values = evt.state.values;
		if (values.all_pages === false && !values.page.length) {
			errors.page = Messages.FieldIsRequired;
		}
		return errors;
	}
	public formOnFieldChange(evt: FormEvent): void {
		if (evt.fieldUID === 'all_pages') {
			evt.state.fieldState('page').refresh()
		}
	}
}