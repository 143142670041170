import styled from "@emotion/styled";

export const ItemStatus = styled.span`
  font-family: var(--font-semi-bold);

  &.published {
    color: var(--color-green)
  }

  &.scheduled {
    color: var(--color-orange)
  }
`
