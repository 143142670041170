import { fi } from '../../utils/helpers';
import { Lists } from '../../utils/lists';
import { Numbers } from '../../utils/numbers';
import { Strings } from '../../utils/strings';
import { UUID } from '../types';
import { CMSObject, DisplayMode } from './__CMSObject';
import {ChildAssessment} from "./ChildAssessment";
import {Component} from "./Component";

export class Assessment extends CMSObject {
	public static title = 'Assessment';
	public static autoPublish = true;
	public code: string;
	public modular: boolean;
	public name: string;
	public qualification: UUID;
	public specification_group: UUID[];
	public status: boolean;
	public subject: UUID[];
	public version: number;

	public childAssessments: ChildAssessment[] = [];
	public components: Component[] = [];

	constructor(item: any = {}) {
        super(item);

		this.code = Strings.default(item.code);
		this.modular = Boolean(item.modular);
		this.name = Strings.default(item.name);
		this.qualification = item.qualification;
		this.specification_group = Lists.default<UUID>(item.specification_group);
		this.status = Boolean(item.status);
		this.subject = Lists.default(item.subject);
		this.version = Numbers.default(item.version, 1);
    }

	public displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
		switch (options) {
			case DisplayMode.SHORT:
                return this.code;
            case DisplayMode.FULL:
                return `${this.code} - ${this.name}`;
			case DisplayMode.DETAILED:
				return `${this.code} - ${this.name} (${fi(this.modular, 'Modular', 'Linear')})`;
		}
	}
}
