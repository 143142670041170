import { columnDefinition, formConfig, tableColumn, tableConfig, tableHidden} from '../../utils/decorators';
import {Strings} from '../../utils/strings';
import {DisplayMode} from './__CMSObject';
import {ColumnType, unitsOrComponentsRenderer} from '../../components/TableComponent/renderers';
import { ProductMapped } from './__ProductMapped';
import {
    ExportRapportButtons,
    SubjectUpdatesByEmail,
} from '../../components/SubjectUpdateButtons/SubjectUpdateButtons';
import React from 'react';
import { getRecoil , setRecoil} from '../../state/recoilNexus';
import { references, subjectUpdatesEmails} from '../../state/state';
import { Messages } from '../../utils/messages';
import Client from "../client";
import {Page} from "./Page";
import {Types} from "../types";
import {Objects} from "../../utils/objects";
import {Dates} from "../../utils/dates";

@tableConfig({
    initialLoad: async () => {
        const res = await Client.query<Page>({
            types: [Types.EMAIL_UPDATES],
            limit: -1
        })
        setRecoil(subjectUpdatesEmails, res.results);
    }
})
@formConfig({
    buttons:[<ExportRapportButtons/>,<SubjectUpdatesByEmail/>]
})
export class SubjectUpdate extends ProductMapped {
    public static title = 'Subject update';
    public title: string;
    public description: string;
    public text: string;
    @tableHidden
    public label: string;
    @tableHidden
    @columnDefinition({label: 'Call to action'})
    public link: string;
    @tableHidden
    public urgent: boolean;
    public type: string;

    constructor(obj: any = {}) {
        super(obj);

        this.title = Strings.default(obj.title);
        this.description = Strings.default(obj.description);
        this.text = Strings.default(obj.text);
        this.label = Strings.default(obj.label);
        this.link = Strings.default(obj.link);
        this.urgent = Boolean(obj.urgent);
        this.type = Strings.default(obj.type, "Subject Update");
    }

    displayLabel(options: DisplayMode = DisplayMode.SHORT): string {
        return this.title;
    }

    public async canDelete(): Promise<string> {
        if (this.isPublished()) {
            return Messages.CantDeletePublishedItem;
        }
        return '';
    }


    @tableColumn({
        order: 3.5,
        label: 'Units / Components',
        field: 'units',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __unitsOrComponents(plain?: boolean): any {
        return unitsOrComponentsRenderer(this, '', plain)
    }

    @tableColumn({
        order: 3.5,
        label: 'Qualification Mapping',
        field: 'qualification',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __QualificationMapping(plain?: boolean): any {
        const labels: string[] = []
        this.qualification.forEach((id:string) => {
            const data = getRecoil(references(id))
            if (data) {
                labels.push(data.displayLabel())
            }
        })
        return labels.join(', ')
    }

    @tableColumn({
        order: 3.5,
        label: 'Subject Mapping',
        field: 'subject',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __SubjectMapping(plain?: boolean): any {
        const labels: string[] = []
        this.subject.forEach((id:string) => {
            const data = getRecoil(references(id))
            if (data) {
                labels.push(data.displayLabel())
            }
        })
        return labels.join(', ')
    }

    @tableColumn({
        order: 3.5,
        label: 'Email status',
        field: 'email_status',
        default: true,
        type: ColumnType.Reference,
        filter: {
            noFilter: true
        }
    })
    public __EmailStatus(plain?: boolean): any {
        let emailStatus = "-";
        const emailNotifications = getRecoil(subjectUpdatesEmails);
        let email = emailNotifications.filter((e:any) => e.__data.subject_update === this.__uuid);
        if (email.length === 0) {
            return emailStatus;
        }
        email = email.sort((a:any, b:any) => {
            return new Date(b.__meta.published).getTime() - new Date(a.__meta.published).getTime();
        });

        if (email[0]&&Objects.default(email[0].__meta).published) {
            const publishDate = Dates.local(email[0].__meta.published);
            if (Objects.default(email[0].__meta).unpublish) {
                emailStatus = "Sent ";
            } else {
                emailStatus = "Scheduled ";
            }
            emailStatus += publishDate;
        }
        return emailStatus;
    }
}
