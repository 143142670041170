import {LocalizedString} from '../cms/types';
import {Lists} from './lists';
import {Objects} from './objects';

export const Strings = {
	default: (value: any, def: string = ''): string => {
		switch (typeof value) {
			case 'string':
				if (!value && def) return def;
				return value;
			case 'undefined':
				return def;
			case 'number':
				return value.toString();
			default:
				if (value === null) {
					return def;
				}
				if (typeof value.toString === 'function') {
					return value.toString();
				}
				return def;
		}
	},

	en: (value: LocalizedString, def: string = ''): string => {
		return Strings.default(Objects.default(Lists.default<LocalizedString>(value).find((item: any) => item.lang === 'en')).text, def);
	},

	localizedTxt: (value: string): LocalizedString => {
		return [{lang: 'en', text: value}];
	},
	localized: (...value: string[]): LocalizedString[] => {
		return value.map((item: string): LocalizedString => Strings.localizedTxt(item));
	},
	titleCase: (value: string): string => {
		const str = Strings.startCase(value).toLowerCase();
		return str.charAt(0).toUpperCase() + str.slice(1);
	},
	startCase: (value: string): string => {
		let tmp = '';
		let nonChar = true;
		const strValue = Strings.default(value);
		for (let char of strValue) {
			if (char.toLowerCase() >= 'a' && char.toLowerCase() <= 'z') {
				if (nonChar) {
					nonChar = false;
					tmp += char.toUpperCase();
				} else {
					tmp += char.toLowerCase();
				}
			} else {
				nonChar = true;
				tmp += char;
			}
		}
		return tmp;
	},

	pad: (value: number): string => {
		return value.toString().padStart(2, '0');
	},

	isLink: (value: string): boolean => {
		return /^((https?|HTTPS?):\/\/)?(www.|WWW.)?[a-zA-Z\d]+(\.[a-zA-Z]{2,}){1,3}(#?\/?[a-zA-Z\d#]+)*\/?(\?[a-zA-Z\d-_]+=[a-zA-Z\d-%]+&?)?/.test(value);
	},

	pluralize: (value: number, singular: string, plural: string): string => {
		return `${value} ${Strings.ofPlural(value, singular, plural)}`;
	},

	ofPlural: (value: number, singular: string, plural: string): string => {
		if (value === 1) return singular;
		return plural;
	},

	capitalize: (value: string): string => {
		return value.substring(0, 1).toUpperCase() + value.substring(1).toLowerCase();
	},
};

window['Strings'] = Strings;