import styled from "@emotion/styled";
import React from "react";
import {useSnackbar} from "notistack";
import {fi} from "../../../utils/helpers";
import Loader from "../../Loader/Loader";
import GetApp from "@mui/icons-material/GetApp";

export const DownloadReport = ({label, callback}: { label: string, callback: () => Promise<any> }) => {
    const [loading, setLoading] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const download = () => {
        if (loading) return;

        setLoading(true);
        callback().catch(err => {
            enqueueSnackbar("Error downloading report: " + err.message, {variant: "error"});
        }).finally(() => {
            setLoading(false)
        })
    }
    return (
        <Row onClick={download}>
            <div>{label}</div>
            {fi(loading, <span title='Preparing....'><Loader size={20}/></span>, <span
                title='Download' data-testid={`download ${label}`}><GetApp/></span>)}
        </Row>
    )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 3px 0;
  position: relative;
  min-height: 34px;

  div {
    flex-grow: 1;
    min-width: 200px;
  }

  svg {
    height: 20px;
    width: 20px;
  }
`