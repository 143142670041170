import {atom, atomFamily, selector, selectorFamily} from 'recoil';
import {CMSObject} from '../cms/models/__CMSObject';
import {CMSFile} from "../cms/models/File";
import {Types, UUID} from "../cms/types";
import Client from "../cms/client";
import {setRecoil} from "./recoilNexus";
import {InstanceOf} from "../cms/models";
import {Page} from "../cms/models/Page";

// What element from the main menu is selected
export const selectedMenuItem = atom<any | null>({
    key: 'selectedMenuItem',
    default: null
})

// What element from the tree is selected
export const selectedTreeItem = atom<any | null>({
    key: 'selectedTreeItem',
    default: null
})

export const cmsObjectLoader = selectorFamily<CMSObject | null, UUID>({
    key: 'cmsObjectLoader',
    get: (typeId) => async ({get}) => {
        get(cacheBuster(typeId));
        const [type, id] = typeId.split('/');
        if (!id || id === 'create') {
            return InstanceOf(type)
        }
        const res = await Client.get(id)
        setRecoil(references(id), res)
        return res
    }
})

export const cmsObjectsLoader = selectorFamily<CMSObject[], string[]>({
    key: 'cmsObjectsLoader',
    get: (types) => async ({get}) => {
        get(cacheBuster(types.join(",")));
        const res = await Client.query<CMSObject>({
            limit: -1,
            types: types,
        })
        return res.results
    }
})

export const selectedObject = atom<CMSObject | null>({
    key: 'selectedObject',
    default: null
})

export const breadcrumbSelection = atom<string | null>({
    key: 'breadcrumbSelection',
    default: null
})

export const uploadedFile = atom<CMSFile | null>({
    key: 'uploadedFile',
    default: null
});

export const cacheBuster = atomFamily<number, string>({
    key: 'cacheBuster',
    default: 0
})

export const references = atomFamily<CMSObject | CMSFile | null, string>({
    key: 'references',
    default: null,
})

type rating = {
    rating: number,
    created: string,
    feedback?: string,
    full_name: string,
    item_version: number,
    job_title: string,
}

// Ratings of an item, wrapped in a selector to take advantage of the Suspense
export const ratingsSelectors = selectorFamily<rating[], string>({
    key: "ratings",
    get: (id) => async () => Client.itemRating(id),
})

// Caches all the pages in the CMS, used to display the "Referenced on page" section in the sidebar and in the table
export const pagesAtom = atom<Page[]>({
    key: "pagesAtom",
    default: [],
})
export const pagesSelector = selector({
    key: "pagesSelector",
    get: async ({get}) => {
        get(cacheBuster("pagesSelector"))
        const request = await Client.query<Page>({
            types: [Types.PAGE],
            limit: -1
        })
        return request.results
    }
})
// Returns and caches the product data tree used by the product mapping selector
export const productDataSelector = selector({
    key: "productDataSelector",
    get: async ({get}) => {
        get(cacheBuster("productDataSelector"))
        return await Client.productDataTree()
    }
})

export const dragAndDropState = atom<any>({
    key: "dragAndDropState",
    default: null,
})

// This atom holds the id of the widget being edited on the page form
// when this is not empty, most of the form elements should be disabled
export const editWidgetState = atom<string>({
    key: "editWidgetState",
    default: '',
})


// This atom holds the way the widget edit form should open in
export const createWidgetState = atom<boolean>({
    key: "createWidgetState",
    default: false,
})


export const subjectUpdatesEmails = atom<any[]>({
    key: "subjectUpdatesEmails",
    default: [],
})

export const bulkUploadScrollState = atom<boolean>({
    key: "bulkUploadScrollState",
    default: false,
})