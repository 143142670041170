import React, {useEffect} from 'react';
import {selector, useRecoilValue} from 'recoil';
import Client from '../../cms/client';
import styled from '@emotion/styled';
import {BulkFile} from '../../cms/models/BulkFile';
import ItemRow from './ItemRow';
import {cacheBuster, cmsObjectsLoader, productDataSelector} from '../../state/state';
import {cmsModelsSelector} from '../../state/models';
import {Types} from '../../cms/types';
import {setRecoil} from '../../state/recoilNexus';
import {getFormConfig} from "../../utils/decorators";
import {Lists} from '../../utils/lists';

const EmptySate = styled.td`
    padding: 64px 0;
    vertical-align: middle;
    height: 200px;

    h4 {
        font-family: var(--font-semi-bold);
        font-size: 20px;
        margin-bottom: 24px;
    }
`

const bulkUploadFilesSelector = selector<BulkFile[]>({
    key: 'bulkUploadFilesSelector',
    get: async ({get}) => {
        get(cacheBuster('bulkFilesList'))
        return Client.bulkUploadFiles()
    }
})

const BulkFiles = () => {
    // preload required data for bulk rows
    const model = useRecoilValue(cmsModelsSelector(Types.DOCUMENT))
    useRecoilValue(cmsObjectsLoader([Types.CONTENT_TYPE]))
    useRecoilValue(productDataSelector)

    const list = useRecoilValue(bulkUploadFilesSelector)

    useRecoilValue(cacheBuster(`bulk-file-all`));

    useEffect(() => {
        const config = getFormConfig(Types.DOCUMENT)
        BulkFile.model = config.model
    }, [model]);

    useEffect(() => {
        BulkFile.files = list.map(f => new BulkFile(f))
        Lists.sort(BulkFile.files, 'filename')
        setRecoil(cacheBuster(`bulk-file-all`), (val) => val + 1)
    }, [list]);


    if (BulkFile.files.length === 0) {
        return (
            <tr>
                <EmptySate colSpan={11}>
                    <h4>There are no files here</h4>
                    <div>Drag and drop files here to upload or select from your computer</div>
                </EmptySate>
            </tr>
        )
    }

    return (
        <>
            {BulkFile.files.map((item, idx) => <ItemRow key={idx} file={item}/>)}
        </>
    )
}

export default BulkFiles;